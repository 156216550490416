import { useEffect, useRef, useState } from 'react';
import useResizeObserver from 'use-resize-observer';
import * as Plot from '@observablehq/plot';
import { ChevronDown } from 'lucide-react';
// Constants
import { SIGNALS_LIST } from './model/constants/signals';
import { PLOT_CATEGORY } from './model/constants/categories';
// Types
import { ITimelineRange } from './types';
import { IPlotPayload, PlotSizeMap } from './types';
import { AvailableRoleSignalsT } from './model/types/signals';
// Utils
import { getPlotVisualisationElements } from './helpers';
import { getPlotColorRange } from './model/utils/getPlotColorRange';
// Components
import SelectableList from './ui/SelectableList';
import useClickOutside from '@hooks/useClickOutside';
import { USER_ROLES } from '@interfaces/user';
import { ValueOf } from '@utils/types';
import { PLOT_SIGNALS } from '@shared/constants/plot/signals';
import { emptyDataPlaceHolder } from './model/constants/graph';
import Switch from '@shared/ui/switch/Switch';

type ConfigurablePlotGraphProps = {
  mainYPayload: IPlotPayload | null;
  secondaryYPayload: IPlotPayload | null;
  timelineRange: ITimelineRange;
  plotType?: string;
  plotSize?: string;
  isHrFilter: boolean;
  configuredSignals: Record<ValueOf<typeof USER_ROLES>, ValueOf<typeof PLOT_SIGNALS>[]>;
  availableRoleSignals: Record<AvailableRoleSignalsT, boolean>;
  onPlotClick?: (value: number) => void;
  onSignalChange: (role: ValueOf<typeof USER_ROLES>, value: ValueOf<typeof PLOT_SIGNALS>[]) => void;
  onSwitchAvailableRoleSignalChange: (role: AvailableRoleSignalsT, value: boolean) => void;
};

export const ConfigurablePlotGraph = ({
  mainYPayload,
  secondaryYPayload,
  timelineRange,
  plotSize,
  plotType,
  isHrFilter,
  onSignalChange,
  onSwitchAvailableRoleSignalChange,
  configuredSignals,
  availableRoleSignals,
}: ConfigurablePlotGraphProps) => {
  const [isSignalPlotMenuByRoleOpen, setSignalPlotMenuByRoleIsOpen] = useState<
    Record<ValueOf<typeof USER_ROLES>, boolean>
  >({
    [USER_ROLES.PATIENT]: false,
    [USER_ROLES.THERAPIST]: false,
    [USER_ROLES.ADMIN]: false,
  });

  const patientMenuRef = useRef(null);
  const therapistMenuRef = useRef(null);
  const containerRef = useRef(null);

  const height = PlotSizeMap[plotSize as keyof typeof PlotSizeMap];
  const { data, label } = mainYPayload || {};
  const secondaryData = secondaryYPayload?.data;

  // add to trigger re-render when resize container
  const { width } = useResizeObserver({ ref: containerRef });

  const handlePlotMenuClose = (role: ValueOf<typeof USER_ROLES>) => (flag: boolean) => {
    setSignalPlotMenuByRoleIsOpen((prev) => ({ ...prev, [role]: flag }));
  };

  const handlePlotMenuOpen = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    role: ValueOf<typeof USER_ROLES>
  ) => {
    e.preventDefault();
    switch (role) {
      case USER_ROLES.THERAPIST: {
        setSignalPlotMenuByRoleIsOpen((prev) => ({ ...prev, [role]: !prev[role] }));
        handlePlotMenuClose(USER_ROLES.PATIENT)(false);
        break;
      }
      case USER_ROLES.PATIENT: {
        setSignalPlotMenuByRoleIsOpen((prev) => ({ ...prev, [role]: !prev[role] }));
        handlePlotMenuClose(USER_ROLES.THERAPIST)(false);
        break;
      }
      default: {
        setSignalPlotMenuByRoleIsOpen((prev) => ({ ...prev, [role]: !prev[role] }));
        break;
      }
    }
  };

  const handleSignalChange =
    (role: ValueOf<typeof USER_ROLES>, secondaryRole: ValueOf<typeof USER_ROLES>) =>
    (value: ValueOf<typeof PLOT_SIGNALS>[]) => {
      const secondaryConfiguredSignals = configuredSignals[secondaryRole] || [];
      // handle edge case when all item were unselected
      if (secondaryConfiguredSignals.length === 1 && value.length === 1) {
        onSignalChange?.(role, value);
        return;
      }
      // only two plots at a time for both roles
      if (secondaryConfiguredSignals.length >= 1 && value.length >= 1) {
        secondaryConfiguredSignals.shift();
        onSignalChange?.(secondaryRole, secondaryConfiguredSignals.filter(Boolean));
      }

      onSignalChange?.(role, value);
    };

  const handleOutsideClick = (flag: boolean) => {
    if (isSignalPlotMenuByRoleOpen[USER_ROLES.THERAPIST]) {
      handlePlotMenuClose(USER_ROLES.THERAPIST)(flag);
    }
    if (isSignalPlotMenuByRoleOpen[USER_ROLES.PATIENT]) {
      handlePlotMenuClose(USER_ROLES.PATIENT)(flag);
    }
  };

  const handleRoleSwitch = (checked: boolean, role: AvailableRoleSignalsT) => {
    onSwitchAvailableRoleSignalChange?.(role, checked);
  };

  useClickOutside([therapistMenuRef, patientMenuRef], handleOutsideClick);

  useEffect(() => {
    if (data === null) return;

    let primary = data;
    let secondary = secondaryData;
    if (!primary && secondary) {
      [primary, secondary] = [secondary, primary];
    }

    const { leftYaxisPlot, rightYaxisPlot, pointerLineLeftAxixData, y2, y2toy1Scale } =
      getPlotVisualisationElements(primary, secondary, plotType, height, isHrFilter, timelineRange);

    const plot = Plot.plot({
      width: (containerRef?.current as any).clientWidth,
      height,
      marginTop: 25,
      marginBottom: 25,
      marginRight: 27,
      marginLeft: 27,
      color: {
        legend: false,
        domain: [
          ...Array.from(new Set(data?.map((d) => `${d.role}-${d.key}`))),
          ...Array.from(new Set(secondaryData?.map((d) => `${d.role}-${d.key}`))),
        ],
        range: [...getPlotColorRange(data || []), ...getPlotColorRange(secondaryData || [])],
      },
      marks:
        data || secondaryData
          ? [
              ...leftYaxisPlot.flat(),
              pointerLineLeftAxixData,
              ...(secondaryYPayload && y2 && y2toy1Scale ? [rightYaxisPlot] : []),
            ]
          : [...emptyDataPlaceHolder],
      x: {
        label: 'Time',
        type: 'time',
        transform: (d) => new Date(d),
        tickFormat: '%H:%M:%S',
        axis: null,
        ...(timelineRange && {
          domain: [timelineRange.startTimestamp, timelineRange.endTimestamp],
        }),
      },
      y: {
        grid: true,
      },
    });

    (containerRef?.current as any).append(plot);

    return () => {
      plot.remove();
    };
  }, [data, secondaryData, height, label, plotType, timelineRange, width]);

  return (
    <div
      ref={containerRef}
      className="w-full mt-12"
    >
      <div className="absolute left-[2rem] top-[-2rem] flex">
        <div className="flex items-center text-sm gap-2">
          <div
            ref={therapistMenuRef}
            className="flex gap-2 items-center"
          >
            <Switch
              checked={availableRoleSignals[USER_ROLES.THERAPIST]}
              onChange={(checked) => handleRoleSwitch(checked, USER_ROLES.THERAPIST)}
              label=""
            />
            <div>
              {/* <div className="w-3 bg-purple-600 rounded-3xl h-3 mr-2"></div> */}
              <div
                onClick={(e) => handlePlotMenuOpen(e, USER_ROLES.THERAPIST)}
                className="flex cursor-pointer items-center"
              >
                <span>Therapist</span>
                <ChevronDown
                  className={`ml-2 w-5 transition-transform duration-100 ${isSignalPlotMenuByRoleOpen[USER_ROLES.THERAPIST] ? 'rotate-180' : ''}`}
                />
              </div>
              {isSignalPlotMenuByRoleOpen[USER_ROLES.THERAPIST] && (
                <div className="absolute top-5 bg-white overflow-y-auto h-[150px] w-[160px] shadow-md p-2">
                  <SelectableList
                    list={configuredSignals[USER_ROLES.THERAPIST]}
                    disabled={!availableRoleSignals[USER_ROLES.THERAPIST]}
                    onChange={(value: ValueOf<typeof PLOT_SIGNALS>[]) =>
                      handleSignalChange(USER_ROLES.THERAPIST, USER_ROLES.PATIENT)(value)
                    }
                    options={SIGNALS_LIST}
                    category={PLOT_CATEGORY.SIGNALS}
                  />
                </div>
              )}
            </div>
          </div>
          <div
            ref={patientMenuRef}
            className="flex gap-2 items-center"
          >
            <Switch
              checked={availableRoleSignals[USER_ROLES.PATIENT]}
              onChange={(checked) => handleRoleSwitch(checked, USER_ROLES.PATIENT)}
              label=""
            />
            <div>
              <div
                onClick={(e) => handlePlotMenuOpen(e, USER_ROLES.PATIENT)}
                className="flex cursor-pointer items-center"
              >
                <span>Patient</span>
                <ChevronDown
                  className={`ml-2 w-5 transition-transform duration-100 ${isSignalPlotMenuByRoleOpen[USER_ROLES.PATIENT] ? 'rotate-180' : ''}`}
                />
              </div>
              {isSignalPlotMenuByRoleOpen[USER_ROLES.PATIENT] && (
                <div className="absolute top-5 bg-white overflow-y-auto h-[150px] w-[160px] shadow-md p-2">
                  <SelectableList<ValueOf<typeof PLOT_SIGNALS>>
                    list={configuredSignals[USER_ROLES.PATIENT]}
                    disabled={!availableRoleSignals[USER_ROLES.PATIENT]}
                    onChange={(value: ValueOf<typeof PLOT_SIGNALS>[]) =>
                      handleSignalChange(USER_ROLES.PATIENT, USER_ROLES.THERAPIST)(value)
                    }
                    options={SIGNALS_LIST}
                    category={PLOT_CATEGORY.SIGNALS}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
