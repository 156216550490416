import React, {
  useEffect,
  useState,
  useRef,
  KeyboardEvent,
  WheelEvent,
  useCallback,
  useMemo,
} from 'react';
import { useSpring, animated, useTrail } from '@react-spring/web';

import TimelineTooltipContent from './TimelineTooltipContent';

import TimelineDateDisplay from './TimelineToDisplay';
import { Patient } from '@interfaces/index';
import { ClockIcon, TimelineIcon } from '@assets/icons';
import { refaelaTherapistActor } from '@components/xState/machines/refaelaTherapistMachine';
import { useDateTimeUpdater } from '@hooks/useDateTimeUpdate';
import { getPositionOnTimelineByTime } from './model/utils/getPositionOnTimelineByHour';

export interface TimelineProps {
  startHour?: number;
  endHour?: number;
  patientData: Patient;
  isTimelineOpen: boolean;
}

const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

const PatientViewTimeline: React.FC<TimelineProps> = ({
  startHour = 6,
  endHour = 24,
  patientData,
  isTimelineOpen,
}) => {
  const [tooltip, setTooltip] = useState<null | { x: number; y: number; content: JSX.Element }>(
    null
  );
  const [isTooltipHovered, setIsTooltipHovered] = useState(false);
  const [offset, setOffset] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);
  const pathRef = useRef<SVGPathElement>(null);
  const [isVisible, setIsVisible] = useState<boolean>(false);

  const { currentDateTime, currentTimelineDateTime, setCurrentTimelineDateTime } =
    useDateTimeUpdater(1);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  const handleScroll = useCallback((deltaY: number) => {
    setCurrentTimelineDateTime((prev) => {
      const newDate = new Date(prev);
      newDate.setDate(prev.getDate() + (deltaY > 0 ? 1 : -1));
      return newDate;
    });
    setOffset((prevOffset) => prevOffset + (deltaY > 0 ? 1 : -1));
  }, []);

  const handleDateChange = (event: KeyboardEvent<HTMLDivElement> | WheelEvent<HTMLDivElement>) => {
    if (event.type === 'keydown') {
      const { key } = event as KeyboardEvent<HTMLDivElement>;
      const direction = key === 'ArrowLeft' ? -1 : key === 'ArrowRight' ? 1 : 0;
      if (direction !== 0) {
        setCurrentTimelineDateTime((prev) => {
          const newDate = new Date(prev);
          newDate.setDate(prev.getDate() + direction);
          return newDate;
        });
        setOffset((prevOffset) => prevOffset + direction);
      }
    } else if (event.type === 'wheel') {
      handleScroll((event as WheelEvent<HTMLDivElement>).deltaY);
    }
  };

  const formattedTimelineDate = currentTimelineDateTime.toLocaleDateString();

  const formattedTimelineTimetoDisplay = currentTimelineDateTime.toLocaleString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  });

  const getSessionDate = (timestamp: number | string) => new Date(timestamp).toLocaleDateString();
  const getSessionTime = (timestamp: number | string) => new Date(timestamp).getTime();

  const handleDotHover = (
    event: React.MouseEvent<SVGCircleElement>,
    sessionType: string,
    sessionTimestamp: number | string,
    point: any
  ) => {
    if (!event.currentTarget.ownerSVGElement) return;
    const sessionDate = getSessionDate(sessionTimestamp);
    const sessionTime = getSessionTime(sessionTimestamp);
    const realCurrentTime = currentDateTime.getTime();
    const realCurrentDate = currentDateTime.toLocaleDateString();
    let content;

    if (
      sessionType == 'therapist' &&
      (sessionDate < realCurrentDate ||
        (sessionDate === realCurrentDate && sessionTime < realCurrentTime))
    ) {
      content = (
        <TimelineTooltipContent
          text={'Review Session'}
          onActionClick={() => refaelaTherapistActor.send({ type: 'goToSessionReplayByTherapist' })}
          bgColor={'bg-gray-100 '}
          textColor={'text-gray-800 '}
          additionalStyles={'hover:bg-gray-200'}
        />
      );
    } else if (sessionType == 'therapist') {
      content = (
        <TimelineTooltipContent
          text={'Reschedule Session'}
          onActionClick={() => refaelaTherapistActor.send({ type: 'goToTherapistCalendar' })}
          bgColor={'bg-white'}
          textColor={'text-gray-800'}
          additionalStyles={'shadow border border-gray-200 hover:bg-slate-50'}
        />
      );
    } else if (sessionDate === realCurrentDate && sessionTime <= realCurrentTime + 30 * 60 * 1000) {
      content = (
        <TimelineTooltipContent
          text={'Upcoming Session'}
          onActionClick={() =>
            refaelaTherapistActor.send({ type: 'goToTherapistPretalkWaitingRoom' })
          }
          bgColor={'bg-violet'}
          textColor={'text-white'}
          additionalStyles={'hover:brightness-80'}
        />
      );
    } else {
      content = (
        <TimelineTooltipContent
          text={'Review homework'}
          onActionClick={() => console.log('click')}
          bgColor={'bg-purple-400'}
          textColor={'text-white'}
          additionalStyles={'hover:brightness-80'}
        />
      );
    }

    setTooltip({
      x: document.body.clientWidth >= 2560 ? event.clientX : point.x,
      y: document.body.clientWidth >= 2560 ? point.y / 2 + 40 : point.y - 120,
      content,
    });
  };

  const handleTooltipBlur = () => {
    if (isTooltipHovered) return;
    setTooltip(null);
  };

  const getLineGradientOffset = useCallback(() => {
    if (currentTimelineDateTime.toLocaleDateString() !== currentDateTime.toLocaleDateString()) {
      return currentTimelineDateTime < currentDateTime ? 100 : 0;
    }

    // get minutes
    const startOfDay = startHour * 60;
    const endOfDay = endHour * 60;

    const currentHours = currentDateTime.getHours();
    const currentMinutes = currentDateTime.getMinutes();
    const currentTotalMinutes = currentHours * 60 + currentMinutes;

    const minutesSinceStartOfDay = currentTotalMinutes - startOfDay;
    const totalMinutesInScope = endOfDay - startOfDay;

    return Math.min(100, (minutesSinceStartOfDay / totalMinutesInScope) * 100);
  }, [currentDateTime, currentTimelineDateTime, startHour, endHour]);

  const generatePath = useCallback(
    (svgWidth: number) => {
      const points = 500;
      const path = [];

      for (let i = 0; i <= points; i++) {
        const x = (i / points) * svgWidth;
        const y =
          150 +
          Math.sin(((i / points) * 10 + offset) * 0.5) * 50 +
          Math.sin(((i / points) * 10 + offset) * 0.2) * 30 +
          Math.sin(((i / points) * 10 + offset) * 0.1) * 20;

        path.push(`${i === 0 ? 'M' : 'L'} ${x.toFixed(2)},${y.toFixed(2)}`);
      }

      return path.join(' ');
    },

    [offset]
  );

  const handleIconClick = async () => {
    const value = Math.abs(offset);
    for (let i = value; i >= 0; i--) {
      currentTimelineDateTime !== currentDateTime &&
        currentTimelineDateTime > currentDateTime &&
        setCurrentTimelineDateTime((prev) => {
          const newDate = new Date(prev);
          newDate.setDate(prev.getDate() - 1);
          return newDate;
        });
      if (
        currentTimelineDateTime.getDate() !== currentDateTime.getDate() &&
        currentTimelineDateTime.getDate() < currentDateTime.getDate()
      ) {
        setCurrentTimelineDateTime((prev) => {
          const newDate = new Date(prev);
          newDate.setDate(prev.getDate() + 1);
          return newDate;
        });
      }

      setOffset(i);
      await delay(50);
    }
    setCurrentTimelineDateTime(new Date());
  };

  const lineGradientOffset = useMemo(() => getLineGradientOffset(), [getLineGradientOffset]);
  const pathLength = containerRef.current?.offsetWidth || 1500;
  const [animatedDarkStyle, setAnimatedDarkStyle] = useSpring(() => ({
    strokeDashoffset: pathLength - (pathLength * lineGradientOffset) / 100,
    from: { strokeDashoffset: pathLength },
    config: { duration: 1000 },
  }));

  const [animatedBrightStyle, setAnimatedBrightStyle] = useSpring(() => ({
    strokeDashoffset: 0,
    from: { strokeDashoffset: pathLength },
    config: { duration: 1000 },
  }));

  const animatedBrightProps = useSpring({
    strokeDashoffset: 0,
    from: { strokeDashoffset: pathLength },
    config: { duration: 1000 },
  });

  const animatedDarkProps = useSpring({
    strokeDashoffset: pathLength - (pathLength * lineGradientOffset) / 100,
    from: { strokeDashoffset: pathLength },
    config: { duration: 1000 },
  });
  const animatedBrightPropsClosing = useSpring({
    strokeDashoffset: pathLength,
    from: { strokeDashoffset: 0 },
    config: { duration: 1000 },
  });

  const animatedDarkPropsClosing = useSpring({
    strokeDashoffset: pathLength,
    from: { strokeDashoffset: pathLength - (pathLength * lineGradientOffset) / 100 },
    config: { duration: 1000 },
  });

  useEffect(() => {
    if (isTimelineOpen) {
      setAnimatedDarkStyle(animatedDarkProps);
      setAnimatedBrightStyle(animatedBrightProps);
    } else {
      setAnimatedDarkStyle(animatedDarkPropsClosing);
      setAnimatedBrightStyle(animatedBrightPropsClosing);
    }
  }, [
    animatedBrightProps,
    animatedBrightPropsClosing,
    animatedDarkProps,
    animatedDarkPropsClosing,
    isTimelineOpen,
    setAnimatedBrightStyle,
    setAnimatedDarkStyle,
  ]);
  const trail = useTrail(patientData.sessions.length, {
    opacity: isTimelineOpen ? 1 : 0,
    transform: isTimelineOpen ? 'translate(0, 0)' : 'translate(-50%, -50%)',
    config: { tension: 150, friction: 80, precision: 0.15 },
    from: { opacity: 0, transform: 'translate(0, 0)' },
  });

  const sessionPositions = patientData.sessions.map((session) => {
    const point = getPositionOnTimelineByTime(
      new Date(session.timestamp),
      pathLength,
      offset,
      startHour,
      endHour
    );

    return { session, point };
  });

  const sessionElements = trail.map((animation, index) => {
    if (!pathRef.current) return null;
    const { session, point } = sessionPositions[index];
    if (getSessionDate(session.timestamp) !== formattedTimelineDate) return;
    if (!session) return null;

    const sessionDateTime = new Date(session.timestamp);
    const now = new Date();

    const isPast = sessionDateTime.getTime() < now.getTime();
    const isCurrent =
      sessionDateTime.getTime() <= now.getTime() + 30 * 60 * 1000 &&
      sessionDateTime.getTime() > now.getTime();

    const isTherapistSession = session.sessionType == 'therapist';

    const dotColor = isCurrent
      ? '#8853cf'
      : isPast
        ? '#1f2937'
        : isTherapistSession
          ? '#d1d5db'
          : '#c084fc';

    return (
      <animated.g
        key={session.sessionId}
        style={animation}
      >
        <circle
          cx={point.x}
          cy={point.y}
          r="8"
          fill={dotColor}
          onMouseEnter={(event) =>
            handleDotHover(event, session.sessionType as string, session.timestamp, point)
          }
        />
      </animated.g>
    );
  });

  const { x: calculatedTranslateX, y: calculatedTranslateY } = getPositionOnTimelineByTime(
    currentDateTime,
    pathLength,
    offset,
    startHour,
    endHour
  );

  return (
    <animated.div
      ref={containerRef}
      className={`flex flex-col justify-end transition-all duration-300 ease-in-out transform ${isVisible ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-4'}`}
      style={{ width: '100vw' }}
      onClick={handleTooltipBlur}
      tabIndex={0}
      onKeyDown={handleDateChange}
      onWheel={handleDateChange}
    >
      <div className="w-full text-gray-800">
        <svg
          className="w-full h-24"
          viewBox={`0 0 ${pathLength} 300`}
          preserveAspectRatio="none"
          shapeRendering="geometricPrecision"
        >
          <defs>
            <linearGradient id="lineGradient">
              <stop
                offset="0%"
                stopColor="#1F2937"
              />
              <stop
                offset={`${lineGradientOffset}%`}
                stopColor="#1F2937"
              />
              <stop
                offset={`${lineGradientOffset}%`}
                stopColor="#D1D5DB"
              />
              <stop
                offset="100%"
                stopColor="#D1D5DB"
              />
            </linearGradient>
          </defs>
          <animated.path
            ref={pathRef}
            d={generatePath(pathLength)}
            fill="none"
            stroke="#D1D5DB"
            strokeWidth="3"
            strokeDasharray={pathLength}
            style={animatedDarkStyle}
          />
          <animated.path
            ref={pathRef}
            d={generatePath(pathLength)}
            fill="none"
            stroke="url(#lineGradient)"
            strokeWidth="3"
            strokeDasharray={pathLength}
            style={animatedBrightStyle}
          />
          {currentTimelineDateTime.toLocaleDateString() == currentDateTime.toLocaleDateString() && (
            <g transform={`translate(${calculatedTranslateX}, ${calculatedTranslateY - 10})`}>
              <foreignObject
                width="170"
                height="50"
                x="-50"
                y="-65"
              >
                <TimelineDateDisplay
                  padding="px-[30px]"
                  icon={<ClockIcon className="w-3.5 h-3.5" />}
                  text={formattedTimelineTimetoDisplay}
                />
              </foreignObject>
            </g>
          )}
          {sessionElements}
        </svg>

        <div className="text-center mt-4">
          <TimelineDateDisplay
            icon={
              <TimelineIcon
                className="w-3.5 h-3.5"
                onClick={handleIconClick}
              />
            }
            text="Patient Timeline"
          />
        </div>
      </div>
      {tooltip && (
        <div
          style={{
            position: 'absolute',
            top: tooltip.y,
            left: tooltip.x,
            zIndex: 1000,
          }}
          onMouseEnter={() => setIsTooltipHovered(true)}
          onMouseLeave={() => {
            setIsTooltipHovered(false);
            setTooltip(null);
          }}
        >
          {tooltip.content}
        </div>
      )}
    </animated.div>
  );
};

export default PatientViewTimeline;
