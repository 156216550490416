export const SESSION_TYPES = {
  THERAPIST: 'session_with_therapist',
  AI_BIO_FEEDBACK: 'bio_feedback_session',
  AI_MEMORY_INTERVIEW: 'memory_interview',
  AI_FOLLOW_UP_CONVERSATION: 'follow_up_conversation',
} as const;

export const AI_SESSION_TYPES: string[] = [
  SESSION_TYPES.AI_BIO_FEEDBACK,
  SESSION_TYPES.AI_FOLLOW_UP_CONVERSATION,
  SESSION_TYPES.AI_MEMORY_INTERVIEW,
] as const;
