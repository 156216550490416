import { useSelector } from '@xstate/react';
import { refaelaPatientActor } from '@components/xState/machines/refaelaPatientMachine';
import { isObject } from 'lodash';

import { PATIENT_ROUTE_MAP } from './../routes/patient';

const usePatientRouting = () => {
  const snapshot = useSelector(refaelaPatientActor, (state) => state);

  const xStateRouteValue = isObject(snapshot.value)
    ? Object.entries(snapshot.value).flat().join('.')
    : snapshot.value;

  const { component, props } =
    PATIENT_ROUTE_MAP[xStateRouteValue as keyof typeof PATIENT_ROUTE_MAP] || {};

  const xStateProps = typeof props === 'function' ? props(refaelaPatientActor) : props;

  return {
    PageComponent: component || null,
    props: xStateProps,
    snapshot,
  };
};

export default usePatientRouting;
