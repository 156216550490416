export const getInitials = (name?: string): string => {
  if (!name) return '';
  const nameAndSurname: string[] = name.split(' ');

  if (nameAndSurname.length > 1) {
    return nameAndSurname
      .map((name) => name[0])
      .join('')
      .slice(0, 2);
  } else {
    return nameAndSurname[0].slice(0, 2);
  }
};
