export const THERAPIST_MACHINE_EVENTS = {
  GO_TO_THERAPIST_PRETALK_WAITING_ROOM: 'goToTherapistPretalkWaitingRoom',
  GO_TO_PATIENT_LIST: 'goToPatientList',
  GO_TO_THERAPIST_CALENDAR: 'goToTherapistCalendar',
  GO_TO_THERAPIST_NOTIFICATION: 'goToTherapistNotification',
  GO_TO_THERAPIST_SETTINGS: 'goToTherapistSettings',
  GO_TO_THERAPIST_SESSION: 'goToTherapistSession',
  GO_TO_THERAPIST_OFFICE: 'goToTherapistOffice',
  GO_TO_AI_DASHBOARD: 'goToAIDashboard',
  GO_TO_ASSIGN_AI_SESSION: 'goToAssignAISession',
  END_SESSION: 'endSession',
  GO_TO_PATIENT_VIEW: 'goToPatientView',
  GO_TO_SESSION_REPLAY_BY_THERAPIST: 'goToSessionReplayByTherapist',
  GO_TO_DAILY_MEETING: 'goToDailyMeeting',
  ADD_PATIENT_TO_THERAPIST: 'addPatientToTherapist',
  THERAPIST_USER_UPDATE: 'therapistUser.update',
  UPDATE_THERAPIST_USER: 'UPDATE_THERAPIST_USER',
  AI_VIDEO: 'AIVideo',
  AI_DASHBOARD: 'AIDashboard',
  AI_SESSION_ASSIGN: 'AssignAISession',
  GO_TO_AI_SESSION_REVIEW: 'goToAISessionReview',
} as const;
