import { apiClient } from '..';

export const fetchPatientTranscriptsData = async (
  patientId: string,
  sessionId: string,
  therapistId: string,
  setIsLoadingState?: React.Dispatch<React.SetStateAction<Record<string, any>>>
): Promise<any> => {
  setIsLoadingState?.((prev) => ({
    ...prev,
    transcripts: { isLoading: true, isFailed: false, error: null, data: null },
  }));
  try {
    const { data } = await apiClient.get(`/video_session/patient/${sessionId}/transcripts`, {
      params: {
        patientId: patientId,
        therapistId: therapistId,
        cacheBuster: new Date().getTime(),
      },
    });

    setIsLoadingState?.((prev) => ({
      ...prev,
      transcripts: { isLoading: false, isFailed: false, error: null, data: data },
    }));
  } catch (error: any) {
    setIsLoadingState?.((prev) => ({
      ...prev,
      transcripts: {
        isLoading: false,
        isFailed: true,
        error: `Transcript data is not available: ${error.message}; ${JSON.stringify(error.details)}`,
      },
    }));
  }
};
