import { BUTTON_SIZE, BUTTON_VARIANT } from './buttonSettings';

export const buttonStyles = {
  [BUTTON_VARIANT.PRIMARY]:
    'bg-purple-600 text-white hover:bg-purple-800 active:shadow-[0_0_0_4px_#E3CAF6] focus-visible:shadow-[0_0_0_4px_#E3CAF6] disabled:bg-purple-400',
  [BUTTON_VARIANT.OUTLINE]:
    'bg-white text-purple-600 border border-purple-600 hover:bg-purple-100 active:shadow-[0_0_0_4px_#E3CAF6] focus-visible:shadow-[0_0_0_4px_#E3CAF6]',
  [BUTTON_VARIANT.OUTLINE_PRIMARY]:
    'h-7 p-1.5 rounded-md border border-purple-600 text-purple-600 text-xs font-medium hover:bg-purple-50 focus:border-purple-600 active:border-purple-700 disabled:border-purple-300 disabled:text-purple-300',
  [BUTTON_VARIANT.OUTLINE_SECONDARY]:
    'h-7 p-1.5 rounded-md border border-gray-200 text-gray-500 text-xs font-medium hover:bg-gray-100 focus:bg-white active:shadow disabled:bg-gray-50',
  [BUTTON_VARIANT.PLAIN]:
    'h-7 p-1.5 rounded-md text-purple-600 text-s font-semibold hover:bg-purple-50 disabled:text-purple-300',
  [BUTTON_VARIANT.PLAIN_SECONDARY]:
    ' text-gray-900 text-s font-semibold hover:bg-gray-100 focus:bg-white active:shadow disabled:bg-gray-50',
  [BUTTON_VARIANT.ICON_PURPLE]:
    'w-10 h-10 bg-purple-600 rounded-[5px] flex items-center justify-center hover:bg-purple-800 focus:bg-purple-600 active:shadow disabled:bg-purple-400',
  [BUTTON_VARIANT.ICON_WHITE]:
    'w-10 h-10 bg-white rounded-[5px] flex items-center justify-center shadow hover:bg-gray-200 focus:bg-white active:shadow-lg disabled:bg-gray-100',
};

export const sizeStyles = {
  [BUTTON_SIZE.SMALL]: 'px-2 py-0.5 md_d:px-3 rounded-md text-xs gap-1.5 font-medium',
  [BUTTON_SIZE.MEDIUM]: 'px-3 py-1.5 md_d:px-4 md_d:py-3 rounded-lg text-sm gap-2 font-semibold',
};
