import { findPatientIdByName } from '@utils/patient';
import { PatientInfoType } from './types';
import { refaelaTherapistActor } from '@components/xState/machines/refaelaTherapistMachine';

export const getTherapistActionFromInput = ({
  patientId,
  patientInfo,
  transition,
}: {
  input: string;
  patientId?: string;
  patientInfo?: PatientInfoType;
  transition: any;
}) => {
  if (transition == 'goToPatientView' && patientId) {
    refaelaTherapistActor.send({ type: transition, patientId });
  } else if (transition === 'goToPatientView' && !patientId && patientInfo) {
    const foundPatientId = findPatientIdByName(patientInfo);
    if (foundPatientId) {
      refaelaTherapistActor.send({ type: transition, patientId: foundPatientId });
    } else {
      refaelaTherapistActor.send({ type: 'goToPatientList' });
    }
  } else {
    refaelaTherapistActor.send({ type: transition });
  }
};
