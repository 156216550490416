const InfoRow = ({
  label,
  value,
  additionalStyle,
}: {
  label: string;
  value: string | number | JSX.Element | null;
  additionalStyle?: string;
}) => (
  <div
    className={`flex items-center justify-between text-black text-[13px] font-medium font-['Inter'] leading-normal ${additionalStyle}`}
  >

    <span className="font-bold">{label}</span>

    <span>{value}</span>
  </div>
);
export default InfoRow;
