import clsx from 'clsx';
import { ReactNode } from "react";

type TableCellProps = {
  children: ReactNode;
  classes?: string;
  conditionalClasses?: Record<string, boolean>;
}

export const TableCell = ({ classes = '', conditionalClasses = {}, children }: TableCellProps) => {
    return (
      <div
        className={clsx('px-4 py-2.5 align-middle flex justify-start items-center align-center', classes, {
          ...conditionalClasses,
        })}
      >
        {children}
      </div>
    );
  };

