import { useEffect } from 'react';

function useClickOutside(refs: React.RefObject<HTMLElement>[], setter: (state: boolean) => void) {
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      const isOutside = refs.every(
        (ref) => ref.current && !ref.current.contains(event.target as Node)
      );
      if (isOutside) setter(false);
    }

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [refs, setter]);
}

export default useClickOutside;
