import styled from '@emotion/styled';

export const StyledAvatar = styled.img`
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  height: 2.8rem;
  width: 2.8rem;
`;

export const StyledTextAvatar = styled.div`
  display: flex;
  justify-content: center;
  color: #fff;
  align-items: center;
  border-radius: 50%;
  text-transform: uppercase;
`;

export const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 0;
`;
