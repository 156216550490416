import { GripHorizontal } from 'lucide-react';
import { MouseEventHandler, HTMLAttributes } from 'react';

interface ResizeHandleProps extends HTMLAttributes<HTMLDivElement> {
  onMouseDown: MouseEventHandler<HTMLDivElement>;
  iconSize?: number;
}

const ResizeHandle = ({
  onMouseDown,
  className = '',
  iconSize = 16,
  ...props
}: ResizeHandleProps) => {
  return (
    <div
      onMouseDown={onMouseDown}
      className={`w-full h-4 cursor-row-resize flex justify-center items-center ${className}`}
      {...props}
    >
      <GripHorizontal
        className="text-gray-500"
        size={iconSize}
      />
    </div>
  );
};

export default ResizeHandle;
