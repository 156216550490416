import { useEffect, useState } from 'react';
import { useDaily } from '@daily-co/daily-react';
import Session from '../Session';
import { Alert } from '../VoicebotUI/alert';

import { fetch_start_agent } from '@assets/fetch_start_agent';
import { deleteRoomById } from '@api/deleteRoomById';

type State =
  | 'idle'
  | 'configuring'
  | 'requesting_agent'
  | 'connecting'
  | 'connected'
  | 'started'
  | 'finished'
  | 'error';

interface VoicebotProps {
  setIsVoicebotRun: React.Dispatch<React.SetStateAction<boolean>>;
  setInputValue: React.Dispatch<React.SetStateAction<string>>;
  handleUserInput: any;
}

// Server URL (ensure trailing slash)
let serverUrl = import.meta.env.VITE_SERVER_URL;
const serverAuth = import.meta.env.VITE_SERVER_AUTH;
if (serverUrl && !serverUrl.endsWith('/')) serverUrl += '/';

// Mic mode
const isOpenMic = import.meta.env.VITE_OPEN_MIC ? true : false;

export const Voicebot = ({ setIsVoicebotRun, handleUserInput, setInputValue }: VoicebotProps) => {
  const daily = useDaily();
  const [roomName, setRoomName] = useState<string>('');
  const [state, setState] = useState<State>('idle');
  const [error, setError] = useState<string | null>(null);
  const [startAudioOff] = useState<boolean>(false);

  useEffect(() => {
    start();
  }, []);

  const start = async () => {
    let data;
    // Request agent to start, or join room directly
    if (import.meta.env.VITE_SERVER_URL) {
      // Request a new agent to join the room
      setState('requesting_agent');

      try {
        data = await fetch_start_agent(`${serverUrl}create_room`, serverAuth);

        if (data && !data.error) {
          setRoomName(data.result.name);
          fetch(`${serverUrl}start_bot`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${serverAuth}`,
            },
            body: JSON.stringify({
              room_url: data.result.url,
              token: data.result.token,
            }),
          }).catch((e) => {
            console.error(`Failed to make request to ${serverUrl}/main: ${e}`);
          });
        } else {
          setState('configuring');
          return;
        }
      } catch (e) {
        console.log(e);
        setState('configuring');
        return;
      }
    }

    // Join the daily session, passing through the url and token
    setState('connecting');
    console.log('state: ', state);

    try {
      await daily?.join({
        userName: 'Artsem Kavaleu',
        url: data.result.url,
        token: data.result.token || '',
        videoSource: false,
        startAudioOff: false,
      });
    } catch (e) {
      console.log('e: ', e);
      setError(`Unable to join room: '${data.result.url}'`);
      setState('error');
      return;
    }
    // Away we go...
    setState('connected');
  };

  useEffect(() => {
    return () => {
      const leave = async () => {
        deleteRoomById(roomName);
        await daily?.leave();
        await daily?.destroy();
        setIsVoicebotRun(false);
        setState('idle');
      };
      leave();
    };
  }, []);

  const leave = async () => {
    deleteRoomById(roomName);
    await daily?.leave();
    await daily?.destroy();
    setIsVoicebotRun(false);
    setState('idle');
  };

  if (state === 'error') {
    return (
      <Alert
        intent="danger"
        title="An error occurred"
      >
        {error}
      </Alert>
    );
  }

  if (state === 'requesting_agent') {
    return <div>Creating a room...</div>;
  }

  if (state === 'connecting') {
    return <div>Entering a room...</div>;
  }

  if (state === 'connected') {
    return (
      <Session
        onLeave={() => leave()}
        openMic={isOpenMic}
        startAudioOff={startAudioOff}
        handleUserInput={handleUserInput}
        setInputValue={setInputValue}
      />
    );
  }
};
