import useResizable from '@hooks/useResizable';
import { useScrollTop } from '@hooks/useScrollTop';
import { useRef } from 'react';
import { X } from 'react-feather';
import ResizeHandle from './ResizeHandler';

type ConversationModelEventsProps = {
  items: any;
};

const ConversationModelEvents = ({ items = [] }: ConversationModelEventsProps) => {
  const eventsScrollRef = useRef<HTMLDivElement>(null);
  const eventsScrollHeightRef = useRef(0);

  useScrollTop(eventsScrollRef, eventsScrollHeightRef, items);

  const { height, handleMouseDown } = useResizable(101);

  return (
    <div
      style={{ height: `${height}px` }}
      className="w-full bg-white rounded-[5px] border border-gray-300 text-[#6e6e7f] relative flex-grow pt-1 leading-[1.2em]"
      data-conversation-content
    >
      <div
        ref={eventsScrollRef}
        className="p-2 overflow-x-hidden overflow-y-auto h-full"
      >
        {!items.length && `Click 'Connect' to establish a connection...`}
        {items.map((conversationItem: any) => {
          return (
            <div
              className="conversation-item"
              key={conversationItem.id}
            >
              <div className={`speaker ${conversationItem.role || ''}`}>
                <div>
                  {((conversationItem.role as string) || (conversationItem.type as string)).replace(
                    /_/g,
                    ' '
                  )}
                </div>
                <div
                  className="close"
                  onClick={
                    () => {} //deleteConversationItem(conversationItem.id)
                  }
                >
                  <X />
                </div>
              </div>
              <div className={`speaker-content`}>
                {/* tool response */}
                {conversationItem.type === 'function_call_output' && (
                  <div>{conversationItem.formatted.output}</div>
                )}
                {/* tool call */}
                {!!conversationItem.formatted.tool && (
                  <div>
                    {conversationItem.formatted.tool.name}(
                    {conversationItem.formatted.tool.arguments})
                  </div>
                )}
                {!conversationItem.formatted.tool && conversationItem.role === 'user' && (
                  <div>
                    {conversationItem.formatted.transcript ||
                      (conversationItem.formatted.audio?.length
                        ? '(awaiting transcript)'
                        : conversationItem.formatted.text || '(item sent)')}
                  </div>
                )}
                {!conversationItem.formatted.tool && conversationItem.role === 'assistant' && (
                  <div>
                    {conversationItem.formatted.transcript ||
                      conversationItem.formatted.text ||
                      '(truncated)'}
                  </div>
                )}
                {conversationItem.formatted.file && (
                  <audio
                    src={conversationItem.formatted.file.url}
                    controls
                  />
                )}
              </div>
            </div>
          );
        })}
      </div>
      <ResizeHandle
        className="sticky bottom-0"
        onMouseDown={handleMouseDown}
      />
    </div>
  );
};

export default ConversationModelEvents;
