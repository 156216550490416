import React, { useState } from 'react';
import { useSelector } from '@xstate/react';
import { useParticipantIds } from '@daily-co/daily-react';
import { MicIcon } from '../../assets/icons';

import { processTherapistInput } from '@utils/llm';
import { patientList } from '@mockData/patientMockData';
import { Voicebot } from '../Voicebot/Voicebot';
import { Loader } from '@shared/ui/loader/Loader.tsx';
import { InputProps } from './types.ts';
import { refaelaTherapistActor } from '@components/xState/machines/refaelaTherapistMachine.ts';
import { ShamefulAny } from '@interfaces/index.ts';

const Input: React.FC<InputProps> = ({
  placeholder = 'Type something...',
  className = 'w-[400px]',
  disableMic,
}) => {
  const [inputValue, setInputValue] = useState('');
  const snapshot = useSelector(refaelaTherapistActor, (state) => state);
  const [isVoicebotRun, setIsVoicebotRun] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const participants = useParticipantIds();

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const simplifiedStates = Object.values(snapshot.machine.states).map((state) => ({
    key: state.key,
    transitions: [...state.transitions].map((key) => ({
      transition: key[0],
    })),
  }));

  const handleUserInput = async (input: string) => {
    setIsLoading(true);
    const machineStates = JSON.stringify(simplifiedStates);
    const currentState = snapshot.value;
    await processTherapistInput(machineStates, currentState as ShamefulAny, input, patientList);
    setIsLoading(false);
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      handleUserInput(inputValue);
    }
  };

  return (
    <div
      className={`flex ${isLoading || isVoicebotRun ? 'cursor-not-allowed bg-slate-50' : ''}     bg-white
    rounded-br8`}
    >
      <div
        className={`${className}
          flex gap-2 px-2 items-center justify-between rounded-br8 shadow-md focus-within:transition-all duration-500
          focus-within:ring-1 focus-within:ring-blue-600
          focus-within:outline-none focus-within:outline-4 focus-within:outline-blue-500/[.25] focus-within:outline-offset-0
          ${isLoading ? 'cursor-not-allowed bg-slate-50' : ''}
        `}
      >
        <input
          type="text"
          value={inputValue}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          className={`h-[2.1rem] md_d:h-[2.8rem] border-none focus:outline-none focus:ring-0 focus:border-none truncate disabled:text-slate-500 ${isLoading || isVoicebotRun ? 'cursor-not-allowed bg-slate-50' : ''}`}
          placeholder={placeholder}
          style={{ width: 'calc(100% - 24px)' }}
          disabled={isLoading || isVoicebotRun}
        />
        {isLoading ? (
          <Loader />
        ) : (
          !isVoicebotRun &&
          !disableMic && (
            <MicIcon
              style={{ marginLeft: 'auto', cursor: 'pointer' }}
              onClick={() => setIsVoicebotRun(true)}
            />
          )
        )}
        {isVoicebotRun && (
          <div
            style={
              participants.length > 1
                ? { width: '20px', height: '20px', marginRight: '5px' }
                : { width: '220px' }
            }
          >
            <Voicebot
              setIsVoicebotRun={setIsVoicebotRun}
              setInputValue={setInputValue}
              handleUserInput={handleUserInput}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Input;
