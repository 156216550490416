import React, { useEffect, useState } from 'react';
import { MonthMini, MonthFull } from '@components/CalendarComponents';
import { CalendarProps } from './types';

export const Calendar: React.FC<CalendarProps> = () => {
  const [isVisible, setIsVisible] = useState<boolean>(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  return (
    <div
      className={`max-w-[1296px] w-full max-h-[712px] h-full overflow-auto  py-5 px-4 bg-white rounded-2xl flex justify-center items-start gap-8 transition-all duration-300 ease-in-out transform ${isVisible ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-4'} mt-10`}
    >
      <div className="flex h-full w-full gap-4">
        <div className="flex flex-col h-full max-h-full py-2">
          <MonthMini />
          <div className="border-b border-gray-200 dark:border-neutral-700"></div>
        </div>
        <div id="content" className="flex-grow transition-all duration-300">
          <MonthFull />
        </div>
      </div>
    </div>
  );
};
