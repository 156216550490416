import { RealtimeEventHandler } from '@openai/realtime-api-beta/dist/lib/event_handler';
import { memo, useEffect, useState } from 'react';
import { askChatGPT } from '@api/userAPI';
import ToggleableTabs from '@features/Tab/ToggleableTabs/ToggleableTabs';
import { TOGGLEABLE_SECTIONS } from '../model/constants/togglableSections';
import { useSelector } from '@xstate/react';

import { dashboardActor } from '../model/xstate/dashboardMachine';
import { refaelaPatientActor } from '@components/xState/machines/refaelaPatientMachine';
import { refaelaTherapistActor } from '@components/xState/machines/refaelaTherapistMachine';
import { isUserTherapist } from '@utils/helpers';
import { Patient } from '@interfaces/patient';

type SuggestionDataProps = {
  sessionId: string;
  realtime: RealtimeEventHandler;
  onSectionToggleByKey: (key: string) => void;
  actor: typeof refaelaPatientActor | typeof refaelaTherapistActor;
};

const ConversationAnalysisData = ({
  sessionId,
  actor,
  realtime,
  onSectionToggleByKey,
}: SuggestionDataProps) => {
  const [activeTopic, setActiveTopic] = useState<string>('');
  const [conversation, setConversation] = useState<string[]>([]);

  const sections = useSelector(dashboardActor, (state) => state.context.sections);
  const currentUser = useSelector(actor, (state) => state.context.currentUser)!;

  const userId = isUserTherapist(currentUser.role!)
    ? currentUser.therapistId!
    : (currentUser as Patient).patientId;

  useEffect(() => {
    const sessionCreateHandler = async () => {
      while (true) {
        const transcript_evt: any = await realtime.waitForNext(
          'server.conversation.item.input_audio_transcription.completed'
        );

        setConversation((prev) => [...prev, transcript_evt.transcript]);
      }
    };
    realtime.on('server.session.created', sessionCreateHandler);
    return () => {
      try {
        realtime.off('server.session.created', sessionCreateHandler);
      } catch (e) {
        console.log('error removing event listener: ', e);
      }
    };
  }, [realtime, realtime.eventHandlers]);

  useEffect(() => {
    const askAI = async () => {
      const { result } = await askChatGPT(
        userId,
        sessionId,
        conversation,
        'conversation-analysis-ai-agent'
      );

      setActiveTopic((prev) => result?.activeTopic || prev);
    };

    conversation.length && askAI();
  }, [conversation]);

  const handleSectionToggle = (key: string) => {
    onSectionToggleByKey(key);
  };

  return (
    <div className="mb-4 space-y-4">
      <div className="flex flex-col gap-4 w-full">
        <ToggleableTabs
          isShown={sections[TOGGLEABLE_SECTIONS.CONVERSATION_ANALYSIS]}
          onToggle={() => handleSectionToggle(TOGGLEABLE_SECTIONS.CONVERSATION_ANALYSIS)}
          tabs={[
            {
              label: 'Conversation analysis',
              content: (
                <div className="flex gap-16">
                  <div>
                    <p className="font-bold">Active Topic:</p>
                    <p>{activeTopic}</p>
                  </div>
                  <div>
                    <p className="font-bold">Target Topic:</p>
                    <p>Breathing exercise</p>
                  </div>
                </div>
              ),
            },
          ]}
        />
      </div>
    </div>
  );
};

export default memo(ConversationAnalysisData);
