import React from 'react';
import { TimelineToDisplayProps } from './types.ts';

const TimelineDateDisplay: React.FC<TimelineToDisplayProps> = ({ icon, text, padding }) => {
  return (
    <div
      className={`${padding ? padding : 'px-[4.5rem]'} rounded-lg items-center gap-2.5 flex transition-all duration-500`}
    >
      <div className="flex items-center h-full">{icon}</div>
      <hr className="border h-[1.125rem] border-secondary1" />
      <span className="text-center text-gray-800 text-[0.9rem] font-semibold font-['Inter'] tracking-tight text-nowrap">
        {text}
      </span>
    </div>
  );
};
export default TimelineDateDisplay;
