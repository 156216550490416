import { apiClient } from '@api/index';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { NewPatientMetadataType } from '@shared/types/patient';

export const updatePatientList = async (payload: NewPatientMetadataType) => {
  try {
    const response = await apiClient.patch('/patient/updatePatientsList', payload);

    return response;
  } catch (error) {
    throw error;
  } finally {
    // show toast
  }
};
