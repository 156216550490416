import { useSelector } from '@xstate/react';
import { PatientHighlight } from '../styleHelpers';
import { refaelaTherapistActor } from '@components/xState/machines/refaelaTherapistMachine';
import TextAvatar from '@features/Avatar';
import { findPatientById } from '@utils/patient';
import { getLastSession } from '@utils/sessions';
import InfoCard from '@features/Card/InfoCard';

const PatientCard: React.FC = () => {
  const {
    currentPatientName,
    currentPatientSurname,
    currentPatientEmail,
    currentPatientImage,
    currentUser,
    currentPatientId,
  } = useSelector(refaelaTherapistActor, (snapshot) => snapshot.context);

  const handleReviewLastSessionClick = () => {
    if (!currentUser?.patientList || !currentPatientId) return;
    const currentPatient = findPatientById(currentUser?.patientList, currentPatientId);

    const lastSession = getLastSession(currentPatient?.sessions!);

    refaelaTherapistActor.send({
      type: 'goToSessionReplayByTherapist',
      patientId: currentPatientId,
      sessionId: lastSession?.sessionId,
    });
  };

  return (
    <div className="w-full">
      <h1 className="text-5xl mt-10 text-left font-bold">
        {currentPatientName ? currentPatientName : 'Dana'}{' '}
        {currentPatientSurname ? currentPatientSurname : 'Weiss'}
      </h1>
      <div className="flex gap-4">
        <PatientHighlight>Recurring topics in the last 4 sessions</PatientHighlight>
        <PatientHighlight>Topics with elevated heart rate</PatientHighlight>
        <PatientHighlight>Topics appearing in homework but not in sessions</PatientHighlight>
      </div>
      <div className="flex mt-6 gap-x-6">
        <InfoCard
          customTitle={
            <div className="flex w-full mb-4 gap-4">
              <div className="flex truncate">
                <TextAvatar
                  alt="avatar"
                  img={currentPatientImage}
                >{`${currentPatientName} ${currentPatientSurname}`}</TextAvatar>
                <div className="flex justify-center flex-col overflow-hidden ml-4">
                  <span className="truncate text-2xl font-semibold">
                    {currentPatientName} {currentPatientSurname}
                  </span>
                  <span className="truncate">{currentPatientEmail}</span>
                </div>
              </div>
            </div>
          }
        >
          <div className="flex flex-col mt-4 gap-6">
            <div className="w-full flex flex-col text-left gap-3">
              <p className="text-text-subtext text-sm">Main Therapy Goals</p>
              <p className="text-base">Anxiety, sadness, depression</p>
            </div>
            <div className="w-full flex flex-col text-left gap-3">
              <p className="text-text-subtext text-sm">Last topics discussed</p>
              <p className="text-base">Issues with mother, nightmares, overeating</p>
            </div>
          </div>
        </InfoCard>
        <InfoCard
          title="Homework"
          buttonLabel="Review all homework"
        >
          <div className="w-full flex flex-col text-left gap-3">
            <p className="w-full">Bio feedback</p>
            <p className="w-full">Dana Completed Bio-feedback session on Aug 1</p>
          </div>
        </InfoCard>
        <InfoCard
          title="Last session"
          buttonLabel="Review last session"
          cardClasses="bg-purple-600 text-white"
          onCardButtonClick={handleReviewLastSessionClick}
        >
          {/* <div className="w-full h-full mt-6 flex flex-col">
            <div
              className="flex gap-2 mt-auto"
              onClick={handleReviewLastSessionClick}
            >
              <p className="w-full text-right cursor-pointer">Review last session</p>
              <ArrowRightIcon color="#FFF" />
            </div>
          </div> */}
        </InfoCard>
      </div>
    </div>
  );
};

export default PatientCard;
