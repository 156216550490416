import { memo, useEffect, useState } from 'react';

import { askChatGPT } from '@api/userAPI';
import ToggleableTabs from '@features/Tab/ToggleableTabs/ToggleableTabs';
import { dashboardActor } from '../model/xstate/dashboardMachine';
import { RealtimeEventHandler } from '@openai/realtime-api-beta/dist/lib/event_handler';
import { useSelector } from '@xstate/react';
import { TOGGLEABLE_SECTIONS } from '../model/constants/togglableSections';
import { refaelaPatientActor } from '@components/xState/machines/refaelaPatientMachine';
import { refaelaTherapistActor } from '@components/xState/machines/refaelaTherapistMachine';
import { isUserTherapist } from '@utils/helpers';
import { Patient } from '@interfaces/patient';

type AgentProps = {
  sessionId: string;
  actor: typeof refaelaPatientActor | typeof refaelaTherapistActor;
  realtime: RealtimeEventHandler;
  onSectionToggleByKey: (key: string) => void;
};

type PhraseType = {
  words: number;
  length: number;
  text?: string;
};

export function TopicDetectionAgent({
  sessionId,
  actor,
  realtime,
  onSectionToggleByKey,
}: AgentProps) {
  const [phrases, setPhrases] = useState<Array<PhraseType>>([]);
  const [speechCharacteristics, setSpeechCharacteristics] = useState({
    language: 'Waiting for data...',
    gender: 'Waiting for data...',
    tone: 'Waiting for data...',
  });

  const sections = useSelector(dashboardActor, (state) => state.context.sections);
  const currentUser = useSelector(actor, (state) => state.context.currentUser)!;

  const userId = isUserTherapist(currentUser.role!)
    ? currentUser.therapistId!
    : (currentUser as Patient).patientId;

  useEffect(() => {
    const sessionCreateHandler = async () => {
      while (true) {
        const speech_start = await realtime.waitForNext('server.input_audio_buffer.speech_started');
        const speech_stop = await realtime.waitForNext('server.input_audio_buffer.speech_stopped');
        const transcript_evt: any = await realtime.waitForNext(
          'server.conversation.item.input_audio_transcription.completed'
        );

        const { result } = await askChatGPT(
          userId,
          sessionId,
          transcript_evt.transcript,
          'speech-characteristics-ai-agent'
        );

        setSpeechCharacteristics((prev) => ({
          language: result?.language || prev.language,
          gender: result?.gender || prev.gender,
          tone: result?.tone || prev.tone,
        }));

        setPhrases((phrases) => [
          ...phrases,
          {
            words: transcript_evt.transcript.split(/\s+/).length,
            length: speech_stop?.audio_end_ms - speech_start?.audio_start_ms,
            text: transcript_evt.transcript,
          },
        ]);
      }
    };
    realtime.on('server.session.created', sessionCreateHandler);
    return () => {
      try {
        realtime.off('server.session.created', sessionCreateHandler);
      } catch (e) {
        console.error('error removing event listener: ', e);
      }
    };
  }, [realtime, realtime.eventHandlers]);

  const handleSectionToggle = (key: string) => {
    onSectionToggleByKey(key);
  };

  return (
    <ToggleableTabs
      isShown={sections[TOGGLEABLE_SECTIONS.SPEECH_CHARACTERISTICS]}
      onToggle={() => handleSectionToggle(TOGGLEABLE_SECTIONS.SPEECH_CHARACTERISTICS)}
      tabs={[
        {
          label: 'Speech characteristics',
          content: (
            <div className="my-4">
              {phrases.slice(-1).map((p, index) => (
                <div
                  key={index}
                  className="flex gap-4"
                >
                  <div>
                    <p className="font-bold">Language:</p>
                    <p className="font-bold">Gender:</p>
                    <p className="font-bold">Tone:</p>
                    <p className="font-bold">Speed:</p>
                  </div>
                  <div>
                    <p>{speechCharacteristics.language}</p>
                    <p>{speechCharacteristics.gender}</p>
                    <p>{speechCharacteristics.tone}</p>
                    <p>{((p.words / p.length) * 1000).toFixed(2)} wpm</p>
                  </div>
                </div>
              ))}
            </div>
          ),
        },
      ]}
    />
  );
}

export default memo(TopicDetectionAgent);
