export const PATIENT_TILE_HEIGHT = '75%';
export const ASIDE_TILE_HEIGHT = {
  XS: '50px',
  S: '70px',
  M: '90px',
  L: '122px',
  XL: '10%',
};
export const ASIDE_TILE_WIDTH = {
  XS: '100px',
  S: '121px',
  M: '170px',
  L: '255px',
  XL: '10%',
};
