import { PropsWithChildren } from 'react';

interface NotificationBarProps extends PropsWithChildren {}

const NotificationBar = ({ children }: NotificationBarProps) => {
  return (
    <div className="flex w-fit items-center">
      <div className="flex items-center gap-4">{children}</div>
    </div>
  );
};

export default NotificationBar;
