// Constants
import { BreadcrumbItems } from '../constants/breadcrumbDict';
// Types
import { BreadcrumbItem } from '@shared/types/breadcrumbs/breadcrumb';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getActiveBreadcrumbFromState = (snapshot: any): BreadcrumbItem => {
  const {
    therapistOffice,
    patientList,
    therapistCalendar,
    therapistSettings,
    therapistNotification,
    patientView,
    therapistPretalkWaitingRoom,
    therapistDebriefWaitingRoom,
  } = BreadcrumbItems;

  switch (true) {
    case snapshot.matches(therapistOffice.value):
      return therapistOffice;

    case snapshot.matches(patientList.value):
      return patientList;

    case snapshot.matches(therapistCalendar.value):
      return therapistCalendar;

    case snapshot.matches(therapistSettings.value):
      return therapistSettings;

    case snapshot.matches(therapistNotification.value):
      return therapistNotification;

    case snapshot.matches(patientView.value):
      return patientView;

    case snapshot.matches(therapistPretalkWaitingRoom.value):
      return therapistPretalkWaitingRoom;

    case snapshot.matches(therapistDebriefWaitingRoom.value):
      return therapistDebriefWaitingRoom;

    default:
      return therapistOffice;
  }
};
