import { useEffect, useState, MutableRefObject } from 'react';

type Size = { width: number; height: number };

const useResizeObserver = <T extends HTMLElement>(ref: MutableRefObject<T | null>): Size => {
  const [size, setSize] = useState({ width: 0, height: 0 });

  useEffect(() => {
    if (!ref.current) return;

    const resizeObserver = new ResizeObserver((entries) => {
      const { width, height } = entries[0].contentRect;
      setSize({ width, height });
    });

    resizeObserver.observe(ref.current);

    return () => {
      if (ref.current) {
        resizeObserver.unobserve(ref.current);
      }
    };
  }, [ref]);

  return size;
};

export default useResizeObserver;
