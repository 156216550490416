import styled from '@emotion/styled';
import { keyframes, css } from '@emotion/react';

const isRTL = import.meta.env.VF_DM_API_KEY === import.meta.env.VF_DM_PTSD_API_KEY;

export const breakpoints = {
  mobile: `(max-width: 520px)`,
  tablet: `(max-width: 768px)`,
  desktop: `(max-width: 1024px)`,
};

export const GlobalStyle = css`
body {
  box-sizing: border-box;
}
  @media ${breakpoints.tablet}{
     body {
      font-family: var(--fonts-default);
      font-weight: 400;
      line-height: 22px;
      font-size: 16px;
      color: #777;
    }
  }
  @media (min-width: 1025px){
    body {
        font-family: var(--fonts-default);
        font-weight: 400;
        line-height: 28px;
        font-size: 20px;
        color: #777;
      }
  }
`;

const fadeInUp = keyframes`
  from {
    transform: translate3d(0, 20px, 0);
    opacity: 0;
  }

  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
`;

// direction: rtl;
// direction: ${(props) => (props.isRTL ? 'rtl' : 'ltr')};

export const AppContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  color: #333;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url(background.jpeg);
    background-size: cover;
    background-repeat: no-repeat;
    transform: ${isRTL ? 'scaleX(-1)' : 'none'};
    z-index: -1; // Ensure it stays behind the content
  }
`;
// background-image: url(background.jpeg);
// background-size: cover;
// background-repeat: no-repeat;

export const TopLogo = styled.a`
  position: fixed;
  z-index: 100;
  top: 30px;
  left: 30px;
`;

export const TopRight = styled.a`
  position: fixed;
  z-index: 100;
  top: 30px;
  right: 30px;
`;

export const ComponentContainer = styled.div`
  position: relative;
`;

export const DisclaimerContainer = styled.div`
  z-index: 1;
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  padding: 2%;
  font-size: 0.75rem;
  color: #777;
  @media ${breakpoints.mobile} {
    font-size: 12px;
  }
`;

export const MainContainer = styled.main`
  position: relative;
  height: 100%;
`;

export const BoxContainer = styled.div`
  direction: ltr;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 8rem 3.75rem 8rem 8rem; // Top, Right, left, Bottom
  max-width: 65vw;
  margin: 0 auto;
  animation: ${fadeInUp} 1s ease-out;

  .welcom-message-block {
    font-family: 'Varela Round', sans-serif;
    font-size: 18px;
    text-align: right;
    line-height: 26px;
    color: #333;
    direction: rtl;
  }
  @media (max-width: 1024px) {
    padding: 6rem 3rem;
  }

  @media (max-width: 768px) {
    padding: 6rem 1.5rem;
    .welcom-message-block {
      font-size: 16px;
      line-height: 22px;
    }
  }

  @media (max-width: 480px) {
    padding: 6rem 1rem;
    .welcom-message-block {
      font-size: 14px;
      line-height: 18px;
    }
  }
  @media (max-height: 740px) {
    padding: 3rem 3rem;
    .welcom-message-block {
      margin-top: 6rem;
    }
  }
  @media (max-height: 700px) {
    padding: 2rem 3rem;
  }
  @media (max-height: 670px) {
    padding: 7px 3rem;
  }
`;

export const AuthButton = styled.button`
  display: inline-block;
  padding: 0.5rem 1rem;
  margin: 0.5rem 0;
  border-radius: 0.25rem;
  background: transparent;
  // color: #777;
  color: #007bff; 
  border: none;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover &:focus {
    background: #fff;
    color: red; 
    text-decoration: underline;
  }
    box-shadow: rgba(0, 0, 0, 0.08) 0px 16px 32px;
  }
    &:active {
    color: #004085; 
  }
`;
export const ButtonLink = styled.a`
  display: inline-block;
  padding: 0.5rem 1rem;
  margin: 0.5rem 0;
  border-radius: 0.25rem;
  background: transparent;
  color: #007bff;
  border: 2px solid transparent; // Transparent border
  box-shadow: rgba(0, 0, 0, 0.08) 0px 16px 32px;
  text-decoration: none;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-wrap: nowrap;
  &:hover,
  &:focus {
    background-color: #0056b3; // Darker shade for hover
  }
`;
export const TopBlock = styled.div`
  direction: rtl;
  z-index: 1;
  width: -webkit-fill-available;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  padding: 1rem 1rem 0;
  align-items: center;
  .settings-icon-container {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    top: unset;
    right: unset;
    color: #777;
  }
`;
export const LogoWrapper = styled.a`
  min-width: 60px;
  max-width: 120px;
  width: 10vw;
  height: 100%;
  .logo {
    width: 100%;
  }
  @media ${breakpoints.tablet} {
    max-width: ;
  }
`;

export const TopSettingsBlock = styled.div`
  direction: ltr;
  display: flex;
  gap: 20px;
`;
export const TooLongSessionBlock = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;
export const DefaultButton = styled.button`
  text-wrap: nowrap;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 16px 32px;
  display: inline-block;
  padding: 0.5rem;
  border-radius: 0.7rem;
  background: transparent;
  color: #333;
  border: none;
  font-size: 1rem;

  &:focus,
  &:active,
  &:hover,
  &:focus-visible {
    outline: none;
    border: none;
  }
  &:hover {
    background: white;
    color: #777;
  }
`;

export const TooltipWrapper = styled.span`
  display: flex;
  align-items: center;

  .tooltiptext {
    width: 180px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    font-size: 16px;
    line-height: 16px;

    opacity: 0;
    transition: opacity 0.3s;
  }

  .tooltiptext::after {
    content: '';
    position: absolute;

    border-width: 5px;
    border-style: solid;
  }

  .top {
    bottom: 115%;
  }
  .top::after {
    top: 100%;
    left: 50%;
    border-color: #555 transparent transparent transparent;
  }

  .bottom {
    top: 60%;
  }
  .bottom::after {
    bottom: 100%;
    left: 50%;
    border-color: transparent transparent #555 transparent;
  }

  .left {
    right: 105%;
  }
  .left::after {
    transform: rotate(90deg);
    left: 100%;
    top: 40%;
    border-color: transparent transparent #555 transparent;
  }

  .right {
    margin-left: 2.5rem;
  }
  .right::after {
    transform: rotate(-90deg);
    left: -10px;
    bottom: 35%;
    border-color: transparent transparent #555 transparent;
  }

  &:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }
`;
