import { MoveLeft } from 'lucide-react';
import { TERMS } from './constants/terms';

type TermsOfUseProps = {
  actor: any;
  withReturnButton?: boolean;
};

const TermsOfUse = ({ actor, withReturnButton = false }: TermsOfUseProps) => {
  const handleBackButton = () => {
    actor.send({ type: 'goBack' });
  };

  return (
    <div className="flex max-w-[1160px] w-11/12">
      {withReturnButton && (
        <div
          onClick={handleBackButton}
          className="flex mt-[44px] sticky top-0 h-8 cursor-pointer pr-6"
        >
          <div className="flex items-center text-sm gap-x-4 sm_d:text-base">
            <MoveLeft />
            Back
          </div>
        </div>
      )}
      <div className="flex flex-col gap-8 items-start font-['Inter'] tracking-tight self-start text-gray-800">
        <div className="text-4xl font-bold mt-[44px]">Terms of Use</div>
        <div className="flex flex-col gap-6 w-full text-start mb-[88px]">
          {TERMS.map((term, index) => (
            <div key={index}>
              <div className="text-lg font-bold">{term.blockTitle}</div>
              <div className="mt-2">
                {term.blockContent.map((content, contentIndex) => (
                  <p
                    key={contentIndex}
                    className="mb-4 text-sm font-normal"
                  >
                    {content.includes('<strong>') ? (
                      <span dangerouslySetInnerHTML={{ __html: content }} />
                    ) : (
                      content
                    )}
                  </p>
                ))}
              </div>
              {term.blockList && term.blockList.length > 0 && (
                <ul className="list-disc ml-6 text-sm font-normal">
                  {term.blockList.map((item, itemIndex) => (
                    <li
                      key={itemIndex}
                      className="mb-2"
                    >
                      {item}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TermsOfUse;
