import React, { useEffect, useState, useRef, useContext, useCallback } from 'react';
import { useSelector } from '@xstate/react';

import { refaelaTherapistActor } from '@components/xState/machines/refaelaTherapistMachine';
import { AppContext } from '../../contextApp';
// API
import { getUpdatedKeyMoments, saveUpdatedKeyMoments } from '@api/userAPI';
import { fetchPatientRecordingData } from '@api/session/recordingData';
import { fetchPatientTranscriptsData } from '@api/session/transcriptsData';
// Types
import { ITimelineRange, PlotSizeEnum } from '@components/Plot/types';
import { ProcessedMXLabsData } from '@shared/types/mxlabs/logs';
import { KeyMomentsData } from '@components/SessionComponents/KeyMoments/types';
// Utils
import { formatTime } from '@utils/keyMoment';
import { trimByVideoDuration } from '@utils/HRGraph/trimByVideoDuration';
import { useMXLabsLogs } from '@components/Plot/utils';
// Components

import Plot from '@components/Plot';
import { Loader } from '@shared/ui/loader/Loader';
import TherapistWrapUpStepper from '@modules/Therapist/SessionWrapup/TherapistWrapUpStepper';
import { getFrames } from '@api/frame';
import { ShamefulAny } from '@interfaces/index';
import { USER_ROLES } from '@interfaces/user';
import { PageTitle } from '@shared/ui/caption/PageTitle';

//TODO: delete all commented functionality if there's no usecases for it
const SessionReviewByTherapist: React.FC = () => {
  const stateContext = useSelector(refaelaTherapistActor, (state) => state.context);

  const sessionId = stateContext.currentSessionId!;
  const patientId = stateContext.currentPatientId!;
  // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain, @typescript-eslint/no-extra-non-null-assertion
  const therapistId = stateContext.currentUser?.therapistId!!;

  const [keyMomentsData, setKeyMomentsData] = useState<KeyMomentsData | null>(null);
  const [error, setError] = useState<string | null>(null);

  const [videoDuration, setVideoDuration] = useState<number | null>(null);
  const [currentTime, setCurrentTime] = useState<number>(0);
  const [trimmedMxLabs, setTrimmedMxLabs] = useState<Record<string, ProcessedMXLabsData[]> | null>(
    null
  );

  const [sessionDuration, setSessionDuration] = useState<number | null>(null); // for case when there is not video only frames
  const [loadingState, setIsLoadingState] = useState<Record<string, ShamefulAny>>({
    general: { isLoading: false, isFailed: false, error: null, info: null, data: null },
    recording: { isLoading: false, isFailed: false, error: null, info: null, data: null },
    transcripts: { isLoading: false, isFailed: false, error: null, info: null, data: null },
    frames: { isLoading: false, isFailed: false, error: null, data: null },
  });

  const videoRef = useRef<HTMLVideoElement>(null);
  const [recordingTimeFrame, setRecordingTimeFrame] = useState<{
    recordingStartTime: number | undefined;
    recordingStopTime: number | undefined;
  }>({
    recordingStartTime: undefined,
    recordingStopTime: undefined,
  });
  const [updatedKeyMoments, setUpdatedKeymoments] = useState<KeyMomentsData | null>(null);

  const { step, setStep } = useContext(AppContext);

  const {
    general: generalState,
    recording: recordingState,
    transcripts: transcriptsState,
    frames: framesState,
  } = loadingState;

  useEffect(() => {
    setStep(0);
  }, []);

  useEffect(() => {
    const { recordingStartTime, recordingStopTime } = recordingTimeFrame;
    if (!recordingStopTime || !recordingStartTime) return;

    setSessionDuration(recordingStopTime - recordingStartTime);
  }, [recordingTimeFrame]);

  useEffect(() => {
    if (videoRef.current && videoRef.current.duration) {
      setVideoDuration(videoRef.current.duration * 1000);
    } else if (sessionDuration) {
      setVideoDuration(sessionDuration);
    }
  }, [sessionDuration, videoRef.current?.duration]);

  useEffect(() => {
    if (!keyMomentsData) return;
    const { recordingStartTime, recordingStopTime } = keyMomentsData;

    setRecordingTimeFrame({
      recordingStartTime: new Date(recordingStartTime).getTime(),
      recordingStopTime: new Date(recordingStopTime).getTime(),
    });
  }, [updatedKeyMoments]);

  const fetchFrames = useCallback(async () => {
    setIsLoadingState((prev) => ({
      ...prev,
      frames: { ...prev.frames, isLoading: true, error: null },
    }));

    try {
      const framesResponse = await getFrames(USER_ROLES.PATIENT, patientId, therapistId, sessionId);

      if (framesResponse.error) {
        throw new Error(framesResponse.message);
      }

      const framesData = framesResponse.frames || [];

      setIsLoadingState((prev) => ({
        ...prev,
        frames: { ...prev.frames, isLoading: false, data: framesData },
      }));
    } catch (error: ShamefulAny) {
      setIsLoadingState((prev) => ({
        ...prev,
        frames: { ...prev.frames, isLoading: false, isFailed: true, error: error.message },
      }));
      console.error('Error fetching frames:', error);
    }
  }, [patientId, therapistId, sessionId]);
  const fetchData = useCallback(async () => {
    setIsLoadingState((prev) => ({ ...prev, general: { isLoading: true } }));
    try {
      if (patientId && sessionId && therapistId) {
        fetchPatientRecordingData(
          patientId,
          sessionId,
          therapistId,
          undefined,
          undefined,
          true,
          setIsLoadingState
        );

        await fetchPatientTranscriptsData(patientId, sessionId, therapistId, setIsLoadingState);

        const patientIdPostfixed = patientId.includes('_P') ? patientId : `${patientId}_P`;

        const keyMomentsUpdated = await getUpdatedKeyMoments(
          patientIdPostfixed,
          therapistId,
          sessionId
        );

        setKeyMomentsData(keyMomentsUpdated);
        setUpdatedKeymoments(keyMomentsUpdated);
        await fetchFrames();
      }
    } catch (error) {
      setError(`Error fetching session data: ${JSON.stringify(error)}`);
      console.error('Error fetching session data:', error);
    } finally {
      setIsLoadingState((prev) => ({ ...prev, general: { isLoading: false } }));
    }
  }, [setIsLoadingState, patientId, therapistId, sessionId]);

  useEffect(() => {
    fetchData();
  }, [patientId]);

  useEffect(() => {
    if (!updatedKeyMoments) return;

    saveUpdatedKeyMoments(updatedKeyMoments, patientId, therapistId, sessionId);
  }, [updatedKeyMoments]);

  const handleTimeUpdate = (currentTimeMillis: number) => {
    setCurrentTime(currentTimeMillis);
  };

  const handleTimelineClick = (e: React.MouseEvent<HTMLDivElement>) => {
    const durationToUse = videoDuration || sessionDuration;
    if (durationToUse) {
      const timelineRect = e.currentTarget.getBoundingClientRect();
      const clickPositionX = e.clientX - timelineRect.left;
      const clickPositionPercent = clickPositionX / timelineRect.width;
      const newTimeInSeconds = clickPositionPercent * (durationToUse / 1000);

      if (videoRef.current) {
        videoRef.current.currentTime = newTimeInSeconds;
      }

      handleTimeUpdate(newTimeInSeconds * 1000);
    }
  };

  // INFO: following should get mxlabs file data from patient and therapist session folder
  const { logs: mxlabs, loading: mxlabsloading } = useMXLabsLogs(
    therapistId!,
    patientId!,
    sessionId!
  );

  const [timelineRange, setTimelineRange] = useState<ITimelineRange>({
    startTimestamp: 0,
    endTimestamp: 0,
  });

  useEffect(() => {
    const areMxLabsReceived =
      mxlabs && Object.values(mxlabs).every((logs: ProcessedMXLabsData[]) => logs.length);

    if (
      areMxLabsReceived &&
      recordingTimeFrame.recordingStartTime &&
      recordingTimeFrame.recordingStopTime
    ) {
      const {
        startTimestamp,
        endTimestamp,
        [USER_ROLES.THERAPIST]: therapistTrimmedData,
        [USER_ROLES.PATIENT]: patientTrimmedData,
      } = Object.keys(mxlabs).reduce(
        (trimmedAcc: Record<string, ProcessedMXLabsData[] | number>, role: string) => {
          const { startTimestamp, endTimestamp, trimmedData } = trimByVideoDuration(
            mxlabs[role],
            recordingTimeFrame.recordingStartTime,
            recordingTimeFrame.recordingStopTime
          );
          if (!trimmedAcc.startTimestamp || !trimmedAcc.endTimestamp) {
            trimmedAcc = {
              startTimestamp,
              endTimestamp,
            };
          }
          trimmedAcc[role] = trimmedData;

          return trimmedAcc;
        },
        {}
      );

      setTrimmedMxLabs({
        [USER_ROLES.THERAPIST]: therapistTrimmedData as ProcessedMXLabsData[],
        [USER_ROLES.PATIENT]: patientTrimmedData as ProcessedMXLabsData[],
      });

      setTimelineRange({
        startTimestamp: startTimestamp as number,
        endTimestamp: endTimestamp as number,
      });
    }
  }, [mxlabs, sessionId, recordingTimeFrame]);

  if (mxlabsloading) {
    return generalState.isLoading || mxlabsloading ? (
      <div className="flex flex-col items-center justify-center w-full h-full">
        <div>
          <Loader
            label="Data processing..."
            className="ml-4"
          />
        </div>
      </div>
    ) : (
      <div className="flex items-center justify-center w-full h-full">
        Please try again later. {error}
      </div>
    );
  }
  const { recordingStartTime, recordingStopTime } = recordingTimeFrame;
  return (
    <div className="h-full w-full flex flex-col gap-4 md_d:gap-10 mt-[20px] relative">
      <div className="flex items-center justify-between">
        <PageTitle>Review, Reflect, and Assign</PageTitle>
      </div>

      <div className="flex h-full  justify-between">
        {framesState.isLoading && <p>Loading frames...</p>}
        {error && <p>Error: {error}</p>}
        {keyMomentsData && updatedKeyMoments && (
          <div className="w-full h-full overflow-y-auto">
            <TherapistWrapUpStepper
              recordingState={recordingState}
              framesState={framesState}
              transcriptsState={transcriptsState}
              keyMomentsData={keyMomentsData}
              setVideoDuration={setVideoDuration}
              onTimeUpdate={handleTimeUpdate}
              currentVideoTime={currentTime}
              videoRef={videoRef}
              updatedKeyMoments={updatedKeyMoments}
              setUpdatedKeymoments={setUpdatedKeymoments}
              sessionTranscripts={transcriptsState?.data?.transcript}
              framesData={framesState.data}
              recordingTimeFrame={recordingTimeFrame}
            />
          </div>
        )}
      </div>
      <div className="mt-6 md_d:mt-auto">
        {step === 0 && (
          <div className="w-full">
            {trimmedMxLabs && (
              <Plot
                plotConfig={{ plotId: 1, plotSize: PlotSizeEnum.MEDIUM }}
                signalsData={trimmedMxLabs}
                timelineRange={timelineRange}
                isHrFilter={false}
              />
            )}

            <div
              className="ml-7 mr-[1.6rem] mt-8"
              onClick={handleTimelineClick}
            >
              <div className="relative h-3 bg-[#ccced0] rounded-xl">
                {updatedKeyMoments?.keyMoments.map((moment, index) => {
                  const durationToUse = videoDuration || sessionDuration;
                  const leftPercent = durationToUse
                    ? `${(moment.timeInMillis / durationToUse) * 100}%`
                    : '0%';

                  return (
                    <div
                      key={index}
                      className={`absolute transform -translate-x-1/2 -translate-y-1/2 cursor-pointer ${
                        moment.approvalStatus === 'approved'
                          ? 'w-[18px] h-[18px] bg-purple-600 hover:shadow-[0_0_6px_2px_rgba(128,0,128,0.2)]'
                          : 'w-4 h-4 bg-gray-600 hover:shadow-[0_0_6px_2px_rgba(75,75,75,0.2)]'
                      } rounded-full`}
                      style={{ left: leftPercent, top: '50%' }}
                    ></div>
                  );
                })}

                <div
                  className="absolute h-4 bg-purple-600"
                  style={{
                    left:
                      videoDuration || sessionDuration
                        ? `${(currentTime / (videoDuration ?? sessionDuration ?? 1)) * 100}%`
                        : '0%',
                    width: '2px',
                    top: '50%',
                    transform: 'translateY(-50%)',
                  }}
                />
              </div>

              <div className="flex w-full justify-between mt-3">
                {recordingStartTime && (
                  <div className="w-[34px] h-[18px] text-gray-800 text-sm font-normal font-['Inter'] leading-tight tracking-tight">
                    {formatTime(currentTime + recordingStartTime)}
                  </div>
                )}
                {recordingStopTime && (
                  <div className="w-[39.74px] h-[18px] text-gray-800 text-sm font-normal font-['Inter'] leading-tight tracking-tight">
                    {formatTime(recordingStopTime)}
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SessionReviewByTherapist;
