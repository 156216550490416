import { cva } from 'class-variance-authority';

export const alertVariants = cva('text-left border border-black rounded-lg p-4', {
  variants: {
    intent: {
      info: 'alert-info',
      danger: 'border-red-200 text-red-600 bg-red-50',
    },
  },
  defaultVariants: {
    intent: 'info',
  },
});

export const buttonVariants = cva(
  'inline-flex gap-2 items-center justify-center whitespace-nowrap rounded-xl border text-base font-semibold ring-ring transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 [&>svg]:size-5',
  {
    variants: {
      variant: {
        primary: 'border-primary bg-primary text-primary-foreground hover:bg-primary/90 disabled:text-primary-foreground/50',
        ghost: 'border-primary-200 bg-white text-primary hover:border-primary-300 hover:bg-white/0 disabled:text-primary-foreground/50',
        outline: 'button-outline',
        light: 'border-transparent bg-transparent hover:bg-primary-50/20',
        icon: 'bg-transparent border-0 hover:bg-primary-200',
      },
      size: {
        default: 'h-12 px-6 py-2',
        sm: 'h-9 rounded-md px-3',
        lg: 'h-11 rounded-md px-8',
        icon: 'h-12 w-12',
        iconSm: 'h-9 w-9',
      },
    },
    defaultVariants: {
      variant: 'primary',
      size: 'default',
    },
  }
);

export const aCx = 'underline decoration-primary-400/0 hover:decoration-primary-400 underline-offset-4 transition-all duration-300';

export const inputVariants = cva(
  'flex h-12 px-3 w-full rounded-xl border border-primary-200 bg-background text-sm ring-ring file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50',
  {
    variants: {
      variant: {
        default: '',
        danger: 'border-red-500 text-red-500 focus-visible:ring-red-500 placeholder:text-red-300',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  }
);

export const selectVariants = cva(
  'appearance-none bg-none bg-white bg-selectArrow bg-no-repeat bg-selectArrow flex h-12 px-3 pr-10 w-full rounded-xl border border-primary-200 text-sm ring-ring file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50',
  {
    variants: {
      variant: {
        default: '',
        danger: 'border-red-500 text-red-500 focus-visible:ring-red-500 placeholder:text-red-300',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  }
);
