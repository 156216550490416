import Button from '@components/Button';
import { PropsWithChildren, useEffect, useState } from 'react';
import { CircleCheck } from 'lucide-react';

type Step = {
  label: string;
  value?: string | number;
  checks: { label: string }[];
  isStepBlocked?: boolean;
  nextButtonLabel?: string;
  backButtonLabel?: string;
};

type StepperProps = {
  steps: Step[];
  currentStepValue: number;
  isConnected?: boolean;
  isAutoMode?: boolean;
  onFinish?: () => void;
  onReset?: () => void;
  onPause?: () => void;
  contentClasses?: string;
  onChangeStepCb?: (step: number) => void;
  onConfirmationRequired?: (nextStepCallback: () => void) => void;
  isNotesApproved?: boolean;
  isSummaryApproved?: boolean;
};

const RealtimeDashboardStepper = ({
  steps,
  isConnected,
  currentStepValue,
  isAutoMode,
  onFinish,
  onPause,
  onChangeStepCb,
  onConfirmationRequired,
  children,
}: PropsWithChildren<StepperProps>) => {
  const [currentStep, setCurrentStep] = useState(0);
  const currentStepPrioritized = isAutoMode ? currentStepValue || 0 : currentStep;

  const handleNext = () => {
    if (currentStepPrioritized === 1) {
      if (onConfirmationRequired) {
        onConfirmationRequired(() => {
          goToNextStep();
        });
      } else {
        goToNextStep();
      }
    } else {
      goToNextStep();
    }
  };

  const goToNextStep = () => {
    if (currentStepPrioritized < steps.length - 1 && !isAutoMode) {
      const nextStepIndex = currentStepPrioritized + 1;
      setCurrentStep(nextStepIndex);
    } else if (onFinish) {
      onFinish();
    }
  };

  const handleBack = () => {
    if (currentStepPrioritized > 0) {
      setCurrentStep(currentStepPrioritized - 1);
    }
  };

  useEffect(() => {
    onChangeStepCb?.(currentStepPrioritized);
  }, [currentStepPrioritized, steps]);

  const gridTemplate = `grid-cols-[repeat(4,minmax(0,max-content))] md_d:grid-cols-[repeat(${steps.length},1fr)]`;
  return (
    <div data-hs-stepper={`{"currentIndex": ${currentStepPrioritized + 1}}`}>
      {/* Stepper Navigation */}
      <div className="grid w-full items-start">
        <ul className={`grid w-full gap-x-4 ${gridTemplate}`}>
          {steps.map((_, index) => {
            const isLast = index === steps.length - 1;
            const isActive = isConnected ? currentStepPrioritized === index : false;
            const isCompleted = isConnected ? currentStepPrioritized > index : false;

            return (
              <li
                key={index}
                className={`flex flex-grow justify-end w-auto basis-auto group ${
                  isCompleted ? 'completed' : isActive ? 'active' : ''
                }`}
                data-hs-stepper-nav-item={`{"index": ${index + 1}, "isCompleted": ${isCompleted}}`}
              >
                <div className="flex w-full">
                  <div className="flex gap-3 w-full items-center">
                    <div className="min-w-7 min-h-7 flex flex-col items-center text-xs">
                      <span
                        className={`size-7 flex justify-center shadow-md items-center font-medium rounded-full ${
                          isCompleted
                            ? 'bg-purple-600 text-white'
                            : isActive
                              ? 'bg-purple-600 text-white'
                              : 'bg-gray-100 text-gray-700'
                        }`}
                      >
                        <span>{index + 1}</span>
                      </span>
                    </div>
                    {/* Line between steps, if not the last step */}
                    {!isLast && (
                      <div
                        className={`min-w-[20px] h-px flex-1 ${
                          isCompleted ? 'bg-purple-600' : isActive ? 'bg-purple-600' : 'bg-gray-200'
                        }`}
                      />
                    )}
                    {isLast && (
                      <div className="flex flex-wrap space-y-1 sm_d:flex-nowrap sm_d:space-y-0 items-stretch justify-end ml-4 sm_d:items-center gap-x-3">
                        <Button
                          type="button"
                          className="disabled:cursor-not-allowed"
                          onClick={handleBack}
                          disabled={
                            steps[currentStepPrioritized].isStepBlocked ||
                            currentStepPrioritized === 0
                          }
                          text={steps[currentStepPrioritized].backButtonLabel || 'Back'}
                        />
                        <Button
                          className="disabled:cursor-not-allowed"
                          disabled={true}
                          onClick={onPause}
                          text="Pause"
                        />
                        {currentStepPrioritized < steps.length - 1 ? (
                          <Button
                            onClick={handleNext}
                            className="disabled:cursor-not-allowed"
                            disabled={steps[currentStepPrioritized].isStepBlocked}
                            text={steps[currentStepPrioritized].nextButtonLabel || 'Next'}
                          />
                        ) : (
                          <Button
                            type="button"
                            className="disabled:cursor-not-allowed"
                            onClick={onFinish}
                            disabled={steps[currentStepPrioritized].isStepBlocked}
                            text={steps[currentStepPrioritized].nextButtonLabel || 'Finish'}
                          />
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </li>
            );
          })}
          {steps.map((step, index) => {
            const isLast = index === steps.length - 1;
            const isActive = isConnected ? currentStepPrioritized === index : false;

            return (
              <div
                key={`${step.label + index}`}
                className={`grid ${isLast ? 'grid-cols-[minmax(0,max-content),auto]' : 'grid-cols-[1fr]'} w-full gap-4`}
              >
                <div className="flex flex-col">
                  <div
                    className={`mt-6 mb-4 text-gray-800 text-sm md_d:text-base font-semibold font-['Inter'] leading-[19px] tracking-tight max-w-[132px] text-left ${
                      isActive && isConnected ? 'text-purple-600' : 'text-gray-700'
                    }`}
                  >
                    {step.label}
                  </div>
                  <div className="flex flex-col space-y-2">
                    {step.checks.map((check: any) => {
                      return (
                        <div
                          key={`${check.label + index}`}
                          className="flex items-center"
                        >
                          <div className="w-3">
                            <CircleCheck
                              fill={`${check.isCompleted && isConnected ? '#9333ea' : 'none'}`}
                              className={`${check.isCompleted && isConnected ? 'text-white' : 'text-purple-600'}`}
                              strokeWidth={1.5}
                            />
                          </div>
                          <div className="max-w-16 md_d:max-w-full h-5 flex items-center text-[8px] sm_d:text-[12px] ml-4 leading-3">
                            {check.label}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
                {isLast && <>{children}</>}
              </div>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default RealtimeDashboardStepper;
