import { ButtonHTMLAttributes } from 'react';

interface SwitchButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  label: string;
  isActive: boolean;
  onClick: () => void;
  customClassName?: string;
}

const SwitchButton: React.FC<SwitchButtonProps> = ({
  label,
  isActive,
  onClick,
  customClassName = '',
  ...buttonProps
}: SwitchButtonProps) => (
  <button
    className={`px-2 py-1 rounded-lg w-1/2 flex items-center justify-center ${
      isActive ? 'bg-gray-100 text-purple-600' : 'bg-transparent text-gray-700'
    } ${customClassName}`}
    onClick={onClick}
    {...buttonProps}
  >
    {label}
  </button>
);

export default SwitchButton;
