export const END_TYPE_OPTIONS = {
  VAD: 'server_vad',
  MANUAL: 'none',
} as const;

export const END_TYPE_OPTIONS_MAP = {
  [END_TYPE_OPTIONS.VAD]: 'Auto',
  [END_TYPE_OPTIONS.MANUAL]: 'Manual',
} as const;

export const END_TYPE_OPTIONS_LABEL_MAP = {
  [END_TYPE_OPTIONS_MAP[END_TYPE_OPTIONS.VAD]]: 'VAD',
  [END_TYPE_OPTIONS_MAP[END_TYPE_OPTIONS.MANUAL]]: 'Manual',
} as const;

export const getEndTypeOptions = () => {
  return Object.entries(END_TYPE_OPTIONS).map(([_, value]) => ({
    label: END_TYPE_OPTIONS_MAP[value],
    value,
  }));
};
