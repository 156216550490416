import { useCallback, useEffect, useState } from 'react';
import { useSelector } from '@xstate/react';

// Utils
import { getActiveBreadcrumbFromState } from './model/utils/getActiveBreadcrumb';
// Constants
import { PREVSTATE_IGNORE_LIST } from './model/constants/breadcrumbDict';
// Machines
import { refaelaTherapistActor } from '@components/xState/machines/refaelaTherapistMachine.ts';

import Breadcrumbs from '@features/Breadcrumb/Breadcrumbs.tsx';

const TherapistBreadcrumbs = () => {
  const snapshot: any = useSelector(refaelaTherapistActor, (state) => state);

  const [prevSnapshot, setPrevSnapshot] = useState<any | null>(null);
  const [currentSnapshot, setCurrentSnapshot] = useState<any | null>(null);

  useEffect(() => {
    setPrevSnapshot(PREVSTATE_IGNORE_LIST.includes(snapshot.value) ? null : currentSnapshot);
    setCurrentSnapshot(snapshot);
  }, [snapshot]);

  const currentState = getActiveBreadcrumbFromState(snapshot);

  const prevState = prevSnapshot && getActiveBreadcrumbFromState(prevSnapshot);

  const handlePrevRouteClick = useCallback(() => {
    if (prevState) prevState.goToAction();
  }, [prevState]);

  return (
    <Breadcrumbs
      currentState={currentState}
      prevState={prevState}
      onPrevRouteClick={handlePrevRouteClick}
    />
  );
};

export default TherapistBreadcrumbs;
