import React, { useContext } from 'react';
import { ShenaiSDK, MeasurementState } from '../../../public/shenai-sdk';
import { getStatusForBioString } from './utils';
import { ShenaiSdkBaseState } from '@shared/types/shenai/sdk';
import { HeartIcon } from '../../assets/icons';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../../contextApp';
import { BioStatusBlockShort, BioStatusCard, BioStatusCardDaily } from './bioModule.styled';

interface ShortBioInfoBlockProps {
  sdkState: ShenaiSdkBaseState | undefined;
  shenaiSDK: ShenaiSDK | null;
  isMini?: boolean;
  isDailyMeeting: boolean;
}

const ShortBioInfoBlock: React.FC<ShortBioInfoBlockProps> = ({
  sdkState,
  shenaiSDK,
  isMini,
  isDailyMeeting,
}) => {
  const { i18n } = useTranslation();
  const { direction } = useContext(AppContext)!;
  const getShortBioClass = (val: MeasurementState | undefined) => {
    switch (val) {
      case shenaiSDK?.MeasurementState.NOT_STARTED:
      case shenaiSDK?.MeasurementState.WAITING_FOR_FACE:
      case shenaiSDK?.MeasurementState.RUNNING_SIGNAL_BAD_DEVICE_UNSTABLE:
      case shenaiSDK?.MeasurementState.RUNNING_SIGNAL_SHORT:
        return 'idle';
      case shenaiSDK?.MeasurementState.RUNNING_SIGNAL_BAD:
      case shenaiSDK?.MeasurementState.FAILED:
        return 'error';
      case shenaiSDK?.MeasurementState.RUNNING_SIGNAL_GOOD:
      case shenaiSDK?.MeasurementState.FINISHED:
        return 'success';
      default:
        return '';
    }
  };

  if (isDailyMeeting) {
    return (
      <BioStatusCardDaily className="heartbeat-block">
        <div className={sdkState?.faceState === shenaiSDK?.FaceState.OK ? 'active' : 'disabled'}>
          <div
            className={
              direction == 'ltr' ? 'heartbeat-block-number left' : 'heartbeat-block-number'
            }
          >
            <span
              className={sdkState?.faceState === shenaiSDK?.FaceState.OK ? 'active' : 'disabled'}
            >
              {sdkState?.hr4s ? `${sdkState.hr4s.toFixed(0)}` : ''}
            </span>
          </div>
        </div>
        <div className={sdkState?.faceState === shenaiSDK?.FaceState.OK ? 'active' : 'disabled'}>
          <div className={'heartIcon'}>
            <HeartIcon />
          </div>
          <span className={'unit'}>{sdkState?.hr4s ? ' bpm' : '...'}</span>
        </div>
      </BioStatusCardDaily>
    );
  } else {
    return (
      <BioStatusBlockShort className={isMini ? 'bio-status-mini' : ''}>
        <BioStatusCard className="heartbeat-block">
          <div className={sdkState?.faceState === shenaiSDK?.FaceState.OK ? 'active' : 'disabled'}>
            <div
              className={
                direction == 'ltr' ? 'heartbeat-block-number left' : 'heartbeat-block-number'
              }
            >
              <span
                className={sdkState?.faceState === shenaiSDK?.FaceState.OK ? 'active' : 'disabled'}
              >
                {sdkState?.hr4s ? `${sdkState.hr4s.toFixed(0)}` : ''}
              </span>
              <span className={'unit'}>{sdkState?.hr4s ? ' bpm' : '...'}</span>
            </div>
          </div>
          <div className={sdkState?.faceState === shenaiSDK?.FaceState.OK ? 'active' : 'disabled'}>
            <div className={'heartIcon'}>
              <HeartIcon />
            </div>
          </div>
        </BioStatusCard>
        <BioStatusCard className="bio-status-block">
          <div className={'outputValue'}>
            <span>
              {sdkState &&
                getStatusForBioString(
                  shenaiSDK,
                  sdkState.measurementState,
                  sdkState.faceState,
                  i18n.language
                )}
            </span>
          </div>
          <div>
            <span className={`circle ${getShortBioClass(sdkState?.measurementState)}`}></span>
          </div>
        </BioStatusCard>
      </BioStatusBlockShort>
    );
  }
};

export default ShortBioInfoBlock;
