import { useState } from 'react';
import { patientList } from '@mockData/patientMockData';

const MonthFull: React.FC = () => {
  const [currentDate, setCurrentDate] = useState(new Date());

  const getDaysInMonth = (date: Date): number[] => {
    const year = date.getFullYear();
    const month = date.getMonth();
    const firstDay = new Date(year, month, 1).getDay();
    const lastDate = new Date(year, month + 1, 0).getDate();

    const days: number[] = [];

    for (let i = 0; i < firstDay; i++) {
      days.push(0);
    }

    for (let i = 1; i <= lastDate; i++) {
      days.push(i);
    }

    while (days.length % 7 !== 0) {
      days.push(0);
    }

    return days;
  };

  const changeMonth = (offset: number) => {
    const newDate = new Date(currentDate);
    newDate.setMonth(currentDate.getMonth() + offset);
    setCurrentDate(newDate);
  };

  const daysInMonth = getDaysInMonth(currentDate);
  const monthName = currentDate.toLocaleString('default', { month: 'long', year: 'numeric' });
  const today = new Date();

  const therapistSessions = patientList.flatMap((patient) =>
    patient.sessions
      .filter((session) => session.sessionType === 'therapist')
      .map((session) => ({
        ...session,
        patientName: `${patient.name} ${patient.surname}`,
      }))
  );

  const isSameDay = (date1: Date, date2: Date) =>
    date1.getDate() === date2.getDate() && date1.getMonth() === date2.getMonth() && date1.getFullYear() === date2.getFullYear();

  return (
    <div className="flex flex-col h-full overflow-y-auto">
      <div className="sticky top-0 inset-x-0 z-[9] bg-white border-b border-gray-100 ">
        <div className="flex justify-between items-center p-3">
          <button onClick={() => changeMonth(-1)} className="text-gray-800 ">
            Previous
          </button>
          <span className="text-gray-800 ">{monthName}</span>
          <button onClick={() => changeMonth(1)} className="text-gray-800 ">
            Next
          </button>
        </div>
        <div className="grid grid-cols-7">
          {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map((day) => (
            <span key={day} className="py-2 px-3 block text-center text-xs bg-white text-gray-800">
              {day}
            </span>
          ))}
        </div>
      </div>
      <div className="flex-grow grid grid-cols-7 auto-rows-fr">
        {daysInMonth.map((day, index) => {
          const isCurrentDay =
            day === today.getDate() && currentDate.getMonth() === today.getMonth() && currentDate.getFullYear() === today.getFullYear();

          const sessionDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), day);
          const sessionsToday = therapistSessions.filter((session) => isSameDay(new Date(session.timestamp), sessionDate));

          return (
            <div key={index} className="flex flex-col items-end h-full justify-start border  border-gray-100 ">
              <div
                className={`h-6 flex w-6 items-center justify-center border border-transparent text-xs ${
                  day === 0 ? 'text-transparent' : 'text-gray-800'
                } rounded-full ${
                  isCurrentDay ? 'bg-purple-600 text-white font-medium' : 'hover:bg-gray-200'
                } hover:bg-gray-200 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-200 `}
              >
                {day === 0 ? '' : day}
              </div>
              {sessionsToday.map((session) => (
                <div
                  key={session.sessionId}
                  className="py-0.5 px-1 w-[80%] flex items-start justify-center border-s-4 border-purple-600 bg-purple-100 text-purple-800 text-xs rounded focus:bg-pink-200 focus:shadow-sm "
                >
                  <span className="block truncate">
                    <span className="block font-semibold truncate">{session.patientName}</span>
                    <span className="block truncate">
                      {new Date(session.timestamp).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                    </span>
                  </span>
                </div>
              ))}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default MonthFull;
