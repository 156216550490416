import styled from '@emotion/styled';
import { ASIDE_TILE_HEIGHT, ASIDE_TILE_WIDTH } from '../model/constants/style';
import { BREAKPOINTS } from '@shared/constants';

export const TileContainer = styled.div`
  position: relative;
  width: 960px;
  background-color: #202124;
  height: 470px;
  transition:
    bottom 0.3s cubic-bezier(0.4, 0, 0.2, 1),
    left 0.3s cubic-bezier(0.4, 0, 0.2, 1),
    right 0.3s cubic-bezier(0.4, 0, 0.2, 1),
    top 0.3s cubic-bezier(0.4, 0, 0.2, 1),
    width 0.3s cubic-bezier(0.4, 0, 0.2, 1),
    height 0.3s cubic-bezier(0.4, 0, 0.2, 1);

  &.tile-aside {
    position: absolute;
    width: 216px;
    height: 128px;
    top: 40%;
    right: 0;
    @media only screen and (max-width: ${BREAKPOINTS.TABLET}px) {
      top: 44%;
      width: ${ASIDE_TILE_WIDTH.XS};
      height: ${ASIDE_TILE_HEIGHT.XS};
    }
    @media only screen and (min-width: ${BREAKPOINTS.TABLET}px) {
      top: 51%;
      width: ${ASIDE_TILE_WIDTH.S};
      height: ${ASIDE_TILE_HEIGHT.S};
    }
    @media only screen and (min-width: ${BREAKPOINTS.LAPTOP}px) {
      top: 44%;
      width: ${ASIDE_TILE_WIDTH.M};
      height: ${ASIDE_TILE_HEIGHT.M};
    }
    @media only screen and (min-width: ${BREAKPOINTS.S_DESKTOP}px) {
      top: 38%;
      width: ${ASIDE_TILE_WIDTH.L};
      height: ${ASIDE_TILE_HEIGHT.L};
    }
    @media only screen and (min-width: ${BREAKPOINTS.M_DESKTOP}px) {
      top: 48%;
      right: 9%;
      width: ${ASIDE_TILE_WIDTH.XL};
      height: ${ASIDE_TILE_HEIGHT.XL};
    }
  }

  @media only screen and (max-width: 600px) {
    width: 240px;
    height: 135px;
  }

  &.self-view {
    width: 960px;
    height: 470px;
  }

  @media only screen and (max-width: 600px) {
    &.self-view {
      width: 240px;
      height: 135px;
    }
  }

  &.no-video {
    background-color: var(--dark-blue-border);
  }

  &.no-video video {
    visibility: hidden;
  }

  &.self-view.alone {
    position: absolute;
    width: 100%;
    height: 75%;
    z-index: 1;
  }

  &.self-view.alone video {
    height: 100%;
  }

  @media only screen and (max-width: 600px) {
    &.self-view.alone {
      width: 240px;
      height: 135px;
    }
  }

  &.tile-screenshare {
    grid-column: 1 / -1;
    grid-row: 1;
  }
`;
