import { useEffect, useState } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { RedirectToSignIn, SignedIn, SignedOut, useSignIn } from '@clerk/clerk-react';
import { DailyProvider } from '@daily-co/daily-react';
import { TooltipProvider } from '@components/VoicebotUI/tooltip';
import { AppProvider } from './contextApp';
import { getTokenFromURL } from '@utils/getTokenFromURL';
import { getFullToken } from '@api/user/me';
import { WebSocketProvider } from './websocket';
import App from './App';
import { Loader } from '@shared/ui/loader/Loader';

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
  },
]);

const AppWrapper = () => {
  const [isSigningIn, setIsSigningIn] = useState(true);
  const tokenFromUrl = getTokenFromURL(window.location.href);
  const { signIn } = useSignIn();

  useEffect(() => {
    const processToken = async () => {
      try {
        if (tokenFromUrl) {
          await signIn?.create({ strategy: 'ticket', ticket: tokenFromUrl });
        } else {
          const shortId = new URLSearchParams(window.location.search).get('shortId');
          if (shortId) {
            const fetchedFullToken = await getFullToken(shortId);
            if (fetchedFullToken) {
              await signIn?.create({ strategy: 'ticket', ticket: fetchedFullToken });
            }
          }
        }
      } catch (err) {
        console.error('Error during token handling:', err);
      } finally {
        setIsSigningIn(false);
      }
    };

    processToken();
  }, [tokenFromUrl, signIn]);

  if (isSigningIn) {
    return <Loader />;
  }

  return (
    <>
      <SignedIn>
        <AppProvider>
          <WebSocketProvider>
            <DailyProvider>
              <TooltipProvider>
                <RouterProvider router={router} />
              </TooltipProvider>
            </DailyProvider>
          </WebSocketProvider>
        </AppProvider>
      </SignedIn>
      <SignedOut>
        <RedirectToSignIn redirectUrl={import.meta.env.VITE_VF_SITE_FE_URL} />
      </SignedOut>
    </>
  );
};

export default AppWrapper;
