export const USER_ROLES = {
  ADMIN: 'org:admin',
  PATIENT: 'org:patient',
  THERAPIST: 'org:therapist',
} as const;

export enum VfScenarioEnum {
  JOURNAL = 'journal',
  MEMORY = 'memory',
  OTHER = 'other',
}

export interface UserVFState {
  userRole: keyof typeof USER_ROLES;
  vfScenario: VfScenarioEnum;
}
