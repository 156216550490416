import * as d3 from 'd3';

import { ProcessedMXLabsData } from '@shared/types/mxlabs/logs';

export const trimByVideoDuration = (
  data: ProcessedMXLabsData[],
  startTime?: number,
  stopTime?: number
) => {
  if (!startTime || !stopTime) {
    return {
      startTimestamp: undefined,
      endTimestamp: undefined,
      trimmedData: data,
    };
  }

  const trimmedData = data.filter(
    (item) => item.timestamp >= startTime && item.timestamp <= stopTime
  );

  if (trimmedData.length === 0) {
    return {
      startTimestamp: undefined,
      endTimestamp: undefined,
      trimmedData: data,
    };
  }

  // finds closest previous point to include
  const startIndex = d3.bisector((d: any) => d.timestamp).left(data, startTime) - 1;
  const startPoint = startIndex >= 0 ? data[startIndex] : data[0];

  // finds closest next point to include
  const stopIndex = d3.bisector((d: any) => d.timestamp).right(data, stopTime);
  const stopPoint = stopIndex < data.length ? data[stopIndex] : data[data.length - 1];

  const firstPoint = trimmedData[0];
  const lastPoint = trimmedData[trimmedData.length - 1];

  // handle case when 2800ms neighbours with 3400
  const getFirstPointSeconds = new Date(firstPoint.timestamp).getSeconds();
  const getStartTimeSeconds = new Date(startTime).getSeconds();
  const getLastPointSeconds = new Date(lastPoint.timestamp).getSeconds();
  const getStopTimeSeconds = new Date(stopTime).getSeconds();

  const finalStart =
    Math.abs(startTime - firstPoint.timestamp) > 1000 &&
    getFirstPointSeconds !== getStartTimeSeconds
      ? { ...startPoint, timestamp: startTime }
      : firstPoint;

  const finalStop =
    Math.abs(stopTime - lastPoint.timestamp) > 1000 && getLastPointSeconds !== getStopTimeSeconds
      ? { ...stopPoint, timestamp: stopTime }
      : lastPoint;

  const enhancedTrimmedData = [finalStart, ...trimmedData, finalStop];

  const roundStartTime = d3.min(enhancedTrimmedData, (o: any) => o.timestamp);
  const roundEndTime = d3.max(enhancedTrimmedData, (o: any) => o.timestamp);

  return {
    startTimestamp: roundStartTime,
    endTimestamp: roundEndTime,
    trimmedData: enhancedTrimmedData,
  };
};
