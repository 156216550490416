import { memo, useEffect, useRef } from 'react';
import { RealtimeClient } from '@openai/realtime-api-beta';
import { useSelector } from '@xstate/react';
// xState
import { dashboardActor } from '@components/RealtimeDashboard/model/xstate/dashboardMachine';
// Constants
import { TOGGLEABLE_SECTIONS } from '@components/RealtimeDashboard/model/constants/togglableSections';
// Types
import { ItemType } from '@openai/realtime-api-beta/dist/lib/client';
import { ShamefulAny } from '@interfaces/index';
// Components
import AgentState from '@components/RealtimeDashboard/ui/AgentState.js';
import ConversationData from '@components/RealtimeDashboard/ui/ConversationData.js';
import ToggleableTabs from '@features/Tab/ToggleableTabs/ToggleableTabs';
import { SelectOption, SimpleSelect } from '@shared/ui/input/select/SimpleSelect';
import RealtimeDashboardStepper from '@features/Stepper/RealtimeDashboardStepper';
import Button from '@components/Button';

import { WavRenderer } from './model/utils/wav_renderer';
import './ConsolePage.css';

const mock = [
  { label: 'Setup', value: 0 },
  { label: 'Start and chat', value: 1 },
  { label: 'Do exercise', value: 2 },
  { label: 'Reflect and finish', value: 3 },
];

interface RealtimeEvent {
  time: string;
  source: 'client' | 'server';
  count?: number;
  event: { [key: string]: any };
}
interface RealtimeAIProps {
  items?: ItemType[];
  apiKey?: string;
  isConnected?: boolean;
  hideUI?: boolean;
  agentState?: ShamefulAny;
  startTimeRef?: ShamefulAny;
  instructions: string;
  realtimeEvents?: RealtimeEvent[];
  realtimeClientInstance: ShamefulAny;
  wavRecorderRef?: ShamefulAny;
  wavStreamPlayerRef?: ShamefulAny;
  customInstructions?: string;
  onInstructionsChange: (newInstructions: string) => void;
  onSectionToggleByKey?: (key: string) => void;
}

const RealtimeAI = ({
  // items,
  instructions,
  isConnected,
  hideUI = false,
  agentState,
  startTimeRef,
  wavRecorderRef,
  wavStreamPlayerRef,
  realtimeClientInstance,
  // realtimeEvents,
  onInstructionsChange,
  onSectionToggleByKey,
  // apiKey: externalApiKey = '',
}: RealtimeAIProps) => {
  const sections = useSelector(dashboardActor, (state) => state.context.sections);
  const steps = useSelector(dashboardActor, (state) => state.context.steps);
  const items = useSelector(dashboardActor, (state) => state.context.items);
  const realtimeEvents = useSelector(dashboardActor, (state) => state.context.realtimeEvents);
  const currentStep = useSelector(dashboardActor, (state) => state.context.currentStepIndex);

  // Refs
  const clientCanvasRef = useRef<HTMLCanvasElement>(null);
  const serverCanvasRef = useRef<HTMLCanvasElement>(null);

  // API key handling
  // const apiKey = externalApiKey || localStorage.getItem('tmp::voice_api_key') || '';

  /**
   * Set up render loops for the visualization canvas
   */

  useEffect(() => {
    let isLoaded = true;
    const clientCanvas = clientCanvasRef.current;
    const serverCanvas = serverCanvasRef.current;

    const wavRecorder = wavRecorderRef.current;
    let clientCtx: CanvasRenderingContext2D | null = null;

    const wavStreamPlayer = wavStreamPlayerRef.current;
    let serverCtx: CanvasRenderingContext2D | null = null;

    const render = () => {
      if (isLoaded) {
        if (clientCanvas) {
          if (!clientCanvas.width || !clientCanvas.height) {
            clientCanvas.width = clientCanvas.offsetWidth;
            clientCanvas.height = clientCanvas.offsetHeight;
          }
          clientCtx = clientCtx || clientCanvas.getContext('2d');
          if (clientCtx) {
            clientCtx.clearRect(0, 0, clientCanvas.width, clientCanvas.height);
            const result = wavRecorder.recording
              ? wavRecorder.getFrequencies('voice')
              : { values: new Float32Array([0]) };
            WavRenderer.drawBars(clientCanvas, clientCtx, result.values, '#2563EB', 10, 0, 8);
          }
        }
        if (serverCanvas) {
          if (!serverCanvas.width || !serverCanvas.height) {
            serverCanvas.width = serverCanvas.offsetWidth;
            serverCanvas.height = serverCanvas.offsetHeight;
          }
          serverCtx = serverCtx || serverCanvas.getContext('2d');
          if (serverCtx) {
            serverCtx.clearRect(0, 0, serverCanvas.width, serverCanvas.height);
            const result = wavStreamPlayer.analyser
              ? wavStreamPlayer.getFrequencies('voice')
              : { values: new Float32Array([0]) };
            WavRenderer.drawBars(serverCanvas, serverCtx, result.values, '#9333ea', 10, 0, 8);
          }
        }
        window.requestAnimationFrame(render);
      }
    };
    render();

    return () => {
      isLoaded = false;
    };
  }, []);

  const handleSectionToggle = (key: string) => {
    onSectionToggleByKey?.(key);
  };

  const handleInstructionsChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newInstructions = event.target.value;
    onInstructionsChange(newInstructions);
  };

  if (hideUI) {
    return null;
  }

  return (
    <div data-component="ConsolePage">
      <div className="flex flex-col mt-5 space-y-4 w-full">
        <RealtimeDashboardStepper
          steps={steps}
          isAutoMode={true}
          isConnected={isConnected}
          currentStepValue={currentStep}
        >
          <div className="flex flex-col mt-10 ml-7">
            <div className="flex items-center h-[40px] w-[100px] gap-1 client">
              <canvas
                className="w-full h-full text-current"
                ref={clientCanvasRef}
              />
            </div>
            <div className="flex items-center h-[40px] w-[100px] gap-1 server">
              <canvas
                className="w-full h-full text-current"
                ref={serverCanvasRef}
              />
            </div>
          </div>
        </RealtimeDashboardStepper>

        <ToggleableTabs
          isShown={sections[TOGGLEABLE_SECTIONS.SESSION_SETTINGS]}
          onToggle={() => handleSectionToggle(TOGGLEABLE_SECTIONS.SESSION_SETTINGS)}
          withCardStyle
          tabs={[
            {
              label: 'Session settings',
              content: (
                <div className="flex flex-col">
                  <div className="flex px-1 space-x-4">
                    <div className="w-2/3 mb-4">
                      <span className="text-black text-[13px] font-bold font-['Inter'] leading-normal">
                        System Instructions
                      </span>
                      <textarea
                        className="w-full border border-gray-300 rounded p-2 resize-none"
                        rows={4}
                        value={instructions}
                        onChange={handleInstructionsChange}
                      ></textarea>
                    </div>
                    <div className="flex flex-col w-1/3 mb-4">
                      <span className="text-black text-[13px] mb-1 font-bold font-['Inter'] leading-normal">
                        Start state
                      </span>
                      <SimpleSelect
                        disabled={false}
                        withSearch={false}
                        selectClass="w-[11rem] md_d:w-full"
                        selectDropdownClass="w-[11rem]"
                      >
                        {mock.map((setup, index) => {
                          return (
                            <SelectOption
                              key={index}
                              value={setup.value}
                            >
                              {setup.label}
                            </SelectOption>
                          );
                        })}
                      </SimpleSelect>
                      <div className="flex mt-4 justify-end">
                        <Button text="Start auto session" />
                      </div>
                    </div>
                  </div>
                  <AgentState agentState={agentState} />
                </div>
              ),
            },
          ]}
        />
        <ConversationData
          startTime={startTimeRef.current}
          items={items}
          realtimeEvents={realtimeEvents}
          isConnected={isConnected}
          realtimeClientInstance={realtimeClientInstance}
        />
      </div>
      <div className="content-main">
        <div className="content-right">
          {/* <div className="content-block map">
          <div className="content-block-title">get_weather()</div>
          <div className="content-block-title bottom">
            {marker?.location || 'not yet retrieved'}
            {!!marker?.temperature && (
              <>
                <br />
                🌡️ {marker.temperature.value} {marker.temperature.units}
              </>
            )}
            {!!marker?.wind_speed && (
              <>
                {' '}
                🍃 {marker.wind_speed.value} {marker.wind_speed.units}
              </>
            )}
          </div>
        </div> */}
          {/* <div className="content-block kv">
          <div className="content-block-title">set_memory()</div>
          <div className="content-block-body content-kv">
            {JSON.stringify(memoryKv, null, 2)}
          </div>
        </div> */}
        </div>
      </div>
    </div>
  );
};

const MemoRealtimeAi = memo(RealtimeAI);
MemoRealtimeAi.displayName = 'RealtimeAI';
export { MemoRealtimeAi };

export type { RealtimeClient, RealtimeEvent };
