import { ReactNode } from 'react';
import TextAvatar from '@features/Avatar';

type PatientOptionProps = {
  patient: {
    firstName?: string;
    lastName?: string;
    img?: string;
    id: string;
  };
  children: ReactNode;
};

const PatientOption = ({ patient, children }: PatientOptionProps) => {
  return (
    <div className="flex items-center">
      <TextAvatar img={patient.img || undefined}>
        {`${patient.firstName ?? ''} ${patient.lastName ?? ''}`}
      </TextAvatar>
      <span className="ml-4">{children}</span>
    </div>
  );
};

export default PatientOption;
