import { useState } from 'react';
// Constants
import { INVITE_EDIT_PATIENT_FORM_FIELDS } from '../model/constants/inviteEditPatientForm';
// Types
import { FormPatientFieldKeysType, FormPatientDataType } from '@components/Forms/model/types';
// Components
import Button from '@components/Button';

import { Loader } from '@shared/ui/loader/Loader.tsx';

import { TextInputController } from '../ui/TextInputController';
import { CircleAlertIcon } from 'lucide-react';

type EditPatientFormProps = {
  onCancel: () => void;
  onEdit: ({ newPatientData }: { newPatientData: FormPatientDataType }) => void;
  isLoading?: boolean;
  error?: string | null;
  initialData: FormPatientDataType;
};

export const EditPatientForm = ({
  onCancel,
  onEdit,
  isLoading,
  error,
  initialData,
}: EditPatientFormProps) => {
  const [newPatientData, setNewPatientData] = useState<FormPatientDataType>({
    [INVITE_EDIT_PATIENT_FORM_FIELDS.FIRST_NAME]: initialData.firstName || '',
    [INVITE_EDIT_PATIENT_FORM_FIELDS.LAST_NAME]: initialData.lastName || '',
    [INVITE_EDIT_PATIENT_FORM_FIELDS.EMAIL]: initialData.patientEmail || '',
    [INVITE_EDIT_PATIENT_FORM_FIELDS.PHONE_NUMBER]: initialData.phoneNumber || '',
  });

  const handleInputChange = ({ key, value }: { key: FormPatientFieldKeysType; value: string }) => {
    setNewPatientData((prev) => ({ ...prev, [key]: value }));
  };

  const handleInviteNewPatient = (e: any) => {
    e.preventDefault();
    onEdit({ newPatientData });
  };

  return (
    <div className="flex min-h-full flex-1 flex-col justify-center">
      {isLoading && (
        <div className="bg-loading-overlay h-full rounded-br-20 absolute top-0 bottom-0 left-0 right-0 z-[1]">
          <Loader spinnerClasses="absolute z-2 top-1/2 left-1/2 ml-[-0.75rem] mt-[-0.75rem]" />
        </div>
      )}
      <form
        onSubmit={handleInviteNewPatient}
        className="space-y-6"
      >
        <div className="sm:mx-auto sm:w-full">
          <div className="space-y-4">
            <TextInputController
              label="First name"
              onInputChange={handleInputChange}
              id={INVITE_EDIT_PATIENT_FORM_FIELDS.FIRST_NAME}
              type="text"
              required
              autoComplete="given-name"
              value={newPatientData[INVITE_EDIT_PATIENT_FORM_FIELDS.FIRST_NAME]}
            />
            <TextInputController
              label="Last name"
              onInputChange={handleInputChange}
              id={INVITE_EDIT_PATIENT_FORM_FIELDS.LAST_NAME}
              type="text"
              required
              autoComplete="family-name"
              value={newPatientData[INVITE_EDIT_PATIENT_FORM_FIELDS.LAST_NAME]}
            />
            <TextInputController
              label="Email address"
              onInputChange={handleInputChange}
              id={INVITE_EDIT_PATIENT_FORM_FIELDS.EMAIL}
              type="email"
              required
              autoComplete="email"
              value={newPatientData[INVITE_EDIT_PATIENT_FORM_FIELDS.EMAIL]}
            />
            <TextInputController
              label="Phone number"
              onInputChange={handleInputChange}
              id={INVITE_EDIT_PATIENT_FORM_FIELDS.PHONE_NUMBER}
              type="tel"
              required
              autoComplete="tel"
              value={newPatientData[INVITE_EDIT_PATIENT_FORM_FIELDS.PHONE_NUMBER]}
            />
            <div
              className={`text-rose-600 text-sm ${error ? 'opacity-100' : 'opacity-0'} flex gap-1`}
            >
              <CircleAlertIcon
                color="#dc2626"
                height="24px"
                width="24px"
              />
              <span> {error}</span>
            </div>
            <div className="flex justify-center pt-5 gap-4">
              <Button
                variant="outline"
                text="Cancel"
                onClick={onCancel}
                className="w-[50%]"
              />
              <Button
                type="submit"
                text="Edit patient data"
                className="w-[50%]"
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
