import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Mic, MicOff, Video, VideoOff, Image, ImageOff } from 'lucide-react';
import { useAudioTrack, useDaily, useLocalSessionId, useVideoTrack } from '@daily-co/daily-react';

import { isUserTherapist } from '@utils/helpers';
import Button from '@components/Button';
import HeartBeatBlock from '@components/Calibration/HeartBeat';

import './Tray.css';

type TrayProps = {
  therapistSessionId?: string;
  patientSessionId?: string;
  userRole: string;
  patientId?: string;
  therapistId?: string;
  sessionId?: string;
  onPresentationMode: () => void;
  calibratedPatient: any;
  isPresentationMode: boolean;
  onKeyMoments: (newKeyMoments: any) => void;
};

export const Tray: React.FunctionComponent<TrayProps> = ({
  userRole,
  onPresentationMode,
  calibratedPatient,
  isPresentationMode,
  onKeyMoments,
}) => {
  const callObject = useDaily();
  const localSessionId = useLocalSessionId();
  const localVideo = useVideoTrack(localSessionId);
  const localAudio = useAudioTrack(localSessionId);
  const mutedVideo = localVideo.isOff;
  const mutedAudio = localAudio.isOff;
  const [isKeyMomentClicked, setIsKeyMomentClicked] = useState(false);

  const calibratedPatientRef = useRef(calibratedPatient);

  //#region handlers
  const toggleVideo = useCallback(() => {
    callObject?.setLocalVideo(mutedVideo);
  }, [callObject, mutedVideo]);

  const toggleAudio = useCallback(() => {
    callObject?.setLocalAudio(mutedAudio);
  }, [callObject, mutedAudio]);

  const handlePresentationModeToggle = () => {
    onPresentationMode();
  };

  const handleKeyMomentCaptured = (calibratedPatient: any) => {
    if (!isUserTherapist(userRole)) return;

    setIsKeyMomentClicked((prev) => !prev);
    const keyMomentTimestamp = Date.now();
    const heartbeat = calibratedPatient ? calibratedPatient.sdkState.hr4s : '---';

    onKeyMoments({
      timestamp: keyMomentTimestamp,
      heartBeat: heartbeat,
    });

    setTimeout(() => {
      setIsKeyMomentClicked((prev) => !prev);
    }, 2000);
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.code === 'Space') {
      handleKeyMomentCaptured(calibratedPatientRef.current);
    }
  };
  //#endregion handlers

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  useEffect(() => {
    calibratedPatientRef.current = calibratedPatient;
  }, [calibratedPatient]);

  return (
    <>
      <div className="flex h-16 items-center absolute bottom-20">
        {calibratedPatient?.sdkState && (
          <HeartBeatBlock
            isHeartColorChanged={isKeyMomentClicked}
            key="heart_beat_block"
            sdkState={calibratedPatient.sdkState}
          />
        )}
        {isUserTherapist(userRole) && (
          <>
            <div className="w-1 h-4/5 bg-slate-800 ml-10" />
            <div className="flex items-center whitespace-nowrap ml-7">
              {!isKeyMomentClicked && (
                <div
                  className="cursor-pointer text-ellipsis text-sm"
                  onClick={() => handleKeyMomentCaptured(calibratedPatientRef.current)}
                >
                  Press <span className="rounded-lg font-mono p-1 bg-gray-300">space</span> to
                  capture a key moment
                </div>
              )}
              {isKeyMomentClicked && (
                <div className="cursor-pointer text-sm">
                  Key moment captured <span className=" opacity-50">Review after session</span>
                </div>
              )}
            </div>
          </>
        )}
      </div>
      <div className="flex items-center h-16 z-10 absolute left-1/2 bottom-20">
        <div className="flex relative -left-1/2 gap-4 items-center justify-center ">
          <Button
            className="!p-3"
            title={mutedVideo ? 'Turn camera on' : 'Turn camera off'}
            type="button"
            onClick={toggleVideo}
            icon={
              mutedVideo ? (
                <VideoOff
                  className="text-white"
                  size={18}
                  strokeWidth={1.5}
                />
              ) : (
                <Video
                  size={18}
                  strokeWidth={1.5}
                />
              )
            }
          />
          <Button
            className="!p-3"
            title={mutedAudio ? 'Unmute microphone' : 'Mute microphone'}
            onClick={toggleAudio}
            type="button"
            icon={
              mutedAudio ? (
                <MicOff
                  size={18}
                  strokeWidth={1.5}
                />
              ) : (
                <Mic
                  size={18}
                  strokeWidth={1.5}
                />
              )
            }
          />
          {isUserTherapist(userRole) && (
            <Button
              className="!p-3"
              title="Presentation mode"
              onClick={handlePresentationModeToggle}
              type="button"
              icon={
                isPresentationMode ? (
                  <ImageOff
                    size={18}
                    strokeWidth={1.5}
                  />
                ) : (
                  <Image
                    size={18}
                    strokeWidth={1.5}
                  />
                )
              }
            />
          )}
        </div>
      </div>
    </>
  );
};
