import * as Plot from '@observablehq/plot';
export const EDGE_TICK_OFFSET = 10;

export const emptyDataPlaceHolder = [
  Plot.frame({ rx: '10', stroke: '#aaaaaa5f' }),
  Plot.text(
    [
      {
        x: 0.5,
        y: 0.5,
        text: 'No selected data',
      },
    ],
    {
      text: 'text',
      frameAnchor: 'middle',
      fontSize: 16,
      fill: '#6373817F',
    }
  ),
];
