import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import {
  useAudioLevel,
  useAudioTrack,
  useLocalSessionId,
  useParticipantIds,
  useParticipantProperty,
} from "@daily-co/daily-react";

import { VAD, VADState } from "../../vad";
import AudioWorkletURL from "../../vad/worklet.ts?worker&url";

import { calculateMedian } from "./utils";

enum State {
  SPEAKING = "Speaking",
  SILENT = "Silent",
}

const REMOTE_AUDIO_THRESHOLD = 0.1;
const LATENCY_MIN = 100;

const Latency: React.FC<{
  started: boolean;
  botStatus: string;
  statsAggregator: StatsAggregator;
  handleUserInput: any;
  setInputValue: any;
}> = memo(
  ({ started = false, statsAggregator, setInputValue, handleUserInput }) => {
    const localSessionId = useLocalSessionId();
    const [localAudioTrack] = useParticipantProperty(localSessionId, ['tracks.audio.persistentTrack']);
    const remoteParticipantId = useParticipantIds({ filter: 'remote' })[0];
    const remoteAudioTrack = useAudioTrack(remoteParticipantId);

    const [vadInstance, setVadInstance] = useState<VAD | null>(null);
    const [currentState, setCurrentState] = useState<State>(State.SILENT);
    const [, setLastDelta] = useState<number | null>(null);
    const [, setMedian] = useState<number | null>(null);
    const [hasSpokenOnce, setHasSpokenOnce] = useState<boolean>(false);

    const deltaRef = useRef<number>(0);
    const deltaArrayRef = useRef<number[]>([]);
    const startTimeRef = useRef<Date | null>(null);
    const mountedRef = useRef<boolean>(false);

    /* ---- Timer actions ---- */
    const startTimer = useCallback(() => {
      startTimeRef.current = new Date();
    }, []);

    const stopTimer = useCallback(() => {
      if (!startTimeRef.current) {
        return;
      }

      const now = new Date();
      const diff = now.getTime() - startTimeRef.current.getTime();

      // Ignore any values that are obviously wrong
      // These may be triggered by small noises such as coughs etc
      if (diff < LATENCY_MIN) {
        return;
      }

      deltaArrayRef.current = [...deltaArrayRef.current, diff];
      setMedian(calculateMedian(deltaArrayRef.current));
      setLastDelta(diff);
      startTimeRef.current = null;

      // Increment turns
      if (statsAggregator) {
        statsAggregator.turns++;
      }
    }, [statsAggregator]);

    // Stop timer when bot starts talking
    useAudioLevel(
      remoteAudioTrack?.persistentTrack,
      useCallback(
        (volume) => {
          if (volume > REMOTE_AUDIO_THRESHOLD && startTimeRef.current) {
            stopTimer();
          }
        },
        [stopTimer]
      )
    );

    /* ---- Effects ---- */

    // Reset state on mount
    useEffect(() => {
      startTimeRef.current = null;
      deltaRef.current = 0;
      deltaArrayRef.current = [];
      setVadInstance(null);
      setHasSpokenOnce(false);
    }, []);

    // Start timer after user has spoken once
    // Note: we use 'hasSpokenOnce' to avoid starting the timer
    // as soon as the experience loads (if for some reason VAD is triggered)
    useEffect(() => {
      if (!started || !hasSpokenOnce || !vadInstance || vadInstance.state !== VADState.listening || currentState !== State.SILENT) {
        return;
      }
      startTimer();
    }, [started, vadInstance, currentState, startTimer, hasSpokenOnce]);

    function startRecognition() {
      const recognition = new (window.SpeechRecognition || window.webkitSpeechRecognition)();
  
      recognition.onresult = function(event: any) {
        let transcript: string = event.results[0][0].transcript;
        
        if (transcript.includes('settings') || transcript.includes('thanks')) {
          setInputValue('navigate to settings');
          handleUserInput('navigate to settings');  
        }
        if (transcript.includes('notifications')) {
          setInputValue('navigate to notifications');
          handleUserInput('navigate to notifications');  
        }
        if (transcript.includes('home')) {
          setInputValue('navigate to home');
          handleUserInput('navigate to home');  
        }
        if (transcript.includes('waiting room') || transcript.includes('session')) {
          setInputValue('navigate to waiting room');
          handleUserInput('navigate to waiting room');  
        }
        if (transcript.includes('calendar')) {
          setInputValue('navigate to calendar');
          handleUserInput('navigate to calendar');  
        }
        if (transcript.includes('patient') || transcript.includes('patience')) {
          setInputValue('navigate to patients');
          handleUserInput('navigate to patients');  
        }
        recognition.stop();
        console.log('Result received: ' + event.results[0][0].transcript);
      };

      recognition.onEnd = () => {
        recognition.start();
      };
  
      recognition.onerror = function(event: any) {
          console.log('Error occurred in recognition: ' + event.error);
      };
  
      recognition.start();
    }

    useEffect(() => {
      if (mountedRef.current || !localAudioTrack) {
        return;
      }

      async function loadVad() {
        const stream = new MediaStream([localAudioTrack!]);

        const vad = new VAD({
          workletURL: AudioWorkletURL,
          stream,
          positiveSpeechThreshold: 0.8,
          negativeSpeechThreshold: 0.8 - 0.15,
          minSpeechFrames: 8,
          redemptionFrames: 3,
          preSpeechPadFrames: 1,
          onSpeechStart: () => {
            setCurrentState(State.SPEAKING);
            startRecognition();
          },
          onVADMisfire: () => {
            setCurrentState(State.SILENT);
          },
          onSpeechEnd: () => {
            setHasSpokenOnce(true);
            setCurrentState(State.SILENT);
          },
        });
        await vad.init();
        vad.start();
        setVadInstance(vad);
      }

      // Load VAD
      loadVad();

      mountedRef.current = true;
    }, [localAudioTrack]);

    // Cleanup VAD
    useEffect(
      () => () => {
        if (vadInstance && vadInstance.state !== VADState.destroyed) {
          setVadInstance(null);
          vadInstance?.destroy();
        }
      },
      [vadInstance]
    );

    /* ---- Render ---- */

    return (
      <></>
    );
  },
  (prevState, nextState) => prevState.started === nextState.started && prevState.botStatus === nextState.botStatus
);

export default Latency;
