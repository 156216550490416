import { Patient } from '@interfaces/index';

export const patientList: Patient[] = [
  {
    patientId: 'user_2kYbZRnGSfAp0bRNAZWwwlfRCsw',
    name: 'Dana',
    surname: 'Weiss',
    email: 'danamartinez@example.com',
    image: 'https://example.com/images/danamartinez.jpg',
    concern: 'Work-related anxiety',
    sessions: [
      { sessionId: 'sess1-pat1', timestamp: '2024-07-26T14:00:00Z', sessionType: 'therapist' },
      { sessionId: 'sess2-pat1', timestamp: '2024-07-28T10:00:00Z', sessionType: 'AI' },
      { sessionId: 'sess3-pat1', timestamp: '2024-07-30T16:20:00Z', sessionType: 'therapist' },
      { sessionId: 'sess4-pat1', timestamp: '2024-07-31T11:30:00Z', sessionType: 'therapist' },
    ],
    createdAt: Date.now(),
    updatedAt: Date.now(),
  },
  {
    patientId: 'pat1',
    name: 'Dana',
    surname: 'Martinez',
    email: 'danamartinez@example.com',
    image: 'https://example.com/images/danamartinez.jpg',
    concern: 'Work-related anxiety',
    sessions: [
      { sessionId: 'sess1-pat1', timestamp: '2024-07-26T14:00:00Z', sessionType: 'therapist' },
      { sessionId: 'sess2-pat1', timestamp: '2024-07-28T10:00:00Z', sessionType: 'AI' },
      { sessionId: 'sess3-pat1', timestamp: '2024-07-30T16:20:00Z', sessionType: 'therapist' },
      { sessionId: 'sess4-pat1', timestamp: '2024-07-31T11:30:00Z', sessionType: 'therapist' },
    ],
    createdAt: Date.now(),
    updatedAt: Date.now(),
  },
  {
    patientId: 'pat2',
    name: 'John',
    surname: 'Doe',
    email: 'johndoe@example.com',
    image: 'https://example.com/images/johndoe.jpg',
    concern: 'Depression',
    sessions: [
      { sessionId: 'sess1-pat2', timestamp: '2024-07-26T15:30:00Z', sessionType: 'therapist' },
      { sessionId: 'sess2-pat2', timestamp: '2024-07-29T11:00:00Z', sessionType: 'AI' },
      { sessionId: 'sess3-pat2', timestamp: '2024-08-02T14:00:00Z', sessionType: 'therapist' },
    ],
    createdAt: Date.now(),
    updatedAt: Date.now(),
  },
  {
    patientId: 'pat3',
    name: 'Jane',
    surname: 'Smith',
    email: 'janesmith@example.com',
    image: 'https://example.com/images/janesmith.jpg',
    concern: 'Relationship issues',
    sessions: [
      { sessionId: 'sess1-pat3', timestamp: '2024-07-25T13:00:00Z', sessionType: 'therapist' },
      { sessionId: 'sess2-pat3', timestamp: '2024-07-28T09:00:00Z', sessionType: 'AI' },
      { sessionId: 'sess3-pat3', timestamp: '2024-08-01T11:00:00Z', sessionType: 'therapist' },
    ],
    createdAt: Date.now(),
    updatedAt: Date.now(),
  },
  {
    patientId: 'pat4',
    name: 'David',
    surname: 'Johnson',
    email: 'davidjohnson@example.com',
    image: 'https://example.com/images/davidjohnson.jpg',
    concern: 'Stress management',
    sessions: [
      { sessionId: 'sess1-pat4', timestamp: '2024-07-22T10:00:00Z', sessionType: 'therapist' },
      { sessionId: 'sess2-pat4', timestamp: '2024-07-26T11:30:00Z', sessionType: 'AI' },
      { sessionId: 'sess3-pat4', timestamp: '2024-07-29T15:00:00Z', sessionType: 'therapist' },
    ],
    createdAt: Date.now(),
    updatedAt: Date.now(),
  },
  {
    patientId: 'pat5',
    name: 'Emily',
    surname: 'Taylor',
    email: 'emilytaylor@example.com',
    image: 'https://example.com/images/emilytaylor.jpg',
    concern: 'Panic disorder',
    sessions: [
      { sessionId: 'sess1-pat5', timestamp: '2024-07-24T09:00:00Z', sessionType: 'therapist' },
      { sessionId: 'sess2-pat5', timestamp: '2024-07-27T14:00:00Z', sessionType: 'AI' },
      { sessionId: 'sess3-pat5', timestamp: '2024-07-31T12:30:00Z', sessionType: 'therapist' },
    ],
    createdAt: Date.now(),
    updatedAt: Date.now(),
  },
  {
    patientId: 'pat6',
    name: 'Michael',
    surname: 'Brown',
    email: 'michaelbrown@example.com',
    image: 'https://example.com/images/michaelbrown.jpg',
    concern: 'Insomnia',
    sessions: [
      { sessionId: 'sess1-pat6', timestamp: '2024-07-23T16:00:00Z', sessionType: 'therapist' },
      { sessionId: 'sess2-pat6', timestamp: '2024-07-27T10:30:00Z', sessionType: 'AI' },
      { sessionId: 'sess3-pat6', timestamp: '2024-07-30T14:00:00Z', sessionType: 'therapist' },
    ],
    createdAt: Date.now(),
    updatedAt: Date.now(),
  },
];
