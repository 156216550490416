import Spinner from './ui/Spinner';

type LoaderProps = {
  label?: string;
  className?: string;
  spinnerClasses?: string;
  flexDirection?: string;
};

export const Loader = ({
  label,
  className = '',
  spinnerClasses = '',
  flexDirection = '',
}: LoaderProps) => {
  return (
    <div className={`flex ${className} ${flexDirection}`}>
      <span>{label && label}</span>
      <Spinner className={`ml-4 ${spinnerClasses}`} />
    </div>
  );
};
