import { CopyIcon } from 'lucide-react';

interface CopyButtonProps {
  onCopyAuthToken: () => void;
  isCopyClicked: boolean;
}

const CopyButton = ({ onCopyAuthToken, isCopyClicked }: CopyButtonProps) => {
  return (
    <div
      className="cursor-pointer font-bold"
      onClick={onCopyAuthToken}
    >
      <CopyIcon className="inline mr-2" />
      {isCopyClicked ? 'Copied!' : 'Copy Joining Info'}
    </div>
  );
};

export default CopyButton;
