import { ReactNode } from 'react';

type SelectOptionProps = {
  value: any;
  children: ReactNode;
  className?: string;
  onClick?: (value: any) => void;
};

const SelectOption = ({ value, className = '', children, onClick }: SelectOptionProps) => {
  const handleClick = () => {
    onClick?.(value);
  };

  return (
    <li
      className={`cursor-pointer ${className}`}
      onClick={handleClick}
    >
      {children}
    </li>
  );
};

export default SelectOption;
