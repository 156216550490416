import { DASHBOARD_STEPS } from '@states/realtimeDashboard';

export const STEPS_MAP = {
  //First step is manually configured when recieved data from shenai
  //   [DASHBOARD_STEPS.SETUP]: {
  //     index: 0,
  //     substep: 0,
  //   },
  [DASHBOARD_STEPS.ICEBREAKER]: {
    stepIndex: 1,
    substepIndex: 0,
  },
  [DASHBOARD_STEPS.INSTRUCTIONS]: {
    stepIndex: 1,
    substepIndex: 1,
  },
  [DASHBOARD_STEPS.EXCERCISE]: {
    stepIndex: 2,
    substepIndex: 0,
  },
  [DASHBOARD_STEPS.REFLEXY]: {
    stepIndex: 3,
    substepIndex: 0,
  },
  [DASHBOARD_STEPS.GOODBYE]: {
    stepIndex: 3,
    substepIndex: 1,
  },
  [DASHBOARD_STEPS.GETTING_TO_GOODBYE]: {
    stepIndex: 3,
    substepIndex: 1,
  },
};
