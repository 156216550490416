import { ButtonHTMLAttributes, ReactNode } from 'react';
// Constants
import { BUTTON_SIZE, BUTTON_VARIANT } from './model/constants/buttonSettings';
// Styles
import { buttonStyles, sizeStyles } from './model/constants/buttonStyles';
// Types
import { ButtonSizeType, ButtonVariantType } from './model/types/buttonTypes';

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  size?: ButtonSizeType;
  variant?: ButtonVariantType;
  text?: string;
  icon?: ReactNode;
  notificationCount?: number;
  navigation?: boolean;
}

const Button = ({
  icon,
  text = '',
  navigation,
  notificationCount,
  size = BUTTON_SIZE.MEDIUM,
  variant = BUTTON_VARIANT.PRIMARY,
  ...props
}: ButtonProps) => {
  const handleKeyDown: React.KeyboardEventHandler<HTMLButtonElement> = (e) => {
    if (e.code === 'Space') {
      e.preventDefault();
    }
  };
  const sizeClass = sizeStyles[size] || '';
  const variantClass = buttonStyles[variant] || '';
  const navigationClass = navigation
    ? '!px-[7px] !py-[7px] md_d:!px-[13px] md_d:!py-[13px] relative'
    : '';

  return (
    <button
      onKeyDown={handleKeyDown}
      {...props}
      className={`flex justify-center items-center font-['Inter'] transition-all duration-300 ${sizeClass} ${variantClass} ${navigationClass} ${props.className}`}
    >
      {icon}
      {text}
      {notificationCount && navigation && (
        <span className="absolute top-0 end-0 flex justify-center items-center w-[18px] h-[18px] text-[10px] font-medium bg-white leading-none border border-purple-600 rounded-full transform -translate-y-1/2 translate-x-1/2">
          {notificationCount}
        </span>
      )}
    </button>
  );
};

export default Button;
