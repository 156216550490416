export const linkToSessionEmailTemplate = ({
  recipientName = 'Customer',
  activationLink = '#',
  companyName = 'Your Company',
} = {}) => {
  return `<!DOCTYPE html>
    <html lang="en">
    <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>Link to Session Email Template</title>
        <style>
            /* Basic email styles */
            body {
                font-family: 'Inter', sans-serif;
                background-color: #e5e7eb;
                margin: 0;
                padding: 0;
                line-height: 1.6;
                color: #1f2937;
            }
            .email-container {
                background-color: #ffffff;
                width: 100%;
                max-width: 600px;
                margin: 20px auto;
                border-radius: 8px;
                box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
                overflow: hidden;
            }
            .email-header {
                background-color: #f3e8ff;
                color: #1f2937;
                padding: 20px;
                text-align: left;
            }
            .email-header img {
                max-width: 150px;
                height: auto;
            }
            .email-body {
                padding: 20px;
                color: #1f2937;
            }
            .email-footer {
                background-color: #f3e8ff;
                padding: 10px;
                text-align: center;
                font-size: 12px;
                color: #1f2937;
            }
            .button {
                background-color: #9333ea;
                color: #ffffff !important; /* Ensure the text color is white */
                padding: 10px 20px;
                text-decoration: none;
                border-radius: 5px;
                display: inline-block;
                margin-top: 20px;
                text-align: center;
            }
            .button svg {
                width: 16px;
                height: 16px;
                margin-left: 8px;
                vertical-align: middle;
            }
            .button:hover {
                background-color: #7e22ce;
            }
        </style>
    </head>
    <body>
        <div class="email-container">
            <!-- Email Header with Logo -->
            <div class="email-header">
              Betteryou
            </div>
    
            <!-- Email Body -->
            <div class="email-body">
                <p>Dear ${recipientName},</p>
                <p>Click the button below to join the session:</p>
                <a href="${activationLink}" class="button">Join session </a>
                <p>If you have any questions, feel free to reply to this email. We're here to help!</p>
                <p>Best Regards,<br>${companyName}</p>
            </div>
    
            <!-- Email Footer -->
            <div class="email-footer">
                <p>&copy; 2024 ${companyName}. All rights reserved.</p>
            </div>
        </div>
    </body>
    </html>`;
};
