import {
  // Calendar,
  House,
  // Settings,
  //  History,
  //   Video,
  Armchair,
} from 'lucide-react';

import { refaelaPatientActor } from '@components/xState/machines/refaelaPatientMachine';

import { iconProps } from '../../../model/constants/iconSettings';

export const ROOT_ACTIONS = {
  PatientPretalkWaitingRoom: {
    icon: <Armchair {...iconProps} />,
    name: 'Calibration',
    onClick: () => refaelaPatientActor.send({ type: 'goToPatientOffice' }),
  },
  OneOnOneVideo: {
    icon: <Armchair {...iconProps} />,
    name: 'Session',
    onClick: () => refaelaPatientActor.send({ type: 'goToPatientPretalkWaitingRoom' }),
  },
  // PatientSettings: {
  //   icon: <Video {...iconProps} />,
  //   name: 'Settings',
  //   onClick: () => refaelaPatientActor.send({ type: 'goToPatientOffice' }),
  // },
  default: {
    icon: <House {...iconProps} />,
    name: 'Home page',
    onClick: () => refaelaPatientActor.send({ type: 'goToPatientOffice' }),
  },
};

export const ACTIONS = [
  // {
  //   icon: <Calendar {...iconProps} />,
  //   name: 'Calendar',
  //   isDisplayed: true,
  //   onClick: () => refaelaPatientActor.send({ type: 'goToPatientNotification' }),
  // },
  // {
  //   icon: <History {...iconProps} />,
  //   name: 'Self history view',
  //   isDisplayed: true,
  //   onClick: () => refaelaPatientActor.send({ type: 'goToPatientSelfHistoryView' }),
  // },
  // {
  //   icon: <Settings {...iconProps} />,
  //   name: 'Settings',
  //   isDisplayed: true,
  //   onClick: () => refaelaPatientActor.send({ type: 'goToPatientSettings' }),
  // },
];
