export function getPatientIdWithPrefix(patientId: string) {
  if (patientId.includes('_P')) {
    return patientId;
  } else {
    return patientId + '_P';
  }
}

export function getPatientIdWithoutPrefix(patientId: string) {
  return patientId.replace(/_P$/, '');
}

export function getTherapistIdWithPrefix(therapistId: string) {
  if (therapistId.includes('_T')) {
    return therapistId;
  } else {
    return therapistId + '_T';
  }
}

export function getTherapistIdWithoutPrefix(therapistId: string) {
  return therapistId.replace(/_T$/, '');
}

export function getIdWithoutPrefix(id: string): string {
  return id.replace(/(_T|_P)$/, '');
}
