import { Langfuse } from 'langfuse';
import OpenAI from 'openai';
import { getTherapistActionFromInput } from '.';
import { LANGFUSE_PUBLIC_KEY, LANGFUSE_SECRET_KEY } from './constants.tsx';
import { Patient } from '@interfaces/index';

const openai = new OpenAI({ apiKey: import.meta.env.VITE_OPENAI_API_KEY, dangerouslyAllowBrowser: true });

export const langfuseWeb = new Langfuse({
  publicKey: `${LANGFUSE_PUBLIC_KEY}`,
  secretKey: `${LANGFUSE_SECRET_KEY}`,
  baseUrl: 'https://cloud.langfuse.com',
});

export const fetchTherapistOnActionPrompt = async (machineStates: string, currentState: string, input: string, patientList: Patient[]) => {
  // const patientsNames = getPatientsNamesSurnames(patientList);
  const patientsNames = patientList;

  const prompt = (await langfuseWeb.getPrompt('therapist-action-on-input')).compile({
    machineStates: machineStates,
    currentState: currentState,
    input: input,
    patientList: JSON.stringify(patientsNames),
  });

  return prompt;
};

export const processTherapistInput = async (machineStates: string, currentState: string, input: string, patientList: Patient[]) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const messages: any = [
    {
      role: 'user',
      content: await fetchTherapistOnActionPrompt(machineStates, currentState, input, patientList),
    },
  ];

  const tools = [
    {
      type: 'function',
      function: {
        name: 'transit_to_state',
        description: 'Transit to state',
        parameters: {
          type: 'object',
          properties: {
            input: { type: 'string', description: "The user's input" },
            transition: { type: 'string', description: 'The predicted transition' },
            patientId: { type: 'string', description: 'The patient ID (if applicable)' },
            patientInfo: {
              type: 'object',
              properties: { name: { type: 'string' }, surname: { type: 'string' } },
              description: 'The patient information name, surname etc. (if applicable)',
            },
          },
          required: ['transition'],
        },
      },
    },
  ];

  const startTime = Date.now();
  const response = await openai.chat.completions.create({
    model: 'gpt-4o',
    messages: messages,
    tools: tools,
    tool_choice: 'auto',
  } as OpenAI.ChatCompletionCreateParamsNonStreaming);
  const endTime = Date.now();

  const responseMessage = response.choices[0].message;

  const therapistInputToActionTrace = langfuseWeb.trace({
    name: 'therapist-input-action-1-trace',
    input: { prompt },
    output: { responseMessage },
  });

  therapistInputToActionTrace.generation({
    name: 'therapist-input-action-1',
    model: 'gpt-4o',
    input: prompt,
    output: responseMessage,
    startTime: new Date(startTime),
    endTime: new Date(endTime),
  });

  const toolCalls = responseMessage.tool_calls;
  if (toolCalls) {
    const availableFunctions = {
      transit_to_state: getTherapistActionFromInput,
    };

    messages.push(responseMessage);

    for (const toolCall of toolCalls) {
      const functionName = toolCall.function.name;
      const functionToCall = availableFunctions[functionName as keyof typeof availableFunctions];
      const functionArgs = JSON.parse(toolCall.function.arguments);
      const functionResponse = functionToCall(functionArgs);

      if (functionResponse !== undefined && functionResponse !== null) {
        messages.push({
          tool_call_id: toolCall.id,
          role: 'tool',
          name: functionName,
          content: String(functionResponse),
        });
      } else {
        messages.push({
          tool_call_id: toolCall.id,
          role: 'tool',
          name: functionName,
          content: 'No response',
        });
      }
    }

    const startTime2 = Date.now();
    const secondResponse = await openai.chat.completions.create({
      model: 'gpt-4o',
      messages: messages,
    });
    const endTime2 = Date.now();

    const therapistInputToAction2Trace = langfuseWeb.trace({
      name: 'therapist-input-action-2-trace',
      input: { messages },
      output: { responseMessage },
    });

    therapistInputToAction2Trace.generation({
      name: 'therapist-input-action-2',
      model: 'gpt-4o',
      input: messages,
      output: secondResponse,
      startTime: new Date(startTime2),
      endTime: new Date(endTime2),
    });

    return secondResponse.choices;
  }
};
