import React from 'react';
import { SingleLayoutWrapper } from '@components/styleHelpers';

const Notifications: React.FC = () => {
  return (
    <SingleLayoutWrapper>
      <h2 className="text-primary1">Notification</h2>
    </SingleLayoutWrapper>
  );
};

export default Notifications;
