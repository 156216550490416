import { assign, createActor, setup } from 'xstate';
import { TherapistMachineContext, TherapistMachineEvent } from './types.ts';
import { THERAPIST_MACHINE_EVENTS } from '@machines/therapist/constants/states.ts';
import { TherapistUser } from '@interfaces/therapist.ts';

export const refaelaTherapistMachine = setup({
  types: {
    context: {} as TherapistMachineContext,
    events: {} as TherapistMachineEvent,
  },
  guards: {
    hasPatientId: (_, params: { patientId: string }) => {
      return !!params.patientId;
    },
  },
  actions: {
    UPDATE_THERAPIST_USER: assign({
      currentUser: ({ event }) =>
        event.type === 'therapistUser.update' || event.type === 'UPDATE_THERAPIST_USER'
          ? event.currentUser
          : undefined,
    }),

    UPDATE_CURRENT_PATIENT_ID: assign({
      currentPatientId: ({ event }) =>
        event.type === 'currentPatientId.update' ? event.currentPatientId : undefined,
    }),
  },
}).createMachine({
  context: {
    currentPatientId: undefined,
    currentSessionId: undefined,
    currentUser: {} as TherapistUser,
    currentPatientEmail: '',
    currentPatientImage: '',
    currentPatientName: '',
    currentPatientSurname: '',
    aiReviewUserId: '',
    aiReviewSessionId: '',
    isFirstSessionSkipped: false,
  },
  id: 'TherapistMachine',
  initial: 'TherapistOfficeMode',
  states: {
    TherapistOfficeMode: {
      on: {
        'therapistUser.update': {
          actions: 'UPDATE_THERAPIST_USER',
        },
        skipFirstSession: {
          actions: assign({
            isFirstSessionSkipped: ({ event }) => event.isFirstSessionSkipped,
          }),
        },
        goToTherapistPretalkWaitingRoom: [
          {
            target: 'TherapistSession.TherapistPretalkWaitingRoom',
            actions: assign({
              currentPatientId: ({ event }) => event.patientId,
              currentSessionId: ({ event }) => event.sessionId,
            }),
          },
          {
            target: 'TherapistCalendar',
          },
        ],
        goToAIVideo: {
          target: `#TherapistMachine.${THERAPIST_MACHINE_EVENTS.AI_VIDEO}`,
        },
        goToAIDashboard: {
          target: `#TherapistMachine.${THERAPIST_MACHINE_EVENTS.AI_DASHBOARD}`,
          actions: assign({
            currentUser: ({ event }) => event.currentUser,
          }),
        },
        goToAssignAISession: {
          target: THERAPIST_MACHINE_EVENTS.AI_SESSION_ASSIGN,
        },
        goToPatientList: {
          target: 'PatientList',
        },
        goToTherapistCalendar: {
          target: 'TherapistCalendar',
        },
        goToTherapistNotification: {
          target: 'TherapistNotification',
        },
        goToTherapistSettings: {
          target: 'TherapistSettings',
        },
        goToTermsOfUse: {
          target: 'TermsOfUse',
        },
        goToSessionReplayByTherapist: [
          {
            target: 'SessionReplayByTherapist',
            actions: assign({
              currentPatientId: ({ event }) => event.patientId,
              currentSessionId: ({ event }) => event.sessionId,
            }),
          },
          {
            target: 'TherapistCalendar',
          },
        ],
        goToAISessionReview: [
          {
            target: 'AISessionReview',
            actions: assign({
              aiReviewUserId: ({ event }) => event.userId,
              aiReviewSessionId: ({ event }) => event.sessionId,
            }),
          },
          {
            target: 'TherapistCalendar',
          },
        ],
        goToPatientView: [
          {
            guard: {
              type: 'hasPatientId',
              params: ({ event }) => ({
                patientId: event.patientId,
              }),
            },
            target: 'PatientView',
            actions: assign({
              currentPatientId: ({ event }) => event.patientId,
            }),
          },
          {
            target: 'PatientList',
          },
        ],
        addPatientToTherapist: {
          target: 'AddPatientToTherapist',
        },
      },
      description:
        'Initial state for a therapist. The therapist can navigate to various sections from here.',
    },

    SessionReplayByTherapist: {
      on: {
        goToTherapistOffice: {
          target: 'TherapistOfficeMode',
        },
        goToPatientView: [
          {
            guard: {
              type: 'hasPatientId',
              params: ({ event }) => ({
                patientId: event.patientId,
              }),
            },
            target: 'PatientView',
            actions: assign({
              currentPatientId: ({ event }) => event.patientId,
            }),
          },
          {
            target: 'PatientList',
          },
        ],
        goToPatientList: {
          target: 'PatientList',
        },
        goToTherapistSettings: {
          target: 'TherapistSettings',
        },
        goToTherapistNotification: {
          target: 'TherapistNotification',
        },
        goToTherapistCalendar: {
          target: 'TherapistCalendar',
        },
      },
      description: 'Therapist can look through specific patient session',
    },
    PatientList: {
      on: {
        goToTherapistOffice: {
          target: 'TherapistOfficeMode',
        },
        goToPatientView: [
          {
            guard: {
              type: 'hasPatientId',
              params: ({ event }) => ({
                patientId: event.patientId,
              }),
            },
            target: 'PatientView',
            actions: assign({
              currentPatientId: ({ event }) => event.patientId,
              currentPatientName: ({ event }) => event.patientName,
              currentPatientSurname: ({ event }) => event.patientSurname,
              currentPatientEmail: ({ event }) => event.patientEmail,
              currentPatientImage: ({ event }) => event.patientImage,
            }),
          },
          {
            target: 'PatientList',
          },
        ],
        goToTherapistCalendar: {
          target: 'TherapistCalendar',
        },
        goToTherapistSettings: {
          target: 'TherapistSettings',
        },
        goToTherapistNotification: {
          target: 'TherapistNotification',
        },
        addPatientToTherapist: {
          target: 'AddPatientToTherapist',
        },
        UPDATE_THERAPIST_USER: {
          actions: 'UPDATE_THERAPIST_USER',
        },
      },
      description:
        'Therapist is viewing the patient list. Can go back to office or navigate to patient view, calendar, settings, or notification.',
    },
    TherapistCalendar: {
      on: {
        goToTherapistOffice: {
          target: 'TherapistOfficeMode',
        },
        goToPatientView: [
          {
            guard: {
              type: 'hasPatientId',
              params: ({ event }) => ({
                patientId: event.patientId,
              }),
            },
            target: 'PatientView',
            actions: assign({
              currentPatientId: ({ event }) => event.patientId,
            }),
          },
          {
            target: 'PatientList',
          },
        ],
        goToPatientList: {
          target: 'PatientList',
        },
        goToTherapistSettings: {
          target: 'TherapistSettings',
        },
        goToTherapistNotification: {
          target: 'TherapistNotification',
        },
      },
      description:
        'Therapist is viewing the calendar. Can go back to office or navigate to patient view, settings, or notification.',
    },
    AddPatientToTherapist: {
      on: {
        goToTherapistOffice: {
          target: 'TherapistOfficeMode',
        },
        goToPatientView: [
          {
            guard: {
              type: 'hasPatientId',
              params: ({ event }) => ({
                patientId: event.patientId,
              }),
            },
            target: 'PatientView',
            actions: assign({
              currentPatientId: ({ event }) => event.patientId,
            }),
          },
          {
            target: 'PatientList',
          },
        ],
        goToTherapistCalendar: {
          target: 'TherapistCalendar',
        },
        goToTherapistSettings: {
          target: 'TherapistSettings',
        },
        goToTherapistNotification: {
          target: 'TherapistNotification',
        },
      },
      description: 'Therapist fill form to invite patient',
    },
    TherapistNotification: {
      on: {
        goToPatientList: {
          target: 'PatientList',
        },
        goToTherapistCalendar: {
          target: 'TherapistCalendar',
        },
        goToTherapistSettings: {
          target: 'TherapistSettings',
        },
        goToTherapistOffice: {
          target: 'TherapistOfficeMode',
        },
        goToPatientView: [
          {
            guard: {
              type: 'hasPatientId',
              params: ({ event }) => ({
                patientId: event.patientId,
              }),
            },
            target: 'PatientView',
            actions: assign({
              currentPatientId: ({ event }) => event.patientId,
            }),
          },
          {
            target: 'PatientList',
          },
        ],
      },
      description: 'Therapist is viewing notifications. Can go back to office.',
    },
    TherapistSettings: {
      on: {
        goToPatientList: {
          target: 'PatientList',
        },
        goToTherapistCalendar: {
          target: 'TherapistCalendar',
        },
        goToTherapistNotification: {
          target: 'TherapistNotification',
        },
        goToTherapistOffice: {
          target: 'TherapistOfficeMode',
        },
        goToPatientView: [
          {
            guard: {
              type: 'hasPatientId',
              params: ({ event }) => ({
                patientId: event.patientId,
              }),
            },
            target: 'PatientView',
            actions: assign({
              currentPatientId: ({ event }) => event.patientId,
            }),
          },
          {
            target: 'PatientList',
          },
        ],
      },
      description:
        'Therapist is in settings. Can navigate to patient list, calendar, or notification.',
    },
    TherapistSession: {
      initial: 'TherapistPretalkWaitingRoom',
      states: {
        TherapistPretalkWaitingRoom: {
          on: {
            'currentPatientId.update': {
              actions: 'UPDATE_CURRENT_PATIENT_ID',
            },
            goToTherapistSession: {
              target: 'TherapistMeetingRoom',
              actions: assign({
                currentPatientId: ({ event }) => event.patientId,
                currentSessionId: ({ event }) => event.sessionId,
                roomUrl: ({ event }) => event.roomUrl,
              }),
            },
            goToTherapistOffice: {
              target: '#TherapistMachine.TherapistOfficeMode',
            },
            goToAIDashboard: {
              target: `#TherapistMachine.${THERAPIST_MACHINE_EVENTS.AI_DASHBOARD}`,
              actions: assign({
                currentUser: ({ event }) => event.currentUser,
              }),
            },
          },
          description:
            'Therapist is in the pretalk waiting room. Can proceed to session or go back to office.',
        },
        TherapistMeetingRoom: {
          on: {
            endSession: {
              target: 'TherapistDebriefWaitingRoom',
            },
          },
        },
        TherapistDebriefWaitingRoom: {
          on: {
            goToTherapistOffice: {
              target: '#TherapistMachine.TherapistOfficeMode',
            },
          },
          description: 'Therapist is in the debrief waiting room. Can go back to office from here.',
        },
      },

      description:
        'Therapist is in a session. After session, therapist can go to debrief waiting room.',
    },
    TermsOfUse: {
      on: {
        goToPatientList: {
          target: 'PatientList',
        },
        goToTherapistCalendar: {
          target: 'TherapistCalendar',
        },

        goToTherapistOffice: {
          target: 'TherapistOfficeMode',
        },
      },
      description: 'Terms of use',
    },
    AIDashboard: {
      on: {
        goToAIVideo: {
          target: `#TherapistMachine.${THERAPIST_MACHINE_EVENTS.AI_VIDEO}`,
        },
        goToTherapistOffice: {
          target: '#TherapistMachine.TherapistOfficeMode',
        },
      },
    },
    AISessionReview: {
      on: {
        goToTherapistOffice: {
          target: 'TherapistOfficeMode',
        },
        goToPatientView: [
          {
            guard: {
              type: 'hasPatientId',
              params: ({ event }) => ({
                patientId: event.patientId,
              }),
            },
            target: 'PatientView',
            actions: assign({
              currentPatientId: ({ event }) => event.patientId,
            }),
          },
          {
            target: 'PatientList',
          },
        ],
        goToPatientList: {
          target: 'PatientList',
        },
        goToTherapistSettings: {
          target: 'TherapistSettings',
        },
        goToTherapistNotification: {
          target: 'TherapistNotification',
        },
        goToTherapistCalendar: {
          target: 'TherapistCalendar',
        },
      },
      description: 'Therapist can look through specific patient session',
    },
    AIVideo: {
      on: {
        endSession: {
          target: '#TherapistMachine.TherapistOfficeMode',
        },
        goToTherapistOffice: {
          target: '#TherapistMachine.TherapistOfficeMode',
        },
      },
    },
    AssignAISession: {
      on: {
        goToTherapistOffice: {
          target: 'TherapistOfficeMode',
        },
        goBack: {
          target: 'TherapistOfficeMode',
        },
      },
    },

    PatientView: {
      on: {
        goToTherapistOffice: {
          target: 'TherapistOfficeMode',
        },
        goToPatientList: {
          target: 'PatientList',
        },
        goToTherapistCalendar: {
          target: 'TherapistCalendar',
        },
        goToTherapistNotification: {
          target: 'TherapistSettings',
        },
        goToSessionReplayByTherapist: [
          {
            target: 'SessionReplayByTherapist',
            actions: assign({
              currentPatientId: ({ event }) => event.patientId,
              currentSessionId: ({ event }) => event.sessionId,
            }),
          },
        ],
        goToPatientView: [
          {
            guard: {
              type: 'hasPatientId',
              params: ({ event }) => ({
                patientId: event.patientId,
              }),
            },

            actions: assign({
              currentPatientId: ({ event }) => event.patientId,
            }),
          },
          {
            target: 'PatientList',
          },
        ],
      },
      description: 'Therapist is viewing a patient. Can go back to office.',
    },
  },
});

export const refaelaTherapistActor = createActor(refaelaTherapistMachine, {});

// export function getActionFromInput(input: any) {
//   refaelaTherapistActor.send({ type: input });
// }
