import { SelectOption, SimpleSelect } from '@shared/ui/input/select/SimpleSelect';
import { useEffect, useState } from 'react';

type VoiceOption = {
  value: string;
  label: string;
};
const mockVoiceList = [
  { value: 'amuch', label: 'Amuch' },
  { value: 'dan', label: 'Dan Male' },
  { value: 'elan', label: 'Elan' },
  { value: 'marilyn', label: 'Marilyn Female' },
  { value: 'meadow', label: 'Meadow Female' },
  { value: 'breeze', label: 'Breeze Female' },
  { value: 'cove', label: 'Cove' },
  { value: 'ember', label: 'Ember' },
  { value: 'jupiter', label: 'Jupiter' },
  { value: 'alloy', label: 'Alloy Female' },
  { value: 'echo', label: 'Echo Male' },
  { value: 'shimmer', label: 'Shimmer Female' },
];

interface AIConversationVoiceSelectProps {
  initialValue?: string;
  onChange?: (newVoice: string) => void;
  disabled?: boolean;
}

const AIConversationVoiceSelect: React.FC<AIConversationVoiceSelectProps> = ({
  initialValue,
  onChange,
  disabled,
}) => {
  const [voiceList, setVoiceList] = useState<VoiceOption[]>([]);
  const [selectedOption, setSelectedOption] = useState<VoiceOption | undefined>(undefined);

  const handleVoiceChange = (selected: { value: string; label: string }) => {
    setSelectedOption(selected);
    if (onChange) {
      onChange(selected.value);
    }
  };

  const fetchVoiceList = async () => {
    // Simulate a delay to mimic an API call
    await new Promise((resolve) => setTimeout(resolve, 500));

    setVoiceList(mockVoiceList);
  };

  useEffect(() => {
    fetchVoiceList();
  }, []);

  useEffect(() => {
    if (initialValue) {
      const initialVoice = mockVoiceList.find((voice) => voice.value === initialValue);
      setSelectedOption(initialVoice);
    }
  }, [initialValue]);

  return (
    <SimpleSelect
      disabled={disabled}
      withSearch={false}
      selectClass="w-full"
      selectDropdownClass="w-full overflow-y-auto max-h-[20rem] !top-14 md_d:!top-[4.7rem] !left-0"
      selectedOption={selectedOption}
      placeholder="Select voice"
      onChange={handleVoiceChange}
    >
      {voiceList.map((voice, index) => (
        <SelectOption
          key={index}
          value={voice.value}
        >
          {voice.label}
        </SelectOption>
      ))}
    </SimpleSelect>
  );
};

export default AIConversationVoiceSelect;
