import { PatientMetadataType } from '@shared/types/patient';

export const getUpdatedPatientId = (
  updatedPatients: PatientMetadataType[],
  therapistPatients?: PatientMetadataType[]
) => {
  const filteredPatients = updatedPatients.filter(({ id: oldId }) => {
    if (!Array.isArray(therapistPatients)) {
      return true;
    }

    const isNewPatient = !therapistPatients.some(({ id: newPatientId }) => oldId === newPatientId);

    return isNewPatient;
  });

  const [{ id }] = filteredPatients;

  return id;
};
