import { useState, forwardRef } from 'react';
import { ShamefulAny } from '@interfaces/index';
import { Loader } from '@shared/ui/loader/Loader';
import Button from '@components/Button';

const SelfReviewMediaDisplayBlock = forwardRef<
  HTMLVideoElement,
  { frame: ShamefulAny; recording: ShamefulAny }
>(({ frame, recording }, ref) => {
  const [showVideo, setShowVideo] = useState(true);

  const isLoading = !frame && !recording;

  const toggleMedia = () => {
    setShowVideo((prev) => !prev);
  };

  return (
    <div className="mb-4 w-full">
      <div className="flex justify-end mb-2">
        <Button
          variant="outlinedPrimary"
          onClick={toggleMedia}
          text={showVideo ? 'Show Frame' : 'Show Video'}
          disabled={isLoading || (showVideo && !frame?.link) || (!showVideo && !recording?.link)}
        />
      </div>

      <div className="w-full h-full rounded flex items-center justify-center">
        {isLoading ? (
          <div className="w-[600px] h-[300px] bg-gray-500 flex items-center justify-center">
            <Loader />
          </div>
        ) : showVideo && recording?.link ? (
          <div className="w-[600px] h-[300px] bg-gray-500">
            <video
              className="h-full w-full object-contain"
              controls={false}
              src={recording.link}
              ref={ref}
            >
              Your browser does not support the video tag.
            </video>
          </div>
        ) : !showVideo && frame?.link ? (
          <div className="w-[600px] h-[300px] bg-gray-500">
            <img
              className="h-full w-full object-contain"
              src={frame.link}
              alt="Frame"
            />
          </div>
        ) : (
          <div className="w-[600px] h-[300px] bg-gray-500 flex items-center justify-center">
            <p className="text-white">
              {showVideo ? 'Video is unavailable' : 'Frame is unavailable'}
            </p>
          </div>
        )}
      </div>
    </div>
  );
});

export default SelfReviewMediaDisplayBlock;
