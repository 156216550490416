import { useSelector } from '@xstate/react';
import NavigationButton from '../ui/NavigationButton';
import { refaelaTherapistActor } from '@components/xState/machines/refaelaTherapistMachine';
import { ACTIONS, ROOT_ACTIONS } from './model/constants/actions';

const TherapistNavigation = () => {
  const snapshot = useSelector(refaelaTherapistActor, (snapshot) => snapshot);

  const rootIcon =
    ROOT_ACTIONS[
      snapshot.value as
        | 'TherapistPretalkWaitingRoom'
        | 'TherapistDebriefWaitingRoom'
        | 'TherapistSession'
    ] || ROOT_ACTIONS['default'];
  const actions = snapshot.matches('TherapistSession') ? undefined : ACTIONS;

  return (
    <div className="w-fit">
      <NavigationButton
        actions={actions}
        rootIcon={rootIcon}
      />
    </div>
  );
};

export default TherapistNavigation;
