import TextAvatar from '@features/Avatar';

type SessionListItemProps = {
  img?: string;
  fullName?: string;
  time?: string | number;
  date?: string | number;
  onClick?: () => void;
};

const SessionListItem = ({
  img,
  fullName = 'John Doe',
  time = 'Unset',
  date = '',
  onClick,
}: SessionListItemProps) => {
  return (
    <div
      onClick={onClick}
      className="flex cursor-pointer justify-between items-center py-1 pl-1 pr-2 sm_d:py-3 sm_d:pl-3 sm_d:pr-5 w-full h-10 sm_d:h-16 md_d:h-[72px] rounded-xl bg-bg-gray"
    >
      <div className="flex gap-x-3 overflow-hidden items-center">
        <TextAvatar
          alt="avatar"
          img={img}
        >
          {fullName}
        </TextAvatar>
        <p
          title={fullName}
          className="truncate text-sm sm_d:text-base"
        >
          {fullName}
        </p>
      </div>
      <div className=" flex text-sm sm_d:text-base pl-2 space-x-2">
        <p className="font-semibold">{date}</p>
        <p>{time}</p>
      </div>
    </div>
  );
};

export default SessionListItem;
