import * as d3 from 'd3';

const TICK_RANGE = 4;

export const getPlotYLeftAxisTicks = (min: number, max: number) => {
  if (!min && !max) return undefined;
  if (max < 20) {
    return d3.ticks(min > 0 ? 0 : min - 1, max + 2, TICK_RANGE);
  } else {
    return d3.ticks(min - 15, max + 15, TICK_RANGE);
  }
};
