import axios from 'axios';

export const deleteRoomById = async (roomId: string) => {
  const response = await axios.delete(`https://api.daily.co/v1/rooms/${roomId}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${import.meta.env.VITE_DAILY_API_KEY}`,
    },
  });

  return response;
};
