import { Children, isValidElement, ReactNode } from 'react';

export const findTextRecursively = (children: ReactNode): string => {
  let foundText: string = '';

  Children.forEach(children, (child) => {
    if (foundText) return;

    if (typeof child === 'string') {
      foundText = child;
    } else if (isValidElement(child)) {
      const nestedText = findTextRecursively(child.props.children);
      if (nestedText) {
        foundText = nestedText;
      }
    }
  });

  return foundText;
};
