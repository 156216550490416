import React, { useState } from 'react';

const MonthMini: React.FC = () => {
  const [currentDate, setCurrentDate] = useState(new Date());

  const getDaysInMonth = (date: Date): number[] => {
    const year = date.getFullYear();
    const month = date.getMonth();
    const firstDay = new Date(year, month, 1).getDay(); // 0 for Sunday, 1 for Monday, etc.
    const lastDate = new Date(year, month + 1, 0).getDate();

    const days: number[] = [];

    for (let i = 0; i < firstDay; i++) {
      days.push(0);
    }

    for (let i = 1; i <= lastDate; i++) {
      days.push(i);
    }

    while (days.length % 7 !== 0) {
      days.push(0);
    }

    return days;
  };

  const changeMonth = (offset: number) => {
    const newDate = new Date(currentDate);
    newDate.setMonth(currentDate.getMonth() + offset);
    setCurrentDate(newDate);
  };

  const daysInMonth = getDaysInMonth(currentDate);
  const monthName = currentDate.toLocaleString('default', { month: 'long', year: 'numeric' });
  const today = new Date();

  return (
    <div className="px-2 pb-2 mb-3 grid gap-y-0.5">
      <div className="w-[207px]">
        {/* Months */}
        <div className="flex justify-between items-center gap-1 mb-1">
          <span className="ps-1.5 block text-xs font-medium text-gray-500 dark:text-neutral-500">{monthName}</span>
          <div className="flex justify-end items-center gap-1">
            <button
              type="button"
              className="m-px size-7 flex justify-center items-center text-gray-500 hover:bg-gray-200 hover:text-gray-800 rounded-full disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-200 dark:text-neutral-500 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700 dark:hover:text-neutral-200 dark:focus:text-neutral-200"
              aria-label="Previous"
              onClick={() => changeMonth(-1)}
            >
              <svg
                className="shrink-0 size-3.5"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="m15 18-6-6 6-6" />
              </svg>
            </button>
            <button
              type="button"
              className="m-px size-7 flex justify-center items-center text-gray-500 hover:bg-gray-200 hover:text-gray-800 rounded-full disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-200 dark:text-neutral-500 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700 dark:hover:text-neutral-200 dark:focus:text-neutral-200"
              aria-label="Next"
              onClick={() => changeMonth(1)}
            >
              <svg
                className="shrink-0 size-3.5"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="m9 18 6-6-6-6" />
              </svg>
            </button>
          </div>
        </div>
        {/* Weeks */}
        <div className="flex mb-1.5">
          {['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'].map((day) => (
            <span key={day} className="m-px w-[1.725rem] block text-center text-[11px] text-gray-500 dark:text-neutral-500">
              {day}
            </span>
          ))}
        </div>
        {/* Days */}
        <div className="grid grid-cols-7 gap-1">
          {daysInMonth.map((day, index) => {
            const isCurrentDay =
              day === today.getDate() && currentDate.getMonth() === today.getMonth() && currentDate.getFullYear() === today.getFullYear();

            return (
              <button
                key={index}
                type="button"
                className={`m-px relative size-[1.725rem] flex justify-center items-center ${
                  day === 0 ? 'text-gray-400' : 'text-gray-800'
                } border border-transparent text-xs rounded-full ${
                  isCurrentDay ? 'bg-purple-600 text-white font-medium' : 'hover:bg-gray-200 dark:hover:bg-neutral-700 dark:text-neutral-200'
                } disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-200 dark:focus:bg-neutral-700`}
                disabled={day === 0}
              >
                {day === 0 ? '' : day}
              </button>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default MonthMini;
