// Constants
import { BreadcrumbItems } from '../constants/breadcrumbDict';
// Types
import { BreadcrumbItem } from '@shared/types/breadcrumbs/breadcrumb';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getActiveBreadcrumbFromState = (snapshot: any): BreadcrumbItem => {
  const {
    patientOffice,
    patientSettings,
    patientNotification,
    selfHistoryView,
    patientPretalkWaitingRoom,
  } = BreadcrumbItems;

  switch (true) {
    case snapshot.matches(patientOffice.value):
      return patientOffice;

    case snapshot.matches(patientSettings.value):
      return patientSettings;

    case snapshot.matches(patientNotification.value):
      return patientNotification;

    case snapshot.matches(selfHistoryView.value):
      return selfHistoryView;

    case snapshot.matches(patientPretalkWaitingRoom.value):
      return patientPretalkWaitingRoom;
    default:
      return patientOffice;
  }
};
