import React from 'react';
import { CircleAlert } from 'lucide-react';
import { alertVariants } from './constants.tsx';
import { AlertProps } from './types.ts';

export const Alert: React.FC<AlertProps> = ({ children, intent, title }) => {
  return (
    <div className={alertVariants({ intent })}>
      <AlertTitle>
        {intent === 'danger' && <CircleAlert size={18} />}
        {title}
      </AlertTitle>
      {children}
    </div>
  );
};

export const AlertTitle = React.forwardRef<HTMLParagraphElement, React.HTMLAttributes<HTMLHeadingElement>>(({ className, ...props }, ref) => (
  <p ref={ref} className={'text-base font-bold flex items-center gap-2 mb-2'} {...props} />
));

AlertTitle.displayName = 'AlertTitle';
