/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, memo } from 'react';
// Types
import { ITimelineRange } from './types';
import { AvailableRoleSignalsT } from './model/types/signals';
import { ValueOf } from '@utils/types';
import { ProcessedMXLabsData } from '@shared/types/mxlabs/logs';
import { USER_ROLES } from '@interfaces/user';
import { PlotTypeEnum, IPlotConfig } from './types';
// Components
import { ConfigurablePlotGraph } from './ConfigurablePlot';
import PlotContextMenu from './ui/PlotContextMenu';
// Utils
import { getAxesData } from './model/utils/getAxesData';
import { getDefaultPlotConfigData } from './utils';
import { PLOT_SIGNALS } from '@shared/constants/plot/signals';

type ConfigurableEmotionsProps = {
  isHrFilter: boolean;
  signalsData: Record<string, ProcessedMXLabsData[]>;
  timelineRange: ITimelineRange;
  plotConfig: IPlotConfig;
  onPlotClick?: (value: number) => void;
};

const Plot = memo(
  ({
    isHrFilter,
    signalsData,
    timelineRange,
    plotConfig,
    onPlotClick,
  }: ConfigurableEmotionsProps) => {
    const defaultPlotConfig = getDefaultPlotConfigData(plotConfig);

    const [configuredSignals, setConfiguredSignals] = useState<
      Record<ValueOf<typeof USER_ROLES>, ValueOf<typeof PLOT_SIGNALS>[]>
    >({
      [USER_ROLES.PATIENT]: defaultPlotConfig.configuredSignalsDefault,
      [USER_ROLES.THERAPIST]: defaultPlotConfig.configuredSignalsDefault,
      [USER_ROLES.ADMIN]: defaultPlotConfig.configuredSignalsDefault,
    });

    const [availableRoleSignals, setAvailableRoleSignals] = useState<
      Record<AvailableRoleSignalsT, boolean>
    >({ [USER_ROLES.PATIENT]: true, [USER_ROLES.THERAPIST]: true });

    const [plotType, setPlotType] = useState<PlotTypeEnum>(PlotTypeEnum.DEFAULT);

    const handleContextMenu = (plotType: PlotTypeEnum) => {
      setPlotType(plotType);
    };

    const [mainAxisData, secondaryAxisData] = getAxesData({
      signalsData,
      configuredSignals,
      timelineRange,
      availableRoleSignals,
    });

    const handleSignalChange = (
      role: ValueOf<typeof USER_ROLES>,
      value: ValueOf<typeof PLOT_SIGNALS>[] | boolean
    ) => {
      setConfiguredSignals((prev) => ({ ...prev, [role]: value }));
    };

    const handleAvailableRoleSignals = (role: AvailableRoleSignalsT, value: boolean) => {
      setAvailableRoleSignals((prev) => ({ ...prev, [role]: value }));
    };

    return (
      <div className="relative">
        <PlotContextMenu handleContextMenu={handleContextMenu}>
          <ConfigurablePlotGraph
            plotType={plotType}
            plotSize={plotConfig.plotSize}
            onPlotClick={onPlotClick}
            mainYPayload={mainAxisData}
            secondaryYPayload={secondaryAxisData}
            onSignalChange={handleSignalChange}
            onSwitchAvailableRoleSignalChange={handleAvailableRoleSignals}
            timelineRange={timelineRange}
            isHrFilter={isHrFilter}
            configuredSignals={configuredSignals}
            availableRoleSignals={availableRoleSignals}
          />
        </PlotContextMenu>
      </div>
    );
  }
);

Plot.displayName = 'Plot';

export default Plot;
