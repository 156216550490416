import React, { useEffect, useRef, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import CustomToolbar from './CustomToolbar';
import Quill from 'quill';
import './quill-custom.css';

const Font = Quill.import('formats/font');
Font.whitelist = ['sans-serif', 'serif', 'monospace', 'Inter'];
Quill.register(Font, true);

const Size = Quill.import('attributors/style/size');
Size.whitelist = ['small', 'normal', 'large', '16px'];
Quill.register(Size, true);

interface TextEditorProps {
  value: string;
  onChange: (content: string) => void;
  isLoading?: boolean;
  editorClasses?: string;
}

const TextEditor: React.FC<TextEditorProps> = ({ value, onChange, isLoading, editorClasses }) => {
  const quillRef = useRef<ReactQuill | null>(null);
  const [toolbarPosition, setToolbarPosition] = useState<{ top: number; left: number } | null>(
    null
  );

  const handleSelectionChange = () => {
    const editor = quillRef.current?.getEditor();
    const range = editor?.getSelection();
    if (range && range.length > 0) {
      const bounds = editor?.getBounds(range.index, range.length);
      if (bounds) {
        setToolbarPosition({
          top: bounds.top + bounds.height + 10,
          left: bounds.left,
        });
      }
    } else {
      setToolbarPosition(null);
    }
  };

  useEffect(() => {
    const editor = quillRef.current?.getEditor();
    if (editor) {
      editor.on('selection-change', handleSelectionChange);
    }
    return () => {
      if (editor) {
        editor.off('selection-change', handleSelectionChange);
      }
    };
  }, []);

  return (
    <div className="relative">
      <ReactQuill
        ref={quillRef}
        className={` border rounded-lg shadow border-gray-200 focus:border-gray-400 focus:outline-none focus:ring-0 ${editorClasses}`}
        theme="snow"
        value={value}
        onChange={onChange}
        modules={{
          toolbar: false,
        }}
      />
      {toolbarPosition && quillRef.current && (
        <div
          className="absolute z-10"
          style={{ top: `${toolbarPosition.top}px`, left: `${toolbarPosition.left}px` }}
        >
          <CustomToolbar quill={quillRef.current.getEditor()} />
        </div>
      )}
      {isLoading && (
        <div className="absolute inset-0 bg-white bg-opacity-70 flex items-center justify-center">
          <p>Loading...</p>
        </div>
      )}
    </div>
  );
};

export default TextEditor;
