import { useCallback, useEffect, useState } from 'react';
import { useSelector } from '@xstate/react';

import { refaelaPatientActor } from '@components/xState/machines/refaelaPatientMachine.ts';
// Utils
import { getActiveBreadcrumbFromState } from './model/utils/getActiveBreadcrumb';
// Constants
import { PREVSTATE_IGNORE_LIST } from './model/constants/breadcrumbDict';

import Breadcrumbs from '@features/Breadcrumb/Breadcrumbs.tsx';

const PatientBreadcrumbs = () => {
  const snapshot: any = useSelector(refaelaPatientActor, (state) => state);

  const [prevSnapshot, setPrevSnapshot] = useState<any | null>(null);
  const [currentSnapshot, setCurrentSnapshot] = useState<any | null>(null);

  useEffect(() => {
    setPrevSnapshot(PREVSTATE_IGNORE_LIST.includes(snapshot.value) ? undefined : currentSnapshot);
    setCurrentSnapshot(snapshot);
  }, [snapshot]);

  const currentState = getActiveBreadcrumbFromState(snapshot);

  const prevState = prevSnapshot && getActiveBreadcrumbFromState(prevSnapshot);

  const handlePrevRouteClick = useCallback(() => {
    if (prevState) prevState.goToAction();
  }, [prevState]);

  return (
    <Breadcrumbs
      currentState={currentState}
      prevState={prevState}
      onPrevRouteClick={handlePrevRouteClick}
    />
  );
};

export default PatientBreadcrumbs;
