import axios, { AxiosError, AxiosResponse } from 'axios';
import { BASE_URL } from './base';

const apiClient = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-type': 'application/json',
  },
});

// apiClient.interceptors.request.use(
//   (config: InternalAxiosRequestConfig) => {
//     return config;
//   },
//   (error) => {
//     console.error('interceptor request error', error);
//     Promise.reject(error);
//   }
// );

apiClient.interceptors.response.use(
  (response: AxiosResponse) => {
    return response;
  },
  (error: AxiosError<Record<'info' | 'message' | 'details' | 'errors', string>>) => {
    let errorMessage = `Something went wrong, ${error.status}, ${error.message}`;
    if (error.response) {
      const { data } = error.response;
      // https://docs.daily.co/reference/rest-api#errors
      if (data.info) {
        return Promise.reject({ message: data.info, details: errorMessage });
      }
      // {message} from BE
      if (data.message) {
        return Promise.reject({ message: data.message, details: data.details });
      }
    }
    return Promise.reject({ message: errorMessage, details: error });
  }
);

export { apiClient };
