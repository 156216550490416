import { ShamefulAny } from '@interfaces/index';
import { apiClient } from '..';

export async function getAiSessionRecording(
  userId: string,
  sessionId: string
): Promise<ShamefulAny> {
  try {
    const params = {
      userId,
      sessionId,
    };

    const data = await apiClient.get('/ai/recording', { params });

    return data;
  } catch (e: ShamefulAny) {
    console.error('Error fetching recording:', e.message, e.details);

    return {
      error: true,
      message: `${e.message}: ${e.details || 'No additional details'}`,
    };
  }
}
