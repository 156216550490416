import { ShamefulAny } from '@interfaces/index';
import { apiClient } from '..';

export async function getSessionInfo(sessionId: string): Promise<ShamefulAny> {
  try {
    const params = { sessionId };

    const response = await apiClient.get('files/session-info', { params });

    return response.data;
  } catch (e: ShamefulAny) {
    console.error('Error fetching session info:', e.message, e.details);

    return {
      error: true,
      message: `${e.message}: ${e.details || 'No additional details'}`,
    };
  }
}
