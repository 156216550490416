import { KeyMoment } from '@components/SessionComponents/KeyMoments/types';

export function formatTime(timestamp: number) {
  const date = new Date(timestamp);
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();
  return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
}
export const findClosestKeyMoment = (transcriptTimestamp: number, keyMoments: KeyMoment[]) => {
  let closestKeyMoment: KeyMoment | null = null;
  let minDifference = Infinity;

  for (const keyMoment of keyMoments) {
    const difference = Math.abs(transcriptTimestamp - new Date(keyMoment.timestamp).getTime());

    if (difference < minDifference) {
      closestKeyMoment = keyMoment;
      minDifference = difference;
    }
  }

  return closestKeyMoment;
};
export const convertMillisToTime = (timeInMillis: number) => {
  const totalSeconds = Math.floor(timeInMillis / 1000);
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;

  const formattedMinutes = minutes.toString().padStart(2, '0');
  const formattedSeconds = seconds.toString().padStart(2, '0');

  if (hours > 0) {
    const formattedHours = hours.toString().padStart(2, '0');
    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  } else {
    return `${formattedMinutes}:${formattedSeconds}`;
  }
};
