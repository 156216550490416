import React from 'react';
import { BioStatusCardDaily } from './bioModule.styled';
import { HeartIcon } from '../../assets/icons';
import { ShenaiSdkBaseState } from '@shared/types/shenai/sdk';

interface HeartBeatProps {
  sdkState: ShenaiSdkBaseState | undefined;
  isHeartColorChanged?: boolean;
  heartIconColor?: string;
}

const HeartBeatBlock: React.FC<HeartBeatProps> = ({
  sdkState,
  isHeartColorChanged,
  heartIconColor,
}) => {
  const direction = 'ltr';
  const heartIconColorStyle = heartIconColor ? `fill-[${heartIconColor}]` : 'fill-purple-600';
  const heartIconColorClass = isHeartColorChanged ? heartIconColorStyle : 'fill-[#df6569]';
  return (
    <BioStatusCardDaily className="w-full flex items-center justify-start">
      <div className="active">
        <div
          className={
            direction == 'ltr'
              ? 'heartbeat-block-number left'
              : 'flex items-center flex-row-reverse text-2xl gap-[3px]'
          }
        >
          <span className="text-6xl mr-3 font-semibold leading-[60px] max-w-[130px]">
            {sdkState?.hr4s ? `${sdkState.hr4s.toFixed(0)}` : ''}
          </span>
        </div>
      </div>
      <div className="active">
        <div className={` ${heartIconColorClass} flex justify-center w-full h-6 heartIcon`}>
          <HeartIcon />
        </div>
        <span className={'unit'}>{sdkState?.hr4s ? ' bpm' : '...'}</span>
      </div>
    </BioStatusCardDaily>
  );
};

export default HeartBeatBlock;
