import clsx from 'clsx';
import { DailyVideo, useVideoTrack } from '@daily-co/daily-react';
import { TileContainer } from './styled';
import { memo } from 'react';

export interface TileProps {
  id: string;
  isScreenShare?: boolean;
  isLocal?: boolean;
  isAlone?: boolean;
  isPresentationMode?: boolean;
}

const Tile = memo(
  ({ id, isScreenShare, isLocal, isAlone, isPresentationMode }: TileProps): JSX.Element => {
    const videoState = useVideoTrack(id);

    return (
      <TileContainer
        className={clsx('rounded-2xl', {
          'tile-aside': isPresentationMode,
          'tile-screenshare': isScreenShare,
          'tile-video': !isScreenShare,
          'self-view': isLocal && !isPresentationMode,
          alone: isAlone,
          /* If a participant's video is muted, hide their video and
  add a different background color to their tile. */
          'no-video': videoState.isOff,
        })}
      >
        <DailyVideo
          automirror
          sessionId={id}
          type={isScreenShare ? 'screenVideo' : 'video'}
        />
        {/* {!isScreenShare && <Username id={id} isLocal={isLocal} />} */}
      </TileContainer>
    );
  }
);

export default Tile;
