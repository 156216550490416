import { useMXLabsLogsAISessionReview } from '@components/Plot/utils';
import { AISession, ShamefulAny } from '@interfaces/index';

import { useState, useEffect, useCallback, useRef } from 'react';
import SelfReviewMeasurementGraphs from './ui/SelfReviewMeasurementGraphs';
import SelfReviewTimeline from './ui/SelfReviewTimeline';
import SelfReviewVisionAIModel from './ui/VisionAIModel/SelfReviewVisionAIModel';
import { refaelaTherapistActor } from '@components/xState/machines/refaelaTherapistMachine';
import { useSelector } from '@xstate/react';
import { getAiSessionFrames } from '@api/ai/aiFrame';
import { getAiSessionRecording } from '@api/ai/aiRecording';

import SelfReviewMediaDisplayBlock from './ui/SelfReviewMediaDisplayBlock';
import { getSessionInfo } from '@api/session/sessionInfo';

const SelfWorkReview = () => {
  const [currentSelfReviewTime, setCurrentSelfReviewTime] = useState<number>(0);

  const [currentDataPoint, setCurrentDataPoint] = useState<ShamefulAny | null>(null);
  const [frames, setFrames] = useState<ShamefulAny | null>(null);
  const [recording, setRecording] = useState<ShamefulAny | null>(null);
  const [filteredFrames, setFilteredFrames] = useState<ShamefulAny[]>([]);
  const [filteredMxlabsData, setFilteredMxlabsData] = useState<ShamefulAny[]>([]); // filtered according start and stop time from session.json
  const [sessionInfoData, setSessionInfoData] = useState<AISession | null>(null);

  const [currentFrame, setCurrentFrame] = useState<ShamefulAny | null>(null);
  const videoRef = useRef<HTMLVideoElement>(null);
  const stateMachineContext = useSelector(
    refaelaTherapistActor,
    (state: ShamefulAny) => state.context
  );

  const aiReviewUserId =
    stateMachineContext.aiReviewUserId ===
    stateMachineContext.currentUser.therapistId.replace('_T', '')
      ? stateMachineContext.currentUser.therapistId
      : `${stateMachineContext.aiReviewUserId}_P`;

  const aiReviewSessionId = stateMachineContext.aiReviewSessionId;
  const { logs: mxlabs } = useMXLabsLogsAISessionReview(aiReviewUserId, aiReviewSessionId);

  const fetchSessionData = useCallback(async () => {
    try {
      const sessionResponse = await getSessionInfo(aiReviewSessionId);

      if (sessionResponse.error) {
        throw new Error(sessionResponse.message);
      }

      setSessionInfoData(sessionResponse);
    } catch (error: ShamefulAny) {
      console.error('Error fetching session data:', error);
    }
  }, [aiReviewSessionId]);

  useEffect(() => {
    fetchSessionData();
  }, [fetchSessionData]);

  const fetchFrames = useCallback(async () => {
    try {
      const framesResponse = await getAiSessionFrames(aiReviewUserId, aiReviewSessionId);

      if (framesResponse.error) {
        throw new Error(framesResponse.message);
      }

      const framesData = framesResponse.data.frames || [];
      setFrames(framesData);
    } catch (error: ShamefulAny) {
      console.error('Error fetching frames:', error);
    }
  }, [aiReviewUserId, aiReviewSessionId]);

  useEffect(() => {
    fetchFrames();
  }, [fetchFrames]);

  const fetchRecording = useCallback(async () => {
    try {
      const recordingResponse = await getAiSessionRecording(aiReviewUserId, aiReviewSessionId);

      if (recordingResponse.error) {
        throw new Error(recordingResponse.message);
      }

      const recordingData = recordingResponse.data.recording || {};
      setRecording(recordingData);
    } catch (error: ShamefulAny) {
      console.error('Error fetching recording:', error);
    }
  }, [aiReviewUserId, aiReviewSessionId]);

  useEffect(() => {
    fetchRecording();
  }, [fetchRecording]);

  // Filter frames and mxlabs based on session info start and end times
  useEffect(() => {
    if (sessionInfoData && frames && mxlabs) {
      const { sessionStartTime, sessionStopTime } = sessionInfoData;

      setCurrentSelfReviewTime(sessionStartTime || 0);

      const filteredFramesData = frames.filter(
        (frame: ShamefulAny) =>
          frame.timestamp >= sessionStartTime && frame.timestamp <= sessionStopTime
      );
      setFilteredFrames(filteredFramesData);

      const filteredMxlabs = mxlabs.filter(
        (data: ShamefulAny) =>
          data.timestamp >= sessionStartTime && data.timestamp <= sessionStopTime
      );
      setFilteredMxlabsData(filteredMxlabs);
    }
  }, [sessionInfoData, frames, mxlabs]);

  // Find the closest data point and corresponding frame
  useEffect(() => {
    const closestData =
      filteredMxlabsData.find((el: ShamefulAny) => el.timestamp >= currentSelfReviewTime) || null;
    setCurrentDataPoint(closestData);

    if (filteredFrames) {
      const closestFrame =
        filteredFrames.find((frame: ShamefulAny) => frame.timestamp >= currentSelfReviewTime) ||
        null;
      setCurrentFrame(closestFrame);
    }
  }, [currentSelfReviewTime, filteredMxlabsData, filteredFrames]);

  return (
    <div className="grid grid-cols-[25%_50%_25%] gap-4 p-6 w-full text-left">
      <div className="bg-transparent rounded-lg">
        <SelfReviewVisionAIModel
          key="review-vision-ai-model"
          currentDataPoint={currentDataPoint}
          currentSelfReviewTime={currentSelfReviewTime}
          dataFromMxlabs={filteredMxlabsData}
        />
      </div>
      <div className="bg-transparent p-4 rounded-lg col-span-1.5">
        <SelfReviewMediaDisplayBlock
          frame={currentFrame}
          recording={recording}
          ref={videoRef}
        />
        <SelfReviewTimeline
          startTime={sessionInfoData?.sessionStartTime || 0}
          endTime={sessionInfoData?.sessionStopTime || 0}
          videoRef={videoRef}
          setCurrentSelfReviewTime={setCurrentSelfReviewTime}
          currentSelfReviewTime={currentSelfReviewTime}
        />
        <SelfReviewMeasurementGraphs
          filteredDataFromMxlabs={filteredMxlabsData}
          currentDataPoint={currentDataPoint}
          currentSelfReviewTime={currentSelfReviewTime}
        />
      </div>
      <div className="bg-transparent p-4 rounded-lg">{/* Additional components */}</div>
    </div>
  );
};

export default SelfWorkReview;
