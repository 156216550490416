import {
  NotificationIcon,
  OfficeModeIcon,
  PatientListIcon,
  SettingsIcon,
  WaitingRoomIcon,
} from '@assets/icons';

import { BreadcrumbItem } from '@shared/types/breadcrumbs/breadcrumb';

import { refaelaPatientActor } from '@components/xState/machines/refaelaPatientMachine';

export const BreadcrumbItems: Record<string, BreadcrumbItem> = {
  patientOffice: {
    value: 'PatientOfficeMode',
    nameToDisplay: 'Home',
    icon: <OfficeModeIcon />,
    goToAction: () => refaelaPatientActor.send({ type: 'goToPatientOffice' }),
  },

  patientSettings: {
    value: 'PatientSettings',
    nameToDisplay: 'Settings',
    icon: <SettingsIcon />,
    goToAction: () => refaelaPatientActor.send({ type: 'goToPatientSettings' }),
  },
  patientNotification: {
    value: 'PatientNotification',
    nameToDisplay: 'Notification',
    icon: <NotificationIcon />,
    goToAction: () => refaelaPatientActor.send({ type: 'goToPatientNotification' }),
  },
  selfHistoryView: {
    value: 'SelfHistoryView',
    nameToDisplay: 'Patient Timeline',
    icon: <PatientListIcon />,
    goToAction: (patientId?: string) =>
      patientId && refaelaPatientActor.send({ type: 'goToPatientSelfHistoryView' }),
  },
  patientPretalkWaitingRoom: {
    value: 'PatientVideoSession.PatientPretalkWaitingRoom',
    nameToDisplay: 'Waiting room',
    icon: <WaitingRoomIcon className="stroke-gray-800" />,
    forceIcon: true,
    goToAction: () => refaelaPatientActor.send({ type: 'goToPatientPretalkWaitingRoom' }),
  },
};

export const PREVSTATE_IGNORE_LIST = [
  BreadcrumbItems.patientOffice.value,
  BreadcrumbItems.patientPretalkWaitingRoom.value,
];
