import { MEASUREMENT_STATE } from '../constants/states';
import { MeasurementStateType } from '../types/states';

export const getMeasurementStateValue = (value: MeasurementStateType) => {
  switch (value) {
    case MEASUREMENT_STATE.NOT_STARTED:
      return 'NotStarted';
    case MEASUREMENT_STATE.WAITING_FOR_FACE:
      return 'WaitingFace';
    case MEASUREMENT_STATE.RUNNING_SIGNAL_SHORT:
      return 'ShortSignal';
    case MEASUREMENT_STATE.RUNNING_SIGNAL_GOOD:
      return 'GoodSignal';
    case MEASUREMENT_STATE.RUNNING_SIGNAL_BAD:
      return 'BadSignal';
    case MEASUREMENT_STATE.RUNNING_SIGNAL_BAD_DEVICE_UNSTABLE:
      return 'UnstableDevice';
    case MEASUREMENT_STATE.FINISHED:
      return 'Finished';
    default:
      return 'Failed';
  }
};
