import React from 'react';
import {
  BoldIcon,
  HeadingIcon,
  HighlighterIcon,
  ListIcon,
  RemoveFormattingIcon,
} from 'lucide-react';
import { ShamefulAny } from '@interfaces/index';

interface CustomToolbarProps {
  quill: ShamefulAny;
}

const CustomToolbar: React.FC<CustomToolbarProps> = ({ quill }) => {
  if (!quill) return null;

  return (
    <div className="flex gap-2 p-2 bg-purple-600 text-white rounded">
      <button onClick={() => quill.format('bold', true)}>
        <BoldIcon />
      </button>
      <button onClick={() => quill.format('list', 'bullet')}>
        <ListIcon />
      </button>
      <button
        onClick={() => {
          quill.format('background', '#9333ea');
          quill.format('color', '#fff');
        }}
      >
        <HighlighterIcon />
      </button>
      <button
        onClick={() => {
          quill.format('font', 'Inter');
          quill.format('size', '16px');
          quill.format('bold', true);
        }}
      >
        <HeadingIcon />
      </button>
      <button
        onClick={() => {
          const range = quill.getSelection();
          if (range) {
            quill.removeFormat(range.index, range.length);
          }
        }}
      >
        <RemoveFormattingIcon />
      </button>
    </div>
  );
};

export default CustomToolbar;
