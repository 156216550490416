import { useState, useCallback, useEffect, memo } from 'react';
import { useDailyEvent, useDaily, useDevices } from '@daily-co/daily-react';

import UserMediaError from '../../../../components/Daily.co/UserMediaError/UserMediaError';

import { useUser } from '@clerk/clerk-react';

interface CallProps {
  roomUrl: string;
  meetingToken: string;
  userRole: string;
}

const AiCall = memo(
  ({ userRole = '', roomUrl, meetingToken }: CallProps) => {
    const [getUserMediaError, setGetUserMediaError] = useState(false);

    const [joinedMeeting, setJoinedMeeting] = useState(false);

    const callObject = useDaily();
    const { user } = useUser();

    const firstName = user?.firstName;
    const lastName = user?.lastName;

    const { setCamera, setMicrophone } = useDevices();

    useEffect(() => {
      const initialCameraId = localStorage.getItem('cameraId');
      const initialMic = localStorage.getItem('microphoneId');
      initialCameraId && setCamera(initialCameraId);
      initialMic && setMicrophone(initialMic);
    }, []);

    useDailyEvent(
      'camera-error',
      useCallback(() => {
        setGetUserMediaError(true);
      }, [])
    );

    useEffect(() => {
      !joinedMeeting &&
        callObject &&
        callObject.join({
          url: roomUrl,
          userName: `${firstName} ${lastName}`,
          userData: {
            userRole: userRole,
          },

          token: meetingToken,
        });
      setJoinedMeeting(true);
    }, [callObject, joinedMeeting]);

    return getUserMediaError ? <UserMediaError /> : <></>;
  }
);

export default AiCall;
