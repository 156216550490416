import { PropsWithChildren } from 'react';

const HomePageLayout = ({ children }: PropsWithChildren) => {
  return (
    <div className="w-full pl-[3.875rem] flex-grow flex flex-col justify-start mt-6 gap-2.5">
      {children}
    </div>
  );
};

export default HomePageLayout;
