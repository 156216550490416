import { ShamefulAny } from '@interfaces/index';
import { apiClient } from '..';

export const updateClerkUserDataById = async (userId: string, data: ShamefulAny) => {
  try {
    const response = await apiClient.put('/user/update_user_data', {
      userId,
      ...data,
    });
    return response.data;
  } catch (error: ShamefulAny) {
    console.error('Failed to update patient data:', error);

    const details = error.details;
    const longMessage = details[0]?.longMessage;

    throw new Error(
      `Failed to update patient data: ${longMessage}` || 'Failed to update patient data'
    );
  }
};
