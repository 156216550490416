import { useContext, useEffect, useRef, useState } from 'react';
import { InitializationSettings, ShenaiSDK } from '../../../../../public/shenai-sdk/index'; // import from local
import { useShenaiSdk } from '@hooks/useShenaiSdk';
import { delay } from '@utils/helpers';
import { AppContext } from '../../../../contextApp';
import { EventLogType } from '@models/eventLog';
import { askChatGPT } from '@api/userAPI';
import { dashboardActor } from '../xstate/dashboardMachine';
import { ShenaiSdkBaseState } from '@shared/types/shenai/sdk';
import { postAiCameraFrame } from '@services/frame';

export const useShenai = (
  componentName: string,
  isLogsSending?: boolean,
  userId?: string,
  sessionId?: string
) => {
  const { addEventLog } = useContext(AppContext);
  const shenaiSDKRef = useRef<any | null>(null);
  const [isSDKInitialized, setIsSDKInitialized] = useState(false);
  const [isShenAICalibrated, setIsShenAICalibrated] = useState(false);
  const [isShenAIMeasurementStarted, setShenAIMeasurementStarted] = useState(false);
  const [sdkData, setSdkData] = useState<any>({});
  const [initializationSettings, setInitializationSettings] = useState<InitializationSettings>();
  const shenaiSDK = useShenaiSdk();
  const [cameraId, setCameraId] = useState('');
  shenaiSDKRef.current = shenaiSDK;

  const SHENAI_API_KEY = import.meta.env.VITE_VF_SHENAI_SDK_API_KEY;

  const fetchData = async () => {
    if (isSDKInitialized && shenaiSDKRef.current) {
      const sdk = shenaiSDKRef.current;
      const newData = {
        heartRate: sdk.getRealtimeHeartRate(),
        signalQuality: sdk.getCurrentSignalQualityMetric(),
        measurementProgress: sdk.getMeasurementProgressPercentage(),
        isInitialized: sdk.isInitialized(),
        faceState: sdk.getFaceState(),
        hr4s: sdk.getHeartRate4s(),
        hr10s: sdk.getHeartRate10s(),
        cardiacStressRT: sdk.getRealtimeCardiacStress(),
        hrvSDNN: sdk.getRealtimeHrvSdnn(),
        bbox: sdk.getNormalizedFaceBbox(),
        measurementState: sdk.getMeasurementState(),
        badSignal: sdk.getTotalBadSignalSeconds(),
        facePose: sdk.getFacePose(),
        results: sdk.getMeasurementResults(),
        realtimeMetrics: sdk.getRealtimeMetrics(10),
        realtimeHeartbeats: sdk.getRealtimeHeartbeats(100),
      };

      const { result = 1 } = newData.bbox
        ? await askChatGPT('', '', newData.bbox, 'movement-ai-agent')
        : {};

      setSdkData({
        ...newData,
        visibilityState: document.visibilityState == 'visible' ? 1 : 0,
        movementRateValue: result?.movementRateValue || 1,
      });

      addEventLog([
        {
          type: EventLogType.SHENAI_DATA,
          data: {
            ...newData,
            faceState: newData.faceState?.value,
            measurementState: newData.measurementState?.value,
            visibilityState: document.visibilityState == 'visible' ? 1 : 0,
            movementRateValue: result?.movementRateValue || 1,
          },
        },
      ]);
    }
  };

  const updateCanvas = () => {
    const oldCanvas = document.getElementById('mxcanvas1') as HTMLCanvasElement;

    if (oldCanvas) {
      const newCanvas = document.createElement('canvas');
      newCanvas.width = oldCanvas.width;
      newCanvas.height = oldCanvas.height;

      newCanvas.style.width = '100%';
      newCanvas.style.height = '100%';
      newCanvas.style.maxWidth = '600px';
      newCanvas.style.maxHeight = '300px';
      newCanvas.style.aspectRatio = '5 / 4';
      newCanvas.id = 'mxcanvas1';

      // Using requestAnimationFrame for smooth replacement
      requestAnimationFrame(() => {
        oldCanvas.replaceWith(newCanvas);
        console.log('Canvas updated after deinitialization');
      });
    }
  };

  const initializeShenAiSdk = (settings: InitializationSettings, onSuccess?: () => void) => {
    if (!shenaiSDK || isSDKInitialized) return;

    shenaiSDK.initialize(SHENAI_API_KEY, '', settings, (res) => {
      if (res === shenaiSDK.InitializationResult.OK) {
        console.log('Shen.AI License result: ', res);
        shenaiSDK.setRecordingEnabled(false);
        shenaiSDK.attachToCanvas('#mxcanvas1');
        console.log('initialised successfully with version', shenaiSDK.getVersion());
        setIsSDKInitialized(true);

        shenaiSDK?.selectCameraByDeviceId(cameraId, true);
        onSuccess?.();
      } else {
        console.error('Shen.AI SDK initialization failed or canvas not found.');
      }
    });
  };

  const deinitializeShenAiSdk = async () => {
    if (isSDKInitialized && shenaiSDKRef.current) {
      try {
        shenaiSDKRef.current.deinitialize();
        setIsSDKInitialized(false);
        console.log('Shen.AI SDK deinitialized successfully');

        //TODO: remove? or why we need this log
        // const oldCanvas = document.getElementById('mxcanvas1') as HTMLCanvasElement;

        // console.log('oldCanvas: ', oldCanvas);

        // Wait before updating the canvas
        await delay(500);

        updateCanvas();

        // Add delay to stabilize the process
        await delay(1000);
      } catch (error) {
        console.error('Error during Shen.AI SDK deinitialization:', error);
      }
    }
  };

  useEffect(() => {
    const interval = setInterval(fetchData, 1000);

    return () => {
      clearInterval(interval);

      if (isSDKInitialized && shenaiSDKRef.current) {
        shenaiSDKRef.current.deinitialize();
        setIsSDKInitialized(false);
        console.log('Shen.AI SDK deinitialized successfully');
      }
    };
  }, [isSDKInitialized]);

  async function getDeviceId(retryInterval = 1000, maxRetries = 10) {
    let retries = 0;

    while (retries < maxRetries) {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const videoDevices = devices.filter((device) => device.kind === 'videoinput');

      if (videoDevices.length > 0 && videoDevices[0].deviceId != '') {
        localStorage.setItem('cameraId', `${videoDevices[0].deviceId}`);
        return setCameraId(videoDevices[0].deviceId); // Return the first available video device ID
      }

      retries++;
      await new Promise((resolve) => setTimeout(resolve, retryInterval));
    }

    throw new Error('No media devices found after retries.');
  }

  function getIsFaceOk(saState?: ShenaiSdkBaseState | null, saSDK?: ShenaiSDK | null): boolean {
    if (!saState || !saSDK) {
      return false;
    }

    switch (saState.faceState) {
      case saSDK.FaceState.OK:
      case saSDK.FaceState.TOO_CLOSE:
      case saSDK.FaceState.TOO_FAR:
      case saSDK.FaceState.NOT_CENTERED:
        return true;
      default:
        return false;
    }
  }

  useEffect(() => {
    getDeviceId();

    if (shenaiSDK) {
      const settings: InitializationSettings = {
        precisionMode: shenaiSDK.PrecisionMode.RELAXED,
        operatingMode: shenaiSDK.OperatingMode.MEASURE,
        measurementPreset: shenaiSDK.MeasurementPreset.INFINITE_METRICS,
        cameraMode: shenaiSDK.CameraMode.DEVICE_ID,
        onboardingMode: shenaiSDK.OnboardingMode.HIDDEN,
        showUserInterface: componentName == 'ai_session' ? true : false,
        showFacePositioningOverlay: true,
        showVisualWarnings: false,
        enableCameraSwap: false,
        showFaceMask: true,
        showBloodFlow: true,
        hideShenaiLogo: true,
        enableStartAfterSuccess: true,
        enableSummaryScreen: false,
        enableHealthRisks: false,
        showOutOfRangeResultIndicators: true,
        showTrialMetricLabels: false,
        enableFullFrameProcessing: true,
      };
      setInitializationSettings(settings);
      initializeShenAiSdk(settings);
    }
  }, [shenaiSDK]);

  useEffect(() => {
    //TODO: find a way not to trigger actor if no need or pass actor as an argument
    dashboardActor.send({
      type: 'SDK_DATA_UPDATE',
      data: sdkData,
    });
    // Steps
    if (sdkData.heartRate && !isShenAICalibrated) {
      dashboardActor.send({
        type: 'MARK_ACTIVE_CHECK',
        stepIndex: 0,
        checkIndex: 0,
      });
      setIsShenAICalibrated(true);
    }
    if (
      sdkData.heartRate &&
      sdkData.cardiacStressRT &&
      sdkData.hrvSDNN &&
      !isShenAIMeasurementStarted
    ) {
      dashboardActor.send({
        type: 'MARK_ACTIVE_CHECK',
        stepIndex: 0,
        checkIndex: 1,
      });
      dashboardActor.send({
        type: 'NEXT_STEP',
      });
      setShenAIMeasurementStarted(true);
    }
  }, [sdkData, isShenAICalibrated, isShenAIMeasurementStarted]);

  useEffect(() => {
    if (!sessionId || !getIsFaceOk(sdkData, shenaiSDK) || !isLogsSending) {
      return;
    }

    const controller = new AbortController();

    const sendFrame = async () => {
      try {
        if (!userId) return;
        await postAiCameraFrame(userId, sessionId, controller.signal);
      } catch (error) {
        console.error('Calibration send frame error', error);
      }
    };

    const intervalId = setInterval(() => {
      sendFrame();
    }, 500);

    return () => {
      clearInterval(intervalId);
      controller.abort();
    };
  }, [sdkData?.faceState, isLogsSending, sessionId]);

  return {
    sdkData,
    isSDKInitialized,
    initializationSettings,
    setInitializationSettings,
    initializeShenAiSdk,
    deinitializeShenAiSdk,
    shenaiSDKRef,
  };
};
