import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../contextApp';
import { useSelector } from '@xstate/react';
// API
import { cleanAuthToken, startAISession } from '@api/userAPI';

import { refaelaPatientActor } from '@components/xState/machines/refaelaPatientMachine';

import Button from '@components/Button';
// import PatientCalibration from '@components/PatientCalibration/PatientCalibration';

import { PageTitle } from '@shared/ui/caption/PageTitle';

import { requestMediaPermissionsAndSetDevices } from '@utils/mediaPermissions';
import { ShamefulAny } from '@interfaces/index';

import Calibration from '@components/Calibration/Calibration';

const PatientWaitingRoom: React.FC = () => {
  //TODO: move setCamera nad setMicrophone with their ids here,
  //  and allow calibration component to consume it
  const { setIsExitBioMode, isBioCalibrated, setCameraId, setMicrophoneId } =
    useContext(AppContext);
  const [, setVideoStream] = useState<MediaStream | null>(null);
  const [, setErrorMessage] = useState<string | null>(null);

  const { patientId = '', roomUrl = '' } =
    useSelector(refaelaPatientActor, (state) => state.context.currentUser) || {};

  const snapshot = useSelector(refaelaPatientActor, (state) => state);

  useEffect(() => {
    requestMediaPermissionsAndSetDevices(
      setVideoStream,
      setCameraId,
      setMicrophoneId,
      setErrorMessage
    );
  }, []);

  const clearAuthToken = async () => {
    try {
      await cleanAuthToken(patientId?.replace(/_P$/, ''));
    } catch (e) {
      console.error('Auth token clean issue');
    }
  };

  useEffect(() => {
    clearAuthToken();
    setIsExitBioMode(true); /// ToDo: Check is it needed
  }, []);

  // TODO: add patient calibration data on PATIENT_MACHINE_STATES.PATIENT_VIDEO_SESSION.ROOT level
  const isDaily = snapshot.matches('PatientVideoSession.OneOnOneVideo' as ShamefulAny);
  const isAiDaily = snapshot.matches('PatientVideoSession.AIVideo' as ShamefulAny);

  return (
    <div
      className={`h-full w-full flex flex-col gap-5 md_d:gap-10 mt-5 md_d:mt-10 ${isDaily || isAiDaily ? 'hidden' : 'flex'}`}
    >
      <div className="flex items-center justify-between">
        <PageTitle>Prepare for session</PageTitle>
        <div className="flex gap-2">
          <Button
            variant="primary"
            text="Start session"
            onClick={() => refaelaPatientActor.send({ type: 'goTo1On1Video' })}
            disabled={!isBioCalibrated}
          />
          <Button
            variant="primary"
            text="Start AI session"
            onClick={() => {
              startAISession(snapshot.context.currentUser?.roomUrl!, patientId);
              refaelaPatientActor.send({ type: 'goToAIVideo' });
            }}
            disabled={!isBioCalibrated}
          />
        </div>
      </div>
      <div className="flex h-full justify-between">
        {/* TODO: uncomment when it's ready */}
        {/* <div className="w-[45%] bg-white rounded-2xl h-full p-5 flex flex-col justify-between">
          <Chat messages={messages} />
          <Input width="100%" />
        </div> */}
        <div className="w-full h-full flex justify-center">
          <div className="w-[60%]">
            <Calibration
              // sessionType={'oneOnOne'}
              sessionId={roomUrl?.split('/').pop()}
              isDailyMeeting={false}
              stateMachineActor={refaelaPatientActor}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PatientWaitingRoom;
