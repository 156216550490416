import { useState, useEffect, useCallback } from 'react';
import { getUserMetadata } from '../api/userAPI';
import { PatientPrivateMetadata } from '@interfaces/patient';

export const useUserMetadata = (userId: string) => {
  const [userMetadata, setUserMetadata] = useState<UserPrivateMetadata | PatientPrivateMetadata>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const fetchUserMetadata = useCallback(async () => {
    if (!userId) return;
    setIsLoading(true);
    try {
      const data = await getUserMetadata(userId);
      setUserMetadata(data);
      setError(null);
      return data;
    } catch (e) {
      console.error('Error on get user metadata', e);
      setError('Failed to fetch user metadata');
    } finally {
      setIsLoading(false);
    }
  }, [userId]);

  useEffect(() => {
    fetchUserMetadata();
  }, [fetchUserMetadata]);

  return { userMetadata, isLoading, error, refetch: fetchUserMetadata };
};
