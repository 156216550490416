import * as Sentry from '@sentry/react';
import { useEffect } from 'react';

export const useSentryForPage = (pageId: string) => {
  useEffect(() => {
    if (!pageId) return;

    // Set page tag
    Sentry.setTag('page', pageId);

    let observer: PerformanceObserver | null = null;

    if ('performance' in window) {
      const memory = (window.performance as any).memory || null;
      const convertToMB = (bytes: number) => (bytes / 1024 ** 2).toFixed(2);
      if (memory) {
        Sentry.setContext('Memory usage', {
          page: pageId,
          jsHeapSizeLimitMB: convertToMB(memory.jsHeapSizeLimit),
          totalJSHeapSizeMB: convertToMB(memory.totalJSHeapSize),
          usedJSHeapSizeMB: convertToMB(memory.usedJSHeapSize),
        });
      }

      // Set up a performance observer
      observer = new PerformanceObserver((list) => {
        list.getEntries().forEach((entry) => {
          Sentry.addBreadcrumb({
            category: `Performance on ${pageId}`,
            message: `Component: ${entry.name}, Duration: ${entry.duration}`,
            level: 'info',
          });
        });
      });

      observer.observe({ entryTypes: ['measure'] });
    }

    // Cleanup on unmount
    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, [pageId]);
};
