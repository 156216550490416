import { useEffect, useRef, useState } from 'react';
import { useWebSocket } from './../../websocket';
import { AssetMetaType } from './model/types/assetMetadata';

type AssetsLibraryProps = {
  isActive?: boolean;
  assets: {
    id: string | number;
    size: number;
    fileName: string;
    src: string;
    type: string;
  }[];
  therapistId?: string;
  isPatientMode: boolean;
  isAssetsLoading?: boolean;
};

// TODO: split on different components
export const AssetsLibrary = ({
  isActive,
  assets = [],
  therapistId,
  isAssetsLoading,
  isPatientMode = false,
}: AssetsLibraryProps) => {
  const [selectedSourceMeta, setSelectedSourceMeta] = useState<AssetMetaType>({
    src: '',
    type: 'empty',
  });

  const chunkRef = useRef<any>([]);

  const { socket } = useWebSocket();

  const handleSelectAsset = ({ src, type, id }: AssetMetaType & { id: number | string }) => {
    // therapist already has downloaded resources
    //TODO: how to sync
    setSelectedSourceMeta({ src, type });
    socket?.emit('selectPresentationContent', { fileId: id, therapistId: therapistId });
  };

  useEffect(() => {
    if (socket) {
      socket.on('patientLoadsSelectedMedia', ({ fileId, chunk }) => {
        chunkRef.current = chunkRef.current.map((file: any, i: number) => {
          if (fileId === i) {
            return {
              ...file,
              chunks: [...file.chunks, chunk],
            };
          }
          return file;
        });

        if (!chunkRef.current[fileId]) {
          chunkRef.current[fileId] = {
            chunks: [chunk],
          };
        }
      });

      socket.on('patientFinishesLoadingSelectedMedia', ({ fileId, metadata }) => {
        const blob = new Blob(chunkRef.current[fileId].chunks);
        const url = URL.createObjectURL(blob);
        setSelectedSourceMeta({ src: url, type: metadata.type });
      });
    } else {
      console.error('Check socket connection');
    }
  }, [socket]);

  if (!isActive) return null;

  return (
    <div className="flex absolute h-[75%] w-full">
      <div className="selectedAsset w-[80%] h-full rounded-lg">
        {selectedSourceMeta.type.startsWith('image/') && (
          <img
            key={selectedSourceMeta.src}
            className="w-full h-full object-cover"
            src={selectedSourceMeta.src}
          />
        )}
        {selectedSourceMeta.type.startsWith('video/') && (
          <video
            className="!h-full !object-contain"
            key={selectedSourceMeta.src}
            autoPlay
            controls={!isPatientMode}
          >
            <source
              src={selectedSourceMeta.src}
              type={selectedSourceMeta.type}
            />
          </video>
        )}
        {selectedSourceMeta.type === 'empty' && (
          <div className="rounded-lg border border-dashed border-gray-200 flex justify-center items-center w-full h-full">
            <span>
              {isPatientMode ? 'Here a media will be shown' : 'Choose file from library to share'}
            </span>
          </div>
        )}
      </div>

      <div className="assetsLibrary w-[20%] h-1/2 ml-6 overflow-y-auto overflow-hidden">
        {!isPatientMode && (
          <div className="relative space-y-4 pr-4">
            <div className="flex pb-2 top-0 bg-[#f5f0fb]">
              {isAssetsLoading ? 'Loading...' : 'View'} Full Library ({assets.length})
            </div>
            {assets.map(({ fileName, src, type, id }: any) => {
              return (
                <div
                  key={fileName}
                  className="flex space-x-4"
                >
                  <div
                    onClick={() => handleSelectAsset({ src, type, id })}
                    className="cursor-pointer shrink-0 rounded-[4px] bg-gray-500 h-[64px] w-[120px]"
                  >
                    {type.startsWith('image/') && (
                      <img
                        className="w-full h-full rounded-[4px] object-cover"
                        alt={fileName}
                        src={src}
                      />
                    )}
                    {type.startsWith('video/') && (
                      <video
                        className="object-contain h-full"
                        autoPlay={false}
                        preload="metadata"
                      >
                        <source
                          src={`${src}#t=1`}
                          type={type}
                        />
                      </video>
                    )}
                  </div>
                  <div className="text-sm">{fileName}</div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};
