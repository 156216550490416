import React, { useCallback, useEffect, useState } from 'react';
import { DailyAudio, useAppMessage, useDaily, useDailyEvent, useMeetingState, useParticipantIds } from '@daily-co/daily-react';

import StatsAggregator from '../../assets/stats_aggregator';
import UserMicBubble from '../UserMicBubble';

import Agent from '../Agent';

let stats_aggregator: StatsAggregator;

interface SessionProps {
  onLeave: () => void;
  handleUserInput: any;
  setInputValue: any;
  openMic?: boolean;
  startAudioOff?: boolean;
}

export const Session = React.memo(
  ({ onLeave, startAudioOff = false, openMic = false, handleUserInput, setInputValue }: SessionProps) => {
    const daily = useDaily();
    const [hasStarted, setHasStarted] = useState(openMic);
    /*const [talkState, setTalkState] = useState<"user" | "assistant" | "open">(
          openMic ? "open" : "assistant"
        );*/
    const [muted, setMuted] = useState(startAudioOff);
    const meetingState = useMeetingState();

    // Use active speaker event to trigger the "ready" state
    // so the user can begin talking to the LLM
    // Note: this "air" also avoids a scenario where we immediately
    // trigger interruption on load, causing the LLM to appear silent
    useDailyEvent(
      'active-speaker-change',
      useCallback(() => {
        if (hasStarted) {
          return;
        }
        setHasStarted(true);
      }, [hasStarted])
    );

    // Reset stats aggregator on mount
    useEffect(() => {
      stats_aggregator = new StatsAggregator();
    }, []);

    const participants = useParticipantIds();

    // If we joined unmuted, enable the mic once the
    // active speaker event has triggered once
    useEffect(() => {
      if (!daily || startAudioOff) {
        return;
      }
      if (hasStarted) {
        daily.setLocalAudio(true);
      }
    }, [daily, startAudioOff, hasStarted]);

    // Leave the meeting if there is an error
    useEffect(() => {
      if (meetingState === 'error') {
        onLeave();
      }
    }, [meetingState, onLeave]);

    // Reset on unmount
    useEffect(
      () => () => {
        setHasStarted(false);
      },
      []
    );

    useAppMessage({
      onAppMessage: () => {
        // Aggregate metrics from pipecat
        // if (e.data?.type === "pipecat-metrics") {
        //   e.data.metrics.ttfb.map((m: { name: string; time: number }) => {
        //     stats_aggregator.addStat([m.name, "ttfb", m.time, Date.now()]);
        //   });
        //   return;
        // }
        /*
                Open mic handler (disabled for now)
                if (!daily || !e.data?.cue) return;

                // Determine the UI state from the cue sent by the bot
                if (e.data?.cue === "user_turn") {
                  // Delay enabling local mic input to avoid feedback from LLM
                  setTimeout(() => daily.setLocalAudio(true), 500);
                  setTalkState("user");
                } else {
                  daily.setLocalAudio(false);
                  setTalkState("assistant");
                }*/
      },
    });

    function toggleMute() {
      if (!daily) return;
      daily.setLocalAudio(muted);
      setMuted(!muted);
    }

    return (
      <>
        {participants.length > 1 ? (
          <div>
            {/* <Card
                fullWidthMobile={false}
                className="w-full max-w-[320px] sm:max-w-[420px] mt-auto shadow-long"
              > */}
            <Agent hasStarted={hasStarted} statsAggregator={stats_aggregator} handleUserInput={handleUserInput} setInputValue={setInputValue} />
            {/* </Card> */}

            <UserMicBubble
              openMic={openMic}
              active={hasStarted} //Open mic: && talkState !== "assistant"}
              muted={muted}
              handleMute={() => toggleMute()}
              leave={onLeave}
            />
            <DailyAudio />
          </div>
        ) : (
          <p>Starting bot...</p>
        )}
      </>
    );
  },
  () => true
);

export default Session;
