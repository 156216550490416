import React, { useState, useEffect, memo } from 'react';
import AIConversationVoiceSelect from './AIConversationVoiceSelect';
import InputRange from '@shared/ui/input/range/InputRange';
import ToggleableTabs from '@features/Tab/ToggleableTabs/ToggleableTabs';
import { useSelector } from '@xstate/react';
import { dashboardActor } from '../model/xstate/dashboardMachine';
import { TOGGLEABLE_SECTIONS } from '../model/constants/togglableSections';

interface ConversationalAIModelProps {
  initialVoice: string;
  initialTemperature: number;
  initialMaxTokens: number;
  initialThreshold: number;
  initialPrefixPadding: number;
  initialSilenceDuration: number;
  onVoiceChange: (newVoice: string) => void;
  onTemperatureChange: (newTemperature: number) => void;
  onMaxTokensChange: (newMaxTokens: number) => void;
  onThresholdChange: (newThreshold: number) => void;
  onPrefixPaddingChange: (newPrefixPadding: number) => void;
  onSectionToggleByKey: (key: string) => void;
  onSilenceDurationChange: (newSilenceDuration: number) => void;
  allowVoiceChange?: boolean;
}

const ConversationalModelProperties: React.FC<ConversationalAIModelProps> = ({
  initialVoice,
  initialTemperature,
  initialMaxTokens,
  initialThreshold = 0.5,
  initialPrefixPadding = 300,
  initialSilenceDuration = 500,
  onVoiceChange,
  onTemperatureChange,
  onMaxTokensChange,
  onThresholdChange,
  onPrefixPaddingChange,
  onSilenceDurationChange,
  onSectionToggleByKey,
  allowVoiceChange = true,
}) => {
  const sections = useSelector(dashboardActor, (state) => state.context.sections);
  const [voice, setVoice] = useState(initialVoice);
  const [temperature, setTemperature] = useState(initialTemperature);
  const [maxTokens, setMaxTokens] = useState(initialMaxTokens);
  const [threshold, setThreshold] = useState(initialThreshold);
  const [prefixPadding, setPrefixPadding] = useState(initialPrefixPadding);
  const [silenceDuration, setSilenceDuration] = useState(initialSilenceDuration);

  const handleSectionToggle = (key: string) => {
    onSectionToggleByKey(key);
  };

  useEffect(() => {
    setVoice(initialVoice);
  }, [initialVoice]);

  useEffect(() => {
    setTemperature(initialTemperature);
  }, [initialTemperature]);

  useEffect(() => {
    setMaxTokens(initialMaxTokens);
  }, [initialMaxTokens]);

  useEffect(() => {
    setThreshold(initialThreshold);
  }, [initialThreshold]);

  useEffect(() => {
    setPrefixPadding(initialPrefixPadding);
  }, [initialPrefixPadding]);

  useEffect(() => {
    setSilenceDuration(initialSilenceDuration);
  }, [initialSilenceDuration]);

  const handleVoiceChange = (newVoice: string) => {
    setVoice(newVoice);
    onVoiceChange(newVoice);
  };

  const handleTemperatureChange = (newTemperature: number) => {
    setTemperature(newTemperature);
    onTemperatureChange(newTemperature);
  };

  const handleMaxTokensChange = (newMaxTokens: number) => {
    setMaxTokens(newMaxTokens);
    onMaxTokensChange(newMaxTokens);
  };

  return (
    <div className="w-full">
      <h2 className="text-xl font-bold mb-4">Conversational AI Model</h2>
      <ToggleableTabs
        onToggle={() => handleSectionToggle(TOGGLEABLE_SECTIONS.VOICEAI_SETTINGS)}
        isShown={sections[TOGGLEABLE_SECTIONS.VOICEAI_SETTINGS]}
        tabs={[
          {
            label: 'Voice settings',
            content: (
              <>
                <div className="mb-4 w-full relative">
                  <span className="text-black text-[13px] font-bold font-['Inter'] leading-normal">
                    Voice
                  </span>
                  <AIConversationVoiceSelect
                    initialValue={voice}
                    onChange={handleVoiceChange}
                    disabled={!allowVoiceChange}
                  />
                </div>
                <InputRange
                  label="Threshold"
                  min={0}
                  max={1}
                  step={0.01}
                  defaultValue={Number(threshold)}
                  onChange={(value) => {
                    setThreshold(value);
                    onThresholdChange(value);
                  }}
                />
                <InputRange
                  label="Prefix Padding"
                  min={0}
                  max={1000}
                  step={1}
                  defaultValue={Number(prefixPadding)}
                  onChange={(value) => {
                    setPrefixPadding(value);
                    onPrefixPaddingChange(value);
                  }}
                />
                <InputRange
                  label="Silence Duration"
                  min={0}
                  max={5000}
                  step={10}
                  defaultValue={Number(silenceDuration)}
                  onChange={(value) => {
                    setSilenceDuration(value);
                    onSilenceDurationChange(value);
                  }}
                />
                <InputRange
                  label="Model Configuration (Temperature)"
                  min={0.6}
                  max={1}
                  step={0.01}
                  defaultValue={Number(temperature)}
                  onChange={handleTemperatureChange}
                />
                <InputRange
                  label="Max Tokens"
                  min={50}
                  max={1000}
                  step={10}
                  defaultValue={Number(maxTokens) || 150}
                  onChange={handleMaxTokensChange}
                />
              </>
            ),
          },
        ]}
      />
    </div>
  );
};

export default memo(ConversationalModelProperties);
