import { IPlotPayloadData } from '../../types';
import { SIGNAL_COLOR_MAP_BY_ROLE } from '../constants/maps';

export const getPlotColorRange = (data: IPlotPayloadData[]) => {
  return Array.from(
    new Set(
      data.map((d) => {
        const rolePalette =
          SIGNAL_COLOR_MAP_BY_ROLE[d.role as keyof typeof SIGNAL_COLOR_MAP_BY_ROLE];

        return rolePalette ? rolePalette[d.key as keyof typeof rolePalette] : '#000';
      })
    )
  );
};
