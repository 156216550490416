import { ValueOf } from '@utils/types';

const DateFormat = {
  HH_MM: 'hours',
  DD_MM: 'days',
  YYYY_MM_DD: 'full',
} as const;

type FormatTimestampOptions = ValueOf<typeof DateFormat>;

export const formatTimestamp = (timestamp: number, format: FormatTimestampOptions): string => {
  const date = new Date(timestamp);

  switch (format) {
    case DateFormat.HH_MM:
      return `${String(date.getHours()).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')}`;

    case DateFormat.DD_MM:
      return `${String(date.getDate()).padStart(2, '0')}.${String(date.getMonth() + 1).padStart(2, '0')}`;

    case DateFormat.YYYY_MM_DD:
      return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;

    default:
      throw new Error(`Unknown date format: ${format}`);
  }
};
