import { PATIENT_MACHINE_STATES } from '@machines/patient/constants/states';

import { EndSessionPage } from '@pages/patient/session/EndSessionPage';
import TermsOfUse from '@pages/shared/terms-of-use/TermsOfUse';
// TODO: move pages to pages
import AiSession from '@modules/Patient/AISession';
// TODO: add patient calibration data on PATIENT_MACHINE_STATES.PATIENT_VIDEO_SESSION.ROOT level
// import PatientWaitingRoom from '@modules/Patient/WaitingRoom';
import PatientHome from '@modules/Patient/Home';
import DailyCoMeetComponent from '@components/Daily.co';
import RealtimeDashboard from '@components/RealtimeDashboard/RealtimeDashboard';
import GreetingPatientPage from '@pages/patient/greeting';
import { refaelaPatientActor } from '@components/xState/machines/refaelaPatientMachine';
import { TermsOfPrivacy } from '@pages/patient/concent-data-usage';

type PatientMachineActor = typeof refaelaPatientActor;

export const PATIENT_ROUTE_MAP: Record<string, { component: any; props?: any }> = {
  [PATIENT_MACHINE_STATES.END_SESSION_PAGE]: {
    component: EndSessionPage,
  },
  [PATIENT_MACHINE_STATES.PATIENT_OFFICE_MODE]: { component: PatientHome },
  // Video session
  [PATIENT_MACHINE_STATES.PATIENT_VIDEO_SESSION.ONE_ON_ONE_VIDEO]: {
    component: DailyCoMeetComponent,
    props: (actor: PatientMachineActor) => ({ actor }),
  },
  // [PATIENT_MACHINE_STATES.PATIENT_VIDEO_SESSION.PRETALK_WAITING_ROOM]: {
  //   component: PatientWaitingRoom,
  // },
  [PATIENT_MACHINE_STATES.PATIENT_CONCENT_DATA_USAGE]: {
    component: TermsOfPrivacy,
  },
  [PATIENT_MACHINE_STATES.PATIENT_TERMS_OF_USE_AND_PRIVACY]: {
    component: TermsOfUse,
    props: (actor: PatientMachineActor) => ({ actor: actor, withReturnButton: true }),
  },
  // AI session
  [PATIENT_MACHINE_STATES.AI_VIDEO]: { component: AiSession, props: (actor: any) => ({ actor }) },
  [PATIENT_MACHINE_STATES.AI_DASHBOARD]: {
    component: RealtimeDashboard,
    props: (actor: PatientMachineActor) => ({ actor }),
  },
  [PATIENT_MACHINE_STATES.GREETING_PAGE]: {
    component: GreetingPatientPage,
  },
} as const;
