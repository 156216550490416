import Summary from '@components/SessionComponents/Summary';

import KeyMoments from '@components/SessionComponents/KeyMoments';
import { refaelaTherapistActor } from '@components/xState/machines/refaelaTherapistMachine';
import { KeyMomentsData, Transcript } from '@interfaces/keyMoments';
import { ShamefulAny } from '@interfaces/index';
import AssignHomework from '@components/SessionComponents/AssignHomework';
import { ConfirmationModalContentBlock } from '@components/SessionComponents/Summary/ui/ConfirmationModalContentBlock';
import { Modal } from '@features/Modal/ui/Modal';
import SessionReviewStepper from '@features/Stepper/SessionReviewStepper';
import { useState } from 'react';

export const SessionDetail: React.FC = () => {
  return (
    <div>
      <h2 className="text-xl font-semibold mb-4">Today's session with Dana</h2>
      <p className="mb-4">
        Today's session with Dana focused on exploring the roots of her work-related anxiety and
        perfectionist tendencies. We used a guided visualization technique to trace back to an early
        memory related to her anxiety.
      </p>

      <h3 className="text-lg font-medium mb-2">Key points:</h3>
      <ul className="space-y-2 list-inside">
        {[
          'Dana recalled a significant memory from her childhood where she was criticized harshly for a minor mistake on a school project.',
          "We identified how this early experience contributed to her core belief: 'If I'm not perfect, I'm a failure and will be rejected.'",
          'Dana showed emotional vulnerability during the memory exploration, with her heart rate increasing to 92 bpm.',
          'We began the process of reframing this memory and its impact on her current thought patterns.',
          'Dana successfully applied deep breathing techniques to manage her anxiety during the session, demonstrating improved self-regulation.',
        ].map((point, index) => (
          <li
            key={index}
            className="flex items-start space-x-3"
          >
            <span className="w-2.5 h-2.5 mt-1 bg-purple-600 rounded-full flex-shrink-0"></span>
            <span>{point}</span>
          </li>
        ))}
      </ul>

      <h3 className="text-lg font-medium mt-6 mb-2">Progress:</h3>
      <p className="mb-4">
        Dana demonstrated courage in exploring painful early memories and showed insight into the
        connection between past experiences and current anxieties. Her willingness to engage in this
        deep work is commendable.
      </p>
    </div>
  );
};

type TherapistWrapUpStepperProps = {
  setVideoDuration: (newDuration: number | null) => void;
  keyMomentsData: KeyMomentsData;
  videoRef: React.RefObject<HTMLVideoElement>;
  currentVideoTime: number;
  updatedKeyMoments: KeyMomentsData;
  setUpdatedKeymoments:
    | React.Dispatch<React.SetStateAction<KeyMomentsData | undefined>>
    | ShamefulAny;
  onTimeUpdate: (currentTimeMillis: number) => void;
  sessionTranscripts: Transcript[];
  recordingState?: Record<string, ShamefulAny>;
  transcriptsState?: Record<string, ShamefulAny>;
  generalState?: Record<string, ShamefulAny>;
  framesState?: Record<string, ShamefulAny>;
  onFinishSession?: () => void;
  framesData: {
    timestamp: number;
    link: string;
  }[];
  recordingTimeFrame: {
    recordingStartTime: number | undefined;
    recordingStopTime: number | undefined;
  };
};

const TherapistReviewStepper = ({
  keyMomentsData,
  setVideoDuration,
  onTimeUpdate,
  currentVideoTime,
  videoRef,
  updatedKeyMoments,
  setUpdatedKeymoments,
  sessionTranscripts,
  recordingState,
  transcriptsState,
  generalState,
  framesState,
  onFinishSession,
  framesData,
  recordingTimeFrame,
}: TherapistWrapUpStepperProps) => {
  const [isNotesApproved, setIsNotesApproved] = useState<boolean>(false);
  const [isSummaryApproved, setIsSummaryApproved] = useState<boolean>(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState<boolean>(false);

  const [confirmNextStep, setConfirmNextStep] = useState<(() => void) | null>(null);

  const handleConfirmationModalClose = () => {
    setIsConfirmationModalOpen(false);
    setConfirmNextStep(null);
  };

  const handleConfirmationModalConfirm = () => {
    setIsConfirmationModalOpen(false);
    if (confirmNextStep) {
      confirmNextStep();
      setConfirmNextStep(null);
    }
  };

  const steps = [
    {
      label: 'Key moments and transcripts',
      content: (
        <KeyMoments
          recordingState={recordingState}
          transcriptsState={transcriptsState}
          generalState={generalState}
          framesState={framesState}
          keyMomentsData={keyMomentsData}
          setVideoDuration={setVideoDuration}
          onTimeUpdate={onTimeUpdate}
          currentVideoTime={currentVideoTime}
          videoRef={videoRef}
          updatedKeyMoments={updatedKeyMoments}
          setUpdatedKeymoments={setUpdatedKeymoments}
          keyMomentsWrapperClasses="h-full min-h-[13rem] sm:max-h-[13rem] lg:max-h-[16rem] lg:min-h-[16rem]"
          framesData={framesData}
          recordingTimeFrame={recordingTimeFrame}
        />
      ),
    },
    {
      label: 'AI Summary',
      content: (
        <Summary
          setIsNotesApproved={setIsNotesApproved}
          setIsSummaryApproved={setIsSummaryApproved}
          isNotesApproved={isNotesApproved}
          isSummaryApproved={isSummaryApproved}
          sessionTranscripts={sessionTranscripts}
          updatedKeyMoments={updatedKeyMoments}
        />
      ),
    },
    {
      label: 'Homework',
      content: (
        <AssignHomework />
      ),
    },
  ];

  const handleFinish = () => {
    refaelaTherapistActor.send({ type: 'goToTherapistOffice' });
    onFinishSession?.();
  };
  const handleConfirmationRequired = (nextStepCallback: () => void) => {
    setIsConfirmationModalOpen(true);
    setConfirmNextStep(() => nextStepCallback);
  };
  return (
    <>
      <SessionReviewStepper
        steps={steps}
        onFinish={handleFinish}
        onConfirmationRequired={handleConfirmationRequired}
        isNotesApproved={isNotesApproved}
        isSummaryApproved={isSummaryApproved}
      />
      <Modal
        isOpen={isConfirmationModalOpen}
        onCancel={handleConfirmationModalClose}
      >
        <ConfirmationModalContentBlock
          onCancel={handleConfirmationModalClose}
          onConfirm={handleConfirmationModalConfirm}
        />
      </Modal>
    </>
  );
};

export default TherapistReviewStepper;
