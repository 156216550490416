import { DEFAULT_COLLAPSED_SECTIONS } from '../constants/togglableSections';

export function generateToggleState<T extends Record<string, string>>(
  sections: T
): Record<T[keyof T], boolean> {
  return Object.values(sections).reduce(
    (acc, section) => {
      acc[section as T[keyof T]] = DEFAULT_COLLAPSED_SECTIONS.includes(
        section as (typeof DEFAULT_COLLAPSED_SECTIONS)[number]
      )
        ? false
        : true;
      return acc;
    },
    {} as Record<T[keyof T], boolean>
  );
}
