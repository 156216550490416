import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Video } from 'lucide-react';
import { SelectOption, SimpleSelect } from '@shared/ui/input/select/SimpleSelect';
import { useMediaDevicePermissionGranted } from '@hooks/useMediaDevicePermissionGranted';
import { ShamefulAny } from '@interfaces/index';
import { InitializationSettings } from '../../../../public/shenai-sdk/index'; // import from local

const CameraSelector = ({
  setCamera,
  camera,
  updateShenai,
  selectClass = '',
  selectDropdownClass = '',
  setShenaiInitSettings,
}: {
  setCamera: (x: string) => void;
  camera: string | null;
  updateShenai: ShamefulAny;
  selectClass?: string;
  selectDropdownClass?: string;
  setShenaiInitSettings?: Dispatch<SetStateAction<InitializationSettings | undefined>>;
}) => {
  const [videoDevicesList, setVideoDevicesList] = useState<MediaDeviceInfo[]>([]);
  const [selectedCameraOption, setSelectedCameraOption] = useState({ value: '', label: '' });

  const permissionGranted = useMediaDevicePermissionGranted('camera');

  useEffect(() => {
    if (permissionGranted) {
      navigator.mediaDevices.enumerateDevices().then((res) => {
        const videoDeviceList = res?.filter((el) => {
          return el.kind === 'videoinput';
        });

        setVideoDevicesList(videoDeviceList);
      });
    }
  }, [permissionGranted]);

  useEffect(() => {
    if (videoDevicesList.length > 0) {
      const currentDevice = videoDevicesList.find(
        (device: MediaDeviceInfo) => device.deviceId === camera
      );
      camera
        ? setSelectedCameraOption({
            value: camera,
            label: currentDevice?.label || 'Default camera device',
          })
        : setSelectedCameraOption({
            value: videoDevicesList[0].deviceId,
            label: videoDevicesList[0].label,
          });
    }
  }, [videoDevicesList]);

  const handleCameraChange = ({ value, label }: Record<string, string>) => {
    setSelectedCameraOption({ value, label });
    setCamera(value);
    //TODO: move this logic on the upper level
    updateShenai?.selectCameraByDeviceId(value, true);
    setShenaiInitSettings &&
      setShenaiInitSettings((s: ShamefulAny) =>
        updateShenai
          ? {
              ...s,
              cameraMode: updateShenai.CameraMode.DEVICE_ID,
            }
          : s
      );

    localStorage.setItem('cameraId', value);
  };

  return (
    <SimpleSelect
      withSearch={false}
      selectClass={`w-[13rem] ${selectClass}`}
      selectDropdownClass={`w-[15rem] overflow-y-auto max-h-[20rem] max-w-[15rem] ${selectDropdownClass}`}
      selectedOption={selectedCameraOption}
      renderValue={(placeholder) => {
        return (
          <div className="flex overflow-hidden items-center">
            <div className="mr-2 w-5">
              <Video
                size={20}
                strokeWidth={1.5}
              />
            </div>
            <div className="text-ellipsis truncate">
              {selectedCameraOption.label || placeholder}
            </div>
          </div>
        );
      }}
      placeholder="Select camera device"
      onChange={handleCameraChange}
    >
      {videoDevicesList.map((device: MediaDeviceInfo, index) => {
        return (
          <SelectOption
            key={index}
            value={device.deviceId}
          >
            {device.label}
          </SelectOption>
        );
      })}
    </SimpleSelect>
  );
};

export default CameraSelector;
