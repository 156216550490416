import { useEffect, useState, useCallback, useContext, useRef, useMemo } from 'react';
import { useSelector } from '@xstate/react';

import { DailyAudio, DailyProvider, useDaily, useParticipantIds } from '@daily-co/daily-react';
import { pageUrlFromRoomUrl } from './utils';
import { ValueOf } from '@utils/types';
import { isUserPatient, isUserTherapist } from '@utils/helpers';
import Call from './Call/Call';
import { Tray } from './Tray/Tray';
import { AppContext } from '../../contextApp';
import { useUser } from '@clerk/clerk-react';

import { useUserMetadata } from '../../hooks/useUserMetadata';

import { useWebSocket } from '../../websocket';

import { useCalibratedPatients } from '@hooks/useCalibratedPatients';

import './DailyCo.css';
import { DailyEvent } from '@daily-co/daily-js';
import { Loader } from '@shared/ui/loader/Loader';
import { endDailySession, saveTranscriptionId } from '@api/userAPI';
import { refaelaPatientActor } from '@components/xState/machines/refaelaPatientMachine';
import { refaelaTherapistActor } from '@components/xState/machines/refaelaTherapistMachine';
import { Patient, ShamefulAny, TherapistUser } from '@interfaces/index';
import { TherapistMachineContext } from '@components/xState/machines/types';

const DailyCoMeetComponentState = {
  IDLE: 'STATE_IDLE',
  CREATING: 'STATE_CREATING',
  JOINING: 'STATE_JOINING',
  JOINED: 'STATE_JOINED',
  LEAVING: 'STATE_LEAVING',
  ERROR: 'STATE_ERROR',
  HAIRCHECK: 'STATE_HAIRCHECK',
} as const;

export default function DailyCoMeetComponent({
  actor,
  onFinishSession,
}: {
  actor: typeof refaelaPatientActor | typeof refaelaTherapistActor;
  onFinishSession: () => void;
}) {
  const { isExitBioMode, setIsDailyEnabled } = useContext(AppContext);

  const snapshot = useSelector(actor, (state) => state);

  const [dailyCoState, setDailyCoState] = useState<ValueOf<typeof DailyCoMeetComponentState>>(
    DailyCoMeetComponentState.IDLE
  );

  const [apiError] = useState<string | null>(null);
  const [showCallComponent, setShowCallComponent] = useState<boolean>(false);
  const [isPresentationMode, setPresentationMode] = useState(false);
  const [isSessionStarted, setSessionStarted] = useState(false);
  const [isFinishSessionDisabled, setIsFinishSessionDisabled] = useState(false);
  const [libraryAssets, setLibraryAssets] = useState<any>({});
  const [isAssetsLoading, setIsAssetsLoading] = useState<any>({});
  const [isSessionFinishing, setIsSessionFinishing] = useState<boolean>(false);

  const [keyMoments, setKeyMoments] = useState<
    {
      timestamp: number;
      heartBeat: number;
    }[]
  >([]);

  const filesRef = useRef<any>([]);

  // TODO: may be it make sense to move it inside assets library
  const { socket } = useWebSocket();

  const callObject = useDaily();
  const { user } = useUser();
  const participants = useParticipantIds({ filter: 'remote' });
  const { id: userId } = user!;
  const { userMetadata } = useUserMetadata(userId);
  const { calibratedPatients } = useCalibratedPatients();

  const currentUser: Patient | TherapistUser | null = snapshot.context.currentUser ?? null;
  const therapistId = (currentUser as TherapistUser)?.therapistId || '';
  const userRole = currentUser?.role || '';

  const currentPatientId = isUserTherapist(userRole)
    ? (snapshot.context as TherapistMachineContext).currentPatientId
    : (currentUser as Patient)?.patientId;
  const roomUrl = isUserTherapist(userRole)
    ? (snapshot.context as TherapistMachineContext).roomUrl
    : (currentUser as Patient)?.roomUrl;

  const sessionId = isUserTherapist(userRole)
    ? roomUrl?.split('/').pop()
    : (currentUser as Patient).roomUrl?.split('/').pop();

  const saveTranscriptionData = async (e: any) => {
    if (!sessionId) return;
    await saveTranscriptionId(e.transcriptId!, sessionId);
  };

  const leaveMeetingRoom = () => {
    actor.send({ type: 'endSession' });
  };

  useEffect(() => {
    callObject?.on('transcription-stopped', leaveMeetingRoom);
    if (isUserTherapist(userRole)) {
      callObject?.on('transcription-started', saveTranscriptionData);
    }
    return () => {
      callObject?.off('transcription-stopped', leaveMeetingRoom);
      if (isUserTherapist(userRole)) {
        callObject?.off('transcription-started', saveTranscriptionData);
      }
    };
  }, []);

  //#region handlers

  const handlePresentationModeToggle = () => {
    if (socket && socket.connected) {
      socket.emit('presentationMode', {
        isPresentationMode: !isPresentationMode,
        userId: userId,
        meetingToken: currentUser?.meetingToken,
      });
    } else {
      console.error('Check socket connection');
    }
  };

  const handleSetKeyMoments = useCallback(
    (newKeyMoment: any) => {
      setKeyMoments((prevKeyMoments) => {
        return [...prevKeyMoments, newKeyMoment];
      });
    },
    [keyMoments]
  );

  const handleStartLeavingCall = useCallback(async () => {
    if (!callObject) return;

    setIsFinishSessionDisabled(true);

    try {
      if (isUserTherapist(userRole) && currentPatientId && therapistId && roomUrl) {
        setIsSessionFinishing(true);
        await endDailySession(
          currentPatientId,
          therapistId,
          roomUrl,
          keyMoments.length ? keyMoments : [{ timestamp: Date.now(), heartBeat: 81 }]
        );
        onFinishSession();
      }

      // TODO: pass sessionId instead of 999
      socket?.emit('leaveMeetingRoom', '999');

      if (isUserPatient(userRole)) actor.send({ type: 'endSession' });

      // for therapist in case of patient leaving room first
      if (isUserTherapist(userRole)) setIsDailyEnabled(false);

      if (dailyCoState === DailyCoMeetComponentState.ERROR) {
        await callObject.destroy();
        // setRoomUrl(null);
        setDailyCoState(DailyCoMeetComponentState.IDLE);
      } else {
        setDailyCoState(DailyCoMeetComponentState.LEAVING);
        await callObject.leave();
        setIsDailyEnabled(false);
      }

      if (isUserPatient(userRole)) {
        setIsDailyEnabled(false);

        if (dailyCoState === DailyCoMeetComponentState.ERROR) {
          await callObject.destroy();
          // setRoomUrl(null);
          setDailyCoState(DailyCoMeetComponentState.IDLE);
        } else {
          setDailyCoState(DailyCoMeetComponentState.LEAVING);
          await callObject.leave();
          setIsDailyEnabled(false);
        }
        setIsSessionFinishing(false);
      }
    } catch (error) {
      console.error('Error during leaving call process: ', error);
    }
  }, [callObject, dailyCoState, keyMoments]);

  //#endregion handlers

  const memoCalibratedPatient = useMemo(() => {
    if (Object.keys(calibratedPatients).length > 0) {
      return (
        (isUserTherapist(userRole) &&
          Object.values(calibratedPatients || {}).find(
            (item: ShamefulAny) => item.therapistId === userId
          )) ||
        {}
      );
    } else return calibratedPatients;
  }, [calibratedPatients, userRole, userId]);

  useEffect(() => {
    if (participants.length > 0) {
      setSessionStarted(true);
    }

    if (isSessionStarted && participants.length == 0 && isUserPatient(userRole)) {
      handleStartLeavingCall();
    }
  }, [participants, userRole]);

  useEffect(() => {
    if (socket) {
      // creates socket room
      //TODO: instead of 999 there's should be session id
      socket.emit('joinMeetingRoom', '999');

      socket?.on('presentationMode', ({ isPresentationMode }) => {
        setPresentationMode(isPresentationMode);
      });

      socket.on('startLoadingAssets', () => {
        setIsAssetsLoading(true);
      });

      socket.on('loadUserAssets', ({ index, chunk }) => {
        filesRef.current = filesRef.current.map((file: any, i: number) => {
          if (index === i) {
            return {
              ...file,
              chunks: [...file.chunks, chunk],
            };
          }
          return file;
        });

        if (!filesRef.current[index]) {
          filesRef.current[index] = {
            chunks: [chunk],
          };
        }
      });

      socket?.on('fileComplete', ({ index, metadata }) => {
        const fileData = filesRef.current[index].chunks;

        const blob = new Blob(fileData, { type: metadata.type });
        const tempUrl = URL.createObjectURL(blob);

        setLibraryAssets((prev: any) => {
          return { ...prev, [index]: { src: tempUrl, ...metadata } };
        });
      });

      socket.on('endLoadingAssets', () => {
        setIsAssetsLoading(false);
      });

      socket.on('fileError', ({ index, message }) => {
        console.error(`Error receiving file ${index}:`, message);
      });

      return () => {
        socket.off('presentationMode');
        socket.off('startLoadingAssets');
        socket.off('loadUserAssets');
        socket.off('fileComplete');
        socket.off('endLoadingAssets');
        socket.off('fileError');
      };
    }
  }, [socket]);

  useEffect(() => {
    if (isUserTherapist(userRole)) {
      if (isExitBioMode) {
        setDailyCoState(DailyCoMeetComponentState.HAIRCHECK);
        setShowCallComponent(true);
      }
    }
    if (isUserPatient(userRole)) {
      if (isExitBioMode) {
        setDailyCoState(DailyCoMeetComponentState.HAIRCHECK);
        setShowCallComponent(true);
      }
    }
  }, [isExitBioMode, userRole, currentUser]);

  useEffect(() => {
    const pageUrl = roomUrl && pageUrlFromRoomUrl(roomUrl);
    if (pageUrl === window.location.href) return;
    window.history.replaceState(null, '', pageUrl);
    return () => {
      const urlWithoutQuery = window.location.origin + window.location.pathname;
      window.history.replaceState(null, '', urlWithoutQuery);
    };
  }, [roomUrl]);

  useEffect(() => {
    if (!callObject) return;

    const events: DailyEvent[] = ['joined-meeting', 'left-meeting', 'error', 'camera-error'];

    function handleNewMeetingState() {
      switch (callObject?.meetingState()) {
        case 'joined-meeting':
          setDailyCoState(DailyCoMeetComponentState.JOINED);
          break;
        case 'left-meeting':
          callObject.leave().then(() => {
            // setRoomUrl(null);
            // setCallObject(null);
            setDailyCoState(DailyCoMeetComponentState.IDLE);
          });
          break;
        case 'error':
          setDailyCoState(DailyCoMeetComponentState.ERROR);
          break;
        default:
          break;
      }
    }

    // Use initial state
    handleNewMeetingState();

    /*
     * Listen for changes in state.
     * We can't use the useDailyEvent hook (https://docs.daily.co/reference/daily-react/use-daily-event) for this
     * because right now, we're not inside a <DailyProvider/> (https://docs.daily.co/reference/daily-react/daily-provider)
     * context yet. We can't access the call object via daily-react just yet, but we will later in Call.js and HairCheck.js!
     */
    events.forEach((event) => callObject.on(event, handleNewMeetingState));

    // Stop listening for changes in state
    return () => {
      events.forEach((event) => callObject.off(event, handleNewMeetingState));
    };
  }, [callObject]);

  const readyToCall = !apiError && isExitBioMode && showCallComponent;

  const dailyStateProcess = () => {
    switch (dailyCoState) {
      case DailyCoMeetComponentState.CREATING:
        return 'Connecting...';
      case DailyCoMeetComponentState.JOINING:
        return 'Connected. Joining...';
      case DailyCoMeetComponentState.IDLE:
        return 'Reload the page and try again';
      default:
        break;
    }
  };

  const isPatientWaitingRoom = snapshot.matches(
    'PatientVideoSession.PatientPretalkWaitingRoom' as ShamefulAny
  );
  const isTherapistWaitingRoom = snapshot.matches(
    'TherapistSession.TherapistPretalkWaitingRoom' as ShamefulAny
  );

  return (
    <>
      {!isPatientWaitingRoom && !isTherapistWaitingRoom ? (
        <div className="w-full h-full">
          {!apiError && !readyToCall && (
            <div className="flex h-full justify-center space-x-4 items-center">
              <div>{dailyStateProcess()}</div>
              {dailyCoState !== DailyCoMeetComponentState.IDLE && <Loader />}
            </div>
          )}

          {apiError && (
            <div className="flex w-full flex-col h-full item justify-center">
              <h1 className="daily">Error</h1>
              <p>
                <b>{apiError}</b>
              </p>
            </div>
          )}

          {readyToCall && (
            <DailyProvider callObject={callObject}>
              <button
                disabled={isFinishSessionDisabled}
                className="daily disabled:opacity-50 float-end p-2 mb-2 flex items-center gap-2 rounded-lg bg-purple-500"
              >
                <div
                  className="text-white text-[12px] font-semibold"
                  onClick={handleStartLeavingCall}
                >
                  {isSessionFinishing ? (
                    <div>
                      <Loader
                        className="items-center"
                        spinnerClasses="!w-3 !h-3"
                        label="Finishing session..."
                      />
                    </div>
                  ) : (
                    'Finish session'
                  )}
                </div>
              </button>

              <Call
                key="call-container-daily-session-co"
                libraryAssets={libraryAssets}
                isAssetsLoading={isAssetsLoading}
                isPresentationMode={isPresentationMode}
                therapistId={therapistId}
                userRole={userRole}
                roomUrl={
                  roomUrl
                    ? roomUrl
                    : typeof userMetadata?.roomUrl == 'string'
                      ? userMetadata?.roomUrl
                      : ''
                }
                calibratedPatientForTherapist={memoCalibratedPatient}
              />
              <Tray
                key="tray-dailyco"
                userRole={userRole}
                onPresentationMode={handlePresentationModeToggle}
                isPresentationMode={isPresentationMode}
                calibratedPatient={memoCalibratedPatient}
                onKeyMoments={handleSetKeyMoments}
              />
              <DailyAudio />
            </DailyProvider>
          )}
        </div>
      ) : (
        <></>
      )}
    </>
  );
}
