export const inputIgnoreList = [
  'TherapistPretalkWaitingRoom',
  'TherapistDebriefWaitingRoom',
  'PatientList',
  'TermsOfUse',
];

export const notificationIgnoreList = [
  'TherapistNotification',
  'TherapistPretalkWaitingRoom',
  'TherapistDebriefWaitingRoom',
];

export const ignoreBreadcrumpsList = ['TermsOfUse'];
