import React from 'react';
import { SingleLayoutWrapper } from '@components/styleHelpers';

const Settings: React.FC = () => {
  return (
    <SingleLayoutWrapper>
      <h2 className="text-primary1">Settings</h2>
    </SingleLayoutWrapper>
  );
};

export default Settings;
