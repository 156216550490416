import { Loader } from '@shared/ui/loader/Loader';
import FramesComponent from '../FramesComponent/FramesComponent';

import { useState, useEffect } from 'react';
import { CirclePauseIcon, CirclePlayIcon } from 'lucide-react';
import { ShamefulAny } from '@interfaces/index';

interface VideoPlayerComponentProps {
  showFrames: boolean;
  framesState?: Record<string, unknown>;
  framesData: {
    timestamp: number;
    link: string;
  }[];
  recordingTimeFrame: {
    recordingStartTime: number | undefined;
    recordingStopTime: number | undefined;
  };
  currentVideoTime: number;
  isPlaying: boolean;
  setIsPlaying: (x: boolean) => void;
  startOrPauseTimeUpdate: () => void;
  isLoading: boolean;
  handleSwitchToVideo: () => void;
  videoSrc: string | null;
  intervalId: ShamefulAny;
  isFailed: boolean;
  error: string | null;
  videoRef: React.RefObject<HTMLVideoElement>;
}

const VideoPlayerComponent: React.FC<VideoPlayerComponentProps> = ({
  showFrames,
  framesState,
  framesData,
  recordingTimeFrame,
  currentVideoTime,
  isPlaying,
  startOrPauseTimeUpdate,
  intervalId,
  isLoading,
  handleSwitchToVideo,
  setIsPlaying,
  videoSrc,
  isFailed,
  error,
  videoRef,
}) => {
  const [showVideo, setShowVideo] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    if (!isLoading && videoSrc) {
      setShowVideo(true);

      // Clear the frame-based interval when switching to video
      if (intervalId.current !== null) {
        clearInterval(intervalId.current);
        intervalId.current = null;
      }

      setIsPlaying(false);
      handleSwitchToVideo();
    }
  }, [isLoading, videoSrc]);
  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);
  useEffect(() => {
    const handleResize = () => setViewportHeight(window.innerHeight);
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);
  const containerStyle = {
    maxHeight: viewportHeight <= 950 ? '300px' : '330px',
  };
  return (
    <div className="flex relative">
      {!showVideo && showFrames && (
        <>
          {framesState?.isLoading ? (
            <div className="flex flex-col">
              <Loader
                flexDirection="flex-col"
                label="Session video is loading..."
                className="w-full h-full self-center items-center justify-center"
              />
            </div>
          ) : (
            <div
              className="h-full w-full max-w-[700px] max-h-[330px]  flex relative"
              style={containerStyle}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              <FramesComponent
                framesData={framesData}
                recordingTimeFrame={recordingTimeFrame}
                currentTime={currentVideoTime}
              />

              {isHovered && (
                <div
                  className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center cursor-pointer z-20"
                  onClick={startOrPauseTimeUpdate}
                >
                  <button className="text-white text-2xl p-4 bg-opacity-60 bg-black rounded-full">
                    {isPlaying ? (
                      <CirclePauseIcon
                        color="white"
                        width={'100px'}
                        height={'100px'}
                        strokeWidth={'1px'}
                      />
                    ) : (
                      <CirclePlayIcon
                        color="white"
                        width={'100px'}
                        height={'100px'}
                        strokeWidth={'1px'}
                      />
                    )}
                  </button>
                </div>
              )}
            </div>
          )}
        </>
      )}

      {!videoSrc && !isLoading && isFailed && (
        <div
          className="h-full flex flex-col items-center justify-center w-full bg-slate-200 rounded-lg max-w-[504px] max-h-[330px]"
          style={containerStyle}
        >
          {error && <span className="text-rose-400">{error}</span>}
        </div>
      )}

      {showVideo && videoSrc && !isLoading && (
        <div
          className="relative h-full w-full max-w-[700px] max-h-[330px] "
          style={containerStyle}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <video
            preload="auto"
            controls={false}
            className="h-full w-full"
            ref={videoRef}
            onLoadedData={() => {
              if (videoRef.current) {
                videoRef.current.currentTime = 0.1; // Display the first frame on load
              }
            }}
          >
            <source
              src={videoSrc}
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>

          {isHovered && (
            <div
              className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center cursor-pointer z-20"
              onClick={startOrPauseTimeUpdate}
            >
              <button className="text-white text-2xl p-4 bg-opacity-60 bg-black rounded-full">
                {isPlaying ? (
                  <CirclePauseIcon
                    color="white"
                    width={'100px'}
                    height={'100px'}
                    strokeWidth={'1px'}
                  />
                ) : (
                  <CirclePlayIcon
                    color="white"
                    width={'100px'}
                    height={'100px'}
                    strokeWidth={'1px'}
                  />
                )}
              </button>
            </div>
          )}
        </div>
      )}

      {!videoSrc && !isLoading && isFailed === false && (
        <div
          className="h-full flex flex-col items-center justify-center w-full bg-slate-200 rounded-lg max-w-[504px] max-h-[330px]"
          style={containerStyle}
        >
          No video loaded. {error && <span className="text-rose-400">{error}</span>}
        </div>
      )}
    </div>
  );
};

export default VideoPlayerComponent;
