import { useEffect, useState } from 'react';
import { StyleHelpersProps } from './types.ts';

export const SingleLayoutWrapper = ({ children }: StyleHelpersProps) => {
  const [isVisible, setIsVisible] = useState<boolean>(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  return (
    <div
      className={`w-[1024px] min-h-[500px] py-5 bg-blue-100 rounded-2xl justify-center items-start gap-8 inline-flex transition-all duration-300 ease-in-out transform ${isVisible ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-4'} mt-10`}
    >
      {children}
    </div>
  );
};

export const PatientHighlight = ({ children }: StyleHelpersProps) => {
  const [isVisible, setIsVisible] = useState<boolean>(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  return (
    <div
      className={`py-2 px-4 bg-blue-100 rounded-2xl justify-center items-start gap-8 inline-flex transition-all duration-300 ease-in-out transform ${isVisible ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-4'} mt-6`}
    >
      <h2 className={'text-primary4'}>{children}</h2>
    </div>
  );
};
