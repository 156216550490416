import { useContext, useMemo } from 'react';
import { useSelector } from '@xstate/react';
import { refaelaTherapistActor } from '@components/xState/machines/refaelaTherapistMachine';
// Constants
import { THERAPIST_MACHINE_EVENTS } from '@machines/therapist/constants/states';
// Utils
import { getLastSessionData, getPreviousSessionsData } from './model/utils';
import { AppContext } from '../../../contextApp';
// Components
import { Chip } from '@shared/ui/chip/Chip';
import HomePageLayout from '@shared/ui/layout/HomePageLayout';
import InfoCard from '@features/Card/InfoCard';
import SessionListItem from './ui/SessionListItem';
import { ShamefulAny } from '@interfaces/index';
import { formatTimestamp } from './model/utils/formatters';
import { AI_SESSION_TYPES, SESSION_TYPES } from '@interfaces/constants';
import AISessionCardTitle from './ui/AISessionCardTitle';

const Home = () => {
  const { patientToMeetId } = useContext(AppContext);
  const currentUser = useSelector(refaelaTherapistActor, (state) => state.context.currentUser);
  const patientList = useSelector(
    refaelaTherapistActor,
    (state) => state.context.currentUser?.patientList
  );

  const previousSessions = useMemo(() => {
    return getPreviousSessionsData(patientList);
  }, [patientList]);

  const sessionsWithTherapist = previousSessions.filter(
    (session: ShamefulAny) =>
      // === '' means session completed before definition of session type created
      // and backthen we had only session_with_therapist
      session.session.sessionType === SESSION_TYPES.THERAPIST || session.session.sessionType === ''
  );

  const aiSessions = previousSessions.filter((session: ShamefulAny) =>
    AI_SESSION_TYPES.includes(session.session.sessionType)
  );

  //#region handlers
  const handleGoToSessionReview = (patientId: string, sessionId: string) => {
    refaelaTherapistActor.send({
      type: THERAPIST_MACHINE_EVENTS.GO_TO_SESSION_REPLAY_BY_THERAPIST,
      patientId: patientId,
      sessionId: sessionId,
    });
  };
  const handleGoToAISessionReview = (userId: string, sessionId: string) => {
    refaelaTherapistActor.send({
      type: THERAPIST_MACHINE_EVENTS.GO_TO_AI_SESSION_REVIEW,
      userId: userId,
      sessionId: sessionId,
    });
  };
  const handleStartInstantSession = () => {
    refaelaTherapistActor.send({
      type: THERAPIST_MACHINE_EVENTS.GO_TO_THERAPIST_PRETALK_WAITING_ROOM,
      patientId: patientToMeetId,
    });
  };

  const handleLastSessionClick = () => {
    const lastSessionData = getLastSessionData(patientList);
    if (lastSessionData) {
      const { patient, session } = lastSessionData;
      handleGoToSessionReview(patient.patientId, session.sessionId);
    }
  };

  const handlePatientListOpen = () => {
    refaelaTherapistActor.send({ type: THERAPIST_MACHINE_EVENTS.GO_TO_PATIENT_LIST });
  };

  //#endregion handlers

  const chips = [
    { label: 'Pull up last session', onClickFn: handleLastSessionClick },
    { label: 'Open patient list', onClickFn: handlePatientListOpen },
  ];

  return (
    <HomePageLayout>
      <div className="flex flex-col items-start gap-y-2">
        <p className="text-4xl font-semibold text-black-20">Hello, {currentUser?.name}!</p>
        <div className="flex gap-2 mb-4 overflow-hidden overflow-x-auto">
          {chips.map((c, i) => {
            return (
              <Chip
                key={i}
                onClick={c.onClickFn}
                bgColor="#F3E8FF"
                textColor="#9333EA"
                label={c.label}
              />
            );
          })}
        </div>
        <div className="flex gap-x-6 h-auto">
          <InfoCard
            title="Last sessions"
            cardClasses="md_d:!h-full"
          >
            <div className="space-y-2">
              {sessionsWithTherapist.map(({ patient, session }, i) => {
                const { name, surname, image, patientId } = patient;
                const { sessionId, timestamp } = session;
                const formattedHHmmTime = formatTimestamp(timestamp as number, 'hours');
                const formattedDDMMDate = formatTimestamp(timestamp as number, 'days');
                return (
                  <SessionListItem
                    key={`${patientId}-${name}-${i}-${surname}`}
                    img={image}
                    date={formattedDDMMDate}
                    time={formattedHHmmTime}
                    fullName={`${name} ${surname}`}
                    onClick={() => handleGoToSessionReview(patientId, sessionId)}
                  />
                );
              })}
            </div>
          </InfoCard>

          <InfoCard
            cardClasses="md_d:!h-full"
            customTitle={<AISessionCardTitle />}
          >
            <div className="space-y-2">
              {aiSessions.map(({ patient, session }, i) => {
                const { name, surname, image, patientId } = patient;
                const { sessionId, timestamp } = session;
                const formattedHHmmTime = formatTimestamp(timestamp as number, 'hours');
                const formattedDDMMDate = formatTimestamp(timestamp as number, 'days');
                return (
                  <SessionListItem
                    key={`${patientId}-${name}-${i}-${surname}`}
                    img={image}
                    date={formattedDDMMDate}
                    time={formattedHHmmTime}
                    fullName={`${name} ${surname}`}
                    onClick={() => handleGoToAISessionReview(patientId, sessionId)}
                  />
                );
              })}
            </div>
          </InfoCard>
          <div className="flex flex-col h-full justify-between">
            <InfoCard
              title="Start session"
              cardClasses="bg-gradient-to-r from-purple-600 to-purple-500 !h-[48%] text-white"
              captionClasses="text-white"
              buttonLabel="Prepare for the session"
              onCardButtonClick={handleStartInstantSession}
            />
            <InfoCard
              title="Start AI session"
              cardClasses="bg-gradient-to-r from-blue to-blue-500 !h-[48%] text-white"
              captionClasses="text-white"
              buttonLabel="Start session"
              onCardButtonClick={() =>
                refaelaTherapistActor.send({
                  type: 'goToAIDashboard',
                  currentUser,
                })
              }
            />
          </div>
        </div>
      </div>
    </HomePageLayout>
  );
};

export default Home;
