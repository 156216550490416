import { useState, useCallback, useEffect, memo } from 'react';
import {
  useParticipantIds,
  useScreenShare,
  useDailyEvent,
  useLocalSessionId,
  useDaily,
  useDevices,
} from '@daily-co/daily-react';

import { CallContainer } from './styled';

import Tile from '../Tile/Tile';
import UserMediaError from '../UserMediaError/UserMediaError';

import { useUser } from '@clerk/clerk-react';

import { getUserMetadata } from '@api/userAPI';

import styled from '@emotion/styled';
import TherapistTile from '../TherapistTile/TherapistTile';
import { AssetsLibrary } from '@components/AssetsLibrary';
import { isUserPatient, isUserTherapist } from '@utils/helpers';

import { Loader } from '@shared/ui/loader/Loader';

interface CallProps {
  roomUrl: string;
  userRole: string;
  isPresentationMode?: boolean;
  therapistId: string;
  libraryAssets: any;
  isAssetsLoading: boolean;
  calibratedPatientForTherapist: any;
}

const ParticipantWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const Call = memo(
  ({
    userRole = '',
    isPresentationMode = false,
    therapistId = '',
    libraryAssets = {},
    isAssetsLoading = false,
  }: CallProps) => {
    const [getUserMediaError, setGetUserMediaError] = useState(false);

    const [joinedMeeting, setJoinedMeeting] = useState(false);

    const callObject = useDaily();
    const { user } = useUser();
    const { id: userId } = user!;

    const firstName = user?.firstName;
    const lastName = user?.lastName;

    const { setCamera, setMicrophone } = useDevices();

    useEffect(() => {
      const initialCameraId = localStorage.getItem('cameraId');
      const initialMic = localStorage.getItem('microphoneId');
      initialCameraId && setCamera(initialCameraId);
      initialMic && setMicrophone(initialMic);
    }, []);

    useDailyEvent(
      'camera-error',
      useCallback(() => {
        setGetUserMediaError(true);
      }, [])
    );

    useEffect(() => {
      !joinedMeeting &&
        user?.reload().then(async () => {
          const userMetadata = await getUserMetadata(userId);

          callObject &&
            userMetadata &&
            callObject.join({
              url: typeof userMetadata?.roomUrl == 'string' ? userMetadata?.roomUrl : '',
              userName: `${firstName} ${lastName}`,
              userData: {
                userRole: userRole,
              },

              token:
                typeof userMetadata?.meetingToken == 'string' ? userMetadata?.meetingToken : '',
            });
          setJoinedMeeting(true);
        });
    }, [callObject, joinedMeeting]);

    const { screens } = useScreenShare();
    const remoteParticipantIds = useParticipantIds({ filter: 'remote' });

    const localSessionId = useLocalSessionId();

    const isAlone = remoteParticipantIds.length < 1 || screens.length < 1;

    const renderCallScreen = (): JSX.Element => (
      <CallContainer
        key="call_container"
        className={screens.length > 0 ? 'is-screenshare' : ''}
      >
        <div className="flex absolute z-10 w-full h-full top-0 bottom-0 left-0 right-0">
          <AssetsLibrary
            therapistId={therapistId}
            isActive={isPresentationMode}
            isPatientMode={isUserPatient(userRole)}
            isAssetsLoading={isAssetsLoading}
            assets={Object.values(libraryAssets)}
          />
          <ParticipantWrapper>
            {!localSessionId && (
              <div>
                <Loader
                  label={`${isUserTherapist(userRole) ? 'Creating' : 'Entering'} a room, please wait`}
                />
              </div>
            )}

            {(remoteParticipantIds.length > 0 || screens.length > 0) && (
              <Tile
                key="patient_tile"
                isPresentationMode={isPresentationMode}
                id={remoteParticipantIds && remoteParticipantIds[0]}
                isLocal
                isAlone={isAlone}
              />
            )}
          </ParticipantWrapper>
          {localSessionId && (
            <TherapistTile
              key="therapist_tile"
              isPresentationMode={isPresentationMode}
              id={localSessionId}
            />
          )}
        </div>
      </CallContainer>
    );

    return getUserMediaError ? <UserMediaError /> : renderCallScreen();
  }
);

export default Call;
