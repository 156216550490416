import { InputHTMLAttributes, ReactNode, SyntheticEvent } from 'react';

type TextInputControllerProps<T> = {
  label?: string;
  id: T;
  StartAdornment?: ReactNode;
  onInputChange?: ({ key, value }: { key: T; value: string }) => void;
} & InputHTMLAttributes<HTMLInputElement>;

export const TextInputController = <T,>({
  onInputChange,
  label,
  id,
  StartAdornment,
  ...props
}: TextInputControllerProps<T>) => {
  const handleInputChange = (e: SyntheticEvent<HTMLInputElement>) => {
    onInputChange?.({ key: id, value: (e.target as HTMLInputElement).value });
  };

  const startAdornmentStyle = StartAdornment ? 'pl-10' : '';

  return (
    <div>
      {label && (
        <label
          htmlFor={id}
          className="block text-sm font-medium leading-6 text-gray-900"
        >
          {label}
        </label>
      )}
      <div className="mt-1 relative">
        {StartAdornment && (
          <div className="absolute text-gray-400 top-[7px] md_d:top-[15px] left-3">
            {StartAdornment}
          </div>
        )}
        <input
          id={id}
          name={id}
          onInput={handleInputChange}
          className={`block h-[32px] md_d:h-[50px] w-full rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-button-hover placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 ${startAdornmentStyle}`}
          {...props}
        />
      </div>
    </div>
  );
};
