export interface HomeworkProps {
  tasks: HomeworkTask[];
  emptyLabelText?: string;
}

export enum HomeworkStatus {
  NOT_STARTED = 'not_started',
  IN_PROGRESS = 'in_progress',
  COMPLETED = 'completed',
}

export type HomeworkTask = {
  id: string;
  label: string;
  text: string;
  status: HomeworkStatus;
  createdAt: string;
  inProgressAt?: string;
  completedAt?: string;
};
