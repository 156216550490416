import Button from '@components/Button';

type ConfirmationModalContentBlockProps = {
  onCancel: () => void;
  onConfirm: () => void;
};
export const ConfirmationModalContentBlock = ({
  onCancel,
  onConfirm,
}: ConfirmationModalContentBlockProps) => {
  return (
    <div className="flex min-h-full flex-1 flex-col justify-center">
      <div>
        <p className=" text-gray-900 text-base font-normal font-['Inter'] leading-normal">
          Are you sure you want to continue and leave summary without changes?
        </p>

        <div className="flex justify-center pt-10 gap-4">
          <Button
            variant="outline"
            text="Cancel"
            onClick={onCancel}
            className="w-[50%]"
          />
          <Button
            variant="primary"
            text="Continue"
            onClick={onConfirm}
            className="w-[50%]"
          />
        </div>
      </div>
    </div>
  );
};
