import { EDGE_TICK_OFFSET } from '../constants/graph';

export const getDomainByValues = (min: number, max: number) => {
  if (!min && !max) return [0, 10];
  if (max < 20) {
    return [min, max + 5];
  } else {
    return [min! - EDGE_TICK_OFFSET, max! + EDGE_TICK_OFFSET];
  }
};
