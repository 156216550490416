import { useContext, useMemo } from 'react';
import { useSelector } from '@xstate/react';
// xState
import { refaelaTherapistActor } from '@components/xState/machines/refaelaTherapistMachine';
// Constants
import {
  inputIgnoreList,
  notificationIgnoreList,
  ignoreBreadcrumpsList,
} from './model/constants/ignoreLists';
//Components
import NotificationBar from '@components/NotificationBar';
import TherapistBreadcrumbs from '@components/Breadcrumbs/Therapist/TBreadcrumbs';
import TherapistNavigation from '@components/Navigation/Therapist/TherapistNavigation';
import Header from '@features/Layout/Header/Header';
import AIDashboardPageNotifications from './ui/NotificationContent/AIDashboardNotifications';
import HomePageNotifications from './ui/NotificationContent/HomePageNotifications';
import { useUserMetadata } from '@hooks/useUserMetadata';
import { getSessionsCountByUser } from '@utils/sessions';
import { AppContext } from '../../../contextApp';

const TherapistHeader: React.FC = () => {
  const snapshot = useSelector(refaelaTherapistActor, (state) => state);

  const stateValue = snapshot.value as string;
  const currentUser = snapshot?.context?.currentUser || null;

  const { setIsExitBioMode } = useContext(AppContext);

  const { userMetadata } = useUserMetadata(currentUser?.therapistId!);

  const sessionsCount = currentUser ? getSessionsCountByUser(currentUser) : 0;

  const memoizedNavigation = useMemo(() => <TherapistNavigation />, []);
  const memoizedBreadcrumbs = useMemo(() => <TherapistBreadcrumbs />, []);

  const memoizedNotification = useMemo(
    () => (
      <NotificationBar>
        {sessionsCount! > 0 && (
          <>
            {snapshot.matches('AIDashboard') && (
              <AIDashboardPageNotifications
                onSetIsExitBioMode={setIsExitBioMode}
                therapistActor={refaelaTherapistActor}
              />
            )}
            {snapshot.matches('TherapistOfficeMode') && (
              <HomePageNotifications
                userMetadata={userMetadata}
                onSetIsExitBioMode={setIsExitBioMode}
                therapistActor={refaelaTherapistActor}
              />
            )}
          </>
        )}
      </NotificationBar>
    ),
    [stateValue, sessionsCount, userMetadata]
  );

  const isMicDisabled = ['TherapistOfficeMode'].includes(stateValue);

  return (
    <Header
      ignoreInputList={inputIgnoreList}
      ignoreNotificationList={notificationIgnoreList}
      ignoreBreadcrumpsList={ignoreBreadcrumpsList}
      navigation={memoizedNavigation}
      breadcrumbs={memoizedBreadcrumbs}
      notification={memoizedNotification}
      stateValue={stateValue}
      disabledMic={isMicDisabled}
    />
  );
};

export default TherapistHeader;
