import { postAIEventLogs, postEventLogs } from '@api/eventLog';
import { EventLog, EventLogSource, EventLogType } from '@models/eventLog';
import {
  FaceState,
  InitializationResult,
  MeasurementPreset,
  MeasurementState,
  ShenaiSDK,
} from '../../../public/shenai-sdk';

export function getInitResultString(shenaiSDK: ShenaiSDK | null, initResult: InitializationResult) {
  switch (initResult) {
    case shenaiSDK?.InitializationResult.OK:
      return 'OK';
    case shenaiSDK?.InitializationResult.INVALID_API_KEY:
      return 'INVALID_API_KEY';
    case shenaiSDK?.InitializationResult.CONNECTION_ERROR:
      return 'CONNECTION_ERROR';
    case shenaiSDK?.InitializationResult.INTERNAL_ERROR:
      return 'INTERNAL_ERROR';
    default:
      console.error('Unknown initialization result:', initResult, 'SDK is null:', !shenaiSDK);
      return 'UNKNOWN';
  }
}

export function getFaceStateString(shenaiSDK: ShenaiSDK | null, faceState: FaceState) {
  switch (faceState) {
    case shenaiSDK?.FaceState.OK:
      return 'Face detected!';
    case shenaiSDK?.FaceState.TOO_CLOSE:
      return 'Please move away from the camera';
    case shenaiSDK?.FaceState.TOO_FAR:
      return 'Please move closer to the camera';
    case shenaiSDK?.FaceState.NOT_CENTERED:
      return 'Please move your face to the center';
    case shenaiSDK?.FaceState.NOT_VISIBLE:
      return 'Please look at the camera';
    case shenaiSDK?.FaceState.UNKNOWN:
    default:
      return 'Waiting...';
  }
}

export function getMeasurementStateString(
  shenaiSDK: ShenaiSDK | null,
  measurementState: MeasurementState
) {
  switch (measurementState) {
    case shenaiSDK?.MeasurementState.NOT_STARTED:
      return 'Calibrating';
    case shenaiSDK?.MeasurementState.WAITING_FOR_FACE:
      return 'Please look at the camera';
    case shenaiSDK?.MeasurementState.RUNNING_SIGNAL_SHORT:
      return 'Please stay still';
    case shenaiSDK?.MeasurementState.RUNNING_SIGNAL_BAD:
    case shenaiSDK?.MeasurementState.RUNNING_SIGNAL_BAD_DEVICE_UNSTABLE:
      return 'Please try not to move';
    case shenaiSDK?.MeasurementState.RUNNING_SIGNAL_GOOD:
      return 'Getting measurements!';
    case shenaiSDK?.MeasurementState.FINISHED:
      return 'Finished';
    case shenaiSDK?.MeasurementState.FAILED:
      return 'Failed';
    default:
      return 'Waiting...';
  }
}
export function getStatusForBioString(
  shenaiSDK: ShenaiSDK | null,
  measurementState: MeasurementState,
  faceState: FaceState,
  language?: string
) {
  const isCalibrating = measurementState == shenaiSDK?.MeasurementState.NOT_STARTED;
  const isTooClose = faceState == shenaiSDK?.FaceState.TOO_CLOSE;
  const isTooFar = faceState == shenaiSDK?.FaceState.TOO_FAR;
  const isNotCentered = faceState == shenaiSDK?.FaceState.NOT_CENTERED;
  const isNoFace =
    faceState == shenaiSDK?.FaceState.NOT_VISIBLE ||
    measurementState == shenaiSDK?.MeasurementState.WAITING_FOR_FACE;
  const isStayStill =
    measurementState == shenaiSDK?.MeasurementState.RUNNING_SIGNAL_SHORT ||
    measurementState == shenaiSDK?.MeasurementState.RUNNING_SIGNAL_BAD ||
    measurementState == shenaiSDK?.MeasurementState.RUNNING_SIGNAL_BAD_DEVICE_UNSTABLE;

  const isMeasuring = measurementState == shenaiSDK?.MeasurementState.RUNNING_SIGNAL_GOOD;

  const isFinished = measurementState == shenaiSDK?.MeasurementState.FINISHED;
  const isFailed = measurementState == shenaiSDK?.MeasurementState.FAILED;

  const cases = [
    { isCalibrating: isCalibrating },
    { isTooClose: isTooClose },
    { isTooFar: isTooFar },
    { isNotCentered: isNotCentered },
    { isNoFace: isNoFace },
    { isStayStill: isStayStill },
    { isMeasuring: isMeasuring },
    { isFinished: isFinished },
    { isFailed: isFailed },
  ];
  const casesFiltered = cases.filter((el) => Object.values(el).some((value) => value == true))[0];
  if (casesFiltered) {
    const caseStatus = Object.keys(casesFiltered)![0];
    switch (caseStatus) {
      case 'isCalibrating':
        return language == 'he' ? 'בכיול...' : 'Calibrating';
      case 'isTooClose':
        return language == 'he' ? 'קרוב מדי' : 'Too close';
      case 'isTooFar':
        return language == 'he' ? 'רחוק מדי' : 'Too far';
      case 'isNotCentered':
        return language == 'he' ? 'לא ממורכז' : 'Not centered';
      case 'isNoFace':
        return language == 'he' ? 'לא מזהה פנים' : 'No face found';
      case 'isStayStill':
        return language == 'he' ? 'הישאר יציב' : 'Stay still';
      case 'isMeasuring':
        return language == 'he' ? 'במדידה' : 'Measuring';
      case 'isFinished':
        return language == 'he' ? 'הסתיים' : 'Finished';
      case 'isFailed':
        return language == 'he' ? 'נכשל' : 'Failed';
      default:
        return language == 'he' ? 'ממתין' : 'Waiting...';
    }
  }
}

export function getMeasurementPresetString(
  shenaiSDK: ShenaiSDK | null,
  measurementPreset: MeasurementPreset
) {
  switch (measurementPreset) {
    case shenaiSDK?.MeasurementPreset.INFINITE_HR:
      return 'INFINITE_HR';
    case shenaiSDK?.MeasurementPreset.INFINITE_METRICS:
      return 'INFINITE_METRICS';
    case shenaiSDK?.MeasurementPreset.ONE_MINUTE_HR_HRV_BR:
      return 'ONE_MINUTE_HR_HRV_BR';
    case shenaiSDK?.MeasurementPreset.ONE_MINUTE_BETA_METRICS:
      return 'ONE_MINUTE_BETA_METRICS';
    case shenaiSDK?.MeasurementPreset.FOURTY_FIVE_SECONDS_UNVALIDATED:
      return 'FOURTY_FIVE_SECONDS_UNVALIDATED';
    case shenaiSDK?.MeasurementPreset.THIRTY_SECONDS_UNVALIDATED:
      return 'THIRTY_SECONDS_UNVALIDATED';
    default:
      return '';
  }
}

export const stopTracks = () => {
  const initialCameraId = localStorage.getItem('cameraId');

  navigator.mediaDevices.enumerateDevices().then((res) => {
    const filteredDevices = res?.filter((el) => {
      return el.kind === 'videoinput' && el.deviceId !== initialCameraId;
    });
    const isCameraExist = res
      ?.filter((el) => {
        return el.kind === 'videoinput';
      })
      .some((el) => el.deviceId == initialCameraId);
    if (isCameraExist) {
      filteredDevices.length > 0 &&
        filteredDevices.map((el: { deviceId: any }) => {
          navigator.mediaDevices
            .getUserMedia({ video: { deviceId: { exact: el.deviceId } } })
            .then((t) => {
              return t.getTracks().forEach((track: any) => {
                track.stop();
              });
            });
        });
    } else localStorage.setItem('cameraId', `${filteredDevices[0].deviceId}`);
  });
};

function getEventSourceByType(eventType: EventLogType) {
  switch (eventType) {
    case EventLogType.HEART_RATE_10S:
    case EventLogType.SHENAI_DATA:
      return EventLogSource.MXLABS;
    case EventLogType.VF_TRANSCRIPTS:
      return EventLogSource.VF;
    case EventLogType.USER_FACE_EMOTIONS:
      return EventLogSource.HUME;
    case EventLogType.FEEDBACK:
      return EventLogSource.FEEDBACK;
    case EventLogType.FOR_LIVE:
      return EventLogSource.FOR_LIVE;
    default:
      return EventLogSource.CUSTOM;
  }
}

export const initEventLogsPolling = async (
  getEventLogs: () => EventLog[],
  clearEventLogs: () => void,
  therapistId: string,
  patientId: string,
  sessionId: string,
  userRole: string,
  isLogsSending: boolean
) => {
  try {
    const logs: EventLog[] = getEventLogs();

    if (!document.hidden && isLogsSending) {
      const logsWithSource = logs.map((log) => ({
        ...log,
        source: getEventSourceByType(log.type),
      }));

      await postEventLogs({
        therapistId: therapistId,
        patientId: patientId,
        sessionId: sessionId,
        userRole: userRole,
        logs: logsWithSource,
        json_obj: JSON.stringify(logs),
      });
    }

    clearEventLogs();
  } catch (error) {
    console.error('Error in initEventLogsPolling:', error);
  }
};

export const initAIEventLogsPolling = async (
  getEventLogs: () => EventLog[],
  clearEventLogs: () => void,
  userId: string,
  sessionId: string,
  isLogsSending: boolean
) => {
  try {
    const logs: EventLog[] = getEventLogs();

    if (!document.hidden && isLogsSending) {
      const logsWithSource = logs.map((log) => ({
        ...log,
        source: getEventSourceByType(log.type),
      }));

      await postAIEventLogs({
        userId,
        sessionId,
        logs: logsWithSource,
        json_obj: JSON.stringify(logs),
      });
    }

    clearEventLogs();
  } catch (error) {
    console.error('Error in initEventLogsPolling:', error);
  }
};
