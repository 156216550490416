// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ShamefulAny } from '@interfaces/index';
import { apiClient } from '.';

export const sendSms = async (phoneNumber: string, message: string): Promise<void> => {
  try {
    const response = await apiClient.post('/twilio/send_sms', {
      phoneNumber,
      message,
    });
    if (response.data.success) {
      console.log('SMS sent successfully');
    } else {
      console.error('Failed to send SMS:', response.data.message);
    }
  } catch (error: ShamefulAny) {
    console.error('Error sending SMS:', error.message, error.details);
    throw new Error(error.message || 'Failed to send SMS');
  }
};
