export const PATIENT_MACHINE_EVENTS = {
  GO_TO_PATIENT_PRETALK_WAITING_ROOM: 'goToPatientPretalkWaitingRoom',
  GO_TO_PATIENT_SESSION: 'goToPatientSession',
  GO_TO_PATIENT_OFFICE: 'goToPatientOffice',
  GO_TO_PATIENT_SETTINGS: 'goToPatientSettings',
  GO_TO_PATIENT_NOTIFICATION: 'goToPatientNotification',
  GO_TO_PATIENT_SELF_HISTORY_VIEW: 'goToPatientSelfHistoryView',
  GO_TO_1ON1_VIDEO: 'goTo1On1Video',
  GO_TO_AI_VIDEO: 'goToAIVideo',
  GO_TO_AI_DASHBOARD: 'goToAIDashboard',
  GO_TO_GREETING_PAGE: 'goToGreetinPage',
  GO_TO_PATIENT_CONCENT_DATA_USAGE: 'goToPatientConcentDataUsage',
  END_SESSION: 'endSession',
  UPDATE_PATIENT_USER: 'UPDATE_PATIENT_USER',
  GO_TO_PATIENT_TERMS_OF_USE_AND_PRIVACY: 'goToTermsOfUseAndPrivacy',
} as const;
