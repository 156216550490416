import { useState, useEffect } from 'react';

export function useDateTimeUpdater(intervalMinutes: number = 1) {
  const [currentDateTime, setCurrentDateTime] = useState(new Date());
  const [currentTimelineDateTime, setCurrentTimelineDateTime] = useState(new Date());

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;

    const updateDateTime = () => {
      const now = new Date();
      setCurrentDateTime(now);
    };

    const getNextMinuteDelay = () => {
      const now = new Date();
      const secondsTillNextMinute = 60 - now.getSeconds();
      const millisecondsTillNextMinute = secondsTillNextMinute * 1000 - now.getMilliseconds();
      return millisecondsTillNextMinute;
    };

    const scheduleNextUpdate = () => {
      const delay = getNextMinuteDelay();
      timeoutId = setTimeout(() => {
        updateDateTime();
        requestAnimationFrame(scheduleNextUpdate); // Plan next update
      }, delay);
    };

    scheduleNextUpdate();

    return () => clearTimeout(timeoutId);
  }, [intervalMinutes]);

  return { currentDateTime, currentTimelineDateTime, setCurrentTimelineDateTime };
}
