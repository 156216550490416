import { useEffect, useState } from 'react';
import {
  EventsNamesEnum,
  IPlotConfig,
  IPlotPayloadData,
  PlotSizeEnum,
  VisibilityState,
} from './types';
import { apiClient } from '@api/index';
import { USER_ROLES } from '@interfaces/user';
import { PLOT_SIGNALS } from '@shared/constants/plot/signals';
import { MXLabsDataItem, ProcessedMXLabsData } from '@shared/types/mxlabs/logs';
import { fetchAiSessionFileContent } from '@api/userAPI';
import { ShamefulAny } from '@interfaces/index';
import { getFaceStateValue } from '@features/Plot/model/utils/getFaceStateValue';
import { FACE_STATE } from '@features/Plot/model/constants/states';
import { ValueOf } from '@utils/types';
import { getMeasurementStateValue } from '@features/Plot/model/utils/getMeasurementStateValue';
import { MeasurementStateType } from '@features/Plot/model/types/states';

export const getDefaultPlotConfigData = (plotConfig: IPlotConfig) => {
  return [1, 3, 4, 5, 8, 9, 10, 11, 12, 13, 14, 15].some((el) => plotConfig.plotId === el)
    ? {
        configuredEmotionsDefault:
          plotConfig.plotSize === PlotSizeEnum.MEDIUM
            ? ['Embarrassment']
            : plotConfig.plotId === 2
              ? ['Top']
              : [],
        configuredSignalsDefault:
          plotConfig.plotId === 1
            ? [PLOT_SIGNALS.HR_4S]
            : plotConfig.plotId === 3
              ? [PLOT_SIGNALS.HRV_SDNN]
              : plotConfig.plotId === 4
                ? [PLOT_SIGNALS.MEASUREMENT_STATE]
                : plotConfig.plotId === 5
                  ? [PLOT_SIGNALS.FACE_STATE]
                  : plotConfig.plotId === 6
                    ? [PLOT_SIGNALS.SIGNAL_QUALITY]
                    : [],
        configuredEventsDefault:
          plotConfig.plotId === 7
            ? [EventsNamesEnum.USER_INPUT, EventsNamesEnum.SYSTEM_OUTPUT]
            : plotConfig.plotId === 8
              ? [EventsNamesEnum.TABS_FOCUS, EventsNamesEnum.COMPONENT_ACT]
              : plotConfig.plotId === 9
                ? [EventsNamesEnum.CALIBRATION_AGENT]
                : plotConfig.plotId === 10
                  ? [EventsNamesEnum.ACTION_DETECTION_AGENT]
                  : plotConfig.plotId === 11
                    ? [EventsNamesEnum.AROUSAL_DETECTION_AGENT]
                    : plotConfig.plotId === 12
                      ? [EventsNamesEnum.HISTORY_AGENT]
                      : plotConfig.plotId === 13
                        ? [EventsNamesEnum.CONTEXT_AGENT]
                        : plotConfig.plotId === 14
                          ? [EventsNamesEnum.INTERPRETATION_AGENT]
                          : plotConfig.plotId === 15
                            ? [EventsNamesEnum.SUGGESTION_AGENT]
                            : [],
      }
    : {
        configuredEmotionsDefault: [],
        configuredSignalsDefault: [],
        configuredEventsDefault: [],
      };
};

export const setValueToPlotDisplay = (d: IPlotPayloadData) => {
  // Show 'Top_Emotion' name instead of just 'Top' when Top emotions graph is selected
  const name = d.name == 'Top' ? d.description : d.name;
  //TODO: ADD MAP FOR ROLE NAMES
  return `Timestamp: ${new Date(d.timestamp).toLocaleTimeString()}\nSignal: ${name}\nRole: ${d.role}\nValue: ${
    d.key == PLOT_SIGNALS.MEASUREMENT_STATE
      ? getMeasurementStateValue(d.value as MeasurementStateType)
      : d.key == PLOT_SIGNALS.FACE_STATE
        ? getFaceStateValue(d.value as ValueOf<typeof FACE_STATE>)
        : d.key == EventsNamesEnum.TABS_FOCUS
          ? getTabFocusValue(d.value)
          : d.key == EventsNamesEnum.SYSTEM_THOUGHTS
            ? d.description
            : d.key == EventsNamesEnum.CUSTOM_ACT
              ? d.description
              : toFixedOrInteger(d.value || 0)
  }`;
};

export const getTabVisibilityStateValue = (value: VisibilityState) => {
  switch (value) {
    case VisibilityState.VISIBLE:
      return 'Visible';
    case VisibilityState.NOT_VISIBLE:
      return 'Not visible';
    default:
      return 'Unknown';
  }
};

export const getTabFocusValue = (value: number) => {
  return value == 1 ? 'visible' : 'not visible';
};

export const toFixedOrInteger = (num: number, toFixedNum: number = 4): number => {
  if (Number.isInteger(num)) {
    return num;
  }
  return Number(num.toFixed(toFixedNum));
};

// syncs patient data with therapist
// TODO: handle case when patient data bigger than therapists'
export const processMXLabLogs = (therapistData: any[], patientData: any[]) => {
  if (therapistData.length === 0 && patientData.length === 0) return [];

  const startedAt = therapistData[0].timestamp;
  return therapistData.map((log: any) => {
    const { timestamp, data } = log;

    if (!data.isInitialized) {
      return { timestamp, relative: timestamp - startedAt };
    }

    const closestEvent = patientData.reduce((closest, current) => {
      const currentDiff = Math.abs(current.timestamp - timestamp);
      const closestDiff = Math.abs(closest.timestamp - timestamp);

      return currentDiff < closestDiff ? current : closest;
    });

    return {
      timestamp: timestamp,
      relative: startedAt - closestEvent.timestamp,
      [PLOT_SIGNALS.HRV_SDNN]: closestEvent.data?.realtimeMetrics?.hrv_sdnn_ms,
      [PLOT_SIGNALS.HRV_LN_RMSSD]: closestEvent.data?.realtimeMetrics?.hrv_lnrmssd_ms,
      [PLOT_SIGNALS.CARDIAC_STRESS]: closestEvent.data?.realtimeMetrics?.stress_index,
      ...closestEvent.data,
    };
  });
};

export const processMXLabsLogs = (data: MXLabsDataItem[]): ProcessedMXLabsData[] => {
  if (data.length === 0) return [];

  const startedAt = data[0].timestamp;

  return data.map((log) => {
    const { timestamp, data } = log;
    return {
      timestamp,
      relative: timestamp - startedAt,
      [PLOT_SIGNALS.HRV_SDNN]: data?.realtimeMetrics?.hrv_sdnn_ms,
      [PLOT_SIGNALS.HRV_LN_RMSSD]: data?.realtimeMetrics?.hrv_lnrmssd_ms,
      [PLOT_SIGNALS.CARDIAC_STRESS]: data?.realtimeMetrics?.stress_index,
      ...data,
    };
  });
};

export const useMXLabsLogs = (therapistId: string, patientId: string, sessionId: string) => {
  const [logs, setLogs] = useState<Record<string, ProcessedMXLabsData[]> | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<null | Error>(null);

  const _getLogs = async () => {
    const therapistUrl = `/files/file-content?sessionId=${sessionId}&patientId=${patientId}&therapistId=${therapistId}&fileName=MXLABS&userRole=${USER_ROLES.THERAPIST}`;
    const patientUrl = `/files/file-content?sessionId=${sessionId}&patientId=${patientId}&therapistId=${therapistId}&fileName=MXLABS&userRole=${USER_ROLES.PATIENT}`;

    try {
      const { data: therapistData } = await apiClient.get(therapistUrl);
      const { data: patientData } = await apiClient.get(patientUrl);

      const processedTherapistData = processMXLabsLogs(therapistData);
      const processedPatientData = processMXLabLogs(therapistData, patientData);

      setLogs({
        [USER_ROLES.THERAPIST]: processedTherapistData,
        [USER_ROLES.PATIENT]: processedPatientData,
      });

      setLoading(false);
    } catch (err) {
      setError(err as Error);
      setLogs(null);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (therapistId && patientId && sessionId) {
      _getLogs();
    }
  }, [therapistId, patientId, sessionId]);

  return {
    logs,
    loading,
    error,
  };
};

export const useMXLabsLogsAISessionReview = (aiReviewUserId: string, aiReviewSessionId: string) => {
  const [logs, setLogs] = useState<ShamefulAny>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<null | Error>(null);

  const _getLogs = async () => {
    try {
      setLoading(true);

      const aiReviewFileResult = await fetchAiSessionFileContent(
        aiReviewSessionId,
        aiReviewUserId,
        'MXLABS'
      );

      const processedAiReviewFileData = processMXLabsLogs(aiReviewFileResult);

      setLogs(processedAiReviewFileData);
      setError(null);
    } catch (err: ShamefulAny) {
      console.error('Error fetching or processing logs:', err.message);
      setError(err as Error);

      setLogs([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (aiReviewUserId && aiReviewSessionId) {
      _getLogs();
    }
  }, [aiReviewUserId, aiReviewSessionId]);

  return {
    logs,
    loading,
    error,
  };
};
