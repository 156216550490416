import { ValueOf } from '@utils/types';
// Constants
import { PLOT_CATEGORY } from '../model/constants/categories';
import { SIGNAL_LABEL_MAP } from '../model/constants/maps';

type SelectableListProps<T> = {
  onChange: (list: T[]) => void;
  list: T[];
  options: T[];
  category: ValueOf<typeof PLOT_CATEGORY>;
  disabled?: boolean;
};

const SelectableList = <T extends string>({
  onChange,
  list,
  options,
  category,
  disabled,
}: SelectableListProps<T>) => {
  const handleSelectedOptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = e.target;

    if (checked) {
      if (category === PLOT_CATEGORY.EMOTIONS || list.length <= 1) {
        onChange([...list, value as T]);
      } else {
        onChange([value as T]);
      }
    } else {
      onChange(list.filter((item) => item !== value));
    }
  };

  return (
    <ul className={`flex flex-col items-start w-full ${disabled ? 'opacity-65' : ''} `}>
      {options.map((o) => (
        <li
          className="overflow-hidden w-full"
          key={o}
        >
          <label
            title={SIGNAL_LABEL_MAP[o as keyof typeof SIGNAL_LABEL_MAP]}
            className="flex flex-nowrap"
          >
            <input
              className="rounded-md focus:ring-offset-none"
              type="checkbox"
              value={o}
              disabled={disabled}
              checked={list.indexOf(o) != -1}
              onChange={handleSelectedOptionChange}
            />
            <div className="truncate">
              &nbsp;{SIGNAL_LABEL_MAP[o as keyof typeof SIGNAL_LABEL_MAP]}
            </div>
          </label>
        </li>
      ))}
    </ul>
  );
};

export default SelectableList;
