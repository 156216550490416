export const BUTTON_SIZE = {
  SMALL: 'sm',
  MEDIUM: 'md',
} as const;

export const BUTTON_VARIANT = {
  PRIMARY: 'primary',
  OUTLINE: 'outline',
  OUTLINE_PRIMARY: 'outlinedPrimary',
  OUTLINE_SECONDARY: 'outlinedSecondary',
  PLAIN: 'plain',
  ICON_PURPLE: 'iconPurple',
  ICON_WHITE: 'iconWhite',
  PLAIN_SECONDARY: 'plainSecondary',
} as const;
