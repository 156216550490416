import { setup, assign, createActor } from 'xstate';

import { TOGGLEABLE_SECTIONS } from '../constants/togglableSections';
import { generateToggleState } from '../utils/generateToggleSectionsState';
import { ShenaiSdkDataI } from '@shared/types/shenai/sdk';
import { DASHBOARD_STEPS } from '@states/realtimeDashboard';
import { ValueOf } from '@utils/types';
import { Step } from '../types/steps';
import { ItemType } from '@openai/realtime-api-beta/dist/lib/client';
import { RealtimeEvent } from '@modules/Patient/AISession/RealtimeAISession';

// TODO: move to global shared since it's synced between FE and BE
type DashboardState = ValueOf<typeof DASHBOARD_STEPS>;

type Events =
  | { type: 'SDK_DATA_UPDATE'; data: ShenaiSdkDataI }
  | { type: 'REALTIME_EVENTS_UPDATE'; data: RealtimeEvent & { role: string | null } }
  | { type: 'REALTIME_CONVERSATION_ITEMS_UPDATE'; data: ItemType[] }
  | { type: 'DASHBOARD_STATE_UPDATE'; state: DashboardState }
  | { type: 'TOGGLE_SECTION'; key: string }
  | { type: 'NEXT_STEP' }
  | { type: 'PREVIOUS_STEP' }
  | { type: 'MARK_ACTIVE_CHECK'; stepIndex: number; checkIndex: number }
  | { type: 'PAUSE' }
  | { type: 'RESUME' };

interface Context {
  sdkData: ShenaiSdkDataI | null;
  items: ItemType[];
  realtimeEvents: RealtimeEvent[];
  state: DashboardState | null;
  sections: Record<string, boolean>;
  steps: Step[];
  currentStepIndex: number;
  currentCheckIndex: number;
  isPaused: boolean;
}

const dashboardMachine = setup({
  types: {
    context: {} as Context,
    events: {} as Events,
  },
}).createMachine({
  id: 'PATIENT_DASHBOARD_MACHINE',
  initial: 'idle',
  context: {
    sdkData: null,
    items: [],
    realtimeEvents: [],
    state: null,
    sections: generateToggleState(TOGGLEABLE_SECTIONS),
    steps: [
      {
        label: 'Setup',
        isStepBlocked: false,
        checks: [
          { label: 'lighting and setup', isCheckBlocked: false, isCompleted: false },
          { label: 'heartrate measure', isCheckBlocked: false, isCompleted: false },
        ],
      },
      {
        label: 'Start and chat',
        isStepBlocked: true,
        checks: [
          { label: 'short discussion', isCheckBlocked: true, isCompleted: false },
          { label: 'ready to do excersize', isCheckBlocked: true, isCompleted: false },
        ],
      },
      {
        label: 'Do exercise',
        isStepBlocked: true,
        checks: [
          { label: 'instructions were given', isCheckBlocked: true, isCompleted: false },
          { label: 'show the timer visual', isCheckBlocked: true, isCompleted: false },
        ],
      },
      {
        label: 'Reflect and finish',
        isStepBlocked: true,
        checks: [
          { label: 'short discussion', isCheckBlocked: true, isCompleted: false },
          { label: 'concent to finish', isCheckBlocked: true, isCompleted: false },
        ],
      },
    ],
    currentStepIndex: 0,
    currentCheckIndex: 0,
    isPaused: false,
  },
  states: {
    idle: {
      on: {
        SDK_DATA_UPDATE: {
          actions: assign({
            sdkData: ({ event }) => event.data,
          }),
        },
        REALTIME_EVENTS_UPDATE: {
          actions: assign({
            realtimeEvents: ({ context, event }) => {
              const prev = context.realtimeEvents;
              const realtimeEventData = event.data;

              const lastEvent = prev[prev.length - 1];
              if (lastEvent?.event.type === realtimeEventData.event.type) {
                lastEvent.count = (lastEvent.count || 0) + 1;
                return [...prev.slice(0, -1), lastEvent];
              }
              return [...prev, { ...realtimeEventData }];
            },
          }),
        },
        REALTIME_CONVERSATION_ITEMS_UPDATE: {
          actions: assign({
            items: ({ event }) => {
              return event.data;
            },
          }),
        },
        DASHBOARD_STATE_UPDATE: {
          actions: assign({
            state: ({ event }) => event.state,
          }),
        },
        TOGGLE_SECTION: {
          actions: assign({
            sections: ({ context, event }) => {
              const key = event.key;
              return {
                ...context.sections,
                [key]: !context.sections[key],
              };
            },
          }),
        },
        NEXT_STEP: {
          actions: assign({
            currentStepIndex: ({ context }) => {
              const nextIndex = context.currentStepIndex + 1;
              return nextIndex < context.steps.length ? nextIndex : context.currentStepIndex;
            },
            steps: ({ context }) => {
              const nextIndex = context.currentStepIndex + 1;
              return context.steps.map((step, index) => ({
                ...step,
                isStepBlocked: index > nextIndex,
              }));
            },
          }),
        },
        PREVIOUS_STEP: {
          actions: assign({
            currentStepIndex: ({ context }) => {
              const prevIndex = context.currentStepIndex - 1;
              return prevIndex >= 0 ? prevIndex : context.currentStepIndex;
            },
            steps: ({ context }) => {
              const prevIndex = context.currentStepIndex - 1;
              return context.steps.map((step, index) => ({
                ...step,
                isStepBlocked: index > prevIndex,
              }));
            },
          }),
        },
        MARK_ACTIVE_CHECK: {
          actions: assign({
            steps: ({ context, event }) => {
              const { stepIndex, checkIndex } = event;
              return context.steps.map((step, index) =>
                index === stepIndex
                  ? {
                      ...step,
                      checks: step.checks.map((check, i) =>
                        i === checkIndex ? { ...check, isCompleted: true } : check
                      ),
                    }
                  : step
              );
            },
          }),
        },
        PAUSE: {
          actions: assign({
            isPaused: true,
          }),
        },
        RESUME: {
          actions: assign({
            isPaused: false,
          }),
        },
      },
    },
  },
});

export const dashboardActor = createActor(dashboardMachine, {});
