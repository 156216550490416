import type { Patient } from '@interfaces/index';
import { patientList } from '@mockData/patientMockData.ts';

export function findPatientById(patients: Patient[], userId: string) {
  return patients.find((patient) => patient.patientId === userId);
}

export function filterPatientById(patients: Patient[], userId: string) {
  return patients.filter((patient) => patient.patientId === userId);
}

export const findPatientIndexById = (patients: Patient[], patientId: string): number => {
  return patients.findIndex((patient) => patient.patientId === patientId);
};

export const getPatientsNamesSurnames = (patients: Patient[]) => {
  return patients.map((patient) => {
    return patient.name + patient.surname;
  });
};

export function findPatientIdByName({
  name,
  surname,
}: {
  name?: string;
  surname?: string;
}): string | null {
  for (const [userId, patient] of Object.entries(patientList)) {
    if ((!name || patient.name === name) && (!surname || patient.surname === surname)) {
      return userId;
    }
  }
  return null;
}
