export const PLOT_SIGNALS = {
  HR_4S: 'hr4s',
  HR_10S: 'hr10s',
  HRV_SDNN: 'hrvSDNN',
  HRV_LN_RMSSD: 'hrvlnRMSSD',
  CARDIAC_STRESS: 'cardiacStress',
  FACE_STATE: 'faceState',
  MEASUREMENT_STATE: 'measurementState',
  SIGNAL_QUALITY: 'signalQuality',
  // FACE_POSE: 'facePose', // TODO: uncomment when requirements will be defined as it should be displayed
} as const;
