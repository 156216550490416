import { AppContext } from '../../contextApp';
import { ReactNode, useContext, useEffect, useState } from 'react';

type Step = {
  label: string;
  content: ReactNode;
  isStepBlocked?: boolean;
  nextButtonLabel?: string;
  backButtonLabel?: string;
};

type StepperProps = {
  steps: Step[];
  onFinish?: () => void;
  onReset?: () => void;
  contentClasses?: string;
  onChangeStepCb?: (step: number) => void;
  onConfirmationRequired?: (nextStepCallback: () => void) => void;
  isNotesApproved?: boolean;
  isSummaryApproved?: boolean;
};

const SessionReviewStepper = ({
  steps,
  onFinish,
  onReset,
  contentClasses,
  onChangeStepCb,
  onConfirmationRequired,
  isNotesApproved,
  isSummaryApproved,
}: StepperProps) => {
  const [visitedSteps, setVisitedSteps] = useState([0]);
  const [currentStep, setCurrentStep] = useState(0);
  const { setStep } = useContext(AppContext);

  const handleNext = () => {
    if (currentStep === 1 && (!isNotesApproved || !isSummaryApproved)) {
      if (onConfirmationRequired) {
        onConfirmationRequired(() => {
          goToNextStep();
        });
      }
    } else {
      goToNextStep();
    }
  };
  const goToNextStep = () => {
    if (currentStep < steps.length - 1) {
      const nextStepIndex = currentStep + 1;
      setCurrentStep(nextStepIndex);
      setStep(nextStepIndex);
      setVisitedSteps((prev) => [...prev, nextStepIndex]);
    } else if (onFinish) {
      onFinish();
    }
  };

  const handleBack = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
      setStep(currentStep - 1);
    }
  };

  const handleReset = () => {
    setCurrentStep(0);
    setStep(0);
    onReset?.();
  };

  useEffect(() => {
    onChangeStepCb?.(currentStep);
  }, [currentStep, steps]);

  return (
    <div data-hs-stepper={`{"currentIndex": ${currentStep + 1}}`}>
      {/* Stepper Navigation */}
      <div className="flex justify-between items-start">
        <ul className="grid grid-cols-[1fr_1fr_1fr] gap-x-2">
          {steps.map((step, index) => {
            const isLast = index === steps.length - 1;
            const isActive = currentStep === index;
            const isCompleted = currentStep > index;

            return (
              <li
                key={index}
                className={`flex items-start flex-col  ${
                  isLast ? '' : 'w-[150px]'
                } group ${isCompleted ? 'completed' : isActive ? 'active' : ''}`}
                data-hs-stepper-nav-item={`{"index": ${index + 1}, "isCompleted": ${isCompleted}}`}
              >
                <div className="flex gap-3 w-full items-center">
                  {' '}
                  <div className="min-w-7 min-h-7 flex flex-col items-center text-xs">
                    <span
                      className={`size-7 flex justify-center shadow-md items-center font-medium rounded-full ${
                        isCompleted
                          ? 'bg-purple-600 text-white'
                          : isActive
                            ? 'bg-purple-600 text-white'
                            : 'bg-gray-100 text-gray-700'
                      }`}
                    >
                      <span>{index + 1}</span>
                    </span>
                  </div>{' '}
                  {/* Line between steps, if not the last step */}
                  {!isLast && (
                    <div
                      className={`h-px flex-1 ${
                        isCompleted ? 'bg-purple-600' : isActive ? 'bg-purple-600' : 'bg-gray-200'
                      }`}
                      style={{ minWidth: '20px' }}
                    ></div>
                  )}
                </div>
                <span
                  className={`mt-2 text-gray-800 text-base font-semibold font-['Inter'] leading-[19px] tracking-tight max-w-[132px] text-left ${
                    isActive ? 'text-purple-600' : 'text-gray-700'
                  }`}
                >
                  {step.label}
                </span>
              </li>
            );
          })}
        </ul>
        {/* Button Group */}
        <div className="flex justify-end items-center gap-x-6">
          {currentStep !== 0 && (
            <button
              type="button"
              className="py-2 px-3 inline-flex items-center gap-x-1 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm focus:outline-none focus:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none"
              onClick={handleBack}
              disabled={steps[currentStep].isStepBlocked}
            >
              {steps[currentStep].backButtonLabel || 'Back'}
            </button>
          )}
          {currentStep < steps.length - 1 ? (
            <button
              type="button"
              className="py-2 px-3 inline-flex items-center gap-x-1 text-sm font-medium rounded-lg border border-transparent bg-purple-600 text-white hover:bg-purple-700 focus:outline-none focus:bg-purple-700 disabled:opacity-50 disabled:pointer-events-none"
              onClick={handleNext}
              disabled={steps[currentStep].isStepBlocked}
            >
              {steps[currentStep].nextButtonLabel || 'Next'}
            </button>
          ) : (
            <button
              type="button"
              className="py-2 px-3 inline-flex items-center gap-x-1 text-sm font-medium rounded-lg border border-transparent bg-purple-600 text-white hover:bg-purple-700 focus:outline-none focus:bg-purple-700 disabled:opacity-50 disabled:pointer-events-none"
              onClick={onFinish}
              disabled={steps[currentStep].isStepBlocked}
            >
              {steps[currentStep].nextButtonLabel || 'Finish'}
            </button>
          )}
          {onReset && (
            <button
              type="reset"
              className="py-2 px-3 inline-flex items-center gap-x-1 text-sm font-medium rounded-lg border border-transparent bg-purple-600 text-white hover:bg-purple-700 focus:outline-none focus:bg-purple-700 disabled:opacity-50 disabled:pointer-events-none"
              onClick={handleReset}
            >
              Reset
            </button>
          )}
        </div>
      </div>

      {/* Stepper Content */}
      <div className={`mt-5 md_d:mt-8 ${contentClasses}`}>
        {steps.map((step, index) => {
          return (
            visitedSteps.includes(index) && (
              <div
                className="h-full w-full"
                key={index}
                data-hs-stepper-content-item={`{"index": ${index + 1}, "isCompleted": ${currentStep > index}}`}
                style={{ display: currentStep === index ? 'block' : 'none' }}
              >
                <div className="flex rounded-xl">{step.content}</div>
              </div>
            )
          );
        })}
      </div>
    </div>
  );
};

export default SessionReviewStepper;
