import { IPlotPayloadData } from '@components/Plot/types';
import * as d3 from 'd3';

export const getMinMaxValueFromData = (
  data: IPlotPayloadData[] | undefined,
  getValueFn: (el: IPlotPayloadData) => number
) => {
  if (!data || !data.length) return [];
  const [minValue, maxValue] = (data && [d3.min(data, getValueFn), d3.max(data, getValueFn)]) || [];
  return [minValue, maxValue];
};
