import React from 'react';
import { TimelineTooltipContentProps } from './types.ts';

const TimelineTooltipContent: React.FC<TimelineTooltipContentProps> = ({
  icon,
  text,
  onActionClick,
  bgColor,
  textColor,
  additionalStyles,
  onMouseEnter,
}) => (
  <button
    onClick={onActionClick}
    onMouseEnter={onMouseEnter}
    className={` ${bgColor} ${textColor} ${additionalStyles || ''} px-4 py-3.5 rounded-lg justify-center items-center gap-2.5 inline-flex `}
  >
    {icon && <div className="flex  items-center h-[100%]">{icon}</div>}

    <span className="text-center text-[15px] font-semibold font-['Inter'] tracking-tight">{text}</span>
  </button>
);

export default TimelineTooltipContent;
