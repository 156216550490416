import { useMemo } from 'react';
import merge from 'lodash/merge';

import { colors } from '../model/constants/colors';
// Utils
import { getInitials, getIndexByInitials } from '../model/utils';
// Components
import { StyledWrapper, StyledAvatar, StyledTextAvatar } from './styled';

type TextAvatarProps = {
  children: string | undefined;
  alt?: string;
  wrapperClass?: string;
  className?: string;
  style?: object;
  onClick?: () => void;
  img?: string;
  size?: 'medium' | 'small';
};

export const TextAvatar = ({
  alt = '',
  wrapperClass,
  children,
  className = '',
  size = 'medium',
  img = undefined,
  onClick = () => {},
}: TextAvatarProps) => {
  const initials = getInitials(children) || null;

  const color = useMemo(
    () => (initials ? colors[getIndexByInitials(children)] : '#979DAC'),
    [children, initials]
  );

  const sizeStyle =
    size === 'small'
      ? 'h-[1.55rem] w-[1.55rem] text-[0.8rem]'
      : 'h-[1.5rem] w-[1.5rem] md_d:h-[2.8rem] md_d:w-[2.8rem] text-[9px] md_d:text-base';

  return (
    <StyledWrapper
      className={wrapperClass}
      onClick={onClick}
    >
      {img ? (
        <StyledAvatar
          crossOrigin="anonymous"
          alt={alt}
          src={img}
        />
      ) : (
        <StyledTextAvatar
          style={merge({ backgroundColor: color })}
          className={`${sizeStyle} ${className}`}
        >
          {initials ? initials : 'J Doe'}
        </StyledTextAvatar>
      )}
    </StyledWrapper>
  );
};
