import { EventLog } from '../models/eventLog';
import { apiClient } from '.';

export interface PostEventLogsPayload {
  therapistId: string;
  patientId: string;
  sessionId: string;
  userRole: string;
  logs: EventLog[];
  json_obj: string;
}

export interface PostAIEventLogsPayload {
  userId: string;
  sessionId: string;
  logs: EventLog[];
  json_obj: string;
}

export const postEventLogs = async (payload: PostEventLogsPayload): Promise<Response> => {
  return apiClient.post('/logs', payload);
};

export const postAIEventLogs = async (payload: PostAIEventLogsPayload): Promise<Response> => {
  return apiClient.post('/logs/ai', payload);
};
