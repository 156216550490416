import { useState, useEffect, useCallback, useRef, useContext } from 'react';
import debounce from 'lodash/debounce';
//xState
import { useSelector } from '@xstate/react';
import { dashboardActor } from './model/xstate/dashboardMachine';
import { refaelaTherapistActor } from '@components/xState/machines/refaelaTherapistMachine';
import { refaelaPatientActor } from '@components/xState/machines/refaelaPatientMachine';
// Hooks
import { useRealtimeClient } from './hooks/useClient';
import { useShenai } from './model/hooks/useShenai';
import { useSentryForPage } from '@hooks/sentry/useSentryForPage';
// Constants
import { PATIENT_MACHINE_EVENTS } from '@machines/patient/constants';
import {
  END_TYPE_OPTIONS,
  END_TYPE_OPTIONS_MAP,
} from '@modules/Patient/AISession/model/constants/options';
// Types
// import { ShenaiSdkBaseState } from '@shared/types/shenai/sdk';
import { SESSION_TYPES } from '@interfaces/constants';
import { TherapistUser } from '@interfaces/therapist';
import { Patient } from '@interfaces/patient';
import { RealtimeEventHandler } from './types';
import { RealtimeEventState } from './model/types/realtimeEvents';
import { STEPS_MAP } from './model/constants/stepsMap';
// Utils
import { SessionInfo, ConversationalModelProperties, MemoVisionAIModel } from './ui';
import RealtimeMeasurementGraphs from './ui/RealtimeMeasurementGraphs';
import { MemoRealtimeAi } from '../../modules/Patient/AISession/RealtimeAISession';
import { RealtimeClient } from '@openai/realtime-api-beta/dist/lib/client';
import { isUserTherapist } from '@utils/helpers';
import { initAIEventLogsPolling } from '@components/Calibration/utils';
import { getIdWithoutPrefix } from '@utils/prefixes';

import { WavRecorder, WavStreamPlayer } from '@modules/Patient/AISession/lib/wavtools';

import InfoRow from '@shared/ui/caption/InfoRow';
import MemoizedConnectSettingsSection from './ui/ConnectSettingsBlock';
import { cleanAuthToken, createAISession, endAISession, startAISession } from '@api/userAPI';
import { fetchCurrentUserDetails } from '@api/user/me';
import AiSession from '@modules/Patient/AISession';
import { AppContext } from '../../contextApp';
// TODO: remove if the perfomance via state is better
// import { ShenaiSDK } from '../../../public/shenai-sdk';
// import { postAiCameraFrame } from '@services/frame';
import TopicDetectionAgent from './agents/TopicDetectionAgent';
import SummaryData from './ui/SummaryData';
import SuggestionData from './ui/SuggestionData';
import CharacteristicData from './ui/CharacteristicData';
import ConversationAnalysisData from './ui/ConversationAnalysisData';
// import FrequentKeywordsData from './ui/FrequentKeywordsData';
import SpeakerActivationPlot from '@features/Plot/SpeakerActivationPlot';
import { ShamefulAny } from '@interfaces/index';

const RealtimeDashboard = ({
  actor,
}: {
  actor: typeof refaelaPatientActor | typeof refaelaTherapistActor;
}) => {
  useSentryForPage('RealtimeDashboardPage');
  const currentUser = useSelector(actor, (state) => state.context.currentUser);

  const userId =
    currentUser && isUserTherapist(currentUser.role!)
      ? currentUser.therapistId!
      : (currentUser as Patient).patientId;

  // State
  const [instructions, setInstructions] = useState<string>('No instructions found');
  const [temperature, setTemperature] = useState<number>(0.8);
  const [voice, setVoice] = useState<string>('alloy');
  const [maxTokens, setMaxTokens] = useState<number>(600);
  const [threshold, setThreshold] = useState<number>(0.7);
  const [prefixPadding, setPrefixPadding] = useState<number>(300);
  const [silenceDuration, setSilenceDuration] = useState<number>(1300);

  // TODO: remove if the perfomance via state is better
  // const [isShenAICalibrated, setIsShenAICalibrated] = useState(false);
  // const [isShenAIMeasurementStarted, setShenAIMeasurementStarted] = useState(false);

  const [agentState, setAgentState] = useState({});
  const [isConnected, setIsConnected] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [canPushToTalk, setCanPushToTalk] = useState(true);

  // TODO: remove if the perfomance via state is better
  // const [realtimeEvents, setRealtimeEvents] = useState<RealtimeEvent[]>([]);
  // const [items, setItems] = useState<ItemType[]>([]);

  const [roomUrl, setRoomUrl] = useState('');
  const [meetingToken, setMeetingToken] = useState('');
  const sessionId = roomUrl ? roomUrl.split('/').pop()! : '';

  const startTimeRef = useRef<string>(new Date().toISOString());

  const wavRecorderRef = useRef<WavRecorder>(new WavRecorder({ sampleRate: 24000 }));
  const wavStreamPlayerRef = useRef<WavStreamPlayer>(new WavStreamPlayer({ sampleRate: 24000 }));

  const [selectedEndType, setSelectedEndType] = useState<Record<string, string>>({
    label: END_TYPE_OPTIONS_MAP[END_TYPE_OPTIONS.VAD],
    value: END_TYPE_OPTIONS.VAD,
  });

  const { isLogsSending, getEventLogs, clearEventLogs } = useContext(AppContext);

  // Custom hook for client management
  const { voiceBotInstanceRef, updateConfig, handleClientReady, handleConnectionChange } =
    useRealtimeClient({
      voice,
      instructions,
      temperature,
      maxTokens,
      threshold,
      prefixPadding,
      silenceDuration,
      selectedEndType: selectedEndType.value,
    });

  const {
    // sdkData,
    isSDKInitialized,
    initializationSettings,
    setInitializationSettings,
    initializeShenAiSdk,
    deinitializeShenAiSdk,
    shenaiSDKRef,
  } = useShenai('ai_session', isLogsSending, userId, sessionId);

  const shenaiSDK = shenaiSDKRef.current;

  const clearAuthToken = async () => {
    try {
      if (!userId) return;

      await cleanAuthToken(getIdWithoutPrefix(userId));
    } catch (e) {
      console.error('Auth token clean issue');
    }
  };

  const saveAISession = async () => {
    handleDisconnectConversation();
    if (currentUser && userId) {
      endAISession(userId, roomUrl, currentUser.role!);
    }
  };

  useEffect(() => {
    clearAuthToken();
  }, []);

  useEffect(() => {
    if (roomUrl) {
      window.addEventListener('beforeunload', saveAISession);
    }

    return () => {
      if (roomUrl) {
        window.removeEventListener('beforeunload', saveAISession);
      }
    };
  }, [roomUrl]);

  useEffect(() => {
    let pollingInterval = setInterval(() => {});

    if (isLogsSending && roomUrl && userId) {
      pollingInterval = setInterval(
        () =>
          initAIEventLogsPolling(
            getEventLogs,
            clearEventLogs,
            userId,
            roomUrl.split('/').pop()!,
            isLogsSending
          ),
        2000
      );
    }

    return () => clearInterval(pollingInterval);
  }, [isLogsSending, roomUrl]);

  useEffect(() => {
    const createAndStartAISession = async () => {
      try {
        if (!userId) return;
        const sessionType = currentUser?.assignedHomeworks?.type || SESSION_TYPES.AI_BIO_FEEDBACK;
        const { roomUrl, token } = await createAISession(
          userId,
          sessionType,
          currentUser?.assignedHomeworks?.annotation || {}
        );

        setRoomUrl(roomUrl);
        setMeetingToken(token);
        if (roomUrl) {
          await startAISession(roomUrl, userId);
        }
      } catch (e) {
        console.log('Error during session creation and start: ', e);
      }
    };
    createAndStartAISession();
  }, []);

  const handleRealtimeEvent = useCallback(({ event }: RealtimeEventHandler) => {
    if (event.type === 'session.updated' || event.type === 'session.created') {
      console.log('session.update:', event);
      if (event.session) {
        const { instructions, temperature, voice, maxTokens } = event.session;
        if (instructions) setInstructions(instructions);
        if (temperature) setTemperature(temperature);
        if (voice) setVoice(voice);
        if (maxTokens) setMaxTokens(maxTokens);
      }
    }
  }, []);

  useEffect(() => {
    if (voiceBotInstanceRef) {
      console.log('Setting on realtime event');
      voiceBotInstanceRef.on('realtime.event', handleRealtimeEvent);

      return () => {
        if ('realtime.event' in voiceBotInstanceRef.eventHandlers) {
          try {
            voiceBotInstanceRef.off('realtime.event', handleRealtimeEvent);
          } catch (e) {
            console.error('realtime event error:', e);
          }
        }
      };
    }
  }, [voiceBotInstanceRef, handleRealtimeEvent]);

  // @ts-ignore
  const handleStateChanged = useCallback((state: RealtimeEventState) => {
    const { stepIndex, substepIndex } = STEPS_MAP[state.event.data.value as keyof typeof STEPS_MAP];
    const currentStepIndex = dashboardActor.getSnapshot().context.currentStepIndex;

    if (currentStepIndex < stepIndex) {
      dashboardActor.send({ type: 'NEXT_STEP' });
    }

    dashboardActor.send({
      type: 'MARK_ACTIVE_CHECK',
      stepIndex,
      checkIndex: substepIndex,
    });
  }, []);

  // @ts-ignore
  const handleTranscript = useCallback((transcript: string) => {
    // console.log('New transcript:', transcript);
  }, []);

  // @ts-ignore
  const handleAudio = useCallback((audioBlob: Blob) => {
    // console.log('Received audio blob:', audioBlob);
  }, []);

  // @ts-ignore
  const handleError = useCallback(
    (error: any) => {
      console.error('AI Session error:', error);
    },
    [voiceBotInstanceRef]
  );

  const handleDeinitializeShenAISDK = useCallback(() => {
    //TODO: remove if xstate better
    // setIsShenAICalibrated(false);
    deinitializeShenAiSdk();
  }, []);

  const handleInitializeShenAISDK = useCallback((settings: ShamefulAny) => {
    initializeShenAiSdk(settings);
  }, []);

  const handleSetInitializationSettings = useCallback((settings: ShamefulAny) => {
    setInitializationSettings(settings);
  }, []);

  const handleInstructionsChange = useCallback(
    (newInstructions: string) => {
      setInstructions(newInstructions);
      updateConfig({ instructions: newInstructions });
      console.log('Instructions changed:', newInstructions);
    },
    [updateConfig]
  );

  const handleTemperatureChange = useCallback(
    (newTemperature: number) => {
      setTemperature(newTemperature);
      updateConfig({ temperature: newTemperature });

      const debounceSendSessionUpdate = debounce(() => {
        if (voiceBotInstanceRef?.isConnected()) {
          voiceBotInstanceRef.realtime.send('session.update', {
            session: { temperature: newTemperature },
          });
        }
      }, 1000);

      debounceSendSessionUpdate();
      console.log('Temperature changed:', newTemperature);
    },
    [voiceBotInstanceRef, updateConfig]
  );

  const handleVoiceChange = useCallback(
    (newVoice: string) => {
      setVoice(newVoice);
      updateConfig({ voice: newVoice });
      console.log('Voice changed:', newVoice);
    },
    [updateConfig]
  );

  const handleMaxTokensChange = useCallback(
    (newMaxTokens: number) => {
      setMaxTokens(newMaxTokens);
      updateConfig({ maxTokens: newMaxTokens });

      const debounceSendSessionUpdate = debounce(() => {
        if (voiceBotInstanceRef?.isConnected()) {
          voiceBotInstanceRef.realtime.send('session.update', {
            session: { maxTokens: newMaxTokens },
          });
        }
      }, 1000);

      debounceSendSessionUpdate();
      console.log('Max Tokens changed:', newMaxTokens);
    },
    [voiceBotInstanceRef, updateConfig]
  );

  //#region connection
  const handleConnectConversation = useCallback(
    async (sessionId: string) => {
      console.log('Connect conversation');
      try {
        if (!isConnected) {
          const wavRecorder = wavRecorderRef.current;
          const wavStreamPlayer = wavStreamPlayerRef.current;

          startTimeRef.current = new Date().toISOString();
          setIsConnected(true);

          voiceBotInstanceRef?.reset();
          handleSetEventHandlers(voiceBotInstanceRef, wavStreamPlayer);

          await wavRecorder.begin();
          await wavStreamPlayer.connect();

          await voiceBotInstanceRef?.connect();

          voiceBotInstanceRef?.realtime.send('conversation.item.create', {
            item: {
              type: 'message',
              role: 'user',
              content: [{ type: 'input_text', text: sessionId }],
            },
          });

          voiceBotInstanceRef?.realtime.send('conversation.item.create', {
            item: {
              type: 'message',
              role: 'user',
              content: [{ type: 'input_text', text: userId }],
            },
          });

          if (voiceBotInstanceRef?.getTurnDetectionType() === 'server_vad') {
            console.log('starting recording');
            await wavRecorder.record((data: any) =>
              voiceBotInstanceRef.appendInputAudio(data.mono)
            );
          }
          setCanPushToTalk(selectedEndType.value === END_TYPE_OPTIONS.MANUAL);
          handleConnectionChange?.(true);
        } else {
          console.log('Somehow not connected');
        }
      } catch (error) {
        console.error(error);
        handleError(error);
        setIsConnected(false);
        handleConnectionChange?.(false);
      }
      return () => {
        if (isConnected) {
          handleDisconnectConversation();
        }
      };
    },
    [voiceBotInstanceRef, selectedEndType, isConnected]
  );

  const handleDisconnectConversation = useCallback(async () => {
    try {
      console.log('Disconnect conversation');
      setIsConnected(false);

      voiceBotInstanceRef?.disconnect();

      await wavRecorderRef.current.end();
      await wavStreamPlayerRef.current.interrupt();

      handleConnectionChange(false);
    } catch (error) {
      console.error(error);

      handleError(error);
    }
  }, [voiceBotInstanceRef]);

  const handleFinishSession = useCallback(async () => {
    window.removeEventListener('beforeunload', saveAISession);
    isConnected && handleDisconnectConversation();
    if (!currentUser || !userId) return;
    await endAISession(userId, roomUrl, currentUser.role!);
    if (isUserTherapist(currentUser.role!)) {
      const userData = await fetchCurrentUserDetails((currentUser as TherapistUser).therapistId!);
      refaelaTherapistActor.send({
        type: 'therapistUser.update',
        currentUser: userData as TherapistUser,
      });
      refaelaTherapistActor.send({ type: 'goToTherapistOffice' });
    } else {
      const userData = await fetchCurrentUserDetails((currentUser as Patient).patientId);
      refaelaPatientActor.send({
        type: 'UPDATE_PATIENT_USER',
        currentUser: userData as Patient,
      });
      refaelaPatientActor.send({ type: PATIENT_MACHINE_EVENTS.END_SESSION });
    }
  }, [isConnected, currentUser, userId, roomUrl]);

  const handleStartRecording = useCallback(async () => {
    try {
      setIsRecording(true);

      const wavRecorder = wavRecorderRef.current;
      const wavStreamPlayer = wavStreamPlayerRef.current;

      const trackSampleOffset = await wavStreamPlayer.interrupt();
      if (trackSampleOffset?.trackId) {
        const { trackId, offset } = trackSampleOffset;
        voiceBotInstanceRef?.cancelResponse(trackId, offset);
      }

      await wavRecorder.record((data: any) => voiceBotInstanceRef?.appendInputAudio(data.mono));
    } catch (error) {
      handleError?.(error);
      setIsRecording(false);
    }
  }, []);

  const handleStopRecording = useCallback(async () => {
    try {
      setIsRecording(false);

      const wavRecorder = wavRecorderRef.current;
      await wavRecorder.pause();
      voiceBotInstanceRef?.createResponse();
    } catch (error) {
      handleError?.(error);
    }
  }, []);

  const handleTresholdChange = useCallback((value: number) => {
    setThreshold(value);
  }, []);

  const handlePrefixPaddingChange = useCallback((value: number) => {
    setPrefixPadding(value);
  }, []);

  const handleSilenceDurationChange = useCallback((value: number) => {
    setSilenceDuration(value);
  }, []);

  const handleSetEventHandlers = (
    voiceBotInstanceRef: RealtimeClient,
    wavStreamPlayer: WavStreamPlayer
  ) => {
    // Event handlers
    voiceBotInstanceRef.on('realtime.event', (realtimeEvent: RealtimeEventState) => {
      // Handle state updates
      if (realtimeEvent.event?.type == 'state.updated') {
        setAgentState(realtimeEvent.event?.data);
        handleStateChanged?.(realtimeEvent);
      }

      dashboardActor.send({
        type: 'REALTIME_EVENTS_UPDATE',
        data: { ...realtimeEvent, role: wavStreamPlayer.stream ? 'assistant' : null },
      });
      //TODO: remove if perfomance via xstate is better
      // setRealtimeEvents((prev) => {
      //   const lastEvent = prev[prev.length - 1];
      //   if (lastEvent?.event.type === realtimeEvent.event.type) {
      //     lastEvent.count = (lastEvent.count || 0) + 1;
      //     return [...prev.slice(0, -1), lastEvent];
      //   }
      //   return [...prev, { ...realtimeEvent, role: wavStreamPlayer.stream ? 'assistant' : null }];
      // });
    });

    voiceBotInstanceRef.on('error', (error: any) => handleError?.(error));

    voiceBotInstanceRef.on('conversation.interrupted', async () => {
      const trackSampleOffset = await wavStreamPlayer.interrupt();
      if (trackSampleOffset?.trackId) {
        const { trackId, offset } = trackSampleOffset;
        voiceBotInstanceRef.cancelResponse(trackId, offset);
      }
    });

    voiceBotInstanceRef.on('conversation.updated', async ({ item, delta }: any) => {
      const items = voiceBotInstanceRef.conversation.getItems();

      if (delta?.audio) {
        wavStreamPlayer.add16BitPCM(delta.audio, item.id);
        handleAudio?.(new Blob([delta.audio], { type: 'audio/wav' }));
      }

      if (item.formatted.transcript) {
        handleTranscript?.(item.formatted.transcript);
      }

      if (item.status === 'completed' && item.formatted.audio?.length) {
        const wavFile = await WavRecorder.decode(item.formatted.audio, 24000, 24000);
        item.formatted.file = wavFile;
      }

      dashboardActor.send({
        type: 'REALTIME_CONVERSATION_ITEMS_UPDATE',
        data: items,
      });
      // TODO: remove if the perfomance via state is better
      // setItems(items);
    });
  };

  const handleChangeTurnEndType = useCallback(
    async ({ label, value }: { label: string; value: string }) => {
      const wavRecorder = wavRecorderRef.current;
      setSelectedEndType({ label, value: value as string });
      if (voiceBotInstanceRef?.realtime.isConnected()) {
        if (value === END_TYPE_OPTIONS.MANUAL && wavRecorder.getStatus() === 'recording') {
          await wavRecorder.pause();
        }

        (voiceBotInstanceRef.sessionConfig as any).turn_detection =
          value === END_TYPE_OPTIONS.MANUAL
            ? null
            : { ...voiceBotInstanceRef.defaultServerVadConfig, type: END_TYPE_OPTIONS.VAD };
        // Update turn detection
        voiceBotInstanceRef.realtime.send('session.update', {
          session: {
            turn_detection:
              value === END_TYPE_OPTIONS.MANUAL
                ? null
                : { ...voiceBotInstanceRef.defaultServerVadConfig, type: END_TYPE_OPTIONS.VAD },
          },
        });

        if (value === END_TYPE_OPTIONS.VAD) {
          await wavRecorder.record((data: any) => voiceBotInstanceRef.appendInputAudio(data.mono));
        }
      } else {
        if (voiceBotInstanceRef) {
          voiceBotInstanceRef.defaultSessionConfig.turn_detection =
            value === END_TYPE_OPTIONS.MANUAL
              ? null
              : { ...voiceBotInstanceRef?.defaultServerVadConfig, type: END_TYPE_OPTIONS.VAD };
        }
      }

      setCanPushToTalk(value === END_TYPE_OPTIONS.MANUAL);
    },
    []
  );
  //#endregion connection

  // Initial setup
  useEffect(() => {
    // TODO: remove if the perfomance via state is better
    // const wavStreamPlayer = wavStreamPlayerRef.current;

    // // Set up client configuration
    // voiceBotInstanceRef.updateSession({
    //   instructions: customInstructions,
    //   input_audio_transcription: { model: 'whisper-1' }
    // });

    // voiceBotInstanceRef.defaultSessionConfig.turn_detection = null;

    //TODO: redundant remove duplication of listeners?
    // handleSetEventHandlers(voiceBotInstanceRef, wavStreamPlayer);

    // Expose client reference
    handleClientReady?.(voiceBotInstanceRef);

    // Auto-connect if enabled
    if (false) {
      handleConnectConversation(sessionId!);
    }

    return () => {
      voiceBotInstanceRef?.reset();
    };
  }, []);

  useEffect(() => {
    dashboardActor.start();
    return () => {
      dashboardActor.stop();
    };
  }, []);

  const handleSectionToggleByKey = useCallback((key: string) => {
    dashboardActor.send({ type: 'TOGGLE_SECTION', key });
  }, []);

  return (
    <div className="grid grid-cols-[25%_50%_25%] gap-4 p-6 w-full text-left">
      <div className="bg-transparent rounded-lg">
        <MemoVisionAIModel
          key="vision-ai-model"
          shenaiSDK={shenaiSDK}
          isSDKInitialized={isSDKInitialized}
          initializationSettings={initializationSettings}
          onSectionToggleByKey={handleSectionToggleByKey}
          onSetInitializationSettings={handleSetInitializationSettings}
          onInitializeShenAiSdk={handleInitializeShenAISDK}
          onDeinitializeShenAiSdk={handleDeinitializeShenAISDK}
        />
      </div>
      <div className="bg-transparent p-4 rounded-lg col-span-1.5">
        <SessionInfo
          key="session-info-shenai-window"
          isSDKInitialized={isSDKInitialized}
        />
        <MemoRealtimeAi
          key="realtime-ai"
          onSectionToggleByKey={handleSectionToggleByKey}
          instructions={instructions}
          isConnected={isConnected}
          onInstructionsChange={handleInstructionsChange}
          startTimeRef={startTimeRef}
          wavRecorderRef={wavRecorderRef}
          wavStreamPlayerRef={wavStreamPlayerRef}
          realtimeClientInstance={voiceBotInstanceRef}
          agentState={agentState}
          hideUI={false}
          // TODO: remove if the perfomance via state is better
          // items={items}
          // realtimeEvents={realtimeEvents}
        />
        <RealtimeMeasurementGraphs onSectionToggleByKey={handleSectionToggleByKey} />
        {voiceBotInstanceRef ? (
          <div>
            <SummaryData
              actor={actor}
              sessionId={sessionId}
              realtime={voiceBotInstanceRef?.realtime}
            />
            <SuggestionData
              actor={actor}
              sessionId={sessionId}
              realtime={voiceBotInstanceRef?.realtime}
            />
          </div>
        ) : null}
        {roomUrl && (
          <AiSession
            actor={
              currentUser && isUserTherapist(currentUser.role!)
                ? refaelaTherapistActor
                : refaelaPatientActor
            }
            roomUrl={roomUrl}
            meetingToken={meetingToken}
          />
        )}
      </div>
      <div className="bg-transparent p-4 rounded-lg">
        <ConversationalModelProperties
          key="conversational-model-properties"
          onSectionToggleByKey={handleSectionToggleByKey}
          initialTemperature={temperature}
          initialVoice={voice}
          initialMaxTokens={maxTokens}
          initialThreshold={threshold}
          initialPrefixPadding={prefixPadding}
          initialSilenceDuration={silenceDuration}
          onTemperatureChange={handleTemperatureChange}
          onVoiceChange={handleVoiceChange}
          onMaxTokensChange={handleMaxTokensChange}
          onThresholdChange={handleTresholdChange}
          onPrefixPaddingChange={handlePrefixPaddingChange}
          onSilenceDurationChange={handleSilenceDurationChange}
          allowVoiceChange={!voiceBotInstanceRef?.isConnected()}
        />
        {sessionId && (
          <MemoizedConnectSettingsSection
            key="connect-settings-section"
            onStartRecording={handleStartRecording}
            onStopRecording={handleStopRecording}
            onConnectConversation={handleConnectConversation}
            onDisconnectConversation={handleDisconnectConversation}
            onChangeTurnEndType={handleChangeTurnEndType}
            onFinishSession={handleFinishSession}
            isConnected={isConnected}
            isRecording={isRecording}
            canPushToTalk={canPushToTalk}
            selectedEndType={selectedEndType}
            sessionId={sessionId}
          />
        )}
        <div className="flex flex-col space-y-4 w-full">
          <InfoRow
            key="Speaker-activation-state"
            label={'Speaker activation'}
            value={''}
          />
          <SpeakerActivationPlot
            key="speaker"
            isConnected={isConnected}
            voiceBotInstance={voiceBotInstanceRef}
            // TODO: remove if the perfomance via state is better
            // realtimeEvents={realtimeEvents}
          />
        </div>
        {voiceBotInstanceRef && (
          <div className="flex flex-col gap-4">
            {/* <FrequentKeywordsData 
              sessionId={sessionId}
              actor={actor}
              realtime={voiceBotInstanceRef?.realtime} 
              onSectionToggleByKey={handleSectionToggleByKey} 
            /> */}
            <TopicDetectionAgent
              sessionId={sessionId}
              actor={actor}
              key="topicDetectionAgent"
              realtime={voiceBotInstanceRef?.realtime}
              onSectionToggleByKey={handleSectionToggleByKey}
            />
            <ConversationAnalysisData
              sessionId={sessionId}
              actor={actor}
              key="ConversationAnalysisData"
              realtime={voiceBotInstanceRef?.realtime}
              onSectionToggleByKey={handleSectionToggleByKey}
            />
            <CharacteristicData
              sessionId={sessionId}
              actor={actor}
              key="CharacteristicData"
              realtime={voiceBotInstanceRef?.realtime}
              onSectionToggleByKey={handleSectionToggleByKey}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default RealtimeDashboard;
