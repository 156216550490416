export const colors = [
  //TODO: COUPLE COLORS WITH TAILWIND THEME
  '#1771E9',
  '#3BA561',
  '#ED4245',
  '#EB459F',
  '#593695',
  '#9C84EF',
  '#F47B67',
  '#EF8843',
  '#3E70DD',
  '#FAA61A',
];
