import { IPlotPayload, IPlotPayloadData, ITimelineRange } from '@components/Plot/types';
import { USER_ROLES } from '@interfaces/user';
import { PLOT_SIGNALS } from '@shared/constants/plot/signals';
import { ProcessedMXLabsData } from '@shared/types/mxlabs/logs';
import { ValueOf } from '@utils/types';
import { PLOT_CATEGORY } from '../constants/categories';
import { AUXILIARY_SIGNAL_LIST } from '../constants/signals';
import { SIGNAL_LABEL_MAP } from '../constants/maps';
import { AvailableRoleSignalsT } from '../types/signals';

type USER_ROLES_TYPE = ValueOf<typeof USER_ROLES>;

type GetAxesDataProps = {
  signalsData: Record<USER_ROLES_TYPE, ProcessedMXLabsData[]>;
  configuredSignals: Record<USER_ROLES_TYPE, ValueOf<typeof PLOT_SIGNALS>[]>;
  timelineRange: ITimelineRange;
  availableRoleSignals: Record<AvailableRoleSignalsT, boolean>;
};

type ProcessedSignalsData = ProcessedMXLabsData & {
  role: string;
  isHrReliable: boolean;
};

// both signalsData and configuredSignals presented by role
export const getAxesData = ({
  signalsData,
  configuredSignals,
  timelineRange,
  availableRoleSignals,
}: GetAxesDataProps): [IPlotPayload | null, IPlotPayload | null] => {
  const defaultData = createDefaultData(timelineRange);

  // enhance data with isHrReliable flag
  const processedSignalsData = processSignalsData(signalsData);

  const patientData = availableRoleSignals[USER_ROLES.PATIENT]
    ? filterConfiguredData(processedSignalsData, USER_ROLES.PATIENT, configuredSignals)
    : [];
  const therapistData = availableRoleSignals[USER_ROLES.THERAPIST]
    ? filterConfiguredData(processedSignalsData, USER_ROLES.THERAPIST, configuredSignals)
    : [];

  const mainAxisData = createMainAxisData(patientData, therapistData, defaultData);

  const secondaryAxisData = createSecondaryAxisData(patientData, therapistData, defaultData);

  return [mainAxisData, secondaryAxisData];
};

const createDefaultData = (timelineRange: ITimelineRange): IPlotPayloadData[] => [
  {
    timestamp: timelineRange.startTimestamp,
    key: '',
    name: 'default',
    value: 0,
    role: USER_ROLES.THERAPIST,
  },
  {
    timestamp: timelineRange.endTimestamp,
    key: '',
    name: 'default',
    value: 0,
    role: USER_ROLES.THERAPIST,
  },
];

const filterConfiguredData = (
  signalsData: ProcessedSignalsData[],
  role: USER_ROLES_TYPE,
  configuredSignals: Record<USER_ROLES_TYPE, string[]>
) => {
  return getConfiguredData(
    signalsData.filter((d) => d.role === role),
    configuredSignals[role] as ValueOf<typeof PLOT_SIGNALS>[]
  );
};

const createMainAxisData = (
  patientData: IPlotPayloadData[],
  therapistData: IPlotPayloadData[],
  defaultData: IPlotPayloadData[]
): IPlotPayload | null => {
  const mainPatientSignals = filterMainSignals(patientData);
  const mainTherapistSignals = filterMainSignals(therapistData);

  const mainSignalsData = [...mainPatientSignals, ...mainTherapistSignals];

  return mainSignalsData.length
    ? {
        data: mainSignalsData || defaultData,
        type: PLOT_CATEGORY.SIGNALS,
        label: extractSignalLabels(mainSignalsData),
      }
    : null;
};

const createSecondaryAxisData = (
  patientData: IPlotPayloadData[],
  therapistData: IPlotPayloadData[],
  defaultData: IPlotPayloadData[]
): IPlotPayload | null => {
  const secondaryPatientSignals = filterSecondarySignals(patientData);
  const secondaryTherapistSignals = filterSecondarySignals(therapistData);

  const secondarySignalsData = [...secondaryPatientSignals, ...secondaryTherapistSignals];

  return secondarySignalsData.length
    ? {
        data: secondarySignalsData || defaultData,
        type: PLOT_CATEGORY.EMOTIONS,
        label: extractSignalLabels(secondarySignalsData),
      }
    : null;
};

const filterMainSignals = (data: IPlotPayloadData[]) =>
  data.filter(
    (d) => !AUXILIARY_SIGNAL_LIST.includes(d.key as (typeof AUXILIARY_SIGNAL_LIST)[number])
  );

const filterSecondarySignals = (data: IPlotPayloadData[]) =>
  data.filter((d) =>
    AUXILIARY_SIGNAL_LIST.includes(d.key as (typeof AUXILIARY_SIGNAL_LIST)[number])
  );

const extractSignalLabels = (data: IPlotPayloadData[]): string[] => {
  return Array.from(new Set(data.map((d) => d.key)));
};

const getConfiguredData = (
  data: any[], // IPlotPayloadData[]
  configuredSignals: ValueOf<typeof PLOT_SIGNALS>[]
): IPlotPayloadData[] => {
  data?.sort((a, b) => a.timestamp - b.timestamp);

  return data?.reduce((acc, curr) => {
    const timestamp = curr.timestamp;
    const description = curr.description ? curr.description : null;
    const currentConfiguredData = configuredSignals.map((signalKey) => {
      let isReliable = true;

      if (signalKey === PLOT_SIGNALS.HR_4S || signalKey === PLOT_SIGNALS.HR_10S) {
        isReliable = curr.isHrReliable;
      } else if (curr.isReliable !== undefined) {
        isReliable = curr.isReliable;
      }

      return {
        key: signalKey,
        role: curr.role,
        timestamp,
        isReliable,
        description,
        value: curr[signalKey as keyof typeof curr],
        name: SIGNAL_LABEL_MAP[signalKey as keyof typeof SIGNAL_LABEL_MAP],
      };
    });
    return [...acc, ...currentConfiguredData];
  }, []);
};

const processSignalsData = (data: Record<string, ProcessedMXLabsData[]>) =>
  Object.entries(data).flatMap(([role, signalLogs], idx) => {
    return signalLogs.map((el: ProcessedMXLabsData) => {
      const isLowChange =
        !(idx === signalLogs?.length - 1) &&
        Math.abs(el.hr4s - signalLogs[idx + 1][PLOT_SIGNALS.HR_4S]) < 25;
      const isHrReliable =
        el.hr4s >= 40 && el.hr4s <= 140 && isLowChange && el.measurementState === 3;
      return { ...el, isHrReliable, role };
    });
  });
