import { ValueOf } from '@utils/types';
import { FACE_STATE } from '../constants/states';

export const getFaceStateValue = (value: ValueOf<typeof FACE_STATE>) => {
  switch (value) {
    case FACE_STATE.OK:
      return 'Ok';
    case FACE_STATE.TOO_FAR:
      return 'Too Far';
    case FACE_STATE.TOO_CLOSE:
      return 'Too Close';
    case FACE_STATE.NOT_CENTERED:
      return 'Not Centered';
    case FACE_STATE.NOT_VISIBLE:
      return 'Not Visible';
    default:
      return 'Unknown';
  }
};
