import { useState } from 'react';

import SwitchButton from '@shared/ui/button/SwitchButton';

type SwitchButtonOption = { label: string; value: string | number };
interface SwitchButtonsProps {
  options: SwitchButtonOption[];
  initialOption?: Record<string, string | number>;
  onChange?: (value: SwitchButtonOption) => void;
  containerClasses?: string;
}

const SwitchButtons = ({
  options,
  initialOption,
  onChange,
  containerClasses = '',
}: SwitchButtonsProps) => {
  const [selectedOption, setSelectedOption] = useState(initialOption || options[0]);

  const handleOptionSelect = (option: SwitchButtonOption) => {
    setSelectedOption(option);
    onChange?.(option);
  };

  return (
    <div className={`flex items-center bg-white rounded-md shadow p-1 w-fit ${containerClasses}`}>
      {options.map((option) => (
        <SwitchButton
          key={option.value}
          label={option.label}
          isActive={selectedOption.value === option.value}
          onClick={() => handleOptionSelect(option)}
        />
      ))}
    </div>
  );
};

export default SwitchButtons;
