export const requestMediaPermissionsAndSetDevices = async (
  setVideoStream: (stream: MediaStream) => void,
  setCameraId: (cameraId: string) => void,
  setMicrophoneId: (microphoneId: string) => void,
  setErrorMessage: (errorMessage: string | null) => void
): Promise<void> => {
  try {
    const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
    setVideoStream(stream);
    setErrorMessage(null);

    const devices = await navigator.mediaDevices.enumerateDevices();

    const videoDeviceList: MediaDeviceInfo[] = devices.filter(
      (device) => device.kind === 'videoinput'
    );
    const audioDeviceList: MediaDeviceInfo[] = devices.filter(
      (device) => device.kind === 'audioinput'
    );

    const initialCameraId: string = localStorage.getItem('cameraId') || '';
    const initialMicrophoneId: string = localStorage.getItem('microphoneId') || '';

    const isCameraExist = videoDeviceList.some((device) => device.deviceId === initialCameraId);
    if (!initialCameraId || !isCameraExist) {
      const newCameraId = videoDeviceList[0]?.deviceId || '';
      localStorage.setItem('cameraId', newCameraId);
      setCameraId(newCameraId);
    } else {
      setCameraId(initialCameraId);
    }

    const isMicrophoneExist = audioDeviceList.some(
      (device) => device.deviceId === initialMicrophoneId
    );
    if (!initialMicrophoneId || !isMicrophoneExist) {
      const newMicrophoneId = audioDeviceList[0]?.deviceId || '';
      localStorage.setItem('microphoneId', newMicrophoneId);
      setMicrophoneId(newMicrophoneId);
    } else {
      setMicrophoneId(initialMicrophoneId);
    }
  } catch (err) {
    setErrorMessage('Permission denied or no access to camera/microphone.');
    console.error(err);
  }
};
