import * as Plot from '@observablehq/plot';
import * as d3 from 'd3';
import { setValueToPlotDisplay } from './utils';
import {
  EventsNamesEnum,
  IPlotPayloadData,
  PlotSizeEnum,
  PlotSizeMap,
  PlotTypeEnum,
  eventsList,
} from './types';
import { ITimelineRange } from './types';
import { PLOT_SIGNALS } from '@shared/constants/plot/signals';
import { groupBy } from '@utils/groupBy';

import { getMinMaxValueFromData } from './model/utils/getMinMaxValueFromData';
import { getPlotYLeftAxisTicks } from './model/utils/getPlotYLeftAxisTicks';
import { getDomainByValues } from './model/utils/getPlotDomain';
import { ShamefulAny } from '@interfaces/index';

export const getPlotVisualisationElements = (
  data: IPlotPayloadData[] | undefined,
  secondaryData: IPlotPayloadData[] | undefined,
  plotType: string | undefined,
  height: number,
  isHrFilter: boolean,
  timelineRange: ITimelineRange
) => {
  //#region utils
  const mergedData = [...(data || []), ...(secondaryData || [])];
  const extractValue = (d: IPlotPayloadData) => d.value;
  // sort data by signal to extract case if user wants to deal with same signals
  const dataBySignal = groupBy(mergedData, 'key');
  // in case if roles are different but signals are same use merged data
  // not to use secondary axis
  const groupedDataBySignals = (Object.keys(dataBySignal).length === 1 && mergedData) || data || [];

  const dataByRoleOrSignal = groupBy(groupedDataBySignals, 'role');

  // TODO: create a map for readable signals MAP_SIGNALS[data[0].key || 'emtpy_label']
  const primarySignalLabel = data?.length && [...new Set(data.map((item) => item.key))].join(' & ');
  const secondarySignalLabel = secondaryData?.length && secondaryData[0].key;
  // Process value based on whether the data is reliable and within the timeline range
  const processValue = (d: IPlotPayloadData) => {
    const isHrData = d.key === PLOT_SIGNALS.HR_4S || d.key === PLOT_SIGNALS.HR_10S;
    const isReliable = isHrData ? (isHrFilter ? d.isReliable : true) : d.isReliable;
    return isReliable && d.timestamp >= timelineRange.startTimestamp ? d.value : null;
  };
  //#endregion utils

  const [primaryMinValue, primaryMaxValue] = getMinMaxValueFromData(
    groupedDataBySignals,
    extractValue
  );
  const [secondaryMinValue, secondaryMaxValue] = getMinMaxValueFromData(
    secondaryData,
    extractValue
  );

  // Create lines and pointer elements for each role
  // in case if
  const leftAxisElements = [
    ...Object.entries(dataByRoleOrSignal).map(([_, sortedData]) => {
      return [
        Plot.lineY(sortedData, {
          x: 'timestamp',
          y: (d) => d.value,
          stroke: (d) => `${d.role}-${d.key}`,
          marker: plotType === PlotTypeEnum.DOTS,
          filter: (d) =>
            d.name !== 'default' &&
            !eventsList.some((el) => el == d.name && el != EventsNamesEnum.TABS_FOCUS),
        }),
      ];
    }),
    Plot.axisY({
      labelArrow: false,
      label: primarySignalLabel || '',
      labelOffset: 0,
      ticks: getPlotYLeftAxisTicks(primaryMinValue!, primaryMaxValue!),
    }),
  ];

  // Define a y2 scale if secondary data is provided
  const rightYaxis = secondaryData
    ? d3.scaleLinear(
        d3.extent(secondaryData, extractValue) as [number, number],
        getDomainByValues(primaryMinValue!, primaryMaxValue!)
      )
    : null;

  const pointerLineRightAxisData = [
    Plot.ruleX(
      secondaryData,
      Plot.pointerX({
        x: 'timestamp',
        py: (d) => rightYaxis!(d.value),
        stroke: (d) => `${d.role}-${d.key}`,
        maxRadius: 10,
      })
    ),
    Plot.dot(
      secondaryData,
      Plot.pointerX({
        x: 'timestamp',
        y: (d) => rightYaxis!(d.value),
        stroke: (d) => `${d.role}-${d.key}`,
        maxRadius: 10,
      })
    ),
    Plot.text(
      secondaryData,
      Plot.pointerX({
        px: 'timestamp',
        py: (d) => rightYaxis!(d.value),
        dy: 5,
        dx: 115,
        frameAnchor: 'top-left',
        fontVariant: 'tabular-nums',
        text: (d) => setValueToPlotDisplay(d),
      })
    ),
  ];

  const y2Scale = secondaryData
    ? d3.scaleLinear(d3.extent(secondaryData, extractValue) as [number, number], [
        getDomainByValues(secondaryMinValue!, secondaryMaxValue!),
      ])
    : null;

  // Map y2 values to the y1 domain if y2 exists
  const mapY2toY1 = y2Scale
    ? d3
        .scaleLinear()
        .domain(y2Scale.domain())
        .range(getDomainByValues(secondaryMinValue!, secondaryMaxValue!)!)
    : null;

  // Create pointer line elements for the left axis
  const leftPointerElements = [
    Plot.ruleX(
      data,
      Plot.pointerX({ x: 'timestamp', py: processValue, stroke: 'red', maxRadius: 10 })
    ),
    Plot.dot(
      data,
      Plot.pointerX({ x: 'timestamp', y: processValue, stroke: 'red', maxRadius: 10 })
    ),
    Plot.text(
      data,
      Plot.pointerX({
        px: 'timestamp',
        py: processValue,
        dy: 5,
        dx: 5,
        frameAnchor: 'top-left',
        fontVariant: 'tabular-nums',
        text: (d) => setValueToPlotDisplay(d),
      })
    ),
  ];

  const rightYaxisPlot = rightYaxis && [
    Plot.axisY(
      height === PlotSizeMap[PlotSizeEnum.SMALL] ? rightYaxis.ticks(3) : rightYaxis.ticks(5),
      {
        label: secondarySignalLabel || '',
        anchor: 'right',
        labelArrow: false,
        labelOffset: -5,
        y: rightYaxis,
        ticks: rightYaxis.ticks(5),
        tickFormat: rightYaxis.tickFormat(),
      }
    ),
    Plot.lineY(
      secondaryData,
      Plot.mapY((D: ShamefulAny) => D.map(rightYaxis), {
        x: 'timestamp',
        y: processValue,
        stroke: (d) => `${d.role}-${d.key}`,
        marker: plotType === PlotTypeEnum.DOTS,
        filter: (d) => d.name !== 'default',
      })
    ),

    pointerLineRightAxisData,
  ];

  return {
    leftYaxisPlot: leftAxisElements,
    rightYaxisPlot,
    pointerLineLeftAxixData: leftPointerElements,
    pointerLineRightAxixData: pointerLineRightAxisData,
    y2: y2Scale,
    y2toy1Scale: mapY2toY1,
  };
};
