export const groupBy = <T, K extends keyof T & (string | number)>(
  data: T[],
  key: K
): Record<string, T[]> => {
  const groupedData: Record<string, T[]> = {};

  data.forEach((el) => {
    const keyValue = el[key];

    if (!groupedData[keyValue as string]) {
      groupedData[keyValue as string] = [];
    }

    groupedData[keyValue as string].push(el);
  });

  return groupedData;
};
