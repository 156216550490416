import { useRef, useCallback, useState } from 'react';
import { RealtimeClient as RealtimeClientType } from '../../../modules/Patient/AISession/RealtimeAISession';
import { ClientConfig, UseClientResult, VadConfig } from '../types';
import debounce from 'lodash/debounce';
import { BASE_WS_URL } from '@api/base';
import { RealtimeClient } from '@openai/realtime-api-beta';
import { END_TYPE_OPTIONS } from '@modules/Patient/AISession/model/constants/options';

interface UseClientInitialConfig extends ClientConfig {
  selectedEndType: string;
}

export const useRealtimeClient = (initialConfig: UseClientInitialConfig): UseClientResult => {
  const voiceBotInstanceRef = useRef<RealtimeClientType>(
    new RealtimeClient({ url: `${BASE_WS_URL}/azure-rt` })
  );
  const [isConnected, setIsConnected] = useState(false);

  const updateConfig = useCallback((config: Partial<UseClientInitialConfig>) => {
    if (!voiceBotInstanceRef.current) return;

    const newConfig = {
      voice: config.voice ?? voiceBotInstanceRef.current.defaultSessionConfig.voice,
      instructions:
        config.instructions ?? voiceBotInstanceRef.current.defaultSessionConfig.instructions,
      temperature:
        config.temperature ?? voiceBotInstanceRef.current.defaultSessionConfig.temperature,
      max_response_output_tokens:
        config.maxTokens ??
        voiceBotInstanceRef.current.defaultSessionConfig.max_response_output_tokens,
      turn_detection:
        config.selectedEndType === END_TYPE_OPTIONS.MANUAL
          ? null
          : { ...voiceBotInstanceRef.current.defaultServerVadConfig, type: config.selectedEndType },
      input_audio_transcription: { model: 'whisper-1' },
    };

    voiceBotInstanceRef.current.defaultSessionConfig = {
      ...voiceBotInstanceRef.current.defaultSessionConfig,
      ...newConfig,
    };

    voiceBotInstanceRef.current.sessionConfig = {
      ...voiceBotInstanceRef.current.sessionConfig,
      ...newConfig,
    };

    voiceBotInstanceRef.current.defaultServerVadConfig.threshold =
      config.threshold ?? voiceBotInstanceRef.current.defaultServerVadConfig.threshold;
    voiceBotInstanceRef.current.defaultServerVadConfig.prefix_padding_ms =
      config.prefixPadding ?? voiceBotInstanceRef.current.defaultServerVadConfig.prefix_padding_ms;
    voiceBotInstanceRef.current.defaultServerVadConfig.silence_duration_ms =
      config.silenceDuration ??
      voiceBotInstanceRef.current.defaultServerVadConfig.silence_duration_ms;

    voiceBotInstanceRef.current.addTool(
      {
        name: 'start_breathing_exercise',
        description:
          "Call this function when you've provided user with instructions on how exercise will be going and got confirmation that he understood and ready to go",
        type: 'function',
        parameters: {},
      },
      () => true
    );

    voiceBotInstanceRef.current.addTool(
      {
        name: 'get_back_intent',
        description:
          "Call this function when user says he wants to get back to previous state, like 'get back'",
        type: 'function',
        parameters: {},
      },
      () => true
    );

    voiceBotInstanceRef.current.addTool(
      {
        name: 'icebreaker_completed',
        description:
          'Call this function after you had completed an icebreaker dialog and exchanged with user with a 3 to 5 messages and he is now ready to proceed to the next step',
        type: 'function',
        parameters: {},
      },
      () => true
    );

    voiceBotInstanceRef.current.addTool(
      {
        name: 'break_intent',
        description:
          'Call this function when user says he wants to pause, have a break or keep silence. He can say something like "lets pause" or "stop" or "lets have a break"',
        type: 'function',
        parameters: {},
      },
      () => {
        voiceBotInstanceRef.current?.dispatch('conversation.interrupted', null);
      }
    );
  }, []);

  const updateVadConfig = useCallback((config: Partial<VadConfig>) => {
    if (!voiceBotInstanceRef.current) return;

    const currentVadConfig = voiceBotInstanceRef.current.defaultServerVadConfig;

    const newVadConfig = {
      type: config.type ?? currentVadConfig.type,
      threshold: config.threshold ?? currentVadConfig.threshold ?? 0.5,
      prefix_padding_ms: config.prefixPadding ?? currentVadConfig.prefix_padding_ms ?? 300,
      silence_duration_ms: config.silenceDuration ?? currentVadConfig.silence_duration_ms ?? 500,
    };

    if ((voiceBotInstanceRef.current.sessionConfig as any).turn_detection === null) {
      voiceBotInstanceRef.current.defaultServerVadConfig = newVadConfig;
    } else {
      // Update turn detection on the server with debounce
      if (voiceBotInstanceRef.current.isConnected()) {
        console.log('Scheduling update');
        debounce(() => {
          voiceBotInstanceRef?.current?.realtime.send('session.update', {
            session: {
              turn_detection: newVadConfig,
            },
          });
        }, 1000)(); // Assuming a debounce time of 300ms
      }
    }
  }, []);

  const handleClientReady = useCallback(
    (client: RealtimeClient) => {
      voiceBotInstanceRef.current = client;
      updateConfig(initialConfig);
    },
    [initialConfig, updateConfig]
  );

  const handleConnectionChange = useCallback(
    (connected: boolean) => {
      setIsConnected(connected);
      if (connected && voiceBotInstanceRef.current) {
        updateConfig(initialConfig);
      }
    },
    [initialConfig, updateConfig]
  );

  return {
    voiceBotInstanceRef: voiceBotInstanceRef.current,
    isConnected,
    updateConfig,
    updateVadConfig,
    handleClientReady,
    handleConnectionChange,
  };
};
