export const FACE_STATE = {
  OK: 0,
  TOO_FAR: 1,
  TOO_CLOSE: 2,
  NOT_CENTERED: 3,
  NOT_VISIBLE: 4,
  UNKNOWN: 5,
} as const;

export const MEASUREMENT_STATE = {
  NOT_STARTED: 0,
  WAITING_FOR_FACE: 1,
  RUNNING_SIGNAL_SHORT: 2,
  RUNNING_SIGNAL_GOOD: 3,
  RUNNING_SIGNAL_BAD: 4,
  RUNNING_SIGNAL_BAD_DEVICE_UNSTABLE: 5,
  FINISHED: 6,
  FAILED: 7,
} as const;
