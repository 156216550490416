export const formatDateFromISO = (date: number | string): string => {
  if (!date) return '';
  const dateObject = new Date(date);
  return dateObject.toLocaleDateString('en-CA');
};

export const getRandomFutureDate = (maxDays: number): string => {
  const today = new Date();
  const randomDays = Math.floor(Math.random() * (maxDays + 1));
  today.setDate(today.getDate() + randomDays);
  return today.toISOString();
};
