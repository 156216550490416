import React, { useContext, useState, useEffect } from 'react';
import Homework, { HomeworkStatus, HomeworkTask } from '@components/SessionComponents/Homework';

import { AppContext } from '../../../contextApp';
import { refaelaTherapistActor } from '@components/xState/machines/refaelaTherapistMachine';
import InvitePatientStep from '@components/SessionComponents/InvitePatientStep';
import Stepper from '@features/Stepper/Stepper';
import Calibration from '@components/Calibration/Calibration';
import { useSelector } from '@xstate/react';
import { requestMediaPermissionsAndSetDevices } from '@utils/mediaPermissions';
import { ShamefulAny } from '@interfaces/index';
import { PageTitle } from '@shared/ui/caption/PageTitle';
import { HINT_CARD_MAP } from './model/constants/maps';
import SummaryWaitingRoom from '@components/SessionComponents/Summary/SummaryWaitingRoom';

const tasks: HomeworkTask[] = [
  {
    id: '1',
    label: 'AI-Guided Homework Session Recap',
    text: `Some text`,
    status: HomeworkStatus.COMPLETED,
    createdAt: new Date('2024-07-28').toISOString(),
    inProgressAt: new Date('2024-07-28').toISOString(),
    completedAt: new Date('2024-07-28').toISOString(),
  },
];

const WaitingRoom: React.FC = () => {
  const snapshot = useSelector(refaelaTherapistActor, (state) => state);
  const { setIsExitBioMode, isBioCalibrated, patientToMeetId, setCameraId, setMicrophoneId } =
    useContext(AppContext);
  const [, setVideoStream] = useState<MediaStream | null>(null);
  const [, setErrorMessage] = useState<string | null>(null);
  const [roomUrl, setRoomUrl] = useState<string>('');
  const [sessionId, setSessionId] = useState<string>('');
  const [isInviteStepBlocked, setInviteStepIsBlocked] = useState<boolean>(true);
  const [currentStep, setCurrentStep] = useState<number>(0);

  useEffect(() => {
    // request permissions on adjust camera step
    if (currentStep !== 1) return;
    requestMediaPermissionsAndSetDevices(
      setVideoStream,
      setCameraId,
      setMicrophoneId,
      setErrorMessage
    );
  }, [currentStep]);

  const handleStartSession = () => {
    setIsExitBioMode(true);
    refaelaTherapistActor.send({
      type: 'goToTherapistSession',
      patientId: patientToMeetId,
      sessionId: sessionId,
      roomUrl: roomUrl,
    });
  };

  const handleBlockStep = (flag: boolean) => {
    setInviteStepIsBlocked(flag);
  };

  const steps = [
    {
      index: 0,
      label: 'Invite patient',
      content: (
        <InvitePatientStep
          onBlockStep={handleBlockStep}
          setRoomUrl={setRoomUrl}
          setSessionId={setSessionId}
          sessionsCount={1}
        />
      ),
      nextButtonLabel: 'Continue',
      isStepBlocked: isInviteStepBlocked,
    },
    {
      index: 1,
      label: 'Adjust camera',
      content: (
        <Calibration
          sessionId={roomUrl.split('/').pop()}
          isDailyMeeting={false}
          stateMachineActor={refaelaTherapistActor}
        />
      ),
      isStepBlocked: !isBioCalibrated,
      nextButtonLabel: 'Continue',
    },
    {
      index: 2,
      label: 'Last session summary',
      content: <SummaryWaitingRoom />,
      nextButtonLabel: 'Continue',
    },
    {
      index: 3,
      label: 'Self-work recap',
      content: (
        <Homework
          tasks={tasks}
          emptyLabelText="No self-work completed yet"
        />
      ),
      nextButtonLabel: 'Start session',
    },
  ];

  const isDailyTherapist = snapshot.matches('TherapistSession.TherapistMeetingRoom' as ShamefulAny);

  return (
    <div
      className={`h-full w-full flex flex-col gap-5 sm_d:gap-10 mt-5 sm_d:mt-10" ${isDailyTherapist ? 'hidden' : 'flex'}`}
    >
      <div className="flex items-center justify-between">
        <PageTitle>Prepare for session</PageTitle>
      </div>
      <div className="flex h-full justify-between">
        <div className="w-1/3 h-full pt-5 pr-5 flex flex-col justify-between">
          {HINT_CARD_MAP[steps[currentStep].index]}
        </div>
        <div className="w-2/3 h-full">
          {/* overflow y */}
          <Stepper
            onChangeStepCb={setCurrentStep}
            contentClasses="h-full px-4 w-full flex min-h-[23rem] max-h-[23rem] md_d:max-h-[28rem] "
            steps={steps}
            onFinish={handleStartSession}
          />
        </div>
      </div>
    </div>
  );
};

export default WaitingRoom;
