import { useEffect, useState } from 'react';
import 'preline/preline';
import Intercom from '@intercom/messenger-js-sdk';
// API
import { fetchCurrentUserDetails } from '@api/user/me';
//xState
import { appActor } from '@machines/shared/appMachine';
import { refaelaPatientActor } from '@components/xState/machines/refaelaPatientMachine';
import { refaelaTherapistActor } from '@components/xState/machines/refaelaTherapistMachine';

import { IStaticMethods } from 'preline/preline';

import { USER_ROLES } from '@interfaces/user';
import { Patient } from '@interfaces/patient';
import { TherapistUser } from '@interfaces/therapist';

import { isMobile } from '@utils/isMobile';

import TherapistOfficeMode from '@modules/Therapist/OfficeMode';
import PatientOfficeMode from '@modules/Patient/OfficeMode';

import { Loader } from '@shared/ui/loader/Loader';
import GlobalLayout from '@shared/ui/layout/GlobalLayout';
import { useUser } from '@clerk/clerk-react';
import { isUserPatient, isUserTherapist } from '@utils/helpers';

declare global {
  interface Window {
    HSStaticMethods: IStaticMethods;
  }
}

const App = () => {
  const [role, setRole] = useState<string>('');
  const [user, setUser] = useState<Patient | TherapistUser>();
  const { user: clerkUser } = useUser();

  // const { setCameraId, setMicrophoneId } = useContext(AppContext)!;

  const fetchUserDetails = async () => {
    if (!clerkUser?.id) {
      console.warn('clerkUser is undefined or null');
      return;
    }

    try {
      const userData = await fetchCurrentUserDetails(clerkUser.id);

      return userData;
    } catch (e: unknown) {
      if (e instanceof Error) {
        console.error('Error fetching current user data', e.message);
      } else {
        console.error('Unexpected error', e);
      }
    }
  };

  const handlePatientClick = () => {
    setRole(USER_ROLES.PATIENT);
  };

  const handleTherapistClick = () => {
    setRole(USER_ROLES.THERAPIST);
  };

  const handleUserUpdateByRole = (
    role: string,
    userData: Patient | TherapistUser,
    init?: boolean
  ) => {
    switch (role) {
      case USER_ROLES.PATIENT: {
        // start actor if it's initial execution
        init && refaelaPatientActor.start();
        refaelaPatientActor.send({
          type: 'UPDATE_PATIENT_USER',
          currentUser: userData as Patient,
        });
        break;
      }
      case USER_ROLES.THERAPIST: {
        // start actor if it's initial execution
        init && refaelaTherapistActor.start();
        refaelaTherapistActor.send({
          type: 'therapistUser.update',
          currentUser: userData as TherapistUser,
        });
        break;
      }
      default:
        return;
    }
  };

  const handleInitIntercom = (userData: Patient | TherapistUser) => {
    const intercomConfig = {
      app_id: 'skhn64em',
      user_id: isUserTherapist(userData.role!)
        ? userData.therapistId
        : (userData as Patient).patientId,
      name: userData.name,
      email: userData.email,
      ...(isUserPatient(userData.role!) && { created_at: (userData as Patient).createdAt }),
    };
    Intercom(intercomConfig);
  };

  useEffect(() => {
    appActor.start();
    // const initialCameraId = localStorage.getItem('cameraId');
    // const initialMicrophoneId = localStorage.getItem('microphoneId');

    // navigator.mediaDevices.enumerateDevices().then((res) => {
    //   const audioDeviceList = res?.filter((el) => el.kind === 'audioinput');

    //   //TODO: remove setCameraId when no use cases remain
    //   if (initialCameraId) {
    //     setCameraId(initialCameraId);
    //     appActor.send({ type: 'CAMERA_ID_UPDATE', id: initialCameraId });
    //   }

    //   const isMicrophoneExist = audioDeviceList.some((el) => el.deviceId === initialMicrophoneId);
    //   if (!initialMicrophoneId || !isMicrophoneExist) {
    //     localStorage.setItem('microphoneId', `${audioDeviceList[0]?.deviceId}`);
    //     appActor.send({ type: 'MICROPHONE_ID_UPDATE', id: audioDeviceList[0]?.deviceId });
    //     setMicrophoneId(audioDeviceList[0]?.deviceId);
    //   } else if (initialMicrophoneId) {
    //     appActor.send({ type: 'MICROPHONE_ID_UPDATE', id: initialMicrophoneId });
    //     setMicrophoneId(initialMicrophoneId);
    //   }
    // });
    return () => {
      appActor.stop();
    };
  }, []);

  useEffect(() => {
    window.HSStaticMethods.autoInit();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const userData = await fetchUserDetails();
        setUser(userData);
        setRole(userData?.role);
        handleInitIntercom(userData);
        handleUserUpdateByRole(userData?.role, userData, true);
      } catch (e) {
        console.error('Error fetching user details', JSON.stringify(e));
      }
    })();

    return () => {
      role === USER_ROLES.PATIENT && refaelaPatientActor.stop();
      role === USER_ROLES.THERAPIST && refaelaTherapistActor.stop();
    };
  }, []);

  useEffect(() => {
    const refetchUserDataOncePerMinute = setInterval(async () => {
      try {
        const userData = await fetchUserDetails();
        handleUserUpdateByRole(userData?.role, userData);
      } catch (e) {
        console.error('Error while user updating request', JSON.stringify(e));
      }
    }, 60000);

    return () => clearInterval(refetchUserDataOncePerMinute);
  }, []);

  useEffect(() => {
    window.HSStaticMethods.autoInit();
  }, [location.pathname]);

  if (isMobile) {
    return (
      <div className="flex p-8 h-full w-full items-center justify-center">
        <div className="flex flex-col items-start">
          <h4 className="self-center mb-2">Coming soon to Mobile!</h4>
          <span>
            It looks like you're on a <b>mobile device</b>.
          </span>
          <span>
            Our app is currently <b>optimized for desktop or laptop use</b>.
          </span>
          <span>A mobile version is in development — stay tuned for updates!</span>
        </div>
      </div>
    );
  }

  const renderContent = () => {
    switch (role) {
      case USER_ROLES.THERAPIST:
        return <TherapistOfficeMode />;
      case USER_ROLES.PATIENT:
        return (
          <PatientOfficeMode
            //TODO: move it inside since we use patientActor to store user data
            incomingSessionType={user?.incomingSessionType}
            assignedHomeworks={user?.assignedHomeworks}
            areTermsOfPrivacyAccepted={user?.areTermsOfPrivacyAccepted}
            roomUrl={user?.roomUrl}
            user={user}
          />
        );
      case USER_ROLES.ADMIN:
        return (
          <div className="flex gap-24">
            <button onClick={handlePatientClick}>Patient</button>
            <button onClick={handleTherapistClick}>Therapist</button>
          </div>
        );
      default:
        return <Loader />;
    }
  };

  return <GlobalLayout>{renderContent()}</GlobalLayout>;
};

export default App;
