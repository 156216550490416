import { refaelaPatientActor } from '@components/xState/machines/refaelaPatientMachine';
import { PATIENT_MACHINE_EVENTS } from '@machines/patient/constants/events';

export const PrivacyDetailsModalContent = () => {
  const handlePrivacyPolicyDetails = () => {
    refaelaPatientActor.send({
      type: PATIENT_MACHINE_EVENTS.GO_TO_PATIENT_TERMS_OF_USE_AND_PRIVACY,
    });
  };

  return (
    <div className="overflow-y-auto pr-4 h-full">
      This page is meant as a quick brief summary of the main data we collect and how we handle and
      use your data. For a more full and detailed information, please visit our{' '}
      <span
        onClick={handlePrivacyPolicyDetails}
        className="text-blue-600"
      >
        Privacy Policy
      </span>
      .
      <br />
      <br />
      <b>What Data We Collect</b>
      <br />
      <br />
      Contact Details: Your name, email address, and phone number. Session Recordings: Video and
      audio from your teletherapy sessions. Biometric Data: Information like your heart rate and
      facial expressions during sessions. Chat Logs: Your interactions with our AI platform. Notes:
      Any notes or information you will provide through the platform. This could include notes from
      your therapy sessions or notes of reviewed self-work sessions by patients. Technical Data: IP
      address, device identifier or other similar identifiers, information on interaction with our
      platform, activity logs.
      <br />
      <br />
      <b>How We Would Use Your Information</b>
      <br />
      <br />
      We would use this personal data for two main purposes: <br />
      <p className="mt-4">
        {' '}
        1. <b>AI Model Training</b>: training and improving our AI models. This helps us enhance the
        platform's ability to provide meaningful insights and support.
        <br /> 2. <b>System Improvement</b>: Our team would analyse this information to understand
        how well our system is working and make necessary improvements.
      </p>
      <br />
      Please note that we may also use your personal data for other purposes, such as improving our
      security measures, complying with legal obligations, and providing customer support.  During
      this alpha stage, members of our team (composed of AI development specialists and mental
      health professionals) will personally review and analyse your personal data. We want to assure
      you that all team members who have access to your data are bound by strict confidentiality
      agreements. They are trained in handling sensitive information and are obligated to maintain
      your privacy. Access to your data is limited only to those team members who absolutely need it
      for research and development purposes.
      <br />
      <br />
      <b>Your Rights</b>
      <br />
      <p className="mt-4">
        While you've agreed to participate in our Alpha stage, you still retain important rights
        regarding your personal data. These include the right to access, correct, or delete your
        data, and to object to certain types of processing. We're committed to respecting these
        rights and making it easy for you to exercise them. However, please note that exercising
        some of these rights, particularly data deletion, may result in your inability to continue
        participating in the alpha stage.
      </p>
      <br />
      <br />
      <b>Your Choice Matters</b>
      <br />
      <p className="mt-4">
        We want to emphasise a few important points:
        <br />
        1. This is entirely voluntary. You have complete freedom to choose whether to participate or
        not. <br />
        2. You can change your mind at any time. If you start and later decide it's not for you, you
        can stop participating without any negative consequences. <br />
        3. Your comfort is our priority. We want this to be a positive experience for you.
      </p>
      <br />
      <br />
      <b>Our commitment to You</b>
      <br />
      <p className="mt-4">
        We understand that the information you provide us is deeply personal. Our commitments to you
        are:
        <br />- We will always respect your privacy.
        <br /> - We will never sell your personal data.
        <br /> - We will keep your personal data safe and secure.
        <br /> - We will be transparent about how we are using your personal data.
        <br />
      </p>
      <br />
      <b> We are Here for You</b>
      <br />
      <p className="mt-4">
        If you have any questions, concerns, or you just want to discuss this further, please reach
        out. You can email us at privacy@betteryou.tech
        <br />
        Thank you for being a part of our alpha program. Your experiences and insights are
        incredibly valuable, and we are grateful for your trust. In support of your journey,
        <br />
        BetterYou Team
      </p>
    </div>
  );
};
