import { useState, ReactNode, useRef, useLayoutEffect } from 'react';
import { ChevronDown } from 'lucide-react';

interface AccordionProps {
  title?: string;
  titleClasses?: string;
  wrapperClasses?: string;
  contentClasses?: string;
  maxHeight?: number;
  children: ReactNode;
}

export const Accordion = ({
  title,
  children,
  wrapperClasses = '',
  titleClasses = '',
  contentClasses = '',
  maxHeight = 0,
}: AccordionProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [height, setHeight] = useState<string | number>(maxHeight);
  const contentRef = useRef<HTMLDivElement>(null);

  const handleToggleAccordion = () => {
    setIsOpen((prevOpen) => !prevOpen);
  };

  useLayoutEffect(() => {
    if (isOpen && contentRef.current) {
      setHeight(`${maxHeight || contentRef.current.scrollHeight}px`);
    } else {
      setHeight('0px');
    }
  }, [isOpen, maxHeight]);

  return (
    <div
      className={`flex w-full flex-col rounded-lg shadow-sm p-4 mb-4 bg-white  ${wrapperClasses}`}
    >
      <div
        onClick={handleToggleAccordion}
        className={`flex justify-between w-full items-center cursor-pointer ${titleClasses}`}
      >
        <h2 className={`font-bold`}>{title}</h2>
        <ChevronDown
          strokeWidth={1.5}
          className={`transition-transform duration-300 ${isOpen ? 'rotate-180' : ''}`}
        />
      </div>
      <div
        ref={contentRef}
        style={{ maxHeight: height }}
        className={`${maxHeight ? 'overflow-auto' : 'overflow-hidden'} transition-all duration-300`}
      >
        <div className={`mt-4 transition-all ${contentClasses}`}>{children}</div>
      </div>
    </div>
  );
};

export default Accordion;
