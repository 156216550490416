import { getCameraFrame } from '../services/media';
import {
  sendCameraFrame,
  ISendCameraFrameResponseBody,
  sendCameraFrameTherapist,
  sendCameraFramePatient,
  sendAICameraFrame,
} from '../api/frame';
import { blobToBase64 } from '../utils/blob';
import { Base64 } from '../hooks/useExtractFaceEmotions';

export const postCameraFrame = async (
  userId: string
): Promise<[ISendCameraFrameResponseBody, Base64]> => {
  const blob = await getCameraFrame({ video: true });
  const base64 = await blobToBase64(blob);
  const timestamp = Date.now();
  const res = await sendCameraFrame(userId, base64, timestamp);

  return [res, base64];
};

export const postAiCameraFrame = async (
  userId: string,
  sessionId: string,
  signal?: AbortSignal
): Promise<[ISendCameraFrameResponseBody, Base64]> => {
  const blob = await getCameraFrame({ video: true });
  const base64 = await blobToBase64(blob);
  const timestamp = Date.now();
  const res = await sendAICameraFrame(userId, sessionId, base64, timestamp, signal);

  return [res, base64];
};

export const postCameraFrameTherapist = async (
  userId: string,
  patientId: string,
  sessionId: string
): Promise<[ISendCameraFrameResponseBody, Base64]> => {
  const blob = await getCameraFrame({ video: true });
  const base64 = await blobToBase64(blob);
  const timestamp = Date.now();
  const res = await sendCameraFrameTherapist(userId, patientId, sessionId, base64, timestamp);

  return [res, base64];
};
export const postCameraFramePatient = async (
  userId: string,
  sessionId: string
): Promise<[ISendCameraFrameResponseBody, Base64]> => {
  const blob = await getCameraFrame({ video: true });
  const base64 = await blobToBase64(blob);
  const timestamp = Date.now();
  const res = await sendCameraFramePatient(userId, sessionId, base64, timestamp);

  return [res, base64];
};
