import { ReactNode } from 'react';
import clsx from 'clsx';

type HeaderCellProps = {
  title?: string;
  classes?: string;
  conditionalClasses?: Record<string, boolean>;
  children?: ReactNode;
};

export const HeaderCell = ({ title = '', classes = '', conditionalClasses = {}, children }: HeaderCellProps) => {
  return (
    <div
      className={clsx('px-4 py-2.5', classes, {
        ...conditionalClasses,
      })}
    >
      {title}
      {children}
    </div>
  );
};
