import { PropsWithChildren } from 'react';

const GlobalLayout = ({ children }: PropsWithChildren) => {
  return (
    <div className="flex pt-6 pb-16 px-[4.5rem] justify-center items-center h-full w-full overflow-y-auto overflow-x-hidden">
      {children}
    </div>
  );
};

export default GlobalLayout;
