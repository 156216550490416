export const PATIENT_MACHINE_STATES = {
  PATIENT_OFFICE_MODE: 'PatientOfficeMode',
  PATIENT_NOTIFICATION: 'PatientNotification',
  PATIENT_SETTINGS: 'PatientSettings',
  SELF_HISTORY_VIEW: 'SelfHistoryView',
  PRETALK_WAITING_ROOM: 'PatientPretalkWaitingRoom',
  ONE_ON_ONE_VIDEO: 'OneOnOneVideo',
  PATIENT_VIDEO_SESSION: {
    ROOT: 'PatientVideoSession',
    PRETALK_WAITING_ROOM: 'PatientVideoSession.PatientPretalkWaitingRoom',
    ONE_ON_ONE_VIDEO: 'PatientVideoSession.OneOnOneVideo',
    TERMS_OF_PRIVACY: 'PatientVideoSession.termsOfUseAndPrivacy',
  },
  AI_VIDEO: 'AIVideo',
  AI_DASHBOARD: 'AIDashboard',
  DEBRIEF_PATIENT_WAITING_ROOM: 'DebriefPatientWaitingRoom',
  GREETING_PAGE: 'greetingPage',
  END_SESSION_PAGE: 'endSessionPage',
  PATIENT_TERMS_OF_USE_AND_PRIVACY: 'termsOfUseAndPrivacy',
  PATIENT_CONCENT_DATA_USAGE: 'patientConcentDataUsage',
} as const;
