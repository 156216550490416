import { useEffect, MutableRefObject } from 'react';

export const useScrollTop = <T>(
  containerRef: MutableRefObject<HTMLDivElement | null>,
  scrollHeightRef: MutableRefObject<number>,
  values: T
): void => {
  useEffect(() => {
    if (containerRef.current) {
      const eventsEl = containerRef.current;

      setTimeout(() => {
        const scrollHeight = eventsEl.scrollHeight;

        if (scrollHeight !== scrollHeightRef.current) {
          eventsEl.scrollTop = scrollHeight;
          scrollHeightRef.current = scrollHeight;
        }
      }, 0);
    }
  }, [values]);
};
