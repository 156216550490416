import Button from '@components/Button';
import { VideoIcon } from 'lucide-react';

type HomePageNotificationsProps = {
  onSetIsExitBioMode: (flag: boolean) => void;
  therapistActor: any;
  userMetadata: any;
};

const HomePageNotifications = ({
  onSetIsExitBioMode,
  therapistActor,
  userMetadata,
}: HomePageNotificationsProps) => {
  const user = therapistActor.context?.currentUser || null;

  const handleRejoinActiveSession = () => {
    if (userMetadata.assignedHomeworks.count > 0) {
      onSetIsExitBioMode(true);
      therapistActor.send({
        type: 'goToAIDashboard',
        currentUser: user,
      });
    }
  };

  return (
    //TODO: make functional when rejoin functionality will be provided
    false && (
      <Button
        text="Rejoin active session"
        icon={<VideoIcon size={16} />}
        onClick={handleRejoinActiveSession}
      />
    )
  );
};

export default HomePageNotifications;
