import { createActor, setup, assign } from 'xstate';
import { PatientMachineEvent } from './types.ts';
import { PATIENT_MACHINE_STATES } from '@machines/patient/constants/states.ts';
import { PATIENT_MACHINE_EVENTS } from '@machines/patient/constants/events.ts';
import { Patient } from '@interfaces/patient.ts';

type Context = {
  stateHistory: string[];
  currentUser: Patient | null;
};

export const refaelaPatientMachine = setup({
  types: {
    context: {} as Context,
    events: {} as PatientMachineEvent,
  },
  actions: {
    [PATIENT_MACHINE_EVENTS.UPDATE_PATIENT_USER]: assign({
      currentUser: ({ context, event }) =>
        (event.type === PATIENT_MACHINE_EVENTS.UPDATE_PATIENT_USER && event.currentUser) ||
        context.currentUser,
    }),
  },
}).createMachine({
  context: {
    stateHistory: [],
    currentUser: null,
  },
  id: 'PatientMachine',
  initial: PATIENT_MACHINE_STATES.PATIENT_OFFICE_MODE,
  states: {
    [PATIENT_MACHINE_STATES.PATIENT_OFFICE_MODE]: {
      on: {
        [PATIENT_MACHINE_EVENTS.UPDATE_PATIENT_USER]: {
          actions: assign({
            currentUser: ({ event }) => event.currentUser,
          }),
        },
        goToPatientSettings: {
          target: PATIENT_MACHINE_STATES.PATIENT_SETTINGS,
        },
        [PATIENT_MACHINE_EVENTS.GO_TO_PATIENT_PRETALK_WAITING_ROOM]: {
          target: PATIENT_MACHINE_STATES.PATIENT_VIDEO_SESSION.PRETALK_WAITING_ROOM,
          actions: assign({
            currentUser: ({ context, event }) => event.currentUser || context.currentUser,
          }),
        },
        goToPatientNotification: {
          target: PATIENT_MACHINE_STATES.PATIENT_NOTIFICATION,
        },
        goToPatientSelfHistoryView: {
          target: PATIENT_MACHINE_STATES.SELF_HISTORY_VIEW,
        },
        [PATIENT_MACHINE_EVENTS.GO_TO_AI_DASHBOARD]: {
          target: PATIENT_MACHINE_STATES.AI_DASHBOARD,
          actions: assign({
            currentUser: ({ context, event }) => event.currentUser || context.currentUser,
          }),
        },
        [PATIENT_MACHINE_EVENTS.GO_TO_PATIENT_TERMS_OF_USE_AND_PRIVACY]: {
          target: PATIENT_MACHINE_STATES.PATIENT_TERMS_OF_USE_AND_PRIVACY,
        },
        [PATIENT_MACHINE_EVENTS.GO_TO_GREETING_PAGE]: {
          target: PATIENT_MACHINE_STATES.GREETING_PAGE,
        },
      },
      description:
        'Initial state for a patient. The patient can navigate to various sections from here.',
    },
    [PATIENT_MACHINE_STATES.PATIENT_NOTIFICATION]: {
      on: {
        goToPatientOffice: {
          target: PATIENT_MACHINE_STATES.PATIENT_OFFICE_MODE,
        },
        goToPatientSettings: {
          target: PATIENT_MACHINE_STATES.PATIENT_SETTINGS,
        },
      },
      description:
        'Patient is viewing the calendar. Can go back to office or navigate to self-history view.',
    },
    [PATIENT_MACHINE_STATES.PATIENT_SETTINGS]: {
      on: {
        goToPatientOffice: {
          target: PATIENT_MACHINE_STATES.PATIENT_OFFICE_MODE,
        },
        goToPatientNotification: {
          target: PATIENT_MACHINE_STATES.PATIENT_NOTIFICATION,
        },
      },
      description:
        'Patient is viewing the calendar. Can go back to office or navigate to self-history view.',
    },
    [PATIENT_MACHINE_STATES.SELF_HISTORY_VIEW]: {
      on: {
        goToPatientSettings: {
          target: PATIENT_MACHINE_STATES.PATIENT_SETTINGS,
        },
        goToPatientOffice: {
          target: PATIENT_MACHINE_STATES.PATIENT_OFFICE_MODE,
        },
        goToPatientNotification: {
          target: PATIENT_MACHINE_STATES.PATIENT_NOTIFICATION,
        },
      },
      description: 'Patient is viewing their self-history.',
    },
    [PATIENT_MACHINE_STATES.PATIENT_VIDEO_SESSION.ROOT]: {
      initial: PATIENT_MACHINE_STATES.PRETALK_WAITING_ROOM,
      states: {
        [PATIENT_MACHINE_STATES.PRETALK_WAITING_ROOM]: {
          on: {
            goTo1On1Video: {
              target: 'OneOnOneVideo',
            },
            goToAIVideo: {
              target: `#PatientMachine.${PATIENT_MACHINE_STATES.AI_VIDEO}`,
            },
            goToPatientOffice: {
              target: `#PatientMachine.${PATIENT_MACHINE_STATES.PATIENT_OFFICE_MODE}`,
            },
            [PATIENT_MACHINE_EVENTS.UPDATE_PATIENT_USER]: {
              actions: PATIENT_MACHINE_EVENTS.UPDATE_PATIENT_USER,
            },
            [PATIENT_MACHINE_EVENTS.GO_TO_PATIENT_TERMS_OF_USE_AND_PRIVACY]: {
              target: `#PatientMachine.${PATIENT_MACHINE_STATES.PATIENT_TERMS_OF_USE_AND_PRIVACY}`,
            },
          },
          description:
            'Patient is in the pretalk waiting room. Can proceed to session or go back to office.',
        },
        [PATIENT_MACHINE_STATES.ONE_ON_ONE_VIDEO]: {
          on: {
            endSession: {
              target: `#PatientMachine.${PATIENT_MACHINE_STATES.END_SESSION_PAGE}`,
            },
          },
          description:
            'Patient is in a 1:1 video session. After the session ends, can go to debrief waiting room.',
        },
        [PATIENT_MACHINE_STATES.AI_VIDEO]: {
          on: {
            endSession: {
              target: `#PatientMachine.${PATIENT_MACHINE_STATES.END_SESSION_PAGE}`,
            },
          },
          description:
            'Patient is in a 1:1 AI video session. After the session ends, can go to debrief waiting room.',
        },
      },

      description:
        'Therapist is in a session. After session, therapist can go to debrief waiting room.',
    },
    [PATIENT_MACHINE_STATES.PATIENT_TERMS_OF_USE_AND_PRIVACY]: {
      on: {
        goBack: {
          //TODO: make dynamic?
          target: [PATIENT_MACHINE_STATES.PATIENT_CONCENT_DATA_USAGE],
        },
      },
    },
    [PATIENT_MACHINE_STATES.AI_VIDEO]: {
      on: {
        endSession: {
          target: `#PatientMachine.${PATIENT_MACHINE_STATES.END_SESSION_PAGE}`,
        },
        goToPatientOffice: {
          target: `#PatientMachine.${PATIENT_MACHINE_STATES.PATIENT_OFFICE_MODE}`,
        },
      },
      description:
        'Patient is in an AI video session. After the session ends, can go to debrief waiting room.',
    },
    [PATIENT_MACHINE_STATES.AI_DASHBOARD]: {
      on: {
        endSession: {
          target: `#PatientMachine.${PATIENT_MACHINE_STATES.END_SESSION_PAGE}`,
        },
        goToPatientOffice: {
          target: `#PatientMachine.${PATIENT_MACHINE_STATES.PATIENT_OFFICE_MODE}`,
        },
      },
      description:
        'Patient is in an AI video session. After the session ends, can go to debrief waiting room.',
    },
    [PATIENT_MACHINE_STATES.DEBRIEF_PATIENT_WAITING_ROOM]: {
      on: {
        goToPatientOffice: {
          target: PATIENT_MACHINE_STATES.PATIENT_OFFICE_MODE,
        },
      },
      description: 'Patient is in the debrief waiting room. Can go back to office from here.',
    },
    [PATIENT_MACHINE_STATES.GREETING_PAGE]: {
      on: {
        [PATIENT_MACHINE_EVENTS.GO_TO_PATIENT_CONCENT_DATA_USAGE]: {
          target: PATIENT_MACHINE_STATES.PATIENT_CONCENT_DATA_USAGE,
        },
      },
      description:
        'Patient is the first time on a platform. Must go concent using data, then proceed to ai session either therapist session ',
    },
    [PATIENT_MACHINE_STATES.PATIENT_CONCENT_DATA_USAGE]: {
      on: {
        [PATIENT_MACHINE_EVENTS.GO_TO_AI_DASHBOARD]: {
          target: PATIENT_MACHINE_STATES.AI_DASHBOARD,
          actions: assign({
            currentUser: ({ context, event }) => event.currentUser || context.currentUser,
          }),
        },
        [PATIENT_MACHINE_EVENTS.GO_TO_PATIENT_OFFICE]: {
          target: PATIENT_MACHINE_STATES.GREETING_PAGE,
        },
        [PATIENT_MACHINE_EVENTS.GO_TO_PATIENT_PRETALK_WAITING_ROOM]: {
          target: PATIENT_MACHINE_STATES.PATIENT_VIDEO_SESSION.PRETALK_WAITING_ROOM,
          actions: assign({
            currentUser: ({ context, event }) => event.currentUser || context.currentUser,
          }),
        },
        [PATIENT_MACHINE_EVENTS.GO_TO_PATIENT_TERMS_OF_USE_AND_PRIVACY]: {
          target: PATIENT_MACHINE_STATES.PATIENT_TERMS_OF_USE_AND_PRIVACY,
        },
      },
    },
    [PATIENT_MACHINE_STATES.END_SESSION_PAGE]: {
      description: 'Patient ended session.',
    },
  },
});

export const refaelaPatientActor = createActor(refaelaPatientMachine, {});
