export const getIndexByInitials = (string?: string): number => {
  let hash: number = 0;
  if (!string || string.length === 0) return hash;
  for (let i = 0; i < string.length; i++) {
    const char = string.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash = hash & hash;
  }
  return Number(hash.toString().split('').pop());
};
