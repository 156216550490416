import { useState } from 'react';
// Constants
import { EDIT_PROMPT_FORM_FIELDS } from '../model/constants/editPromptForm';
// Types
import { FormPromptFieldKeysType, FormPromptDataType } from '@components/Forms/model/types';
// Components
import Button from '@components/Button';

import { TextInputController } from '../ui/TextInputController';
import { CircleAlertIcon } from 'lucide-react';

type EditPromptFormProps = {
  onCancel: () => void;
  onEdit: ({ newPatientData }: { newPatientData: FormPromptDataType }) => void;
  error?: string | null;
};

export const EditPromptForm = ({ onCancel, onEdit, error }: EditPromptFormProps) => {
  const [newPatientData, setNewPatientData] = useState<FormPromptDataType>({
    [EDIT_PROMPT_FORM_FIELDS.MAIN_PROMPT]: '',
    [EDIT_PROMPT_FORM_FIELDS.SYSTEM_PROMPT]: '',
  });

  const handleInputChange = ({ key, value }: { key: FormPromptFieldKeysType; value: string }) => {
    setNewPatientData((prev) => ({ ...prev, [key]: value }));
  };

  const handleInviteNewPatient = (e: any) => {
    e.preventDefault();
    onEdit({ newPatientData });
  };

  return (
    <div className="flex min-h-full flex-1 flex-col justify-center">
      <p className='text-gray-500'>
        You can change the main prompt for this type of homework. Lorem ipsum dolor sit amet,
        consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna
        aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip
        ex ea commodo consequat.
      </p>
      <form
        onSubmit={handleInviteNewPatient}
        className="space-y-6"
      >
        <div className="sm:mx-auto sm:w-full">
          <div className="space-y-4">
            <TextInputController
              label="Main prompt"
              onInputChange={handleInputChange}
              id={EDIT_PROMPT_FORM_FIELDS.MAIN_PROMPT}
              type="text"
              required
              autoComplete=""
              value={newPatientData[EDIT_PROMPT_FORM_FIELDS.MAIN_PROMPT]}
            />
            <TextInputController
              label="System prompt"
              onInputChange={handleInputChange}
              id={EDIT_PROMPT_FORM_FIELDS.SYSTEM_PROMPT}
              type="text"
              required
              autoComplete=""
              value={newPatientData[EDIT_PROMPT_FORM_FIELDS.SYSTEM_PROMPT]}
            />
            <div
              className={`text-rose-600 text-sm ${error ? 'opacity-100' : 'opacity-0'} flex gap-1`}
            >
              <CircleAlertIcon
                color="#dc2626"
                height="24px"
                width="24px"
              />
              <span>{error}</span>
            </div>
            <div className="flex justify-center pt-5 gap-4">
              <Button
                variant="outline"
                text="Cancel"
                onClick={onCancel}
                className="w-[50%]"
              />
              <Button
                type="submit"
                text="Save"
                className="w-[50%]"
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
