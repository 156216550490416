import { AppContext } from '../../contextApp';
import { ReactNode, useContext, useEffect, useState } from 'react';

type Step = {
  label?: string;
  content: ReactNode;
  isStepBlocked?: boolean;
  nextButtonLabel?: string;
  backButtonLabel?: string;
  hideBackButton?: boolean;
};

type StepperProps = {
  steps: Step[];
  onFinish?: () => void;
  onReset?: () => void;
  contentClasses?: string;
  onChangeStepCb?: (step: number) => void;
  isNavDisabled?: boolean;
};

const Stepper = ({
  steps,
  onFinish,
  onReset,
  contentClasses,
  onChangeStepCb,
  isNavDisabled = false,
}: StepperProps) => {
  const [visitedSteps, setVisitedSteps] = useState([0]);
  const [currentStep, setCurrentStep] = useState(0);
  const { setStep } = useContext(AppContext);

  const handleNext = () => {
    if (currentStep < steps.length - 1) {
      const nextStepIndex = currentStep + 1;

      setCurrentStep(nextStepIndex);
      setStep(nextStepIndex);
      setVisitedSteps((prev) => [...prev, nextStepIndex]);
    } else if (onFinish) {
      onFinish();
    }
  };

  const handleBack = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
      setStep(currentStep - 1);
    }
  };

  const handleReset = () => {
    setCurrentStep(0);
    setStep(0);
    onReset?.();
  };

  useEffect(() => {
    onChangeStepCb?.(currentStep);
  }, [currentStep, steps]);

  return (
    <div
      className="flex flex-col w-full"
      data-hs-stepper={`{"currentIndex": ${currentStep + 1}}`}
    >
      {/* Stepper Nav */}
      {!isNavDisabled && (
        <ul className="flex flex-row gap-x-2">
          {steps.map((step, index) => {
            const isLast = index === steps.length - 1;
            return (
              <li
                key={index}
                className={`flex items-center justify-end gap-x-2 ${isLast ? 'w-auto' : 'w-full'} basis-auto group ${
                  currentStep > index ? 'completed' : currentStep === index ? 'active' : ''
                }`}
                data-hs-stepper-nav-item={`{"index": ${index + 1}, "isCompleted": ${currentStep > index}}`}
              >
                <div className="min-w-7 min-h-7 group flex flex-col items-center text-xs align-middle">
                  <span
                    className={`size-7 flex justify-center shadow-md items-center shrink-0 bg-gray-100 font-medium rounded-full group-focus:bg-gray-200 ${
                      currentStep > index
                        ? 'hs-stepper-completed:bg-nav text-text-primary'
                        : currentStep === index
                          ? 'hs-stepper-active:bg-purple-600 text-white'
                          : ''
                    }`}
                  >
                    <span className={`${currentStep > index ? 'hidden' : ''}`}>{index + 1}</span>
                    {currentStep > index && (
                      <svg
                        className="shrink-0 size-3 hs-stepper-success:block"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="3"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <polyline points="20 6 9 17 4 12"></polyline>
                      </svg>
                    )}
                  </span>
                  <span className="w-max ms-2 text-sm font-medium text-text-primary">
                    {step.label}
                  </span>
                </div>
                {!isLast && (
                  <div
                    className={`w-full h-px flex-1 bg-gray-200 group-last:hidden ${
                      currentStep > index
                        ? 'hs-stepper-completed:bg-teal-600'
                        : 'hs-stepper-active:bg-purple-600'
                    }`}
                  ></div>
                )}
              </li>
            );
          })}
        </ul>
      )}

      {/* Stepper Content */}
      <div className={`mt-5 md_d:mt-8 ${contentClasses}`}>
        {steps.map((step, index) => {
          return (
            visitedSteps.includes(index) && (
              <div
                className="h-full w-full"
                key={index}
                data-hs-stepper-content-item={`{"index": ${index + 1}, "isCompleted": ${currentStep > index}}`}
                style={{ display: currentStep === index ? 'block' : 'none' }}
              >
                <div className="flex rounded-xl h-[inherit] w-[inherit]">{step.content}</div>
              </div>
            )
          );
        })}
      </div>

      {/* Button Group */}
      <div className="mt-5 flex justify-end items-center gap-x-2">
        {currentStep !== 0 && !steps[currentStep].hideBackButton && (
          <button
            type="button"
            className="py-2 px-3 inline-flex items-center gap-x-1 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm focus:outline-none focus:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none"
            onClick={handleBack}
            disabled={steps[currentStep].isStepBlocked}
          >
            {steps[currentStep].backButtonLabel || 'Back'}
          </button>
        )}
        {currentStep < steps.length - 1 ? (
          <button
            type="button"
            className="py-2 px-3 inline-flex items-center gap-x-1 text-sm font-medium rounded-lg border border-transparent bg-purple-600 text-white hover:bg-purple-700 focus:outline-none focus:bg-purple-700 disabled:opacity-50 disabled:pointer-events-none"
            onClick={handleNext}
            disabled={steps[currentStep].isStepBlocked}
          >
            {steps[currentStep].nextButtonLabel || 'Next'}
          </button>
        ) : (
          <button
            type="button"
            className="py-2 px-3 inline-flex items-center gap-x-1 text-sm font-medium rounded-lg border border-transparent bg-purple-600 text-white hover:bg-purple-700 focus:outline-none focus:bg-purple-700 disabled:opacity-50 disabled:pointer-events-none"
            onClick={onFinish}
            disabled={steps[currentStep].isStepBlocked}
          >
            {steps[currentStep].nextButtonLabel || 'Finish'}
          </button>
        )}
        {onReset && (
          <button
            type="reset"
            className="py-2 px-3 inline-flex items-center gap-x-1 text-sm font-medium rounded-lg border border-transparent bg-purple-600 text-white hover:bg-purple-700 focus:outline-none focus:bg-purple-700 disabled:opacity-50 disabled:pointer-events-none"
            onClick={handleReset}
          >
            Reset
          </button>
        )}
      </div>
    </div>
  );
};

export default Stepper;
