import { Patient } from '@interfaces/patient';
import { TSessionInstance } from '@interfaces/session';

export const getLastSessionData = (patients?: Patient[]) => {
  if (!patients || patients.length === 0) return null;

  let lastSessionData: { patient: Patient; session: TSessionInstance } | null = null;

  for (const patient of patients) {
    if (patient.sessions.length === 0) continue;

    for (const session of patient.sessions) {
      const sessionTimestamp = new Date(session.timestamp).getTime();

      if (
        !lastSessionData ||
        sessionTimestamp > new Date(lastSessionData.session.timestamp).getTime()
      ) {
        lastSessionData = { patient, session };
      }
    }
  }

  return lastSessionData;
};
