import { useState } from 'react';
// Constants
import { INVITE_EDIT_PATIENT_FORM_FIELDS } from '../model/constants/inviteEditPatientForm';
// Types
import { FormPatientFieldKeysType, FormPatientDataType } from '../model/types';
// Components
import Button from '@components/Button';
import { TextInputController } from '../ui/TextInputController';
import { Loader } from '@shared/ui/loader/Loader.tsx';
import { CircleAlertIcon } from 'lucide-react';
import Checkbox from '@shared/ui/input/checkbox/Checkbox';

type InvitePatientFormProps = {
  onCancel: () => void;
  onInvite: ({ newPatientData }: { newPatientData: FormPatientDataType }) => void;
  isLoading?: boolean;
  error?: string | null;
};

export const InvitePatientForm = ({
  onCancel,
  onInvite,
  isLoading,
  error,
}: InvitePatientFormProps) => {
  const [newPatientData, setNewPatientData] = useState<FormPatientDataType>({
    [INVITE_EDIT_PATIENT_FORM_FIELDS.FIRST_NAME]: '',
    [INVITE_EDIT_PATIENT_FORM_FIELDS.LAST_NAME]: '',
    [INVITE_EDIT_PATIENT_FORM_FIELDS.EMAIL]: '',
    [INVITE_EDIT_PATIENT_FORM_FIELDS.PHONE_NUMBER]: '',
  });
  const [isAgreed, setIsAgree] = useState(false);

  const handleAgreeTerms = (value: boolean) => {
    setIsAgree(value);
  };
  const handleInputChange = ({ key, value }: { key: FormPatientFieldKeysType; value: string }) => {
    setNewPatientData((prev) => ({ ...prev, [key]: value }));
  };

  const handleInviteNewPatient = (e: React.FormEvent): void => {
    e.preventDefault();
    onInvite({ newPatientData });
  };

  return (
    <div className="flex min-h-full flex-1 flex-col justify-center">
      {isLoading && (
        <div className="bg-loading-overlay h-full rounded-br-20 absolute top-0 bottom-0 left-0 right-0 z-[1]">
          <Loader spinnerClasses="absolute z-2 top-1/2 left-1/2 ml-[-0.75rem] mt-[-0.75rem]" />
        </div>
      )}
      <form
        onSubmit={handleInviteNewPatient}
        className="space-y-6"
      >
        <div className="font-normal text-base text-text-secondary">
          Please enter your patient name and email address to invite them to BetterYou. After
          confirming invitation letter you will be able to schedule and manage sessions.
        </div>
        <div className="sm:mx-auto sm:w-full">
          <div className="space-y-4">
            <TextInputController
              label="First name"
              onInputChange={handleInputChange}
              id={INVITE_EDIT_PATIENT_FORM_FIELDS.FIRST_NAME}
              type="text"
              required
              autoComplete="given-name"
            />
            <TextInputController
              label="Last name"
              onInputChange={handleInputChange}
              id={INVITE_EDIT_PATIENT_FORM_FIELDS.LAST_NAME}
              type="text"
              required
              autoComplete="family-name"
            />
            <TextInputController
              label="Email address"
              onInputChange={handleInputChange}
              id={INVITE_EDIT_PATIENT_FORM_FIELDS.EMAIL}
              type="email"
              required
              autoComplete="email"
            />
            <TextInputController
              label="Phone number"
              onInputChange={handleInputChange}
              id={INVITE_EDIT_PATIENT_FORM_FIELDS.PHONE_NUMBER}
              type="tel"
              autoComplete="tel"
            />
            <div
              className={`text-rose-600 text-sm ${error ? 'opacity-100' : 'opacity-0'} flex gap-1`}
            >
              <CircleAlertIcon
                color="#dc2626"
                height="24px"
                width="24px"
              />
              <span> {error}</span>
            </div>
            <div className="flex gap-5 items-start text-left">
              <Checkbox
                checked={isAgreed}
                onChange={handleAgreeTerms}
              />
              <div className="text-sm font-normal font-['Inter'] leading-tight tracking-tight">
                By continuing, I confirm that I have all the necessary permissions to share this
                information
              </div>
            </div>
            <div className="flex justify-end pt-5 gap-4">
              <Button
                variant="outline"
                text="Cancel"
                onClick={onCancel}
              />
              <Button
                disabled={!isAgreed}
                type="submit"
                text="Invite new patient"
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
