import { VideoIcon } from 'lucide-react';
import Button from '@components/Button';

type AIDashboardPageNotificationsProps = {
  onSetIsExitBioMode: (flag: boolean) => void;
  therapistActor: any;
};

const AIDashboardPageNotifications = ({
  onSetIsExitBioMode,
  therapistActor,
}: AIDashboardPageNotificationsProps) => {
  const handleStartAISession = () => {
    onSetIsExitBioMode(true);
    therapistActor.send({
      type: 'goToAIVideo',
    });
  };

  return (
    <div className="flex gap-2">
      {/* TODO: review if we actually need any notification elements for therapist during ai session */}
      {false && (
        <Button
          text="Start an AI Session"
          icon={<VideoIcon size={16} />}
          onClick={handleStartAISession}
        />
      )}
    </div>
  );
};

export default AIDashboardPageNotifications;
