export const getPositionOnTimelineByTime = (
  timestamp: Date,
  svgWidth: number,
  offset: number,
  startHour: number,
  endHour: number
) => {
  const totalHours = endHour - startHour;

  const currentHour = timestamp.getHours();
  const minutes = timestamp.getMinutes();

  const timePosition = currentHour + minutes / 60;

  const position = (timePosition - startHour) / totalHours;

  const x = position * svgWidth;
  const y =
    150 +
    Math.sin((position * 10 + offset) * 0.5) * 50 +
    Math.sin((position * 10 + offset) * 0.2) * 30 +
    Math.sin((position * 10 + offset) * 0.1) * 20;

  return { x, y };
};
