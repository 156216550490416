import React, { useState } from 'react';
import { PlotTypeEnum } from '../types';

const PlotContextMenu = ({
  children,
  handleContextMenu,
}: {
  children: React.ReactNode;
  handleContextMenu: (x: PlotTypeEnum) => void;
}) => {
  const [context, setIsOpen] = useState(false);

  const [posistionState, ] = useState({ x: 0, y: 0 });

  const hideContext = () => {
    setIsOpen(false);
  };

  const handleMenuItemClick = (type: PlotTypeEnum) => {
    handleContextMenu(type);
  };

  return (
    <div
      // onContextMenu={handleMenuShow}
      onClick={hideContext}
    >
      {context && (
        <div style={{ top: posistionState.y, left: posistionState.x }}>
          <div onClick={() => handleMenuItemClick(PlotTypeEnum.DEFAULT)}>Show default</div>
          <div onClick={() => handleMenuItemClick(PlotTypeEnum.DOTS)}>Show dots</div>
        </div>
      )}
      {children}
    </div>
  );
};

export default PlotContextMenu;
