import {
  CalendarIcon,
  NotificationIcon,
  OfficeModeIcon,
  PatientListIcon,
  SessionWrapUpIcon,
  SettingsIcon,
  WaitingRoomIcon,
} from '@assets/icons';

import { BreadcrumbItem } from '@shared/types/breadcrumbs/breadcrumb';

import { refaelaTherapistActor } from '@components/xState/machines/refaelaTherapistMachine.ts';
import { GlobeLockIcon } from 'lucide-react';

export const BreadcrumbItems: Record<string, BreadcrumbItem> = {
  therapistOffice: {
    value: 'TherapistOfficeMode',
    nameToDisplay: 'Home',
    icon: <OfficeModeIcon />,
    goToAction: () => refaelaTherapistActor.send({ type: 'goToTherapistOffice' }),
  },
  therapistCalendar: {
    value: 'TherapistCalendar',
    nameToDisplay: 'Calendar',
    icon: <CalendarIcon />,
    goToAction: () => refaelaTherapistActor.send({ type: 'goToTherapistCalendar' }),
  },
  therapistSettings: {
    value: 'TherapistSettings',
    nameToDisplay: 'Settings',
    icon: <SettingsIcon />,
    goToAction: () => refaelaTherapistActor.send({ type: 'goToTherapistSettings' }),
  },
  patientList: {
    value: 'PatientList',
    nameToDisplay: 'Patient List',
    icon: <PatientListIcon />,
    goToAction: () => refaelaTherapistActor.send({ type: 'goToPatientList' }),
  },
  termsOfUse: {
    value: 'TermsOfUse',
    nameToDisplay: 'Terms Of Use',
    icon: <GlobeLockIcon />,
    goToAction: () => refaelaTherapistActor.send({ type: 'goToTermsOfUse' }),
  },
  therapistNotification: {
    value: 'TherapistNotification',
    nameToDisplay: 'Notification',
    icon: <NotificationIcon />,
    goToAction: () => refaelaTherapistActor.send({ type: 'goToTherapistNotification' }),
  },
  patientView: {
    value: 'PatientView',
    nameToDisplay: 'Patient Timeline',
    icon: <PatientListIcon />,
    goToAction: (patientId?: string) =>
      patientId && refaelaTherapistActor.send({ type: 'goToPatientView', patientId }),
  },
  therapistPretalkWaitingRoom: {
    value: 'TherapistSession.TherapistPretalkWaitingRoom',
    nameToDisplay: 'Waiting room',
    icon: <WaitingRoomIcon className="stroke-gray-800" />,
    forceIcon: true,
    goToAction: () => refaelaTherapistActor.send({ type: 'goToTherapistPretalkWaitingRoom' }),
  },
  therapistDebriefWaitingRoom: {
    value: 'TherapistDebriefWaitingRoom',
    nameToDisplay: 'Session wrap-up',
    icon: <SessionWrapUpIcon className="stroke-gray-800" />,
    forceIcon: true,
    goToAction: () => refaelaTherapistActor.send({ type: 'endSession' }),
  },
};

export const PREVSTATE_IGNORE_LIST = [
  BreadcrumbItems.therapistOffice.value,
  BreadcrumbItems.therapistPretalkWaitingRoom.value,
];
