// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ShamefulAny } from '@interfaces/index';
import { apiClient } from '..';

const getSeesionCookie = () => {
  const name = '__session=';
  const decodedCookie = decodeURIComponent(document.cookie);
  const cookieArray = decodedCookie.split(';');
  for (let i = 0; i < cookieArray.length; i++) {
    let cookie = cookieArray[i].trim();

    if (cookie.indexOf(name) === 0) {
      return cookie.substring(name.length, cookie.length);
    }
  }
};

export const fetchCurrentUserDetails = async (id: string) => {
  try {
    const sessionCookie = getSeesionCookie();

    const response = await apiClient.get(`/me/${id}`, {
      headers: {
        Authorization: `Bearer ${sessionCookie}`,
      },
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getFullToken = async (shortId: string): Promise<string | null> => {
  try {
    const response = await apiClient.post(`/shorten/expand`, {
      shortId,
    });
    const data = response.data;

    if (data.fullUrl) {
      const urlParams = new URLSearchParams(data.fullUrl.split('?')[1]);
      const token = urlParams.get('token');

      return token;
    }

    return null;
  } catch (error: ShamefulAny) {
    console.error('Error fetching full URL:', error.message);
    throw error;
  }
};
