import 'uplot/dist/uPlot.min.css';
import React, { useMemo, useRef, useEffect, useState } from 'react';
import Uplot from 'uplot';
import UplotReact from 'uplot-react';
import { Heartbeat } from 'shenai-sdk';

const TachogramPlot: React.FC<{
  realtimeBeats?: Heartbeat[];
  finalBeats?: Heartbeat[];
  isCleared?: boolean;
}> = ({ realtimeBeats, finalBeats, isCleared }) => {
  const [realtimeData, setRealtimeData] = useState<Heartbeat[] | undefined>(realtimeBeats);
  const [finalData, setFinalData] = useState<Heartbeat[] | undefined>(finalBeats);

  const [chartSize, setChartSize] = useState<{ width: number; height: number }>({
    width: 400,
    height: 200,
  });
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      if (entries[0].contentRect) {
        const { width, height } = entries[0].contentRect;
        setChartSize({ width, height });
      }
    });

    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        resizeObserver.unobserve(containerRef.current);
      }
    };
  }, []);

  // Effect to clear data when isCleared is set to true
  useEffect(() => {
    if (isCleared) {
      setRealtimeData([]);
      setFinalData([]);
    }
  }, [isCleared]);

  useEffect(() => {
    setRealtimeData(realtimeBeats);
    setFinalData(finalBeats);
  }, [realtimeBeats, finalBeats]);

  const data = useMemo(() => {
    const b1 =
      realtimeData && realtimeData.length > 0
        ? [realtimeData.map((b) => b.end_location_sec), realtimeData.map((b) => b.duration_ms)]
        : [[0], [0]];
    const b2 =
      finalData && finalData.length > 0
        ? [finalData.map((b) => b.end_location_sec), finalData.map((b) => b.duration_ms)]
        : [[0], [0]];

    return Uplot.join([b1 as Uplot.AlignedData, b2 as Uplot.AlignedData]);
  }, [realtimeData, finalData]);

  const chartOptions: Uplot.Options = {
    width: chartSize.width,
    height: chartSize.height,
    padding: [10, 0, 10, -5],
    scales: {
      x: {
        time: false,
      },
      y: {
        auto: true,
      },
    },
    series: [
      {
        label: 'Time (s)',
        value: (_, v) => v && `${v.toFixed(2)} sec`,
      },
      {
        label: 'Realtime intervals',
        stroke: '#9333ea',
        value: (_, v) => v && `${v} ms`,
        spanGaps: true,
      },
      {
        label: 'Final intervals',
        stroke: 'blue',
        value: (_, v) => v && `${v} ms`,
        spanGaps: true,
      },
    ],
    axes: [
      {
        stroke: '#9ca3af',
        grid: { show: true, stroke: '#e5e7eb' },
        values: (_uplot, ticks) => ticks.map((tick) => tick),
      },
      {
        stroke: '#9ca3af',
        grid: { show: true, stroke: '#e5e7eb' },
      },
    ],
    legend: {
      show: false,
    },
    hooks: {
      ready: [
        (u) => {
          const tooltip = document.createElement('div');
          tooltip.style.position = 'absolute';
          tooltip.style.pointerEvents = 'none';
          tooltip.style.backgroundColor = 'rgba(255, 255, 255, 0.8)';
          tooltip.style.padding = '5px';
          tooltip.style.borderRadius = '4px';
          tooltip.style.fontSize = '11px';
          tooltip.style.boxShadow = '0 2px 4px rgba(0, 0, 0, 0.2)';
          tooltip.style.display = 'none';
          u.root.appendChild(tooltip);

          u.over.addEventListener('mousemove', (e) => {
            const { left, top } = u.over.getBoundingClientRect();
            const x = e.clientX - left;
            const y = e.clientY - top;
            const idx = u.posToIdx(x);

            tooltip.style.display = 'block';
            tooltip.style.left = `${x + 5}px`;
            tooltip.style.top = `${y + 5}px`;

            const value = u.data[1][idx];
            tooltip.textContent = `Value: ${value!}`;
          });

          u.over.addEventListener('mouseleave', () => {
            tooltip.style.display = 'none';
          });
        },
      ],
    },
  };

  return (
    <div
      ref={containerRef}
      style={{ width: '100%', height: '100%', position: 'relative' }}
    >
      <UplotReact
        data={data as Uplot.AlignedData}
        options={chartOptions}
      />
    </div>
  );
};

export default TachogramPlot;
