import { useEffect, useState } from 'react';
import { useWebSocket } from '../websocket';

export function useCalibratedPatients() {
  const { socket } = useWebSocket();
  const [calibratedPatients, setCalibratedPatients] = useState({});

  useEffect(() => {
    if (socket) {
      const handleCalibratedPatients = (calibratedPatients: any) => {
        setCalibratedPatients(calibratedPatients);
      };
      socket.on('calibrated_patients', handleCalibratedPatients);
      return () => {
        socket.off('calibrated_patients', handleCalibratedPatients);
      };
    }
  }, []);

  return { calibratedPatients };
}
