import { Check } from 'lucide-react';
import { ChangeEvent, InputHTMLAttributes } from 'react';

type CheckboxProps = {
  label?: string;
  checked?: boolean;
  onChange?: (checked: boolean) => void;
  disabled?: boolean;
  className?: string;
  labelClasses?: string;
  size?: 'small' | 'medium' | 'large';
  props?: InputHTMLAttributes<HTMLInputElement>;
};

const Checkbox = ({
  label,
  checked = false,
  onChange,
  disabled = false,
  className = '',
  labelClasses = '',
  size = 'medium',
  ...props
}: CheckboxProps) => {
  const sizeClasses = {
    small: 'h-4 w-4',
    medium: 'h-5 w-5',
    large: 'h-6 w-6',
  };

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange?.(e.target.checked);
  };

  return (
    <label
      className={`inline-flex items-center cursor-pointer ${disabled ? 'opacity-50 cursor-not-allowed' : ''} ${className}`}
    >
      <input
        type="checkbox"
        className="opacity-0 absolute h-0 w-0 border-none"
        checked={checked}
        onChange={handleOnChange}
        {...props}
      />
      <div
        className={`rounded border ${sizeClasses[size]} flex items-center justify-center ${
          checked ? 'bg-purple-600 border-transparent' : 'bg-white border-slate-300'
        } transition-colors duration-200 ease-in-out`}
      >
        {checked && (
          <Check
            className="text-white"
            size={20}
          />
        )}
      </div>
      {label && <span className={`ml-2 text-gray-700 ${labelClasses}`}>{label}</span>}
    </label>
  );
};

export default Checkbox;
