import { memo, useState } from 'react';
import { useSelector } from '@xstate/react';

import { dashboardActor } from '../model/xstate/dashboardMachine';
import Button from '@components/Button';
import ToggleableTabs from '@features/Tab/ToggleableTabs/ToggleableTabs';
import { TOGGLEABLE_SECTIONS } from '../model/constants/togglableSections';
import { TachogramPlot } from '@features/Plot';
import ValueTimePlot from '@features/Plot/ValueTimePlot';

type RealtimeMeasurementGraphsProps = {
  onSectionToggleByKey: (key: string) => void;
};

const RealtimeMeasurementGraphs = ({ onSectionToggleByKey }: RealtimeMeasurementGraphsProps) => {
  const sdkData = useSelector(dashboardActor, (state) => state.context.sdkData);
  const sections = useSelector(dashboardActor, (state) => state.context.sections);

  const [isCleared, setIsCleared] = useState<boolean>(false);

  const handleSectionToggle = (key: string) => {
    onSectionToggleByKey(key);
  };

  const handlePlotsCleared = () => {
    setIsCleared(true);
    setTimeout(() => setIsCleared(false), 1000);
  };

  return (
    <div className="mb-4 w-full">
      <div className="flex items-center mb-2 justify-between">
        <div className="w-56 h-6 text-black  text-[15px] font-bold font-['Inter'] leading-normal">
          Realtime measurement graphs
        </div>

        <Button
          onClick={handlePlotsCleared}
          variant="outlinedSecondary"
          text={isCleared ? 'Clearing..' : 'Clear all'}
        />
      </div>

      <div className="flex flex-col items-center gap-3 w-full">
        <ToggleableTabs
          isShown={sections[TOGGLEABLE_SECTIONS.REALTIME_MEASUREMENT_GRAPHS_HEARTRATE]}
          onToggle={() =>
            handleSectionToggle(TOGGLEABLE_SECTIONS.REALTIME_MEASUREMENT_GRAPHS_HEARTRATE)
          }
          withCardStyle
          tabs={[
            {
              label: 'HR4',
              content: (
                <ValueTimePlot
                  key="hr4"
                  sdkData={sdkData}
                  valueForPlot={sdkData?.hr4s}
                  isCleared={isCleared}
                  label="hr4"
                />
              ),
            },
            {
              label: 'HR10',
              content: (
                <ValueTimePlot
                  key="hr10"
                  sdkData={sdkData}
                  valueForPlot={sdkData?.hr10s}
                  isCleared={isCleared}
                  label="hr10"
                />
              ),
            },
          ]}
          titleClasses="max-w-[190px]"
          additionalData={sdkData?.hr10s && `${sdkData.hr10s} bpm`}
        />

        <ToggleableTabs
          isShown={sections[TOGGLEABLE_SECTIONS.REALTIME_MEASUREMENT_GRAPHS_HRVSDNN]}
          onToggle={() =>
            handleSectionToggle(TOGGLEABLE_SECTIONS.REALTIME_MEASUREMENT_GRAPHS_HRVSDNN)
          }
          withCardStyle
          titleClasses="max-w-[190px]"
          tabs={[
            {
              label: 'SDNN',
              content: (
                <ValueTimePlot
                  key="hrv-plot"
                  sdkData={sdkData}
                  valueForPlot={sdkData?.hrvSDNN}
                  isCleared={isCleared}
                />
              ),
            },
          ]}
          additionalData={sdkData?.hrvSDNN && `${sdkData?.hrvSDNN} ms`}
        />

        <ToggleableTabs
          isShown={sections[TOGGLEABLE_SECTIONS.REALTIME_MEASUREMENT_GRAPHS_CARDIAC_STRESS]}
          onToggle={() =>
            handleSectionToggle(TOGGLEABLE_SECTIONS.REALTIME_MEASUREMENT_GRAPHS_CARDIAC_STRESS)
          }
          withCardStyle
          tabs={[
            {
              label: 'Cardiac stress',
              content: (
                <ValueTimePlot
                  key="cardiacStress-plot"
                  sdkData={sdkData}
                  valueForPlot={sdkData?.cardiacStressRT}
                  isCleared={isCleared}
                />
              ),
            },
          ]}
          titleClasses="max-w-[190px]"
          additionalData={sdkData?.cardiacStressRT}
        />
        <ToggleableTabs
          isShown={sections[TOGGLEABLE_SECTIONS.REALTIME_MEASUREMENT_GRAPHS_TACHOGRAM]}
          onToggle={() =>
            handleSectionToggle(TOGGLEABLE_SECTIONS.REALTIME_MEASUREMENT_GRAPHS_TACHOGRAM)
          }
          withCardStyle
          tabs={[
            {
              label: 'Tachogram',
              content: (
                <TachogramPlot
                  key="tachogram-plot"
                  isCleared={isCleared}
                  realtimeBeats={sdkData?.realtimeHeartbeats}
                  finalBeats={sdkData?.results?.heartbeats}
                />
              ),
            },
          ]}
          titleClasses="max-w-[190px]"
        />
      </div>
    </div>
  );
};

export default memo(RealtimeMeasurementGraphs);
