import React, { useEffect, useState, useRef } from 'react';
import { debounce } from 'lodash';
import { CheckIcon, CloseIcon, FullHeartIcon } from '@assets/icons';
import Button from '@components/Button';

import {
  KeyMoment,
  KeyMomentsData,
  MergedData,
  MKeyMoment,
  MTranscript,
  Transcript,
} from './types';
import TranscriptItem from './TranscriptItem';
import { formatTime } from '@utils/keyMoment';
import { Loader } from '@shared/ui/loader/Loader';

import { ShamefulAny } from '@interfaces/index';
import VideoPlayerComponent from '../RecordingPlayer/VideoPlayerComponent';

type KeyMomentsProps = {
  setVideoDuration: (newDuration: number) => void;
  onTimeUpdate: (currentTimeMillis: number) => void;
  keyMomentsData: KeyMomentsData;
  videoRef: React.RefObject<HTMLVideoElement>;
  currentVideoTime: number;
  updatedKeyMoments: KeyMomentsData;
  setUpdatedKeymoments: React.Dispatch<React.SetStateAction<KeyMomentsData | undefined>>;
  keyMomentsWrapperClasses?: string;
  recordingState?: Record<string, ShamefulAny>;
  transcriptsState?: Record<string, ShamefulAny>;
  generalState?: Record<string, ShamefulAny>;
  framesState?: Record<string, ShamefulAny>;
  framesData: {
    timestamp: number;
    link: string;
  }[];
  recordingTimeFrame: {
    recordingStartTime: number | undefined;
    recordingStopTime: number | undefined;
  };
};

const KeyMoments = ({
  setVideoDuration,
  onTimeUpdate,
  videoRef,
  keyMomentsData,
  recordingState,
  framesState,
  generalState,
  transcriptsState,
  currentVideoTime,
  setUpdatedKeymoments,
  updatedKeyMoments,
  keyMomentsWrapperClasses = '',
  framesData,
  recordingTimeFrame,
}: KeyMomentsProps) => {
  const [currentTimestamp, setCurrentTimestamp] = useState(0);
  const [mergedData, setMergedData] = useState<MergedData>([]);
  const itemRefs = useRef<(HTMLDivElement | null)[]>([]);
  const [recordingStartTime, setRecordingStartTime] = useState<null | number>(null);
  const [isInitialLoaderEnabled, setIsInitialLoaderEnabled] = useState<boolean>(true);
  const [isPlaying, setIsPlaying] = useState(false);
  const [showFrames, setShowFrames] = useState(true);

  const { data, isLoading, isFailed, error } = recordingState || {
    isLoading: false,
    isFailed: null,
    error: null,
    data: {},
  };

  const videoSrc = data?.recording;

  useEffect(() => {
    if (!keyMomentsData) return;
    const recordingStart = keyMomentsData.recordingStartTime;
    setRecordingStartTime(new Date(recordingStart).getTime());
  }, [keyMomentsData]);

  useEffect(() => {
    if (videoRef && videoRef.current) {
      const handleLoadedMetadata = () => {
        const newDuration = videoRef.current?.duration ? videoRef.current.duration * 1000 : 0;
        setVideoDuration(newDuration);
      };

      const handleTimeUpdate = debounce(() => {
        if (videoRef.current) {
          onTimeUpdate(videoRef.current.currentTime * 1000);
        }
      }, 100);

      videoRef.current.addEventListener('loadedmetadata', handleLoadedMetadata);
      videoRef.current.addEventListener('timeupdate', handleTimeUpdate);

      return () => {
        videoRef.current?.removeEventListener('loadedmetadata', handleLoadedMetadata);
        videoRef.current?.removeEventListener('timeupdate', handleTimeUpdate);
      };
    }
  }, [videoSrc, onTimeUpdate, videoRef, showFrames]);

  useEffect(() => {
    if (videoRef && videoRef.current && isFinite(currentTimestamp)) {
      const targetTimeInSeconds = currentTimestamp;

      // Only update currentTime if the difference is significant (to avoid unnecessary updates)
      if (Math.abs(videoRef.current.currentTime - targetTimeInSeconds) > 0.1) {
        videoRef.current.currentTime = targetTimeInSeconds;
      }
    } else if (showFrames) {
      // Only trigger the onTimeUpdate callback if frames are being shown (i.e., in frames view)
      onTimeUpdate(currentTimestamp * 1000);
    }
  }, [currentTimestamp, videoRef, showFrames]);

  useEffect(() => {
    if (!transcriptsState?.data) return;

    const keyMomentTranscriptIds = new Set(
      keyMomentsData?.keyMoments?.flatMap((km) => km.transcripts.map((t) => t.id))
    );

    const filteredSessionTranscripts: MTranscript[] = transcriptsState.data.transcript
      .filter((t: Transcript) => !keyMomentTranscriptIds.has(t.id))
      .map((t: Transcript) => ({
        type: 'transcript',
        id: t.id,
        timestamp: t.timestamp,
        message: t.message,
        source: t.source,
      }));

    const keyMomentsDataMapped: MKeyMoment[] =
      keyMomentsData?.keyMoments?.map((km) => ({
        type: 'keyMoment',
        timestamp: new Date(km.timestamp).getTime(),
        heartBeat: km.heartBeat,
        timeInMillis: km.timeInMillis,
        approvalStatus: km.approvalStatus,
        transcripts: km.transcripts.map((t) => ({
          type: 'transcript',
          id: t.id,
          timestamp: t.timestamp,
          message: t.message,
          source: t.source,
        })),
      })) || [];

    const mergedData: MergedData = [...filteredSessionTranscripts, ...keyMomentsDataMapped].sort(
      (a, b) => a.timestamp - b.timestamp
    );

    setMergedData(mergedData);
  }, [transcriptsState?.data, keyMomentsData]);

  useEffect(() => {
    if (mergedData.length === 0 || recordingStartTime === null) return;
    const currentTimeMillis = currentVideoTime;
    const closestIndex = mergedData.findIndex((item) => {
      return Math.abs(item.timestamp - recordingStartTime - currentTimeMillis) <= 1000;
    });

    const scrollToFrame = (index: number) => {
      if (index !== -1 && itemRefs.current[index]) {
        const ref = itemRefs.current[index];
        if (ref) {
          ref.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          });
        } else {
          console.error('Ref is undefined for index:', index);
        }
      }
    };

    if (showFrames) {
      scrollToFrame(closestIndex);
      return;
    }

    if (videoRef && videoRef.current) {
      scrollToFrame(closestIndex);
    }
  }, [showFrames, mergedData, currentVideoTime, recordingStartTime, videoRef]);

  useEffect(() => {
    const handleSpacebarPress = (event: KeyboardEvent) => {
      if (
        event.code === 'Space' &&
        videoRef.current &&
        (event.target as HTMLElement)?.tagName !== 'INPUT' &&
        (event.target as HTMLElement)?.tagName !== 'TEXTAREA' &&
        !(event.target as HTMLElement).closest('.ql-editor') // Check if the target is inside the Quill editor(our text editor)
      ) {
        event.preventDefault();

        if (videoRef.current.paused) {
          videoRef.current.play();
        } else {
          videoRef.current.pause();
        }
      }
    };

    document.addEventListener('keydown', handleSpacebarPress);

    return () => {
      document.removeEventListener('keydown', handleSpacebarPress);
    };
  }, [videoRef, showFrames]);

  const intervalId = useRef<NodeJS.Timeout | null>(null);
  const prevTime = useRef<number>(currentVideoTime);

  const startOrPauseTimeUpdate = () => {
    if (showFrames) {
      if (isPlaying) {
        if (intervalId.current !== null) {
          clearInterval(intervalId.current);
          intervalId.current = null;
        }

        intervalId.current = null;
        setIsPlaying(false);
      } else {
        intervalId.current = setInterval(() => {
          prevTime.current += 500; // we post two frames in a second, thats why i use 500ms

          if (
            typeof recordingTimeFrame.recordingStopTime !== 'undefined' &&
            typeof recordingTimeFrame.recordingStartTime !== 'undefined' &&
            prevTime.current <=
              recordingTimeFrame.recordingStopTime - recordingTimeFrame.recordingStartTime
          ) {
            onTimeUpdate(prevTime.current);
          } else {
            if (intervalId.current !== null) {
              clearInterval(intervalId.current);
              intervalId.current = null;
            }
            intervalId.current = null;

            setIsPlaying(false);
          }
        }, 500);

        setIsPlaying(true);
      }
    } else if (videoRef.current) {
      if (videoRef.current.paused) {
        videoRef.current.play();
        setIsPlaying(true);
      } else {
        videoRef.current.pause();
        setIsPlaying(false);
      }
    }
  };

  // Cleanup interval on component unmount
  useEffect(() => {
    return () => {
      if (intervalId.current) clearInterval(intervalId.current);
    };
  }, []);
  useEffect(() => {
    if (!showFrames && videoRef.current && isFinite(currentVideoTime)) {
      const currentTimeInSeconds = currentVideoTime / 1000;
      if (Math.abs(videoRef.current.currentTime - currentTimeInSeconds) > 0.1) {
        videoRef.current.currentTime = currentTimeInSeconds;
      }
    }
  }, [showFrames, currentVideoTime]);

  const handleTimestampClick = (timeInMillis: number) => {
    const seconds = timeInMillis / 1000;
    if (isFinite(seconds)) {
      setCurrentTimestamp(seconds);
    } else {
      console.error('Invalid seconds value:', seconds);
    }
  };

  const mergeWithKeyMoment = (
    transcript: Transcript,
    keyMoment: KeyMoment,
    isUnmerge: boolean = false
  ) => {
    if (!updatedKeyMoments || !keyMoment) return;

    const mappedKeyMoments = updatedKeyMoments.keyMoments.map((km) => {
      if (km.timestamp === keyMoment.timestamp) {
        const newTranscripts = isUnmerge
          ? km.transcripts.filter((t) => t.id !== transcript.id)
          : [...km.transcripts, transcript];

        return {
          ...km,
          transcripts: newTranscripts,
        };
      }
      return km;
    });

    const newUpdatedKeyMoments = {
      ...updatedKeyMoments,
      keyMoments: mappedKeyMoments,
    };

    setUpdatedKeymoments(newUpdatedKeyMoments);

    const updatedMergedData = mergedData
      .filter((item) => !(item.type === 'transcript' && item.id === transcript.id))
      .map((item) => {
        if (
          isUnmerge &&
          item.type === 'keyMoment' &&
          item.timestamp === new Date(keyMoment.timestamp).getTime()
        ) {
          const newItem = {
            ...item,
            transcripts: item.transcripts.filter((t) => t.id !== transcript.id),
          };
          return newItem;
        }

        if (
          !isUnmerge &&
          item.type === 'keyMoment' &&
          item.timestamp === new Date(keyMoment.timestamp).getTime()
        ) {
          const newItem = {
            ...item,
            transcripts: [
              ...item.transcripts,
              {
                id: transcript.id,
                timestamp: transcript.timestamp,
                message: transcript.message,
                source: transcript.source,
              },
            ],
          };
          return newItem;
        }

        return item;
      });

    if (isUnmerge) {
      updatedMergedData.push({
        type: 'transcript',
        ...transcript,
      });
    }

    setMergedData(updatedMergedData.sort((a, b) => a.timestamp - b.timestamp));
  };

  const createNewKeyMoment = (transcript: Transcript) => {
    if (!updatedKeyMoments) {
      return;
    }

    const newKeyMoment: KeyMoment = {
      approvalStatus: 'none',
      heartBeat: 0,
      timeInMillis: recordingStartTime ? transcript.timestamp - recordingStartTime : 0,
      timestamp: transcript.timestamp,
      transcripts: [transcript],
    };

    const newUpdatedKeyMoments: KeyMomentsData = {
      ...updatedKeyMoments,
      keyMoments: [...updatedKeyMoments.keyMoments, newKeyMoment],
    };

    const newMergedData: MergedData = [
      ...mergedData.filter((el) => !(el.type === 'transcript' && el.id === transcript.id)),
      {
        type: 'keyMoment',
        ...newKeyMoment,
      } as MKeyMoment,
    ];

    setUpdatedKeymoments(newUpdatedKeyMoments);
    setMergedData(newMergedData.sort((a, b) => a.timestamp - b.timestamp));
  };

  const removeKeyMoment = (keyMoment: KeyMoment) => {
    if (!keyMoment) return;

    setUpdatedKeymoments((prevKeyMomentsData) => {
      if (!prevKeyMomentsData) return prevKeyMomentsData;

      const updatedKeyMomentsList = prevKeyMomentsData.keyMoments.filter((km) => {
        return new Date(km.timestamp).getTime() !== new Date(keyMoment.timestamp).getTime();
      });

      return { ...prevKeyMomentsData, keyMoments: updatedKeyMomentsList };
    });

    setMergedData((prevMergedData) => {
      if (!prevMergedData) return prevMergedData;

      const updatedMergedData = prevMergedData.filter(
        (item) =>
          !(
            item.type === 'keyMoment' &&
            new Date(item.timestamp).getTime() === new Date(keyMoment.timestamp).getTime()
          )
      );

      const transcriptsAsOrdinaryItems = keyMoment.transcripts.map((transcript) => ({
        type: 'transcript' as const,
        ...transcript,
      }));

      const newMergedData: MergedData = [...updatedMergedData, ...transcriptsAsOrdinaryItems].sort(
        (a, b) => a.timestamp - b.timestamp
      );

      return newMergedData;
    });
  };

  const updateKeyMomentStatus = (
    keyMoment: KeyMoment,
    newStatus: 'none' | 'approved' | 'disapproved',
    setUpdatedKeyMoments: React.Dispatch<React.SetStateAction<KeyMomentsData | undefined>>,
    setMergedData: React.Dispatch<React.SetStateAction<MergedData>>
  ) => {
    if (!keyMoment) return;
    setUpdatedKeyMoments((prevKeyMomentsData) => {
      if (!prevKeyMomentsData) return prevKeyMomentsData;

      const updatedKeyMomentsList = prevKeyMomentsData.keyMoments.map((km) => {
        const kmTimestampNumeric = new Date(km.timestamp).getTime();

        if (kmTimestampNumeric === keyMoment.timestamp) {
          return { ...km, approvalStatus: newStatus };
        }
        return km;
      });

      return { ...prevKeyMomentsData, keyMoments: updatedKeyMomentsList };
    });

    setMergedData((prevMergedData) => {
      const updatedMergedData = prevMergedData.map((item) => {
        const itemTimestampNumeric = new Date(item.timestamp).getTime();

        return item.type === 'keyMoment' && itemTimestampNumeric === keyMoment.timestamp
          ? { ...item, approvalStatus: newStatus }
          : item;
      });

      return updatedMergedData.sort(
        (a, b) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime()
      );
    });
  };

  const handleKeyMomentApproved = (keyMoment: KeyMoment) => {
    updateKeyMomentStatus(keyMoment, 'approved', setUpdatedKeymoments, setMergedData);
  };

  const handleKeyMomentReject = (keyMoment: KeyMoment) => {
    removeKeyMoment(keyMoment);
  };
  const handleSwitchToVideo = () => {
    setShowFrames(false);
  };
  if (
    (transcriptsState?.data && isInitialLoaderEnabled) ||
    (framesState?.data && isInitialLoaderEnabled)
  ) {
    setIsInitialLoaderEnabled(false);
  }

  if (isInitialLoaderEnabled) {
    return (
      <Loader
        flexDirection="flex-col"
        label="Preparing session data..."
        className="w-full h-full self-center items-center justify-center"
      />
    );
  }

  return (
    <div className="flex flex-col gap-7 w-full ">
      <div className=" text-gray-800 text-sm font-normal font-['Inter'] leading-tight tracking-tight max-w-[830px] w-full text-left">
        Watch the video session and explore the transcription to follow along or catch up on
        details. Key moments are highlighted for quick navigation and easy access to important
        parts.
      </div>
      <div className="flex gap-8 w-full h-full overflow-auto">
        <VideoPlayerComponent
          showFrames={showFrames}
          framesState={framesState}
          framesData={framesData}
          recordingTimeFrame={recordingTimeFrame}
          currentVideoTime={currentVideoTime}
          isPlaying={isPlaying}
          setIsPlaying={setIsPlaying}
          startOrPauseTimeUpdate={startOrPauseTimeUpdate}
          handleSwitchToVideo={handleSwitchToVideo}
          intervalId={intervalId}
          isLoading={isLoading}
          videoSrc={videoSrc}
          isFailed={isFailed}
          error={error}
          videoRef={videoRef}
        />
        <div
          className={`flex flex-col my-auto w-[45%] overflow-y-auto p-4 rounded-md ${keyMomentsWrapperClasses}`}
        >
          {generalState?.isLoading && (
            <Loader
              label="Key moments are loading..."
              flexDirection="flex-col"
              className="w-full h-full self-center items-center justify-center"
            />
          )}
          {!transcriptsState?.data &&
            !transcriptsState?.isLoading &&
            transcriptsState?.isFailed && (
              <div className="w-full h-full rounded-md">
                <div className="flex flex-col self-center items-center justify-center">
                  No key moments captured.
                  {transcriptsState?.error && (
                    <span className="text-rose-400">{transcriptsState.error}</span>
                  )}
                </div>
              </div>
            )}
          {mergedData &&
            updatedKeyMoments &&
            mergedData.length > 0 &&
            mergedData.map((item, index) => {
              if (item.type === 'transcript') {
                const isAdjacentToKeyMoment =
                  (mergedData[index - 1] && mergedData[index - 1].type === 'keyMoment') ||
                  (mergedData[index + 1] && mergedData[index + 1].type === 'keyMoment');

                const isAfterKeyMoment =
                  mergedData[index - 1] && mergedData[index - 1].type === 'keyMoment';

                const isBeforeKeyMoment =
                  mergedData[index + 1] && mergedData[index + 1].type === 'keyMoment';

                return (
                  <div
                    key={`general-transcript-${index}`}
                    ref={(el) => (itemRefs.current[index] = el)}
                  >
                    <TranscriptItem
                      index={index}
                      item={item}
                      recordingStartTime={recordingStartTime}
                      handleTimestampClick={handleTimestampClick}
                      itemRefs={itemRefs}
                      keyMoments={updatedKeyMoments.keyMoments}
                      mergeWithKeyMoment={mergeWithKeyMoment}
                      isAdjacentToKeyMoment={isAdjacentToKeyMoment}
                      isInKeyMoment={false}
                      isBeforeKeyMoment={isBeforeKeyMoment}
                      isAfterKeyMoment={isAfterKeyMoment}
                      createNewKeyMoment={createNewKeyMoment}
                    />
                  </div>
                );
              } else if (item.type === 'keyMoment') {
                return (
                  <div
                    key={`key-item-${index}`}
                    ref={(el) => (itemRefs.current[index] = el)}
                    className="bg-white rounded-xl shadow flex p-4 gap-5 justify-between"
                  >
                    <div className="flex flex-col">
                      <div className="flex gap-2 items-center">
                        <div className="flex gap-[7px]">
                          <FullHeartIcon />
                          <div className="text-gray-800 text-[13px] font-semibold font-['Inter'] leading-tight">
                            {item.heartBeat}
                          </div>
                          <div
                            className="cursor-pointer text-gray-500 text-[13px] font-normal font-['Inter'] leading-tight"
                            onClick={() => handleTimestampClick(item.timeInMillis)}
                          >
                            {formatTime(item.timestamp)}
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-col justify-start">
                        {item.transcripts?.map((el, i) => (
                          <div key={el.id}>
                            <TranscriptItem
                              index={i}
                              item={el}
                              recordingStartTime={recordingStartTime}
                              handleTimestampClick={handleTimestampClick}
                              itemRefs={itemRefs}
                              keyMoments={updatedKeyMoments.keyMoments}
                              mergeWithKeyMoment={mergeWithKeyMoment}
                              isAdjacentToKeyMoment={false}
                              isInKeyMoment={true}
                              isBeforeKeyMoment={false}
                              isAfterKeyMoment={false}
                              createNewKeyMoment={createNewKeyMoment}
                              removeKeyMoment={removeKeyMoment}
                            />
                          </div>
                        ))}
                      </div>
                    </div>

                    {item.approvalStatus == 'none' && (
                      <div className="flex flex-col gap-4">
                        <Button
                          variant="iconPurple"
                          size="sm"
                          className="text-white"
                          icon={<CheckIcon />}
                          onClick={() => handleKeyMomentApproved(item)}
                        />
                        <Button
                          variant="iconWhite"
                          size="sm"
                          icon={<CloseIcon />}
                          onClick={() => handleKeyMomentReject(item)}
                        />
                      </div>
                    )}
                  </div>
                );
              }
              return null;
            })}
        </div>
      </div>
    </div>
  );
};

export default KeyMoments;
