import { Patient } from '@interfaces/patient';
import { TSessionInstance } from '@interfaces/session';

export const getPreviousSessionsData = (patients?: Patient[]) => {
  if (!patients || patients.length === 0) return [];

  const previousSessions: { patient: Patient; session: TSessionInstance }[] = [];

  for (const patient of patients) {
    if (patient.sessions.length === 0) continue;

    for (const session of patient.sessions) {
      const sessionTimestamp = new Date(session.timestamp).getTime();

      if (sessionTimestamp < new Date().getTime()) {
        previousSessions.push({ patient, session });
      }
    }
  }

  previousSessions.sort(
    (a, b) => new Date(b.session.timestamp).getTime() - new Date(a.session.timestamp).getTime()
  );

  return previousSessions;
};
