import styled from '@emotion/styled';
import { ASIDE_TILE_HEIGHT, ASIDE_TILE_WIDTH, PATIENT_TILE_HEIGHT } from '../model/constants/style';
import { BREAKPOINTS } from '@shared/constants';

export const TileContainer = styled.div`
  position: absolute;
  top: ${PATIENT_TILE_HEIGHT};
  width: 216px;
  height: 128px;
  right: 0;
  margin-top: 20px;
  z-index: 1;
  transition:
    bottom 0.3s cubic-bezier(0.4, 0, 0.2, 1),
    left 0.3s cubic-bezier(0.4, 0, 0.2, 1),
    right 0.3s cubic-bezier(0.4, 0, 0.2, 1),
    top 0.3s cubic-bezier(0.4, 0, 0.2, 1),
    width 0.3s cubic-bezier(0.4, 0, 0.2, 1),
    height 0.3s cubic-bezier(0.4, 0, 0.2, 1);

  &.isPresentation {
    top: 46.5%;

    @media only screen and (max-width: ${BREAKPOINTS.TABLET}px) {
      top: 68%;
      width: ${ASIDE_TILE_WIDTH.XS};
      height: ${ASIDE_TILE_HEIGHT.XS};
    }
    @media only screen and (min-width: ${BREAKPOINTS.TABLET}px) {
      top: 61%;
      width: ${ASIDE_TILE_WIDTH.S};
      height: ${ASIDE_TILE_HEIGHT.S};
    }
    @media only screen and (min-width: ${BREAKPOINTS.LAPTOP}px) {
      top: 58.6%;
      width: ${ASIDE_TILE_WIDTH.M};
      height: ${ASIDE_TILE_HEIGHT.M};
    }
    @media only screen and (min-width: ${BREAKPOINTS.S_DESKTOP}px) {
      top: 56%;
      width: ${ASIDE_TILE_WIDTH.L};
      height: ${ASIDE_TILE_HEIGHT.L};
    }
    @media only screen and (min-width: ${BREAKPOINTS.M_DESKTOP}px) {
      top: 61%;
      right: 9%;
      width: ${ASIDE_TILE_WIDTH.XL};
      height: ${ASIDE_TILE_HEIGHT.XL};
    }
  }

  @media only screen and (max-width: 600px) {
    width: 240px;
    height: 135px;
  }

  &.self-view {
    width: 250px;
    height: 150px;
  }

  @media only screen and (max-width: 600px) {
    &.self-view {
      width: 240px;
      height: 135px;
    }
  }

  &.no-video {
    background-color: var(--dark-blue-border);
  }

  &.no-video video {
    visibility: hidden;
  }

  &.self-view.alone {
    position: absolute;
    width: 480px;
    height: 270px;
    z-index: 1;
  }

  @media only screen and (max-width: 600px) {
    &.self-view.alone {
      width: 240px;
      height: 135px;
    }
  }

  &.tile-screenshare {
    grid-column: 1 / -1;
    grid-row: 1;
  }
`;
