import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from '@xstate/react';
import { AxiosError } from 'axios';

// API
import { fetchCurrentUserDetails } from '@api/user/me';
import { updateClerkUserDataById } from '@api/user/updateClerkUserDataById';
import { updatePatientList } from './api/updatePatientList';
import { getUserMetadata } from '@api/userAPI';
// Machines
import { refaelaTherapistActor } from '@components/xState/machines/refaelaTherapistMachine';
// Utils
import { formatDateFromISO, getRandomFutureDate } from '@utils/date';
import { getLastSession } from '@utils/sessions';
// Constants
import { INVITE_EDIT_PATIENT_FORM_FIELDS } from '@components/Forms/model/constants/inviteEditPatientForm';
// Types
import { PatientListProps } from './types';
import { NewPatientDataForm } from './model/types';
import { NewPatientMetadataType } from '@shared/types/patient';
import { Patient, ShamefulAny } from '@interfaces/index';
import { FormPatientDataType } from '@components/Forms/model/types';
// Components
import { HeaderCell, TableCell } from '@shared/ui/table';
import Button from '@components/Button';

import TextAvatar from '@features/Avatar';
import { Modal } from '@features/Modal/ui/Modal';
import { InvitePatientForm } from '@components/Forms/InvitePatient/InvitePatientForm';
import { DeletePatientConfirmationBlock } from './ui/DeletePatientConfirmationBlock';
import { EditPatientForm } from '@components/Forms/EditPatient/EditPatientForm';

const PatientList: React.FC<PatientListProps> = ({ patients = [] }) => {
  const therapistUser = useSelector(refaelaTherapistActor, (state) => state.context.currentUser);
  const [, setSelectAll] = useState<boolean>(false);
  const [selectedPatientId, setSelectedPatientId] = useState<string | null>(null);
  const [selectedPatientData, setSelectedPatientData] = useState<FormPatientDataType | null>(null);
  const [isInviteModalOpen, setInviteModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedPatients] = useState<string[]>([]);
  const [patientList, setPatientList] = useState(patients || []);
  const [isFormLoading, setIsFormLoading] = useState<boolean>(false);
  const [inviteFormError, setInviteFormError] = useState<string | null>(null);
  const [deletePatientError, setDeletePatientError] = useState<string | null>(null);
  const [editPatientFormError, setEditPatientFormError] = useState<string | null>(null);
  const therapistPatients = therapistUser?.patientList;

  const [editedPatientData, setEditedPatientData] = useState<{
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
  } | null>(null);

  useEffect(() => {
    if (therapistUser?.patientList) {
      setPatientList(therapistUser.patientList);
    }
  }, [therapistUser?.patientList]);

  const handleEditPatientModalOpen = useCallback(
    (patientId: string) => {
      if (!therapistUser?.patientList) return;
      setSelectedPatientId(patientId);
      const patientData = (therapistUser?.patientList || []).find(
        (patient) => patient.patientId === patientId
      );

      if (patientData) {
        setSelectedPatientData({
          [INVITE_EDIT_PATIENT_FORM_FIELDS.FIRST_NAME]: patientData.name || '',
          [INVITE_EDIT_PATIENT_FORM_FIELDS.LAST_NAME]: patientData.surname || '',
          [INVITE_EDIT_PATIENT_FORM_FIELDS.EMAIL]: patientData.email || '',
          [INVITE_EDIT_PATIENT_FORM_FIELDS.PHONE_NUMBER]: patientData.phoneNumber || '',
        });
      }

      setIsEditModalOpen(true);
    },
    [therapistUser?.patientList]
  );
  const handleEditPatientModalClose = useCallback(() => {
    setIsEditModalOpen(false);
  }, []);
  const handlePatientEditSubmit = (newPatientData: FormPatientDataType) => {
    const updatedData = {
      firstName: newPatientData[INVITE_EDIT_PATIENT_FORM_FIELDS.FIRST_NAME],
      lastName: newPatientData[INVITE_EDIT_PATIENT_FORM_FIELDS.LAST_NAME],
      email: newPatientData[INVITE_EDIT_PATIENT_FORM_FIELDS.EMAIL],
      phoneNumber: newPatientData[INVITE_EDIT_PATIENT_FORM_FIELDS.PHONE_NUMBER],
    };

    if (selectedPatientId) {
      setEditedPatientData({
        firstName: updatedData.firstName,
        lastName: updatedData.lastName,
        email: updatedData.email,
        phone: updatedData.phoneNumber,
      });
      handleEditAccept();
    }
  };
  const handleEditAccept = async () => {
    if (selectedPatientId && editedPatientData && therapistUser?.therapistId) {
      try {
        setIsFormLoading(true);
        const existingMetadata = await getUserMetadata(selectedPatientId);
        await updateClerkUserDataById(selectedPatientId, {
          ...existingMetadata,
          firstName: editedPatientData.firstName,
          lastName: editedPatientData.lastName,
          email: editedPatientData.email,
          phone: editedPatientData.phone,
        });

        const updatedTherapistData = await fetchCurrentUserDetails(therapistUser?.therapistId);
        setPatientList(updatedTherapistData.patientList);

        refaelaTherapistActor.send({
          type: 'UPDATE_THERAPIST_USER',
          currentUser: updatedTherapistData,
        });
        setIsEditModalOpen(false); // Close modal after success
      } catch (error: ShamefulAny) {
        setEditPatientFormError(error.message || 'Failed to update patient');
      } finally {
        setIsFormLoading(false);
      }
    }
  };
  const onPatientDelete = async (patientId: string) => {
    if (!therapistUser?.therapistId) return;
    try {
      setIsFormLoading(true);
      await updateClerkUserDataById(patientId, { metadata: {} });
      const therapistMetadata = (await getUserMetadata(therapistUser?.therapistId)) || {};
      const currentPatientList = therapistMetadata.patients || [];
      const updatedPatientList = currentPatientList.filter(
        (patient: { id: string }) => patient.id !== patientId
      );
      await updateClerkUserDataById(therapistUser?.therapistId, {
        metadata: { ...therapistMetadata, patients: updatedPatientList },
      });
      const updatedTherapistData = await fetchCurrentUserDetails(therapistUser?.therapistId);
      const updatedPatients = patientList.filter((patient) => patient.patientId !== patientId);
      setPatientList(updatedPatients);
      deletePatientError && setDeletePatientError(null);
      refaelaTherapistActor.send({
        type: 'UPDATE_THERAPIST_USER',
        currentUser: updatedTherapistData,
      });
      setIsDeleteModalOpen(false);
      setSelectedPatientId(null);
    } catch (error: ShamefulAny) {
      setDeletePatientError(error.message || 'Failed to delete patient and update metadata');
      console.error('Failed to delete patient and update metadata:', error.message, error.details);
    } finally {
      setIsFormLoading(false);
    }
  };
  const handleInvitePatientModal = useCallback(() => {
    setInviteModalOpen(true);
  }, []);
  const handleDeletePatientModalOpen = useCallback((patientId: string) => {
    setSelectedPatientId(patientId);
    setIsDeleteModalOpen(true);
  }, []);
  const handleInvitePatientModalClose = useCallback(() => {
    setInviteModalOpen(false);
  }, []);
  const handleDeletePatientModalClose = useCallback(() => {
    setIsDeleteModalOpen(false);
  }, []);
  const executeUpdate = async (newPatient: NewPatientMetadataType) => {
    if (!therapistUser?.therapistId) return;
    try {
      setIsFormLoading(true);
      const response = await updatePatientList(newPatient);
      const newUserData = await fetchCurrentUserDetails(therapistUser?.therapistId);
      if (response && newUserData) {
        setInviteModalOpen(false);
        inviteFormError && setInviteFormError(null);
        refaelaTherapistActor.send({ type: 'UPDATE_THERAPIST_USER', currentUser: newUserData });
      }
    } catch (e) {
      if (e instanceof AxiosError) {
        setInviteFormError(e.message || 'Something went wrong, please try again');
      } else {
        console.error(e);
      }
    } finally {
      setIsFormLoading(false);
    }
  };

  const handleSendNewPatientInvite = useCallback(
    ({ newPatientData }: { newPatientData: NewPatientDataForm }) => {
      const { firstName, lastName, patientEmail, phoneNumber } = newPatientData;
      if (!therapistPatients) return;
      if (therapistPatients.some((e: Patient) => e.email == patientEmail)) {
        setInviteFormError('User with this email is already added');
        return;
      }
      if (therapistUser && therapistUser.therapistId) {
        const newPatient: NewPatientMetadataType = {
          firstName,
          lastName,
          patientEmail,
          phoneNumber,
          therapistId: therapistUser.therapistId,
          therapistScheduleUrl: '',
          needsInvitation: false,
        };
        executeUpdate(newPatient);
      } else {
        setInviteFormError('No therapist id provided');
      }
    },
    [therapistUser?.therapistId]
  );

  useEffect(() => {
    if (selectedPatients.length === patients.length) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  }, [selectedPatients]);

  return (
    <>
      <div className="w-full flex-grow mt-10 px-20 lg_d:h-[65%] lg_d:max-h-[65%] h-[48%] max-h-[55%] pb-10">
        <div className="px-6 py-4 flex justify-between">
          <span className="text-2xl leading-normal font-semibold">Patients</span>
          <Button
            size="md"
            text="Invite patient"
            onClick={handleInvitePatientModal}
          />
        </div>
        <div className="border h-full border-gray-200 rounded-xl overflow-hidden">
          <div className="h-full">
            <div className="h-[3.75rem] flex items-center pt-4 px-4 bg-zinc-50 border-b border-b-gray-200 font-semibold text-sm text-left">
              <HeaderCell classes="w-[45px] mr-5" />
              <HeaderCell
                title="Name"
                classes="w-[250px]"
              />
              <HeaderCell
                title="Phone number"
                classes="w-[calc(100%/5)]"
              />
              <HeaderCell
                title="Last session"
                classes="w-[calc(100%/5)]"
              />
              <HeaderCell
                title="Next session"
                classes="w-[calc(100%/5)]"
              />
              <HeaderCell classes="w-[calc(100%/9)]" />
              <HeaderCell classes="w-[calc(100%/9)]" />
            </div>
            <div className="h-[calc(100%-3.75rem)] bg-white overflow-hidden overflow-y-auto">
              {therapistUser?.patientList && therapistUser?.patientList.length > 0 && (
                <div className="px-4 overflow-y-scroll">
                  {therapistUser?.patientList.map(
                    ({ patientId, name, surname, sessions, email, image, phoneNumber }) => {
                      const lastSession = getLastSession(sessions);
                      const lastSessionDate = lastSession
                        ? formatDateFromISO(lastSession.timestamp)
                        : '-';
                      const nextSession =
                        sessions.length > 0 ? formatDateFromISO(getRandomFutureDate(5)) : '-';

                      return (
                        <div
                          className="flex border-b border-b-gray-200 last:border-none text-sm text-left  focus-within:bg-gray-100"
                          key={patientId}
                        >
                          <TableCell classes="w-[45px] mr-5">
                            <TextAvatar
                              alt="avatar"
                              img={image}
                            >
                              {`${name} ${surname}`}
                            </TextAvatar>
                          </TableCell>

                          <TableCell classes="w-[250px]">
                            <div className="flex truncate">
                              <div className="flex justify-center flex-col overflow-hidden ">
                                <span className="truncate font-semibold">
                                  {name} {surname}
                                </span>
                                <span className="truncate">{email}</span>
                              </div>
                            </div>
                          </TableCell>
                          <TableCell classes="w-[calc(100%/5)] text-text-secondary">
                            {phoneNumber}
                          </TableCell>
                          <TableCell classes="w-[calc(100%/5)] text-text-secondary">
                            {lastSessionDate}
                          </TableCell>
                          <TableCell classes="w-[calc(100%/5)] text-text-secondary">
                            {nextSession}
                          </TableCell>

                          <TableCell classes="w-[calc(100%/9)] text-center">
                            <Button
                              size="md"
                              variant="plain"
                              onClick={() => handleEditPatientModalOpen(patientId)}
                              text="Edit"
                            />
                          </TableCell>
                          <TableCell classes="w-[calc(100%/9)] text-center">
                            <Button
                              size="md"
                              variant="plainSecondary"
                              onClick={() => handleDeletePatientModalOpen(patientId)}
                              text="Delete"
                            />
                          </TableCell>
                        </div>
                      );
                    }
                  )}
                </div>
              )}
            </div>
            {(!therapistUser?.patientList || therapistUser?.patientList.length === 0) && (
              <div className="h-[70%] flex items-center justify-center">
                <span className="text-text-light">No patients found</span>
              </div>
            )}
          </div>
        </div>
      </div>
      <Modal
        isOpen={isInviteModalOpen}
        title="Invite patient"
        onCancel={handleInvitePatientModalClose}
      >
        <InvitePatientForm
          isLoading={isFormLoading}
          onCancel={handleInvitePatientModalClose}
          onInvite={handleSendNewPatientInvite}
          error={inviteFormError}
        />
      </Modal>
      <Modal
        isOpen={isEditModalOpen}
        title="Edit patient"
        onCancel={handleEditPatientModalClose}
      >
        {selectedPatientData && (
          <EditPatientForm
            isLoading={isFormLoading}
            onCancel={handleEditPatientModalClose}
            onEdit={({ newPatientData }) => handlePatientEditSubmit(newPatientData)}
            error={editPatientFormError}
            initialData={selectedPatientData}
          />
        )}
      </Modal>
      <Modal
        isOpen={isDeleteModalOpen}
        onCancel={handleDeletePatientModalClose}
      >
        <DeletePatientConfirmationBlock
          isLoading={isFormLoading}
          onCancel={handleDeletePatientModalClose}
          onDelete={() => onPatientDelete(selectedPatientId!)}
          error={deletePatientError}
        />
      </Modal>
    </>
  );
};

export default PatientList;
