import { JsonView, allExpanded, defaultStyles } from 'react-json-view-lite';
import 'react-json-view-lite/dist/index.css';

interface StateProps {
  title: string;
  data: {
    status: string;
    value: string;
    historyValue?: string;
    context?: string;
    children?: string;
  };
}

const StateDisplay = ({ title, data }: StateProps) => {
  return (
    <div>
      <h3 className="text-black text-[11px] font-medium font-['Inter'] leading-snug">{title}:</h3>
      <JsonView
        data={data}
        shouldExpandNode={allExpanded}
        style={defaultStyles}
      />
    </div>
  );
};

type AgentStateType = {
  status: string;
  value: string;
  historyValue?: string;
  context?: string;
  children?: string;
};

type AgentStateProps = {
  agentState: AgentStateType;
};

const AgentState = ({ agentState }: AgentStateProps) => {
  return (
    <div className="flex space-x-4 mb-4">
      <StateDisplay
        title="Agent State"
        data={agentState || {}}
      />
    </div>
  );
};

export default AgentState;
