import { USER_ROLES } from '@interfaces/user';
import { PLOT_SIGNALS } from '@shared/constants/plot/signals';

export const SIGNAL_COLOR_MAP = {
  [PLOT_SIGNALS.HR_4S]: '#9333EA',
  [PLOT_SIGNALS.HR_10S]: '#2563EB',
  // TODO: delete when color scheme provided
  // [PLOT_SIGNALS.THERAPIST_HR]: '#9333EA',
  // [PLOT_SIGNALS.PATIENT_HR]: '#2563EB',
  [PLOT_SIGNALS.HRV_SDNN]: '#BD1C11',
  [PLOT_SIGNALS.HRV_LN_RMSSD]: '#E84135',
  [PLOT_SIGNALS.CARDIAC_STRESS]: '#781008',
  [PLOT_SIGNALS.FACE_STATE]: '#D43C97',
  [PLOT_SIGNALS.MEASUREMENT_STATE]: '#D9AD34',
  [PLOT_SIGNALS.SIGNAL_QUALITY]: '#EC4919',
} as const;

export const SIGNAL_COLOR_MAP_BY_ROLE = {
  [USER_ROLES.PATIENT]: {
    [PLOT_SIGNALS.HR_4S]: '#2563EB',
    [PLOT_SIGNALS.HR_10S]: '#2563EB',
    [PLOT_SIGNALS.HRV_SDNN]: '#BD1C11',
    [PLOT_SIGNALS.HRV_LN_RMSSD]: '#E84135',
    [PLOT_SIGNALS.CARDIAC_STRESS]: '#781008',
    [PLOT_SIGNALS.FACE_STATE]: '#D43C97',
    [PLOT_SIGNALS.MEASUREMENT_STATE]: '#D9AD34',
    [PLOT_SIGNALS.SIGNAL_QUALITY]: '#EC4919',
  },
  [USER_ROLES.THERAPIST]: {
    [PLOT_SIGNALS.HR_4S]: '#8B5CF6', // Lighter purple
    [PLOT_SIGNALS.HR_10S]: '#3B82F6', // Brighter blue
    [PLOT_SIGNALS.HRV_SDNN]: '#FF4C4C', // Brighter red
    [PLOT_SIGNALS.HRV_LN_RMSSD]: '#F87171', // Soft red
    [PLOT_SIGNALS.CARDIAC_STRESS]: '#9B1C1C', // Darker red
    [PLOT_SIGNALS.FACE_STATE]: '#EC4899', // Vibrant pink
    [PLOT_SIGNALS.MEASUREMENT_STATE]: '#FBBF24', // Brighter yellow
    [PLOT_SIGNALS.SIGNAL_QUALITY]: '#FB923C', // Brighter orange
  },
} as const;

export const SIGNAL_LABEL_MAP = {
  [PLOT_SIGNALS.HR_4S]: 'HR 4 seconds',
  [PLOT_SIGNALS.HR_10S]: 'HR 10 seconds',
  [PLOT_SIGNALS.HRV_SDNN]: 'Standard Deviation of NN intervals',
  [PLOT_SIGNALS.HRV_LN_RMSSD]: 'Root Mean Square of Successive Differences',
  [PLOT_SIGNALS.CARDIAC_STRESS]: 'Cardiac stress',
  [PLOT_SIGNALS.FACE_STATE]: 'Face state',
  [PLOT_SIGNALS.MEASUREMENT_STATE]: 'Measurement state',
  [PLOT_SIGNALS.SIGNAL_QUALITY]: 'Signal quality',
};
