import { useState } from 'react';
// Components
import Button from '@components/Button';
import Checkbox from '@shared/ui/input/checkbox/Checkbox';
import { Loader } from '@shared/ui/loader/Loader';
import { Modal } from '@features/Modal/ui/Modal';
import { PrivacyDetailsModalContent } from './ui/PrivacyDetailsModalContent';
import { refaelaPatientActor } from '@components/xState/machines/refaelaPatientMachine';
import { PATIENT_MACHINE_EVENTS } from '@machines/patient/constants/events';
import { getUserMetadata } from '@api/userAPI';
import { updateClerkUserDataById } from '@api/user/updateClerkUserDataById';
import { fetchCurrentUserDetails } from '@api/user/me';
import { ShamefulAny } from '@interfaces/index';
import { AI_SESSION_TYPES, SESSION_TYPES } from '@interfaces/constants';
import { useSelector } from '@xstate/react';

export const TermsOfPrivacy = () => {
  const user = useSelector(refaelaPatientActor, (state) => state.context.currentUser);
  const { roomUrl, incomingSessionType, assignedHomeworks, patientId } =
    user || {};
  const [isAgreed, setIsAgree] = useState(false);
  const [isDetailModalOpen, setDetailModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleOnContinue = async () => {
    setIsLoading(true);
    await handleUserAgreement?.(isAgreed);
    setIsLoading(false);
  };

  const handleAgreeTerms = (value: boolean) => {
    setIsAgree(value);
  };

  const handleOpenDetailInfoClick = () => {
    setDetailModalOpen(true);
  };

  const handleCloseDetailInfoClick = () => {
    setDetailModalOpen(false);
  };

  const handleUserAgreement = async (isAgreed: boolean) => {
    try {
      if (!patientId) return;
      if (!user) return;
      const existingMetadata = await getUserMetadata(patientId);
      await updateClerkUserDataById(patientId, {
        metadata: {
          ...existingMetadata,
          areTermsOfPrivacyAccepted: isAgreed,
        },
      });
      const updatedUser = await fetchCurrentUserDetails(patientId);
      refaelaPatientActor.send({
        type: PATIENT_MACHINE_EVENTS.UPDATE_PATIENT_USER,
        currentUser: updatedUser,
      });

      // Navigate after agreement
      if (roomUrl && incomingSessionType === SESSION_TYPES.THERAPIST) {
        refaelaPatientActor.send({
          type: PATIENT_MACHINE_EVENTS.GO_TO_PATIENT_PRETALK_WAITING_ROOM,
          currentUser: user,
        });
      } else if (
        assignedHomeworks?.count! > 0 &&
        incomingSessionType &&
        AI_SESSION_TYPES.includes(incomingSessionType)
      ) {
        refaelaPatientActor.send({
          type: PATIENT_MACHINE_EVENTS.GO_TO_AI_DASHBOARD,
          currentUser: user,
        });
      }
    } catch (e: ShamefulAny) {
      console.error('Error updating patient agreement', e.message, e.details);
    }
  };

  const handlePrivacyPolicyTransition = () => {
    refaelaPatientActor.send({
      type: PATIENT_MACHINE_EVENTS.GO_TO_PATIENT_TERMS_OF_USE_AND_PRIVACY,
    });
  };

  return (
    <>
      <div className="flex w-1/2 flex-col self-center justify-center mt-8 space-y-4 items-center">
        <p className="font-bold w-2/3 text-2xl">
          Thank you for joining us in shaping the future of AI assisted therapy!
        </p>
        <div className="w-5/6 flex flex-col justify-start">
          <span className="text-left">
            We are happy to invite you to participate in the Alpha version of our platform aimed at
            making psychological treatment more effective, accessible, and personalised.
            <br />
            By participating in this version, you consent to the collection of your personal data
            which includes: name, contact details, age, heart rate, as well as video and audio
            recordings of therapy sessions.
            <br />
            We use this data to train our AI model and to improve our services, enhancing support
            for you and all future patients and therapists.
            <br />
            <br />
            <b>We are committed to protecting your personal information.</b>
            <br />
            <br />
            Your data will be kept confidential, stored securely, and shared only with our trusted
            service providers.
            <br />
            There is no legal obligation for you to provide your personal information, and its
            provision depends on your consent.
            <br />
            <br />
            <b>I agree to share my personal data with the platform</b>
            <br />
            <br />
            For further Info{' '}
            <span
              className="text-blue-600 cursor-pointer"
              onClick={handleOpenDetailInfoClick}
            >
              Click here
            </span>{' '}
            or see our full{' '}
            <span
              onClick={handlePrivacyPolicyTransition}
              className="text-blue-600 cursor-pointer"
            >
              Privacy policy
            </span>
          </span>

          <Checkbox
            className="mt-6 self-start"
            label="Yes, I agree that my data can be used by BetterYou"
            checked={isAgreed}
            onChange={handleAgreeTerms}
          />
        </div>
        <Button
          className="self-end"
          text="Continue"
          disabled={!isAgreed}
          onClick={handleOnContinue}
          icon={isLoading && <Loader spinnerClasses="!h-4 !ml-0" />}
        />
      </div>
      {isDetailModalOpen && (
        <Modal
          paperClass="!h-[90%] !max-h-[90%] !w-[45rem] md_d:!w-[50rem]"
          title="BetterYou Alpha: Your Privacy"
          isOpen={isDetailModalOpen}
          onCancel={handleCloseDetailInfoClick}
          withTitleDivider={false}
          controls={
            <div className="flex mt-4 justify-end">
              <Button
                text="Continue"
                onClick={handleCloseDetailInfoClick}
              />
            </div>
          }
        >
          <PrivacyDetailsModalContent />
        </Modal>
      )}
    </>
  );
};
