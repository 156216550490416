import React, { useEffect, useState } from 'react';

interface Frame {
  timestamp: number;
  link: string;
}

interface FramesComponentProps {
  framesData: Frame[];
  recordingTimeFrame: {
    recordingStartTime: number | undefined;
    recordingStopTime: number | undefined;
  };
  currentTime: number;
}

const FramesComponent: React.FC<FramesComponentProps> = ({
  framesData,
  recordingTimeFrame,
  currentTime,
}) => {
  const [trimmedFrames, setTrimmedFrames] = useState<Frame[]>([]);
  const [currentFrame, setCurrentFrame] = useState<Frame | null>(null);

  // Filter frames based on the recording time frame
  useEffect(() => {
    const { recordingStartTime, recordingStopTime } = recordingTimeFrame;
    if (
      framesData &&
      typeof recordingStartTime === 'number' &&
      typeof recordingStopTime === 'number'
    ) {
      const filteredFrames = framesData.filter((frame) => {
        return frame.timestamp > recordingStartTime && frame.timestamp < recordingStopTime;
      });
      setTrimmedFrames(filteredFrames);
    }
  }, [framesData, recordingTimeFrame]);

  // Find the closest frame to the current time
  useEffect(() => {
    if (!recordingTimeFrame.recordingStartTime) return;
    const time = recordingTimeFrame.recordingStartTime + currentTime;

    if (trimmedFrames.length > 0) {
      const closestFrame = trimmedFrames.reduce((prev, curr) => {
        return Math.abs(curr.timestamp - time) < Math.abs(prev.timestamp - time) ? curr : prev;
      });
      setCurrentFrame(closestFrame);
    }
  }, [trimmedFrames, currentTime, recordingTimeFrame]);

  return (
    <div>
      {currentFrame ? (
        <img
          src={currentFrame.link}
          alt={`Frame at ${currentFrame.timestamp}`}
        />
      ) : (
        <p>No frames available</p>
      )}
    </div>
  );
};

export default FramesComponent;
