import React from 'react';
import { HomeworkProps } from './types';


const Homework: React.FC<HomeworkProps> = ({ emptyLabelText }) => {
  return (
    <div className="w-full h-full">
      <div className="flex flex-col gap-5">
        <div>{emptyLabelText || 'No content yet'}</div>
        {/* TODO: uncomment when real data supplied */}
        {/* {tasks.map((task, index) => (
          <div
            key={index}
            className="flex gap-5"
          >
            {getStatusIcon(task.status)}
            <div className="flex flex-1 flex-col gap-2.5">
              <div className="flex justify-between items-center">
                <span className="text-gray-800 text-base font-semibold">{task.label}</span>
                {task.status === HomeworkStatus.COMPLETED && (
                  <span className="text-gray-500 text-xs">
                    {formatDateFromISO(task.completedAt)}
                  </span>
                )}
              </div>
              <span
                className="text-left"
                dangerouslySetInnerHTML={{ __html: task.text }}
              />

              {task.status === HomeworkStatus.NOT_STARTED && (
                <div className="flex justify-end px-1">
                  <Button
                    text="Assign"
                    size="sm"
                  />
                </div>
              )}
            </div>
          </div>
        ))} */}
      </div>
    </div>
  );
};

export default Homework;
