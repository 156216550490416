import { useEffect, useState, useContext, memo } from 'react';
import { useSelector } from '@xstate/react';

import { Patient, ShamefulAny, TherapistUser } from '@interfaces/index';
import { DailyAudio, DailyProvider, useDaily } from '@daily-co/daily-react';
import { pageUrlFromRoomUrl } from '../../../components/Daily.co/utils';
import { ValueOf } from '@utils/types';
import { AppContext } from '../../../contextApp';

import './DailyCo.css';
import { DailyEvent } from '@daily-co/daily-js';
import { Loader } from '@shared/ui/loader/Loader';
import { refaelaPatientActor } from '@components/xState/machines/refaelaPatientMachine';
import { refaelaTherapistActor } from '@components/xState/machines/refaelaTherapistMachine';
import AiCall from './AiCall/AiCall';

const DailyCoMeetComponentState = {
  IDLE: 'STATE_IDLE',
  CREATING: 'STATE_CREATING',
  JOINING: 'STATE_JOINING',
  JOINED: 'STATE_JOINED',
  LEAVING: 'STATE_LEAVING',
  ERROR: 'STATE_ERROR',
  HAIRCHECK: 'STATE_HAIRCHECK',
} as const;

const AiSession = ({
  actor,
  roomUrl,
  meetingToken,
}: {
  actor: typeof refaelaPatientActor | typeof refaelaTherapistActor;
  roomUrl: string;
  meetingToken: string;
}) => {
  const { isExitBioMode, setIsExitBioMode } = useContext(AppContext);
  const [showCallComponent, setShowCallComponent] = useState<boolean>(false);
  const [apiError] = useState<string | null>(null);
  const snapshot = useSelector(actor, (state) => state);
  const currentUser: Patient | TherapistUser | null = snapshot.context.currentUser ?? null;
  const userRole = currentUser?.role || '';

  const callObject = useDaily();
  const [dailyCoState, setDailyCoState] = useState<ValueOf<typeof DailyCoMeetComponentState>>(
    DailyCoMeetComponentState.IDLE
  );

  useEffect(() => {
    // Cleanup function to leave the call when component unmounts
    return () => {
      if (callObject) {
        callObject.leave();
      }
    };
  }, [callObject]);

  useEffect(() => {
    setIsExitBioMode(true);
  }, []);

  useEffect(() => {
    if (isExitBioMode) {
      setDailyCoState(DailyCoMeetComponentState.HAIRCHECK);
      setShowCallComponent(true);
    }
  }, [isExitBioMode]);

  useEffect(() => {
    const pageUrl = roomUrl && pageUrlFromRoomUrl(roomUrl);
    if (pageUrl === window.location.href) return;
    window.history.replaceState(null, '', pageUrl);
    return () => {
      const urlWithoutQuery = window.location.origin + window.location.pathname;
      window.history.replaceState(null, '', urlWithoutQuery);
    };
  }, [roomUrl]);

  useEffect(() => {
    if (!callObject) return;

    const events: DailyEvent[] = ['joined-meeting', 'left-meeting', 'error', 'camera-error'];

    function handleNewMeetingState() {
      switch (callObject?.meetingState()) {
        case 'joined-meeting':
          setDailyCoState(DailyCoMeetComponentState.JOINED);
          break;
        case 'left-meeting':
          callObject.leave().then(() => {
            setDailyCoState(DailyCoMeetComponentState.IDLE);
          });
          break;
        case 'error':
          setDailyCoState(DailyCoMeetComponentState.ERROR);
          break;
        default:
          break;
      }
    }

    // Use initial state

    handleNewMeetingState();

    events.forEach((event) => callObject.on(event, handleNewMeetingState));

    // Stop listening for changes in state
    return () => {
      events.forEach((event) => callObject.off(event, handleNewMeetingState));
    };
  }, [callObject]);

  const readyToCall = !apiError && isExitBioMode && showCallComponent;

  const dailyStateProcess = () => {
    switch (dailyCoState) {
      case DailyCoMeetComponentState.CREATING:
        return 'Connecting...';
      case DailyCoMeetComponentState.JOINING:
        return 'Connected. Joining...';
      case DailyCoMeetComponentState.IDLE:
        return 'Reload the page and try again';
      default:
        break;
    }
  };

  const isPatientWaitingRoom = snapshot.matches(
    'PatientVideoSession.PatientPretalkWaitingRoom' as ShamefulAny
  );

  return (
    <>
      {!isPatientWaitingRoom ? (
        <div className="w-full h-full">
          {!apiError && !readyToCall && (
            <div className="flex h-full justify-center space-x-4 items-center">
              <div>{dailyStateProcess()}</div>
              {dailyCoState !== DailyCoMeetComponentState.IDLE && <Loader />}
            </div>
          )}

          {apiError && (
            <div className="flex w-full flex-col h-full item justify-center">
              <h1 className="daily">Error</h1>
              <p>
                <b>{apiError}</b>
              </p>
            </div>
          )}

          {readyToCall && (
            <DailyProvider callObject={callObject}>
              <AiCall
                key="call-container-daily-session-co"
                userRole={userRole}
                roomUrl={roomUrl}
                meetingToken={meetingToken}
              />
              <DailyAudio />
            </DailyProvider>
          )}
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default memo(AiSession);
