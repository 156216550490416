import Button from '@components/Button';
import { refaelaTherapistActor } from '@components/xState/machines/refaelaTherapistMachine';
import { THERAPIST_MACHINE_EVENTS } from '@machines/therapist/constants/states';

const AISessionCardTitle = () => {
  const handleAISessionAssign = () => {
    refaelaTherapistActor.send({ type: THERAPIST_MACHINE_EVENTS.GO_TO_ASSIGN_AI_SESSION });
  };

  return (
    <div className="flex justify-between mb-4 items-center">
      <div className="text-xl sm_d:text-2xl truncate font-semibold">AI sessions</div>
      <Button
        className="truncate"
        variant="outlinedPrimary"
        text="Assign AI Session"
        onClick={handleAISessionAssign}
      />
    </div>
  );
};

export default AISessionCardTitle;
