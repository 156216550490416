import { apiClient } from '.';
import { Base64 } from '../hooks/useExtractFaceEmotions';

export interface ISendCameraFrameResponseBody {
  frame_url: string;
  message: string;
  timestamp: number;
}

export const sendCameraFrame = async (
  userId: string,
  base64: Base64,
  timestamp: number | null = null
): Promise<ISendCameraFrameResponseBody> => {
  const response = await apiClient.post<ISendCameraFrameResponseBody>(
    `/frame/${userId}`,
    {
      base64,
      timestamp,
    },
    {
      headers: {
        authorization: import.meta.env.VITE_VF_DM_API_KEY,
      },
    }
  );

  return response.data;
};

export const sendAICameraFrame = async (
  userId: string,
  sessionId: string,
  base64: Base64,
  timestamp: number | null = null,
  signal?: AbortSignal
): Promise<ISendCameraFrameResponseBody> => {
  const response = await apiClient.post<ISendCameraFrameResponseBody>(
    `/frame/ai/${userId}`,
    {
      base64,
      timestamp,
      sessionId,
    },
    {
      headers: {
        authorization: import.meta.env.VITE_VF_DM_API_KEY,
      },
      signal,
    }
  );

  return response.data;
};

export const sendCameraFrameTherapist = async (
  userId: string,
  patientId: string,
  sessionId: string,
  base64: Base64,
  timestamp: number | null = null
): Promise<ISendCameraFrameResponseBody> => {
  const response = await apiClient.post<ISendCameraFrameResponseBody>(
    `/frame/therapist/${userId}`,
    {
      base64,
      timestamp,
      patientId,
      sessionId,
    },
    {
      headers: {
        authorization: import.meta.env.VITE_VF_DM_API_KEY,
      },
    }
  );

  return response.data;
};

export const sendCameraFramePatient = async (
  userId: string,
  sessionId: string,
  base64: Base64,
  timestamp: number | null = null
): Promise<ISendCameraFrameResponseBody> => {
  const response = await apiClient.post<ISendCameraFrameResponseBody>(
    `/frame/patient/${userId}`,
    {
      base64,
      timestamp,
      sessionId,
    },
    {
      headers: {
        authorization: import.meta.env.VITE_VF_DM_API_KEY,
      },
    }
  );

  return response.data;
};

export async function getFrames(
  role: string,
  patientId: string,
  therapistId: string,
  sessionId: string
): Promise<any> {
  try {
    const params = {
      role,
      sessionId,
      patientId,
      therapistId,
    };

    const { data } = await apiClient.get('/frame', { params });

    return data;
  } catch (e: any) {
    console.error('Error fetching frames:', e.message, e.details);

    return {
      error: true,
      message: `${e.message}: ${e.details || 'No additional details'}`,
    };
  }
}
