import React, {
  useEffect,
  useState,
  useRef,
  KeyboardEvent,
  WheelEvent,
  useCallback,
  useMemo,
} from 'react';
import { useSpring, animated, useTrail } from '@react-spring/web';

import TimelineTooltipContent from './TimelineTooltipContent';

import TimelineDateDisplay from './TimelineToDisplay';
import { Patient } from '@interfaces/index';
import { CalendarIcon, ClockIcon } from '../../assets/icons';
import { refaelaTherapistActor } from '@components/xState/machines/refaelaTherapistMachine';
import { useDateTimeUpdater } from '@hooks/useDateTimeUpdate';
import { getPositionOnTimelineByTime } from './model/utils/getPositionOnTimelineByHour';
import { delay } from '@utils/helpers';

export interface TimelineProps {
  startHour?: number;
  endHour?: number;
  patients?: Patient[];
  isTimelineOpen: boolean;
}

const TherapistTimeline: React.FC<TimelineProps> = ({
  startHour = 6,
  endHour = 24,
  patients = [],
  isTimelineOpen,
}) => {
  const [dotTooltip, setDotTooltip] = useState<null | {
    x: number;
    y: number;
    content: JSX.Element;
  }>(null);
  const [nameTooltip, setNameTooltip] = useState<null | {
    x: number;
    y: number;
    content: JSX.Element;
  }>(null);
  const [isNameTooltipHovered, setIsNameTooltipHovered] = useState(false);
  const [isDotTooltipHovered, setIsDotTooltipHovered] = useState(false);
  const [offset, setOffset] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);
  const pathRef = useRef<SVGPathElement>(null);
  const svgRef = useRef<SVGSVGElement>(null);
  const [, setIsPathInitialized] = useState(false);

  const [isVisible, setIsVisible] = useState<boolean>(false);

  const { currentDateTime, currentTimelineDateTime, setCurrentTimelineDateTime } =
    useDateTimeUpdater(1);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  useEffect(() => {
    if (pathRef.current) {
      setIsPathInitialized(true);
    }
  }, [pathRef.current, currentTimelineDateTime]);

  const handleScroll = useCallback((deltaY: number) => {
    setCurrentTimelineDateTime((prev) => {
      const newDate = new Date(prev);
      newDate.setDate(prev.getDate() + (deltaY > 0 ? 1 : -1));
      return newDate;
    });
    setOffset((prevOffset) => prevOffset + (deltaY > 0 ? 1 : -1));
    setNameTooltip(null);
    setDotTooltip(null);
  }, []);

  const handleDateChange = (event: KeyboardEvent<HTMLDivElement> | WheelEvent<HTMLDivElement>) => {
    if (event.type === 'keydown') {
      const { key } = event as KeyboardEvent<HTMLDivElement>;
      const direction = key === 'ArrowLeft' ? -1 : key === 'ArrowRight' ? 1 : 0;
      if (direction !== 0) {
        setCurrentTimelineDateTime((prev) => {
          const newDate = new Date(prev);
          newDate.setDate(prev.getDate() + direction);
          return newDate;
        });
        setOffset((prevOffset) => prevOffset + direction);
      }
    } else if (event.type === 'wheel') {
      handleScroll((event as WheelEvent<HTMLDivElement>).deltaY);
    }
  };

  const formattedTimelineDate = currentTimelineDateTime.toLocaleDateString();
  const formattedDateToDisplay = new Intl.DateTimeFormat('en-US', {
    weekday: 'short',
    month: 'short',
    day: 'numeric',
  }).format(currentTimelineDateTime);
  const formattedTimelineTimetoDisplay = currentDateTime.toLocaleString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  });

  //TODO: move to shared utils
  const getSessionDate = (timestamp: number | string) => new Date(timestamp).toLocaleDateString();
  const getSessionTime = (timestamp: number | string) => new Date(timestamp).getTime();

  const handleDotHover = (
    event: React.MouseEvent<SVGCircleElement>,
    patient: Patient,
    sessionId: string,
    sessionTimestamp: number | string,
    point: any
  ) => {
    if (!event.currentTarget.ownerSVGElement) return;
    const sessionDate = getSessionDate(sessionTimestamp);
    const sessionTime = getSessionTime(sessionTimestamp);
    const realCurrentTime = currentDateTime.getTime();
    const realCurrentDate = currentDateTime.toLocaleDateString();
    let content;

    if (
      sessionDate < realCurrentDate ||
      (sessionDate === realCurrentDate && sessionTime < realCurrentTime)
    ) {
      content = (
        <TimelineTooltipContent
          text={'Review session'}
          onActionClick={() =>
            refaelaTherapistActor.send({
              type: 'goToSessionReplayByTherapist',
              patientId: patient.patientId,
              sessionId: sessionId,
            })
          }
          bgColor={'bg-gray-100'}
          textColor={'text-gray-800 hover:bg-gray-200'}
        />
      );
    } else if (sessionDate === realCurrentDate && sessionTime <= realCurrentTime + 30 * 60 * 1000) {
      content = (
        <TimelineTooltipContent
          text={'Start session'}
          onActionClick={() =>
            refaelaTherapistActor.send({ type: 'goToTherapistPretalkWaitingRoom' })
          }
          bgColor={'bg-violet'}
          textColor={'text-white'}
          additionalStyles={'hover:brightness-80'}
        />
      );
    } else {
      content = (
        <TimelineTooltipContent
          text={'Review session'}
          onActionClick={() =>
            refaelaTherapistActor.send({
              type: 'goToSessionReplayByTherapist',
              patientId: patient.patientId,
              sessionId: sessionId,
            })
          }
          bgColor={'bg-gray-100'}
          textColor={'text-gray-800 hover:bg-gray-200'}
        />
      );
    }
    // SVG position and HTML position is different by scaling so on large resolutions
    // it calculates differently, this is a hack to prevent tooltip diplsay far from target
    setDotTooltip({
      x: document.body.clientWidth >= 2560 ? event.clientX : point.x,
      y: document.body.clientWidth >= 2560 ? point.y / 2 + 40 : point.y - 120,
      content,
    });
  };

  // //To do Y x axis align
  const handleNameHover = (
    event: React.MouseEvent<SVGTextElement>,
    patient: Patient,
    point: any
  ) => {
    if (!event.currentTarget.ownerSVGElement) return;

    const { top } = event.currentTarget.getBoundingClientRect();

    const tooltipYPosition = top + 100 > document.body.offsetHeight ? point.y - 150 : point.y - 10;

    // SVG position and HTML position is different by scaling so on large resolutions
    // it calculates differently, this is a hack to prevent tooltip diplsay far from target
    setNameTooltip({
      x: document.body.clientWidth >= 2560 ? event.clientX : point.x + 2,
      y: document.body.clientWidth >= 2560 ? point.y / 2 + 170 : tooltipYPosition,

      content: (
        <TimelineTooltipContent
          text={'Patient timeline'}
          onActionClick={() =>
            refaelaTherapistActor.send({ type: 'goToPatientView', patientId: patient.patientId })
          }
          bgColor={'bg-white'}
          textColor={'text-violet'}
          additionalStyles={'border border-violet'}
        />
      ),
    });
  };

  const handleNameTooltipClose = (event: React.MouseEvent<SVGElement>) => {
    const relatedTarget = event.relatedTarget as HTMLElement;
    if (isNameTooltipHovered || relatedTarget.dataset?.tooltip) return;
    setNameTooltip(null);
  };

  const handleDotTooltipClose = (event: React.MouseEvent<SVGElement>) => {
    const relatedTarget = event.relatedTarget as HTMLElement;
    if (isDotTooltipHovered || relatedTarget.dataset?.tooltip) return;
    setDotTooltip(null);
  };

  const handleTooltipBlur = () => {
    if (isNameTooltipHovered || isDotTooltipHovered) return;
    setDotTooltip(null);
    setNameTooltip(null);
  };

  const getLineGradientOffset = useCallback(() => {
    if (currentTimelineDateTime.toLocaleDateString() !== currentDateTime.toLocaleDateString()) {
      return currentTimelineDateTime < currentDateTime ? 100 : 0;
    }

    // get minutes
    const startOfDay = startHour * 60;
    const endOfDay = endHour * 60;

    const currentHours = currentDateTime.getHours();
    const currentMinutes = currentDateTime.getMinutes();
    const currentTotalMinutes = currentHours * 60 + currentMinutes;

    const minutesSinceStartOfDay = currentTotalMinutes - startOfDay;
    const totalMinutesInScope = endOfDay - startOfDay;

    return Math.min(100, (minutesSinceStartOfDay / totalMinutesInScope) * 100);
  }, [currentDateTime, currentTimelineDateTime, startHour, endHour]);

  const generatePath = useCallback(
    (svgWidth: number) => {
      const points = 500;
      const path = [];

      for (let i = 0; i <= points; i++) {
        const x = (i / points) * svgWidth;
        const y =
          150 +
          Math.sin(((i / points) * 10 + offset) * 0.5) * 50 +
          Math.sin(((i / points) * 10 + offset) * 0.2) * 30 +
          Math.sin(((i / points) * 10 + offset) * 0.1) * 20;

        path.push(`${i === 0 ? 'M' : 'L'} ${x.toFixed(2)},${y.toFixed(2)}`);
      }

      return path.join(' ');
    },

    [offset]
  );

  const handleIconClick = async () => {
    const value = Math.abs(offset);
    for (let i = value; i >= 0; i--) {
      currentTimelineDateTime !== currentDateTime &&
        currentTimelineDateTime > currentDateTime &&
        setCurrentTimelineDateTime((prev) => {
          const newDate = new Date(prev);
          newDate.setDate(prev.getDate() - 1);
          return newDate;
        });
      if (
        currentTimelineDateTime.getDate() !== currentDateTime.getDate() &&
        currentTimelineDateTime.getDate() < currentDateTime.getDate()
      ) {
        setCurrentTimelineDateTime((prev) => {
          const newDate = new Date(prev);
          newDate.setDate(prev.getDate() + 1);
          return newDate;
        });
      }

      setOffset(i);
      await delay(50);
    }
    setCurrentTimelineDateTime(new Date());
  };

  const lineGradientOffset = useMemo(() => getLineGradientOffset(), [getLineGradientOffset]);
  const pathLength = containerRef.current?.offsetWidth || 1500;
  const [animatedDarkStyle, setAnimatedDarkStyle] = useSpring(() => ({
    strokeDashoffset: pathLength - (pathLength * lineGradientOffset) / 100,
    from: { strokeDashoffset: pathLength },
    config: { duration: 1000 },
  }));

  const [animatedBrightStyle, setAnimatedBrightStyle] = useSpring(() => ({
    strokeDashoffset: 0,
    from: { strokeDashoffset: pathLength },
    config: { duration: 1000 },
  }));

  const animatedBrightProps = useSpring({
    strokeDashoffset: 0,
    from: { strokeDashoffset: pathLength },
    config: { duration: 1000 },
  });

  const animatedDarkProps = useSpring({
    strokeDashoffset: pathLength - (pathLength * lineGradientOffset) / 100,
    from: { strokeDashoffset: pathLength },
    config: { duration: 1000 },
  });
  const animatedBrightPropsClosing = useSpring({
    strokeDashoffset: pathLength,
    from: { strokeDashoffset: 0 },
    config: { duration: 1000 },
  });

  const animatedDarkPropsClosing = useSpring({
    strokeDashoffset: pathLength,
    from: { strokeDashoffset: pathLength - (pathLength * lineGradientOffset) / 100 },
    config: { duration: 1000 },
  });

  useEffect(() => {
    if (isTimelineOpen) {
      setAnimatedDarkStyle(animatedDarkProps);
      setAnimatedBrightStyle(animatedBrightProps);
    } else {
      setAnimatedDarkStyle(animatedDarkPropsClosing);
      setAnimatedBrightStyle(animatedBrightPropsClosing);
    }
  }, [
    animatedBrightProps,
    animatedBrightPropsClosing,
    animatedDarkProps,
    animatedDarkPropsClosing,
    isTimelineOpen,
    setAnimatedBrightStyle,
    setAnimatedDarkStyle,
  ]);

  const trail = useTrail(patients.length, {
    opacity: isTimelineOpen ? 1 : 0,
    transform: isTimelineOpen ? 'translate(0, 0)' : 'translate(-50%, -50%)',
    config: { tension: 150, friction: 80, precision: 0.15 },
    from: { opacity: 0, transform: 'translate(0, 0)' },
  });

  const sessionPositions = patients.map((patient) => {
    const session = patient.sessions.find(
      (session) => getSessionDate(session.timestamp) === formattedTimelineDate
    );

    const point = session
      ? getPositionOnTimelineByTime(
          new Date(session.timestamp),
          pathLength,
          offset,
          startHour,
          endHour
        )
      : { x: 0, y: 0 };

    return { patient, session, point };
  });

  const sessionElements = trail.map((animation, index) => {
    if (!pathRef.current || !svgRef.current) return null;
    const { patient, session, point } = sessionPositions[index];
    if (!session) return null;

    const sessionDateTime = new Date(session.timestamp);
    const now = new Date();

    const isPast = sessionDateTime.getTime() < now.getTime();
    const isCurrent =
      sessionDateTime.getTime() <= now.getTime() + 30 * 60 * 1000 &&
      sessionDateTime.getTime() > now.getTime();

    const dotColor = isCurrent ? '#C084FC' : isPast ? '#1F2937' : '#D1D5DB';

    // prevents overlap
    const yAxisTextPosition =
      point.y + 40 > svgRef.current.clientHeight ? point.y - 35 : point.y + 40;

    return (
      <animated.g
        key={index}
        style={animation}
      >
        <circle
          cx={`${point.x}px`}
          cy={`${point.y}px`}
          r="8"
          fill={dotColor}
          onMouseEnter={(event) =>
            handleDotHover(event, patient, session.sessionId, session.timestamp, point)
          }
          onMouseLeave={handleDotTooltipClose}
        />

        <text
          x={`${point.x}px`}
          y={`${yAxisTextPosition}px`}
          className="hover:text-gray-300 hover:font-bold cursor-pointer overflow-visible"
          textAnchor="middle"
          fontSize="15"
          fontFamily="Inter, sans-serif"
          fontWeight="600"
          fill="currentColor"
          onMouseEnter={(event) => handleNameHover(event, patient, point)}
          onMouseLeave={handleNameTooltipClose}
        >
          {patient.name}
        </text>
      </animated.g>
    );
  });

  const { x: calculatedTranslateX, y: calculatedTranslateY } = getPositionOnTimelineByTime(
    currentDateTime,
    pathLength,
    offset,
    startHour,
    endHour
  );

  const calculatedNameTooltip =
    (nameTooltip &&
      svgRef.current &&
      (nameTooltip.y + 40 > svgRef.current?.clientHeight
        ? nameTooltip?.y - 35
        : nameTooltip.y + 40)) ||
    0;

  return (
    <animated.div
      ref={containerRef}
      className={`flex outline-none flex-col justify-end transition-all duration-300 ease-in-out transform ${isVisible ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-4'}`}
      style={{ width: '100vw' }}
      onClick={handleTooltipBlur}
      tabIndex={0}
      onKeyDown={handleDateChange}
      onWheel={handleDateChange}
    >
      <div className="w-full text-gray-800">
        <svg
          ref={svgRef}
          className="w-full h-[14rem] md_d:h-[18rem] xl_d:h-[23rem]"
          viewBox={`0 0 ${pathLength} 300`}
          preserveAspectRatio="xMaxYMid slice"
          shapeRendering="geometricPrecision"
        >
          <defs>
            <linearGradient id="lineGradient">
              <stop
                offset="0%"
                stopColor="#1F2937"
              />
              <stop
                offset={`${lineGradientOffset}%`}
                stopColor="#1F2937"
              />
              <stop
                offset={`${lineGradientOffset}%`}
                stopColor="#D1D5DB"
              />
              <stop
                offset="100%"
                stopColor="#D1D5DB"
              />
            </linearGradient>
          </defs>
          <animated.path
            ref={pathRef}
            d={generatePath(pathLength)}
            fill="none"
            stroke="#D1D5DB"
            strokeWidth="3"
            strokeDasharray={pathLength}
            style={animatedDarkStyle}
          />
          <animated.path
            ref={pathRef}
            d={generatePath(pathLength)}
            fill="none"
            stroke="url(#lineGradient)"
            strokeWidth="3"
            strokeDasharray={pathLength}
            style={animatedBrightStyle}
          />
          {currentTimelineDateTime.toLocaleDateString() == currentDateTime.toLocaleDateString() && (
            <g transform={`translate(${calculatedTranslateX}, ${calculatedTranslateY - 10})`}>
              <foreignObject
                width="170"
                height="50"
                x="-50"
                y="-65"
              >
                <TimelineDateDisplay
                  padding="px-[30px]"
                  icon={<ClockIcon className="w-3.5 h-3.5" />}
                  text={formattedTimelineTimetoDisplay}
                />
              </foreignObject>
            </g>
          )}
          {sessionElements}
        </svg>
        <div className="text-center mt-4">
          <TimelineDateDisplay
            icon={
              <CalendarIcon
                className="w-3.5 h-3.5"
                onClick={handleIconClick}
              />
            }
            text={formattedDateToDisplay}
          />
        </div>
      </div>
      {dotTooltip && (
        <div
          data-tooltip
          style={{
            position: 'absolute',
            top: dotTooltip.y + 50,
            left: dotTooltip.x - 10,
            paddingLeft: 30,
            paddingBottom: 10,
            zIndex: 10,
          }}
          onMouseEnter={() => {
            setIsDotTooltipHovered(true);
          }}
          onMouseLeave={() => {
            setIsDotTooltipHovered(false);
            setDotTooltip(null);
          }}
        >
          {dotTooltip.content}
        </div>
      )}
      {nameTooltip && (
        <div
          data-tooltip
          style={{
            position: 'absolute',
            top: calculatedNameTooltip - 90,
            left: nameTooltip.x - 10,
            zIndex: 10,
            paddingLeft: 30,
            paddingBottom: 40,
          }}
          onMouseEnter={() => {
            setIsNameTooltipHovered(true);
          }}
          onMouseLeave={() => {
            setIsNameTooltipHovered(false);
            setNameTooltip(null);
          }}
        >
          {nameTooltip.content}
        </div>
      )}
    </animated.div>
  );
};

export default TherapistTimeline;
