import React, { useState, useRef, useEffect } from 'react';

const useResizable = (initialHeight: number = 101) => {
  const [height, setHeight] = useState(initialHeight);

  const isResizing = useRef(false);
  const startY = useRef(0);
  const startHeight = useRef(initialHeight);

  const handleMouseDown = (e: React.MouseEvent) => {
    isResizing.current = true;
    startY.current = e.clientY;
    startHeight.current = height;
    document.addEventListener('mousemove', handleMouseMove as EventListener);
    document.addEventListener('mouseup', handleMouseUp);
  };

  const handleMouseMove = (e: MouseEvent) => {
    if (isResizing.current) {
      const newHeight = startHeight.current + (e.clientY - startY.current);
      setHeight(newHeight > 50 ? newHeight : 50);
    }
  };

  const handleMouseUp = () => {
    isResizing.current = false;
    document.removeEventListener('mousemove', handleMouseMove as EventListener);
    document.removeEventListener('mouseup', handleMouseUp);
  };

  useEffect(() => {
    return () => {
      document.removeEventListener('mousemove', handleMouseMove as EventListener);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, []);

  return { height, handleMouseDown };
};

export default useResizable;
