import React, { useEffect, useMemo, useRef, useState } from 'react';
import Uplot from 'uplot';
import UplotReact from 'uplot-react';
import { ShenaiSdkDataI } from '@shared/types/shenai/sdk';
import { getTabVisibilityStateValue } from '@components/Plot/utils';
import { VisibilityState } from '@components/Plot/types';

const TabVisibilityPlot: React.FC<{
  tabVisibilityValue: VisibilityState;
  sdkData: ShenaiSdkDataI | null;
}> = ({ tabVisibilityValue, sdkData }) => {
  const [visibilityStates, setVisibilityStates] = useState<
    { timestamp: number; state: VisibilityState }[]
  >([]);
  const [chartSize, setChartSize] = useState<{ width: number; height: number }>({
    width: 400,
    height: 150,
  });
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      if (entries[0].contentRect) {
        const { width, height } = entries[0].contentRect;
        setChartSize({ width, height });
      }
    });

    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        resizeObserver.unobserve(containerRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (tabVisibilityValue !== undefined) {
      const timestamp = Date.now();
      setVisibilityStates((prev) => {
        const updated = [...prev, { timestamp, state: tabVisibilityValue as VisibilityState }];

        const twoMinutesAgo = Date.now() - 120000;
        return updated.filter((entry) => entry.timestamp >= twoMinutesAgo);
      });
    }
  }, [tabVisibilityValue, sdkData]);

  const data = useMemo(() => {
    const timestamps = visibilityStates.map((entry) => entry.timestamp);
    const states = visibilityStates.map((entry) => entry.state);

    if (timestamps.length === 0) {
      return [[0], [0]] as Uplot.AlignedData;
    }

    return [timestamps, states] as Uplot.AlignedData;
  }, [visibilityStates]);

  const chartOptions: Uplot.Options = {
    width: chartSize.width,
    height: chartSize.height,
    padding: [0, 0, 0, 0],
    scales: {
      x: {
        time: true,
      },
      y: {
        auto: true,
        min: -1,
        max: 2,
      },
    },
    series: [
      {},
      {
        label: 'Visibility State',
        stroke: '#9333ea',
        value: (_, v) => getTabVisibilityStateValue(v as VisibilityState),
        spanGaps: true,
      },
    ],
    axes: [
      {
        stroke: '#9ca3af',
        grid: { show: true, stroke: '#e5e7eb' },
        values: (_uplot, ticks) => ticks.map((tick) => new Date(tick).toLocaleTimeString()),
      },
      {
        stroke: '#9ca3af',
        grid: { show: true, stroke: '#e5e7eb' },
      },
    ],
    legend: {
      show: false,
    },
    hooks: {
      ready: [
        (u) => {
          const tooltip = document.createElement('div');
          tooltip.style.position = 'absolute';
          tooltip.style.pointerEvents = 'none';
          tooltip.style.backgroundColor = 'rgba(255, 255, 255, 0.8)';
          tooltip.style.padding = '5px';
          tooltip.style.borderRadius = '4px';
          tooltip.style.fontSize = '11px';
          tooltip.style.boxShadow = '0 2px 4px rgba(0, 0, 0, 0.2)';
          tooltip.style.display = 'none';
          u.root.appendChild(tooltip);

          u.over.addEventListener('mousemove', (e) => {
            const { left, top } = u.over.getBoundingClientRect();
            const x = e.clientX - left;
            const y = e.clientY - top;
            const idx = u.posToIdx(x);

            tooltip.style.display = 'block';
            tooltip.style.left = `${x + 5}px`;
            tooltip.style.top = `${y + 5}px`;

            const value = u.data[1][idx];
            tooltip.textContent = `Visibility State: ${getTabVisibilityStateValue(value!)}`;
          });

          u.over.addEventListener('mouseleave', () => {
            tooltip.style.display = 'none';
          });
        },
      ],
    },
  };

  return (
    <div
      ref={containerRef}
      style={{ width: '100%', height: '150px', position: 'relative' }}
    >
      <UplotReact
        data={data}
        options={chartOptions}
      />
    </div>
  );
};

export default TabVisibilityPlot;
