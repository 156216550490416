import {
  Armchair,
  //  Calendar,
  House,
  // Settings,
  GlobeLock,
  Users,
  Video,
} from 'lucide-react';

import { refaelaTherapistActor } from '@components/xState/machines/refaelaTherapistMachine';
import { iconProps } from '../../../model/constants/iconSettings';

export const ROOT_ACTIONS = {
  TherapistPretalkWaitingRoom: {
    icon: <Armchair {...iconProps} />,
    name: 'Calibration',
    onClick: () => refaelaTherapistActor.send({ type: 'goToTherapistOffice' }),
  },
  TherapistDebriefWaitingRoom: {
    icon: <Armchair {...iconProps} />,
    name: 'Wrap up',
    onClick: () => refaelaTherapistActor.send({ type: 'goToTherapistOffice' }),
  },
  TherapistSession: {
    icon: <Video {...iconProps} />,
    name: 'Session',
    onClick: () => refaelaTherapistActor.send({ type: 'goToTherapistSession' }),
  },
  default: {
    icon: <House {...iconProps} />,
    name: 'Home page',
    onClick: () => refaelaTherapistActor.send({ type: 'goToTherapistOffice' }),
  },
};

// TODO: reveal calendar button and settings when functionality appear
export const ACTIONS = [
  // {
  //   icon: <Calendar {...iconProps} />,
  //   name: 'Calendar',
  //   isDisplayed: true,
  //   onClick: () => refaelaTherapistActor.send({ type: 'goToTherapistCalendar' }),
  // },
  {
    icon: <Users {...iconProps} />,
    name: 'Patient List',
    isDisplayed: true,
    onClick: () => refaelaTherapistActor.send({ type: 'goToPatientList' }),
  },
  {
    icon: <GlobeLock {...iconProps} />,
    name: 'Terms Of Use',
    isDisplayed: true,
    onClick: () => refaelaTherapistActor.send({ type: 'goToTermsOfUse' }),
  },
  // {
  //   icon: <Settings {...iconProps} />,
  //   name: 'Settings',
  //   isDisplayed: true,
  //   onClick: () => refaelaTherapistActor.send({ type: 'goToTherapistSettings' }),
  // },
];
