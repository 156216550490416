import { useSelector } from '@xstate/react';
// xState
import { refaelaPatientActor } from '@components/xState/machines/refaelaPatientMachine';
// Constants
import { PATIENT_MACHINE_EVENTS } from '@machines/patient/constants';
// Components
import Button from '@components/Button';

const GreetingPatientPage = () => {
  const currentUser = useSelector(refaelaPatientActor, (state) => state.context.currentUser);

  const { areTermsOfPrivacyAccepted } = currentUser || {};

  const handleJoinSessionButton = () => {
    if (!areTermsOfPrivacyAccepted) {
      refaelaPatientActor.send({ type: PATIENT_MACHINE_EVENTS.GO_TO_PATIENT_CONCENT_DATA_USAGE });
    }
  };

  return (
    <div className="flex flex-col gap-y-5 w-full h-full justify-center items-center">
      <div>
        <h4 className="text-center text-gray-800 text-4xl font-bold font-['Inter'] tracking-tight">
          {currentUser?.name ? `Hello, ${currentUser?.name}`! : 'Hello!'}
        </h4>
      </div>
      <div>
        <span className="text-center text-black text-lg font-normal font-['Inter'] leading-normal">
          Join the session you’ve been invited to
        </span>
      </div>
      <Button
        className="mt-2 w-fit"
        variant="primary"
        text="Join the Session"
        onClick={handleJoinSessionButton}
      />
    </div>
  );
};

export default GreetingPatientPage;
