import clsx from 'clsx';

type ChipProps = {
  label: string;
  bgColor?: string;
  textColor?: string;
  onClick?: () => void;
};

export const Chip = ({ label, bgColor, textColor, onClick }: ChipProps) => {
  const outerStyle = bgColor ? { backgroundColor: bgColor } : undefined;
  const innerStyle = textColor ? { color: textColor } : undefined;
  return (
    <div
      onClick={onClick}
      style={outerStyle}
      className={clsx(
        'inline-flex rounded-br-30 px-4 py-1',
        `${bgColor ? `bg-[${bgColor}]` : 'bg-transparent'} ${onClick ? 'cursor-pointer' : 'cursor-default'}`,
        {}
      )}
    >
      <span
        style={innerStyle}
        className={`truncate ${textColor ? `text-[${textColor}]` : 'text-black'}`}
      >
        {label}
      </span>
    </div>
  );
};
