import { ReactNode } from 'react';
import { CloseIcon } from '@assets/icons';
import Portal from './Portal';

type ModalProps = {
  isOpen: boolean;
  title?: string;
  withCloseIcon?: boolean;
  withTitleDivider?: boolean;
  controls?: ReactNode;
  paperClass?: string;
  onCancel?: () => void;
  children?: ReactNode;
};

export const Modal = ({
  isOpen,
  onCancel,
  title,
  withCloseIcon = true,
  withTitleDivider = true,
  controls,
  children,
  paperClass = '',
}: ModalProps) => {
  if (!isOpen) return null;
  return (
    <>
      <Portal>
        <div
          className="fixed top-0 right-0 bottom-0 left-0 z-1 p-9 box-border flex justify-center items-center"
          role="dialog"
        >
          <div
            className="absolute top-0 right-0 bottom-0 left-0 cursor-pointer bg-overlay"
            onClick={onCancel}
          />
          <div
            className={`absolute flex flex-col bg-white shadow-modal rounded-br-20 p-12 max-h-[800px] w-[530px] ${paperClass}`}
          >
            {title && (
              <>
                <div className="flex justify-between items-center font-semibold text-2xl mb-5">
                  {title}
                  {withCloseIcon && (
                    <div
                      className="cursor-pointer mr-[-0.375rem]" //persist on the right edge position
                      onClick={onCancel}
                    >
                      <CloseIcon />
                    </div>
                  )}
                </div>
                {withTitleDivider && (
                  <div className="bg-purple-600 rounded-md h-[3px] w-1/4 mb-6" />
                )}
              </>
            )}
            <div className="h-[inherit] flex-grow content">{children}</div>
            {controls && <div className="mt-auto controls">{controls}</div>}
          </div>
        </div>
      </Portal>
    </>
  );
};
