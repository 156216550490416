import React, { createContext, useState, useRef } from 'react';

import { EventLog, EventLogType } from './models/eventLog';
import { UserVFState } from '@interfaces/index';
interface PatientCardModalState {
  isOpen: boolean;
  patientId: string;
}
export interface AppContextValue {
  step: number;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  openVideoModal: boolean;
  setOpenVideoModal: React.Dispatch<React.SetStateAction<boolean>>;
  openPatientCardModal: PatientCardModalState;
  setOpenPatientCardModal: React.Dispatch<React.SetStateAction<PatientCardModalState>>;
  isBioComponentReady: boolean;
  setIsBioComponentReady: React.Dispatch<React.SetStateAction<boolean>>;
  isBioCalibrated: boolean;
  setIsBioCalibrated: React.Dispatch<React.SetStateAction<boolean>>;
  addEventLog: (_: EventLog[]) => void;
  getEventLogs: () => EventLog[];
  clearEventLogs: () => void;
  isMuted: boolean;
  setIsMuted: React.Dispatch<React.SetStateAction<boolean>>;
  isLogsSending: boolean;
  setIsLogsSending: React.Dispatch<React.SetStateAction<boolean>>;
  isMessageAnimationCompleted: boolean;
  setIsMessageAnimationCompleted: React.Dispatch<React.SetStateAction<boolean>>;
  showFixedMedia: boolean;
  setShowFixedMedia: React.Dispatch<React.SetStateAction<boolean>>;
  isExitBioMode: boolean;
  setIsExitBioMode: React.Dispatch<React.SetStateAction<boolean>>;
  isDailyEnabled: boolean;
  setIsDailyEnabled: React.Dispatch<React.SetStateAction<boolean>>;
  isVideoReady: boolean;
  setVideoReady: React.Dispatch<React.SetStateAction<boolean>>;
  videoUrl: string;
  setVideoUrl: React.Dispatch<React.SetStateAction<string>>;
  patientToMeetId: string;
  setPatientToMeetId: React.Dispatch<React.SetStateAction<string>>;
  isRTL: boolean;
  setIsRTL: React.Dispatch<React.SetStateAction<boolean>>;
  frameUrl: string;
  setFrameUrl: React.Dispatch<React.SetStateAction<string>>;
  microphoneId: string;
  setMicrophoneId: React.Dispatch<React.SetStateAction<string>>;
  cameraId: string;
  setCameraId: React.Dispatch<React.SetStateAction<string>>;
  direction: string;
  setDirection: React.Dispatch<React.SetStateAction<string>>;
  userVfState: UserVFState | {};
  setUserVfState: React.Dispatch<React.SetStateAction<UserVFState | {}>>;
}

export const AppContext = createContext<AppContextValue>({
  step: 0,
  setStep: () => {},
  openVideoModal: false,
  setOpenVideoModal: () => {},
  openPatientCardModal: { isOpen: false, patientId: '' },
  setOpenPatientCardModal: () => {},
  isBioComponentReady: false,
  setIsBioComponentReady: () => {},
  isBioCalibrated: false,
  setIsBioCalibrated: () => {},
  isExitBioMode: false,
  setIsExitBioMode: () => {},
  addEventLog: () => {},
  getEventLogs: () => [],
  clearEventLogs: () => {},
  isMuted: false,
  setIsMuted: () => {},
  isMessageAnimationCompleted: false,
  setIsMessageAnimationCompleted: () => {},
  showFixedMedia: false,
  setShowFixedMedia: () => {},
  isVideoReady: false,
  setVideoReady: () => {},
  isDailyEnabled: false,
  setIsDailyEnabled: () => {},
  videoUrl: '',
  setVideoUrl: () => {},
  patientToMeetId: '',
  setPatientToMeetId: () => {},
  isRTL: false,
  setIsRTL: () => {},
  frameUrl: '',
  setFrameUrl: () => {},
  microphoneId: '',
  setMicrophoneId: () => {},
  cameraId: '',
  setCameraId: () => {},
  direction: '',
  setDirection: () => {},
  isLogsSending: true,
  setIsLogsSending: () => {},
  setUserVfState: () => {},
  userVfState: {},
});

export const AppProvider = ({ children }: React.PropsWithChildren) => {
  const [step, setStep] = useState(0);
  const [openVideoModal, setOpenVideoModal] = useState(false);
  const [openPatientCardModal, setOpenPatientCardModal] = useState({
    isOpen: false,
    patientId: '',
  }); //user_2biGXbhZVheEcj5UGJK9Q0EpDC0' me
  const [isBioComponentReady, setIsBioComponentReady] = useState(false);
  const [isBioCalibrated, setIsBioCalibrated] = useState(false);
  const [isVideoReady, setVideoReady] = useState(false);
  const [videoUrl, setVideoUrl] = useState('');
  const eventLogsRef = useRef<EventLog[]>([]);
  const [isMuted, setIsMuted] = useState(true);
  const [isLogsSending, setIsLogsSending] = useState(true);
  const [isMessageAnimationCompleted, setIsMessageAnimationCompleted] = useState(false);
  const [showFixedMedia, setShowFixedMedia] = useState(false);
  const [isExitBioMode, setIsExitBioMode] = useState(false);
  const [isDailyEnabled, setIsDailyEnabled] = useState(true);
  const [isRTL, setIsRTL] = useState(false);
  const [frameUrl, setFrameUrl] = useState<string>('');
  const [patientToMeetId, setPatientToMeetId] = useState<string>('');
  const [cameraId, setCameraId] = useState<string>('');
  const [microphoneId, setMicrophoneId] = useState<string>('');
  const [direction, setDirection] = useState<string>('');
  const [userVfState, setUserVfState] = useState<UserVFState | {}>({});

  const addEventLog = (logs: EventLog[]) => {
    const transriptLog = logs.find((log) => log.type === EventLogType.VF_TRANSCRIPTS);
    // Replace a single transcripts log by new data
    const newLogs = transriptLog
      ? [
          ...eventLogsRef.current.filter((log) => log.type !== EventLogType.VF_TRANSCRIPTS),
          ...logs.map((log) => ({
            ...log,
            timestamp: log.timestamp || Date.now(),
          })),
        ]
      : [
          ...eventLogsRef.current,
          ...logs.map((log) => ({
            ...log,
            timestamp: log.timestamp || Date.now(),
          })),
        ];
    eventLogsRef.current = newLogs;
  };

  const getEventLogs = () => eventLogsRef.current;

  const clearEventLogs = () => {
    eventLogsRef.current = [];
  };

  return (
    <AppContext.Provider
      value={{
        step,
        setStep,
        openVideoModal,
        setOpenVideoModal,
        openPatientCardModal,
        setOpenPatientCardModal,
        setUserVfState,
        userVfState,
        isBioComponentReady,
        setIsBioComponentReady,
        isMuted,
        setIsMuted,
        isBioCalibrated,
        setIsBioCalibrated,
        addEventLog,
        getEventLogs,
        clearEventLogs,
        isExitBioMode,
        setIsExitBioMode,
        isDailyEnabled,
        setIsDailyEnabled,
        isVideoReady,
        setVideoReady,
        isMessageAnimationCompleted,
        setIsMessageAnimationCompleted,
        showFixedMedia,
        setShowFixedMedia,
        videoUrl,
        setVideoUrl,
        isRTL,
        setIsRTL,
        frameUrl,
        setFrameUrl,
        patientToMeetId,
        setPatientToMeetId,
        microphoneId,
        setMicrophoneId,
        cameraId,
        setCameraId,
        direction,
        setDirection,
        isLogsSending,
        setIsLogsSending,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
