import InfoRow from '@shared/ui/caption/InfoRow';

import { getMeasurementStateValue } from '@features/Plot/model/utils/getMeasurementStateValue';
import { getFaceStateValue } from '@features/Plot/model/utils/getFaceStateValue';
import ToggleableTabs from '@features/Tab/ToggleableTabs/ToggleableTabs';

import { ShamefulAny } from '@interfaces/index';
import { getTabVisibilityStateValue } from '@components/Plot/utils';
import ValueTimeAISessionPlot from '@features/Plot/ValueTimeAISessionPlot';
import { calculatePercentage, getDataForPlot } from '@components/SelfWorkReview/utils';
import TabVisibilityPlot from '@features/Plot/TabVisibilityPlot';
import { MEASUREMENT_STATE } from '@features/Plot/model/constants/states';

const SelfReviewVisionAIModel = ({
  currentDataPoint,
  dataFromMxlabs,
  currentSelfReviewTime,
}: {
  currentDataPoint: ShamefulAny;
  dataFromMxlabs: ShamefulAny;
  currentSelfReviewTime: number;
}) => {
  const sdkData = currentDataPoint;

  const measurementStateData = getDataForPlot(dataFromMxlabs, 'measurementState');

  const percentage = calculatePercentage(
    measurementStateData,
    'measurementState',
    MEASUREMENT_STATE.RUNNING_SIGNAL_GOOD
  );

  return (
    <div className="p-4 flex flex-col gap-4">
      <h2 className="text-xl font-bold mb-4">Vision AI Model</h2>

      <ToggleableTabs
        tabs={[
          {
            label: 'MxLab Outputs',
            content: (
              <>
                <div className="flex flex-col gap-4  w-full">
                  <InfoRow
                    key="face-state"
                    label={'Face State:'}
                    value={
                      <div>
                        {sdkData?.faceState !== undefined && sdkData.faceState !== null
                          ? `${sdkData.faceState} (${getFaceStateValue(sdkData.faceState)})`
                          : 'N/A'}
                      </div>
                    }
                  />

                  <ValueTimeAISessionPlot
                    key="faceState-plot"
                    currentSelfReviewTime={currentSelfReviewTime}
                    dataForPlot={getDataForPlot(dataFromMxlabs, 'faceState')}
                    plotHeight={120}
                  />
                </div>
                <div className="flex flex-col gap-4 w-full ">
                  <InfoRow
                    key="measurement-state"
                    label={'Measurement State:'}
                    value={
                      <div>
                        {sdkData?.measurementState !== undefined &&
                        sdkData.measurementState !== null
                          ? `${sdkData.measurementState} (${getMeasurementStateValue(sdkData.measurementState)})`
                          : 'N/A'}
                      </div>
                    }
                  />
                  <InfoRow
                    key="measurement-state-percentage"
                    label={'The signal was good:'}
                    value={<div>{percentage} % of total time</div>}
                  />
                  <div className="h-[150px]">
                    <ValueTimeAISessionPlot
                      key="measurementState-plot"
                      currentSelfReviewTime={currentSelfReviewTime}
                      dataForPlot={measurementStateData}
                      plotHeight={120}
                    />
                  </div>
                </div>

                <div className="flex flex-col gap-4 w-full">
                  <InfoRow
                    key="tab-visibility"
                    label={'Tab Visibility:'}
                    value={
                      <div>{`${sdkData?.visibilityState} (${getTabVisibilityStateValue(sdkData?.visibilityState!)})`}</div>
                    }
                  />

                  <TabVisibilityPlot
                    tabVisibilityValue={sdkData?.visibilityState!}
                    sdkData={sdkData}
                  />
                </div>

                <InfoRow
                  key="face-bbox"
                  label={'Face bbox:'}
                  value={
                    <span className="text-xs">
                      {sdkData?.bbox
                        ? `[x: ${sdkData.bbox.x.toFixed(2)}, y: ${sdkData.bbox.y.toFixed(2)}, w: ${sdkData.bbox.width.toFixed(2)}, h: ${sdkData.bbox.height.toFixed(2)}]`
                        : 'N/A'}
                    </span>
                  }
                />
                <InfoRow
                  key="bad-signal"
                  label={'Bad signal:'}
                  value={
                    <span>
                      {sdkData?.badSignal !== undefined && sdkData.badSignal !== null
                        ? sdkData.badSignal.toFixed(2)
                        : 'N/A'}
                    </span>
                  }
                />
                <InfoRow
                  key="face-direction"
                  label={'Face Direction:'}
                  value={
                    <div className="flex flex-col items-end">
                      <span className="text-xs">
                        {sdkData?.facePose
                          ? `(x: ${sdkData.facePose.position.x.toFixed(2)}, y: ${sdkData.facePose.position.y.toFixed(2)}, z: ${sdkData.facePose.position.z.toFixed(2)})`
                          : 'N/A'}
                      </span>
                      <span className="text-xs">
                        {sdkData?.facePose
                          ? `(pitch: ${sdkData.facePose.rotation.pitch.toFixed(2)}, roll: ${sdkData.facePose.rotation.roll.toFixed(2)}, yaw: ${sdkData.facePose.rotation.yaw.toFixed(2)})`
                          : 'N/A'}
                      </span>
                    </div>
                  }
                />
              </>
            ),
          },
        ]}
        key={'rtOutput'}
        titleFont="font-bold text-[13px]"
      />

      <ToggleableTabs
        tabs={[
          {
            label: 'Realtime measurements:',
            content: (
              <>
                {' '}
                <InfoRow
                  key="heart-rate-4s"
                  label="Heart Rate (4 seconds)"
                  value={
                    sdkData?.hr4s !== undefined && sdkData.hr4s !== null
                      ? `${sdkData.hr4s} bpm`
                      : 'N/A'
                  }
                />
                <InfoRow
                  key="heart-rate-10s"
                  label="Heart Rate (10 seconds)"
                  value={
                    sdkData?.hr10s !== undefined && sdkData.hr10s !== null
                      ? `${sdkData.hr10s} bpm`
                      : 'N/A'
                  }
                />
                <InfoRow
                  key="hrv-sdnn"
                  label="HRV SDNN (real-time)"
                  value={
                    sdkData?.hrvSDNN !== undefined && sdkData.hrvSDNN !== null
                      ? `${sdkData.hrvSDNN} ms`
                      : 'N/A'
                  }
                />
                <InfoRow
                  key="cardiac-stress"
                  label="Cardiac stress"
                  value={
                    sdkData?.cardiacStressRT !== undefined && sdkData.cardiacStress !== null
                      ? sdkData.cardiacStress
                      : 'N/A'
                  }
                />
              </>
            ),
          },
        ]}
        key={'selfReviewMeasurements'}
        titleFont="font-bold text-[13px]"
      />
    </div>
  );
};
export default SelfReviewVisionAIModel;
