import { ValueOf } from '@utils/types';
import { PLOT_CATEGORY } from './model/constants/categories';
import { USER_ROLES } from '@interfaces/user';

export interface ITimelineRange {
  startTimestamp: number;
  endTimestamp: number;
}

export interface IPlotPayloadData {
  timestamp: number;
  key: string;
  role: ValueOf<typeof USER_ROLES>;
  name: string;
  value: number;
  isReliable?: boolean;
  description?: string;
}

export enum PlotAxisType {
  EMOTIONS = 'Emotions',
  HR = 'Heartrate',
  SIGNAL_QUALITY = 'Signal Quality',
  FACE_STATE = 'Face State',
  FACIAL_EXPRESSIONS = 'Facial Expressions',
  MEASUREMENT_STATE = 'Measurement State',
  EVENTS = 'Events',
}

export const emotionColorMap = {
  Top: '#050d07',
  Admiration: '#EC7C26',
  Adoration: '#9D9101',
  'Aesthetic Appreciation': '#8A9597',
  Amusement: '#AAFA2D',
  Anger: '#642424',
  Anxiety: '#1F3A3D',
  Awe: '#95208B',
  Awkwardness: '#FEB52C',
  Boredom: '#308446',
  Calmness: '#B50004',
  Concentration: '#FFA420',
  Contemplation: '#B44C43',
  Confusion: '#6C7059',
  Contempt: '#3B83BD',
  Contentment: '#E55137',
  Craving: '#E5BE01',
  Determination: '#1C1C1C',
  Disappointment: '#CF3476',
  Disgust: '#D722F0',
  Distress: '#FFFF00',
  Doubt: '#9D9101',
  Ecstasy: '#763C28',
  Embarrassment: '#F80000',
  'Empathic Pain': '#299A6F',
  Entrancement: '#0A52DC',
  Envy: '#1DE1DA',
  Excitement: '#0FD537',
  Fear: '#343E40',
  Guilt: '#DDCB0B',
  Horror: '#165FF2',
  Interest: '#F8744B',
  Joy: '#EA899A',
  Love: '#D60CAA',
  Nostalgia: '#B32428',
  Pain: '#C6A664',
  Pride: '#25221B',
  Realization: '#3D642D',
  Relief: '#999950',
  Romance: '#E55137',
  Sadness: '#162DF0',
  Satisfaction: '#FE7FE9',
  Desire: '#6C7156',
  Shame: '#E3016F',
  'Surprise (negative)': '#09AB20',
  'Surprise (positive)': '#0A52DC',
  Sympathy: '#E7DF67',
  Tiredness: '#318E96',
  Triumph: '#FF2301',
};

export const emotionsList = Object.keys(emotionColorMap);

export const signalColorMap = {
  hr4s: '#9333EA',
  hr10s: '#2563EB',
  // TODO: delete when color scheme provided
  // therapistHr: '#9333EA',
  // patientHr: '#2563EB',
  hrvSDNN: '#BD1C11',
  hrvlnRMSSD: '#E84135',
  cardiacStress: '#781008',
  faceState: '#D43C97',
  measurementState: '#D9AD34',
  signalQuality: '#EC4919',
};

export const signalsList = Object.keys(signalColorMap);

export enum EventsNamesEnum {
  TABS_FOCUS = 'Tab focus',
  COMPONENT_ACT = 'Component activity',
  BLOCK_ACT = 'Block activity',
  CUSTOM_ACT = 'Custom actions',
  USER_INPUT = 'User input',
  SYSTEM_OUTPUT = 'System output',
  SYSTEM_THOUGHTS = 'System thoughts',
  API_CALL = 'API call',
  HISTORY_AGENT = 'History agent',
  CALIBRATION_AGENT = 'Calibration agent',
  SUGGESTION_AGENT = 'Suggestion agent',
  CONTEXT_AGENT = 'Context agent',
  INTERPRETATION_AGENT = 'Interpretation agent',
  ACTION_DETECTION_AGENT = 'Action detection agent',
  AROUSAL_DETECTION_AGENT = 'Arousal detection agent',
}
export const eventsColorMap = {
  [EventsNamesEnum.TABS_FOCUS]: '#fbceb1',
  [EventsNamesEnum.COMPONENT_ACT]: '#516110',
  [EventsNamesEnum.BLOCK_ACT]: '#8c860e',
  [EventsNamesEnum.CUSTOM_ACT]: '#a18d0d',
  [EventsNamesEnum.USER_INPUT]: '#c7a20e',
  [EventsNamesEnum.SYSTEM_OUTPUT]: '#9dc183',
  [EventsNamesEnum.SYSTEM_THOUGHTS]: '#ffd88e',
  [EventsNamesEnum.API_CALL]: '#c0b6bb',
  [EventsNamesEnum.HISTORY_AGENT]: 'navy',
  [EventsNamesEnum.CALIBRATION_AGENT]: 'red',
  [EventsNamesEnum.SUGGESTION_AGENT]: 'green',
  [EventsNamesEnum.CONTEXT_AGENT]: '#4c83c3',
  [EventsNamesEnum.INTERPRETATION_AGENT]: '#5c749b',
  [EventsNamesEnum.ACTION_DETECTION_AGENT]: 'darkred',
  [EventsNamesEnum.AROUSAL_DETECTION_AGENT]: 'darkgreen',
};

export const eventsList = Object.keys(eventsColorMap);

export const categoryToDomainMap = {
  [PlotAxisType.EMOTIONS]: [0, 1],
  [PlotAxisType.EVENTS]: [0, 1.5],
  [PlotAxisType.HR]: [50, 200],
  [PlotAxisType.SIGNAL_QUALITY]: [-10, 10],
  [PlotAxisType.FACE_STATE]: [0, 5],
};

export interface IPlotPayload {
  data: IPlotPayloadData[];
  type: ValueOf<typeof PLOT_CATEGORY>;
  domain?: [number, number];
  label: string[];
}

export enum PlotSizeEnum {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
}
export const PlotSizeMap = {
  [PlotSizeEnum.SMALL]: 60,
  [PlotSizeEnum.MEDIUM]: 130,
  [PlotSizeEnum.LARGE]: 300,
};
export enum PlotTypeEnum {
  DEFAULT = 'default',
  DOTS = 'dots',
}
export interface IPlotConfig {
  plotId: number;
  plotSize: PlotSizeEnum;
}

export const enum VisibilityState {
  NOT_VISIBLE = 0,
  VISIBLE,
}

export const enum FaceState {
  OK = 0,
  TOO_FAR,
  TOO_CLOSE,
  NOT_CENTERED,
  NOT_VISIBLE,
  UNKNOWN,
}

export interface IGetFramesResponseBodyFrame {
  timestamp: number;
  link: string;
}

export interface IGetFramesResponseBody {
  frames: IGetFramesResponseBodyFrame[];
}

export interface IFrame {
  timestamp: number;
  link: string;
}

export interface Transcript {
  _id: string;
  sessionID: string;
  name: string;
  image: string;
  createdAt: string;
}

export interface WithFiles {
  files: Array<File>;
}
export interface File {
  name: string;
  changed_at: string;
}

export type TranscriptWithFiles = Transcript & WithFiles;

export type FramesType = {
  frames: IFrame[];
  currentFrameLink: string;
};
