import React, { useEffect, useState, useRef, useContext } from 'react';

import { refaelaTherapistActor } from '@components/xState/machines/refaelaTherapistMachine';
import { saveUpdatedKeyMoments } from '@api/userAPI';

import { useSelector } from '@xstate/react';
import TherapistWrapUpStepper from './TherapistWrapUpStepper';
import { formatTime } from '@utils/keyMoment';

import Plot from '@components/Plot';
import { useMXLabsLogs } from '@components/Plot/utils';
import { ITimelineRange, PlotSizeEnum } from '@components/Plot/types';
import { AppContext } from '../../../contextApp';
import { Loader } from '@shared/ui/loader/Loader';
import { fetchCurrentUserDetails } from '@api/user/me';
import { trimByVideoDuration } from '@utils/HRGraph/trimByVideoDuration';
import { ProcessedMXLabsData } from '@shared/types/mxlabs/logs';
import { USER_ROLES } from '@interfaces/user';
import { KeyMoment, KeyMomentsData } from '@components/SessionComponents/KeyMoments/types';
import { ShamefulAny } from '@interfaces/index';

import { PageTitle } from '@shared/ui/caption/PageTitle';

type SessionWrapupProps = {
  keyMomentData: KeyMomentsData | null;
  updatedKeyMoments: KeyMomentsData | null;

  loadingState: Record<
    string,
    { isLoading: boolean; isFailed: boolean | null; error: string | null; data: ShamefulAny }
  >;
  setUpdatedKeymoments:
    | React.Dispatch<React.SetStateAction<KeyMomentsData | undefined>>
    | ShamefulAny;
};

const SessionWrapup = ({
  keyMomentData,
  updatedKeyMoments,
  loadingState,
  setUpdatedKeymoments,
}: SessionWrapupProps) => {
  const stateContext = useSelector(refaelaTherapistActor, (state) => state.context);

  const patientId = stateContext.currentPatientId;
  const therapistId = stateContext.currentUser?.therapistId;
  // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
  const sessionId = stateContext?.roomUrl?.split('/').pop()!;

  const [videoDuration, setVideoDuration] = useState<number | null>(null);
  const [currentTime, setCurrentTime] = useState<number>(0);
  const [trimmedMxLabs, setTrimmedMxLabs] = useState<Record<string, ProcessedMXLabsData[]> | null>(
    null
  );

  const [sessionDuration, setSessionDuration] = useState<number | null>(null); // for case when there is no video only frames
  const videoRef = useRef<HTMLVideoElement>(null);

  const [recordingTimeFrame, setRecordingTimeFrame] = useState<{
    recordingStartTime: number | undefined;
    recordingStopTime: number | undefined;
  }>({
    recordingStartTime: undefined,
    recordingStopTime: undefined,
  });

  const [timelineRange, setTimelineRange] = useState<ITimelineRange>({
    startTimestamp: 0,
    endTimestamp: 0,
  });

  const { step, setStep } = useContext(AppContext);

  const {
    recording: recordingState,
    transcripts: transcriptsState,
    general: generalState,
    frames: framesState,
  } = loadingState;

  useEffect(() => {
    const { recordingStartTime, recordingStopTime } = recordingTimeFrame;
    if (!recordingStopTime || !recordingStartTime) return;

    setSessionDuration(recordingStopTime - recordingStartTime);
  }, [recordingTimeFrame]);
  useEffect(() => {
    setStep(0);
  }, []);

  useEffect(() => {
    if (!updatedKeyMoments) return;
    const { recordingStartTime, recordingStopTime } = updatedKeyMoments;

    setRecordingTimeFrame({
      recordingStartTime: new Date(recordingStartTime).getTime(),
      recordingStopTime: new Date(recordingStopTime).getTime(),
    });
  }, [updatedKeyMoments]);

  useEffect(() => {
    if (!updatedKeyMoments || !patientId || !therapistId || !sessionId) return; // WARN can be the cause of multiple calls

    saveUpdatedKeyMoments(updatedKeyMoments, patientId, therapistId, sessionId);
  }, [updatedKeyMoments, patientId, therapistId, sessionId]);

  const handleTimeUpdate = (currentTimeMillis: number) => {
    setCurrentTime(currentTimeMillis);
  };

  const handleUserUpdateOnFinishSessionReview = async () => {
    if (therapistId) {
      try {
        const userId = therapistId.includes('_T') ? therapistId.replace('_T', '') : therapistId;
        const newUserData = await fetchCurrentUserDetails(userId);
        refaelaTherapistActor.send({ type: 'therapistUser.update', currentUser: newUserData });
      } catch (e) {
        console.error('update error for', therapistId);
      }
    }
  };

  const handleTimelineClick = (e: React.MouseEvent<HTMLDivElement>) => {
    const durationToUse = videoDuration || sessionDuration;
    if (durationToUse) {
      const timelineRect = e.currentTarget.getBoundingClientRect();
      const clickPositionX = e.clientX - timelineRect.left;
      const clickPositionPercent = clickPositionX / timelineRect.width;
      const newTimeInSeconds = clickPositionPercent * (durationToUse / 1000);

      if (videoRef.current) {
        videoRef.current.currentTime = newTimeInSeconds;
      }

      handleTimeUpdate(newTimeInSeconds * 1000);
    }
  };
  const { logs: mxlabs, loading: mxlabsloading } = useMXLabsLogs(
    therapistId!,
    patientId!,
    sessionId!
  );
  useEffect(() => {
    const areMxLabsReceived =
      mxlabs && Object.values(mxlabs).every((logs: ProcessedMXLabsData[]) => logs.length);

    if (
      areMxLabsReceived &&
      recordingTimeFrame.recordingStartTime &&
      recordingTimeFrame.recordingStopTime
    ) {
      const {
        startTimestamp,
        endTimestamp,
        [USER_ROLES.THERAPIST]: therapistTrimmedData,
        [USER_ROLES.PATIENT]: patientTrimmedData,
      } = Object.keys(mxlabs).reduce(
        (trimmedAcc: Record<string, ProcessedMXLabsData[] | number>, role: string) => {
          const { startTimestamp, endTimestamp, trimmedData } = trimByVideoDuration(
            mxlabs[role],
            recordingTimeFrame.recordingStartTime,
            recordingTimeFrame.recordingStopTime
          );
          if (!trimmedAcc.startTimestamp || !trimmedAcc.endTimestamp) {
            trimmedAcc = {
              startTimestamp,
              endTimestamp,
            };
          }
          trimmedAcc[role] = trimmedData;

          return trimmedAcc;
        },
        {}
      );

      setTrimmedMxLabs({
        [USER_ROLES.THERAPIST]: therapistTrimmedData as ProcessedMXLabsData[],
        [USER_ROLES.PATIENT]: patientTrimmedData as ProcessedMXLabsData[],
      });

      setTimelineRange({
        startTimestamp: startTimestamp as number,
        endTimestamp: endTimestamp as number,
      });
    }
  }, [mxlabs, sessionId, recordingTimeFrame]);

  if (mxlabsloading) {
    return generalState.isLoading || mxlabsloading ? (
      <div className="flex flex-col items-center justify-center w-full h-full">
        <div>
          <Loader
            label="Data processing..."
            className="ml-4"
          />
        </div>
      </div>
    ) : (
      <div className="flex items-center justify-center w-full h-full">Please try again later.</div>
    );
  }

  if (!sessionId) {
    return (
      <div className="flex w-full h-full justify-center items-center">
        No session id was found. Can't proceed data processing
      </div>
    );
  }

  const { recordingStartTime, recordingStopTime } = recordingTimeFrame;

  return (
    <div className="h-full w-full flex flex-col gap-4 md_d:gap-10 mt-[20px] relative">
      <div className="flex items-center justify-between">
        <PageTitle>Review, Reflect, and Assign</PageTitle>
      </div>
      <div className="flex h-full  justify-between">
        {keyMomentData && updatedKeyMoments && (
          <div className="w-full h-fit">
            <TherapistWrapUpStepper
              keyMomentsData={keyMomentData}
              setVideoDuration={setVideoDuration}
              onTimeUpdate={handleTimeUpdate}
              currentVideoTime={currentTime}
              videoRef={videoRef}
              updatedKeyMoments={updatedKeyMoments}
              setUpdatedKeymoments={setUpdatedKeymoments}
              sessionTranscripts={transcriptsState?.data?.transcript}
              recordingState={recordingState}
              transcriptsState={transcriptsState}
              framesState={framesState}
              onFinishSession={handleUserUpdateOnFinishSessionReview}
              framesData={framesState.data}
              recordingTimeFrame={recordingTimeFrame}
            />
          </div>
        )}
      </div>
      <div className="mt-6 md_d:mt-auto">
        {step === 0 && (
          <div className="w-full">
            {trimmedMxLabs && (
              <Plot
                plotConfig={{ plotId: 1, plotSize: PlotSizeEnum.MEDIUM }}
                signalsData={trimmedMxLabs}
                timelineRange={timelineRange}
                isHrFilter={false}
              />
            )}
            {recordingStartTime && updatedKeyMoments && (
              <div
                className="ml-7 mr-[1.6rem] mt-8"
                onClick={handleTimelineClick}
              >
                <div className="relative h-3 bg-[#ccced0] rounded-xl">
                  {updatedKeyMoments?.keyMoments.map((moment: KeyMoment, index: number) => {
                    const durationToUse = videoDuration || sessionDuration;
                    const leftPercent = durationToUse
                      ? `${(moment.timeInMillis / durationToUse) * 100}%`
                      : '0%';

                    return (
                      <div
                        key={index}
                        className={`absolute transform -translate-x-1/2 -translate-y-1/2 cursor-pointer ${
                          moment.approvalStatus === 'approved'
                            ? 'w-[18px] h-[18px] bg-purple-600 hover:shadow-[0_0_6px_2px_rgba(128,0,128,0.2)]'
                            : 'w-4 h-4 bg-gray-600 hover:shadow-[0_0_6px_2px_rgba(75,75,75,0.2)]'
                        } rounded-full`}
                        style={{ left: leftPercent, top: '50%' }}
                      ></div>
                    );
                  })}

                  <div
                    className="absolute h-4 bg-purple-600"
                    style={{
                      left:
                        videoDuration || sessionDuration
                          ? `${(currentTime / (videoDuration ?? sessionDuration ?? 1)) * 100}%`
                          : '0%',
                      width: '2px',
                      top: '50%',
                      transform: 'translateY(-50%)',
                    }}
                  />
                </div>

                <div className="flex w-full justify-between mt-3">
                  {recordingStartTime && (
                    <div className="w-[34px] h-[18px] text-gray-800 text-sm font-normal font-['Inter'] leading-tight tracking-tight">
                      {formatTime(currentTime + recordingStartTime)}
                    </div>
                  )}
                  {recordingStopTime && (
                    <div className="w-[39.74px] h-[18px] text-gray-800 text-sm font-normal font-['Inter'] leading-tight tracking-tight">
                      {formatTime(recordingStopTime)}
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default SessionWrapup;
