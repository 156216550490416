import { assignHomework } from '@api/userAPI';
import Button from '@components/Button';
import { EditPromptForm } from '@components/Forms/EditPrompt/EditPromptForm';
import { refaelaTherapistActor } from '@components/xState/machines/refaelaTherapistMachine';
import { Modal } from '@features/Modal/ui/Modal';
import { useSelector } from '@xstate/react';
import React, { useContext, useState } from 'react';
import InvitePatient from '../InvitePatientStep';
import Switch from '@shared/ui/switch/Switch';
import { AppContext } from '../../../contextApp';

const AssignHomework: React.FC = () => {
  const snapshot: any = useSelector(refaelaTherapistActor, (state) => state);
  const patientId = snapshot.context.currentPatientId;
  const [homeworkType, setHomeworkType] = useState('bio_feedback_session');
  const { patientToMeetId } = useContext(AppContext);

  const [homeworksCount, setHomeworksCount] = useState(1);
  const [notificationDays, setNotificationDays] = useState(1);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isAssignSuccessfull, setIsAssignSuccessfull] = useState(false);
  const [isAssignInProgress, setIsAssignInProgress] = useState(false);

  const [homeworkAnnotation, setHomeworkAnnotation] = useState({
    memoryTopic: '',
    followUpTopic: '',
    bioFeedbackTopic: '',
    memoryThingsToAvoid: '',
    followUpThingsToAvoid: '',
    bioFeedbackThingsToAvoid: '',
    followUpThingsToNote: '',
    bioFeedbackThingsToNote: '',
  });

  const [isDataCollectionSubmitted, setIsDataCollectionSubmitted] = useState(false);
  const [isIncludeTranscriptSubmitted, setIsIncludeTranscriptSubmitted] = useState(false);

  // Handles radio button selection
  const handleRadioChange = (event: any) => {
    setHomeworkType(event.target.value);
    setHomeworkAnnotation({
      memoryTopic: '',
      followUpTopic: '',
      bioFeedbackTopic: '',
      memoryThingsToAvoid: '',
      followUpThingsToAvoid: '',
      bioFeedbackThingsToAvoid: '',
      followUpThingsToNote: '',
      bioFeedbackThingsToNote: '',
    });
  };

  const handleHomeworkAnnotationChange = (subject: string, value: string) => {
    setHomeworkAnnotation({ ...homeworkAnnotation, [subject]: value });
  };

  const handleDataCollectionSubmit = () => {
    setIsDataCollectionSubmitted((event) => !event);
  };

  const handleIncludeTranscriptSubmit = () => {
    setIsIncludeTranscriptSubmitted((event) => !event);
  };

  const handlePromptEditSubmit = (promptData: any) => {
    console.log('promptData: ', promptData);
  };

  const handleAssignHomeworkSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsAssignInProgress(true);
    const response = await assignHomework(
      snapshot.value == 'AssignAISession' ? patientToMeetId : patientId,
      homeworkType,
      homeworksCount
    );
    if (response) {
      setIsAssignSuccessfull(true);
      setIsAssignInProgress(false);
      setTimeout(() => {
        setIsAssignSuccessfull(false);
      }, 3000);
    }
  };

  return (
    <div className="w-full h-full">
      <p className="text-left mb-8">
        Here are the types of AI session you can assign to your patient so that they would progress
        in therapy between sessions.
      </p>
      {snapshot.value == 'AssignAISession' && (
        <InvitePatient
          setSessionId={() => {}}
          setRoomUrl={() => {}}
          onBlockStep={() => {}}
          sessionsCount={homeworksCount}
        />
      )}
      <form onSubmit={handleAssignHomeworkSubmit}>
        <div className="flex gap-4 text-left justify-between pl-12 pr-32">
          <div className="w-[50%]">
            <p className="font-bold">Select session type</p>
            <div className="my-4">
              <label>
                <input
                  type="radio"
                  value="bio_feedback_session"
                  checked={homeworkType === 'bio_feedback_session'}
                  onChange={handleRadioChange}
                />
                <span className="ml-4">Bio feedback session (coming soon)</span>
                {homeworkType === 'bio_feedback_session' && (
                  <span
                    onClick={() => setIsEditModalOpen(true)}
                    className="ml-4 font-bold text-primary3 cursor-pointer"
                  >
                    Edit
                  </span>
                )}
              </label>
              {homeworkType === 'bio_feedback_session' && (
                <div className="ml-8 my-4">
                  <div className="flex flex-col my-4 gap-2">
                    <p className="font-bold">What to focus on</p>
                    <input
                      className="placeholder-gray-400 border border-gray-200 rounded-lg w-[60%]"
                      type="text"
                      value={homeworkAnnotation.bioFeedbackTopic}
                      onChange={(e) =>
                        handleHomeworkAnnotationChange('bioFeedbackTopic', e.target.value)
                      }
                      placeholder="Write a topic"
                    />
                  </div>
                  <div className="flex flex-col my-4 gap-2">
                    <p className="font-bold">Thinds to avoid during the session</p>
                    <div className="flex">
                      <textarea
                        className="placeholder-gray-400 border border-gray-200 rounded-lg w-[60%] h-20 resize-none"
                        value={homeworkAnnotation.bioFeedbackThingsToAvoid}
                        onChange={(e) =>
                          handleHomeworkAnnotationChange('bioFeedbackThingsToAvoid', e.target.value)
                        }
                        placeholder="Things to avoid"
                      />
                    </div>
                  </div>
                  <div className="flex flex-col my-4 gap-2">
                    <p className="font-bold">Thinds to note during the session</p>
                    <textarea
                      className="placeholder-gray-400 border border-gray-200 rounded-lg w-[60%] h-20 resize-none"
                      value={homeworkAnnotation.bioFeedbackThingsToNote}
                      onChange={(e) =>
                        handleHomeworkAnnotationChange('bioFeedbackThingsToNote', e.target.value)
                      }
                      placeholder="Things to note"
                    />
                  </div>
                </div>
              )}
            </div>
            <div className="my-4">
              <label>
                <input
                  type="radio"
                  value="memory_interview"
                  checked={homeworkType === 'memory_interview'}
                  onChange={handleRadioChange}
                />
                <span className="ml-4">Memory Interview (coming soon)</span>
                {homeworkType === 'memory_interview' && (
                  <span
                    onClick={() => setIsEditModalOpen(true)}
                    className="ml-4 font-bold text-primary3 cursor-pointer"
                  >
                    Edit
                  </span>
                )}
              </label>
              {homeworkType === 'memory_interview' && (
                <div className="ml-8 my-4 gap-4">
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                    incididunt ut labore et dolore magna aliqua.
                  </p>
                  <div className="flex flex-col my-4 gap-2">
                    <p className="font-bold">Topic of the Memory</p>
                    <input
                      className="placeholder-gray-400 border border-gray-200 rounded-lg w-[60%]"
                      type="text"
                      value={homeworkAnnotation.memoryTopic}
                      onChange={(e) =>
                        handleHomeworkAnnotationChange('memoryTopic', e.target.value)
                      }
                      placeholder="Write a topic"
                    />
                    <label className="flex items-center">
                      <input
                        type="checkbox"
                        id="personalDataCollection"
                        name="personalDataCollection"
                        checked={isDataCollectionSubmitted}
                        onChange={handleDataCollectionSubmit}
                      />
                      <span className="ml-4">Enable personal data collection</span>
                    </label>
                  </div>
                  <div className="flex flex-col my-4 gap-2">
                    <p className="font-bold">Things to avoid during the session</p>
                    <textarea
                      className="placeholder-gray-400 border border-gray-200 rounded-lg w-[60%] h-20 resize-none"
                      value={homeworkAnnotation.memoryThingsToAvoid}
                      onChange={(e) =>
                        handleHomeworkAnnotationChange('memoryThingsToAvoid', e.target.value)
                      }
                      placeholder="Things to avoid"
                    />
                  </div>
                </div>
              )}
            </div>
            <div className="my-4">
              <label>
                <input
                  type="radio"
                  value="follow_up_conversation"
                  checked={homeworkType === 'follow_up_conversation'}
                  onChange={handleRadioChange}
                />
                <span className="ml-4">Follow up Conversation (coming soon)</span>
                {homeworkType === 'follow_up_conversation' && (
                  <span
                    onClick={() => setIsEditModalOpen(true)}
                    className="ml-4 font-bold text-primary3 cursor-pointer"
                  >
                    Edit
                  </span>
                )}
              </label>
              {homeworkType === 'follow_up_conversation' && (
                <div className="ml-8 my-4">
                  <div className="flex flex-col my-4 gap-2">
                    <p className="font-bold">What to focus on</p>
                    <input
                      className="placeholder-gray-400 border border-gray-200 rounded-lg w-[60%]"
                      type="text"
                      value={homeworkAnnotation.followUpTopic}
                      onChange={(e) =>
                        handleHomeworkAnnotationChange('followUpTopic', e.target.value)
                      }
                      placeholder="Write a topic"
                    />
                    <label className="flex items-center">
                      <input
                        type="checkbox"
                        id="includeTranscript"
                        name="includeTranscript"
                        checked={isIncludeTranscriptSubmitted}
                        onChange={handleIncludeTranscriptSubmit}
                      />
                      <span className="ml-4">
                        Include previous session transcripts in the context
                      </span>
                    </label>
                  </div>
                  <div className="flex flex-col my-4 gap-2">
                    <p className="font-bold">Thinds to avoid during the session</p>
                    <textarea
                      className="placeholder-gray-400 border border-gray-200 rounded-lg w-[60%] h-20 resize-none"
                      value={homeworkAnnotation.followUpThingsToAvoid}
                      onChange={(e) =>
                        handleHomeworkAnnotationChange('followUpThingsToAvoid', e.target.value)
                      }
                      placeholder="Things to avoid"
                    />
                  </div>
                  <div className="flex flex-col my-4 gap-2">
                    <p className="font-bold">Thinds to note during the session</p>
                    <textarea
                      className="placeholder-gray-400 border border-gray-200 rounded-lg w-[60%] h-20 resize-none"
                      value={homeworkAnnotation.followUpThingsToNote}
                      onChange={(e) =>
                        handleHomeworkAnnotationChange('followUpThingsToNote', e.target.value)
                      }
                      placeholder="Things to note"
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="flex flex-col gap-4 w-[25%]">
            <div>
              <div className="font-bold">Number of sessions</div>
              <div>
                <select
                  className="border border-gray-200 rounded-lg"
                  id="sessionCountDropdown"
                  value={homeworksCount}
                  onChange={(e) => setHomeworksCount(Number(e.target.value))}
                >
                  {[1, 2, 3, 4, 5].map((num) => (
                    <option
                      key={num}
                      value={num}
                    >
                      {num}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div>
              <div className="font-bold">Session Language</div>
              <Switch
                label="Hebrew"
                checked={false}
                onChange={() => {}}
                containerClasses="justify-between w-full"
                disabled={false}
              />
            </div>
            <div>
              <div className="font-bold">Notify about homework every day</div>
              <div>
                <span>For next</span>
                <select
                  className="border border-gray-200 rounded-lg mx-2"
                  id="notificationDaysDropdown"
                  value={notificationDays}
                  onChange={(e) => setNotificationDays(Number(e.target.value))}
                >
                  {[1, 2, 3, 4, 5].map((num) => (
                    <option
                      key={num}
                      value={num}
                    >
                      {num}
                    </option>
                  ))}
                </select>
                <span>days</span>
              </div>
            </div>
            <Button
              className="w-[60%]"
              type="submit"
              text={isAssignInProgress ? 'Assigning Homework...' : 'Assign Homework'}
              disabled={!patientToMeetId || isAssignInProgress}
            />
            {isAssignSuccessfull && (
              <p className="overflow-auto">Session has been assigned successfully</p>
            )}
          </div>
        </div>
      </form>
      <Modal
        isOpen={isEditModalOpen}
        title="Edit main prompt"
        onCancel={() => setIsEditModalOpen(false)}
      >
        {isEditModalOpen && (
          <EditPromptForm
            onCancel={() => setIsEditModalOpen(false)}
            onEdit={({ newPatientData }) => handlePromptEditSubmit(newPatientData)}
            error={''}
          />
        )}
      </Modal>
    </div>
  );
};

export default AssignHomework;
