import {
  useState,
  useRef,
  useEffect,
  ReactNode,
  Children,
  isValidElement,
  cloneElement,
  ReactElement,
  useLayoutEffect,
} from 'react';
import clsx from 'clsx';
import './styles.css';
import { findTextRecursively } from './model/utils/findOptionText';
import { ChevronDown, Search } from 'lucide-react';
import { TextInputController } from '@components/Forms/ui/TextInputController';
import useClickOutside from '@hooks/useClickOutside';

type SelectProps = {
  isOpen?: boolean;
  value?: any;
  label?: string;
  placeholder?: string;
  dropdownEmptyListPlaceholder?: string;
  onChange?: ({ value, label }: { value: string; label: string }) => void;
  renderValue?: (placeholder: string) => ReactNode;
  selectClass?: string;
  selectLabelClass?: string;
  withSearch?: boolean;
  selectOptionClass?: string;
  selectDropdownClass?: string;
  selectedOption?: { value: string; label: string };
  children?: ReactNode;
  disabled?: boolean; // Added disabled property
};

const SimpleSelect = ({
  isOpen = false,
  value,
  label,
  onChange,
  placeholder = 'Select option',
  dropdownEmptyListPlaceholder = 'No options found',
  renderValue,
  selectClass = '',
  selectLabelClass = '',
  withSearch = true,
  selectOptionClass = '',
  selectDropdownClass = '',
  selectedOption,
  children,
  disabled = false, // Default to false if not provided
}: SelectProps) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(isOpen);
  const [selectedValue, setSelectedValue] = useState(value || '');
  const [isDroppingUp, setIsDroppingUp] = useState(false);
  const [searchValue, setSearchValue] = useState(value || '');
  const [selectedValueLabel, setSelectedValueLabel] = useState(value || '');
  const [position, setPosition] = useState({ left: 0, top: 0 });
  const containerRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLUListElement>(null);

  const selectDropdownDefaultClass = withSearch ? 'px-4 pb-4 space-y-4' : 'p-4 space-y-4';

  useClickOutside([containerRef], setIsDropdownOpen);

  //#region handlers
  const handleToggleDropdown = () => {
    if (!disabled) {
      setIsDropdownOpen(!isDropdownOpen);
    }
  };

  const handleSearch = ({ value }: { value: string }) => {
    setSearchValue(value);
  };

  const handleOptionClick = (value: any, label: string) => {
    setSelectedValue(value);
    setSelectedValueLabel(label);
    setIsDropdownOpen(false);
    onChange?.({ value, label });
  };
  //#endregion handlers

  //#region renders
  const RenderChild = (children: ReactNode) => {
    const childsFilteredBySearch = Children.map(children, (child) => {
      if (isValidElement(child)) {
        if (!child.props.value && child.props.value !== 0) {
          console.error(
            'Wrap select elements into <SelectOption/> and make sure you provide value'
          );
          return;
        }
        const labelToDisplay = findTextRecursively(child);

        if (labelToDisplay.toLowerCase().includes(searchValue.toLowerCase())) {
          return cloneElement(child as ReactElement, {
            onClick: () => handleOptionClick(child.props.value, labelToDisplay),
          });
        }
        return null;
      }

      return child;
    });

    return Children.count(childsFilteredBySearch) ? (
      childsFilteredBySearch
    ) : (
      <div className="flex justify-center text-gray-400">No results found</div>
    );
  };
  //#endregion renders

  //#region effects
  useEffect(() => {
    setSelectedValue(value);
  }, [value]);

  useEffect(() => {
    setIsDropdownOpen(isOpen);
  }, [isOpen]);

  useLayoutEffect(() => {
    if (isDropdownOpen) {
      const dropdown = dropdownRef.current;
      if (dropdown) {
        const dropdownRect = dropdown.getBoundingClientRect();
        const viewportHeight = window.innerHeight;

        if (dropdownRect.bottom > viewportHeight) {
          setIsDroppingUp(true);
        } else {
          setIsDroppingUp(false);
        }
      }
    }
  }, [isDropdownOpen]);

  useLayoutEffect(() => {
    if (isDropdownOpen && containerRef.current && dropdownRef.current) {
      const triggerRect = containerRef.current.getBoundingClientRect();
      const dropdownRect = dropdownRef.current.getBoundingClientRect();

      let top = triggerRect.bottom;
      let left = triggerRect.left;

      const viewportHeight = window.innerHeight;
      const viewportWidth = window.innerWidth;

      // if overlaps bottom we drop it on the top
      if (triggerRect.bottom + dropdownRect.height > viewportHeight) {
        top = triggerRect.top - dropdownRect.height;
      }

      // if it overlaps right we move it to the left
      if (triggerRect.left + dropdownRect.width > viewportWidth) {
        left = viewportWidth - dropdownRect.width - 10; // 10px padding from the edge
      }

      setPosition({ top, left });
    }
  }, [isDropdownOpen]);
  //#endregion effects

  const selectedOptionLabel =
    selectedValueLabel || selectedOption?.label || selectedValue || placeholder;

  const styles = isDroppingUp
    ? {
        bottom: `${position.top}px`,
        left: `${position.left}px`,
      }
    : {
        top: `${position.top}px`,
        left: `${position.left}px`,
      };

  return (
    <div
      ref={containerRef}
      className={`inline-block ${selectClass}`}
    >
      {label && (
        <label
          htmlFor="custom-dropdown"
          className={`block mb-1 font-semibold ${selectLabelClass}`}
        >
          {label}
        </label>
      )}
      <div
        id="custom-dropdown"
        className={`flex justify-between text-[15px] py-1 px-4 md_d:py-[14px] border border-gray-200 rounded-lg cursor-pointer dropdown-trigger ${disabled ? 'cursor-not-allowed opacity-50' : ''}`}
        onClick={handleToggleDropdown}
      >
        <div
          title={selectedOptionLabel}
          className={`flex items-center text-nowrap overflow-hidden text-ellipsis ${selectOptionClass}`}
        >
          {renderValue ? renderValue(placeholder) : selectedOptionLabel}
        </div>
        <div
          className={clsx('dropdown-icon flex items-center ml-2 mr-2', {
            open: isDropdownOpen,
          })}
        >
          <ChevronDown
            size={15}
            strokeWidth={1.5}
          />
        </div>
      </div>

      {isDropdownOpen && (
        <ul
          ref={dropdownRef}
          style={styles}
          className={`absolute z-10 bg-white border border-gray-200 rounded overflow-y-auto ${selectDropdownDefaultClass} ${selectDropdownClass}`}
        >
          {withSearch && (
            <div className="sticky top-0 mb-[-1rem] md_d:mb-0 p-4 mx-[-1rem] bg-gradient-to-b from-white from-0% via-white via-80%">
              <TextInputController
                id="search"
                type="text"
                onInputChange={handleSearch}
                StartAdornment={
                  <Search
                    size={20}
                    strokeWidth={1.5}
                  />
                }
              />
            </div>
          )}
          {children ? (
            RenderChild(children)
          ) : (
            <div className="flex justify-center text-gray-400">{dropdownEmptyListPlaceholder}</div>
          )}
        </ul>
      )}
    </div>
  );
};

export default SimpleSelect;
