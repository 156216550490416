import { Loader } from '@shared/ui/loader/Loader';
import { memo } from 'react';

type SessionVideoComponent = {
  isSDKInitialized?: boolean;
};

const SessionVideoComponent = ({ isSDKInitialized }: SessionVideoComponent) => {
  return (
    <div className="mb-4 w-full">
      <h2 className="text-xl font-bold mb-4">Session Duration 00:00</h2>
      <div className="w-full h-full rounded flex items-center justify-center ">
        <canvas
          style={{
            opacity: !isSDKInitialized ? 0 : 1,
            aspectRatio: '5/4',
            height: '100%',
            width: '100%',
            maxWidth: '600px',
            maxHeight: '300px',
          }}
          id="mxcanvas1"
        />
        {/* loader should be below canvas to prevent shenai inserBefore crush */}
        {!isSDKInitialized && (
          <Loader className="absolute flex min-w-[600px] min-h-[300px] self-center items-center justify-center" />
        )}
      </div>
    </div>
  );
};

const SessionInfo = memo(SessionVideoComponent);
SessionInfo.displayName = 'SessionInfo';

export default SessionInfo;
