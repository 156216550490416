import { useSelector } from '@xstate/react';
import NavigationButton from '../ui/NavigationButton';
import { ACTIONS, ROOT_ACTIONS } from './model/constants/actions';
import { refaelaPatientActor } from '@components/xState/machines/refaelaPatientMachine';

const PatientNavigation = () => {
  const snapshot = useSelector(refaelaPatientActor, (snapshot) => snapshot);

  const rootIcon =
    ROOT_ACTIONS[
      snapshot.value as 'PatientPretalkWaitingRoom' | 'OneOnOneVideo'
      // | 'PatientSettings'
    ] || ROOT_ACTIONS['default'];
  const actions = snapshot.matches('PatientVideoSession') ? undefined : ACTIONS;

  return (
    <div className="w-fit">
      <NavigationButton
        actions={actions}
        rootIcon={rootIcon}
      />
    </div>
  );
};

export default PatientNavigation;
