import React, { memo, useEffect, useState } from "react";
import { useActiveSpeakerId, useParticipantIds } from "@daily-co/daily-react";

import Latency from "../Latency";

import styles from "./styles.module.css";

type AgentState = "connecting" | "loading" | "connected";

export const Agent: React.FC<{
  hasStarted: boolean;
  statsAggregator: StatsAggregator;
  setInputValue: any;
  handleUserInput: StatsAggregator;
}> = memo(
  ({ hasStarted = false, statsAggregator, setInputValue, handleUserInput }) => {
    const participantIds = useParticipantIds({ filter: "remote" });
    const activeSpeakerId = useActiveSpeakerId({ ignoreLocal: true });
    const [agentState, setAgentState] = useState<AgentState>('connecting');

    useEffect(() => {
      if (participantIds.length > 0) {
        setAgentState('connected');
      } else {
        setAgentState('connecting');
      }
    }, [activeSpeakerId, participantIds.length]);

    // Cleanup
    useEffect(() => () => setAgentState('connecting'), []);

    return (
      <div className={styles.agent}>
        <footer className={styles.agentFooter}>
          <Latency
            started={agentState === "connected" && hasStarted}
            botStatus={agentState}
            statsAggregator={statsAggregator}
            handleUserInput={handleUserInput}
            setInputValue={setInputValue}
          />
        </footer>
      </div>
    );
  },
  (p, n) => p.hasStarted === n.hasStarted
);

export default Agent;
