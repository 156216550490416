import styled from '@emotion/styled';
import { BioModuleCard } from './bioModule.styled';
import { breakpoints } from '../../styled';

export const ExtraDataWrapper = styled(BioModuleCard)`
  grid-column: span 2;
  grid-row: 2;
`;
export const ExtraDataContainer = styled.div`
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  width: 100%;
`;
export const OutputValue = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  margin-left: 10px;
  align-items: center;
  justify-content: space-between;

  @media ${breakpoints.tablet} {
    text-wrap: nowrap;
    margin-left: 0px;
    justify-self: flex-end;
  }
`;

export const BioDataItemLabel = styled.div`
  font-weight: unset;
  font-size: 12px;
  color: #000;

  @media ${breakpoints.tablet} {
    text-wrap: nowrap;
  }
`;

const BioModeExtraData = ({ sdkState }: { sdkState: any }) => {
  const BioDataItem = ({ label, value }: { label: string; value: string }) => {
    return (
      <OutputValue>
        <BioDataItemLabel>{label}</BioDataItemLabel>
        <div>{sdkState?.results ? value : ''}</div>
      </OutputValue>
    );
  };

  return (
    <ExtraDataWrapper>
      <ExtraDataContainer>
        <div>
          <BioDataItem label="HRV (SDNN):" value={sdkState?.results?.hrv_sdnn_ms ? `${sdkState?.results.hrv_sdnn_ms.toFixed(0)} ms` : ''} />
          <BioDataItem label="HRV (lnRMSSD):" value={sdkState?.results?.hrv_lnrmssd_ms ? `${sdkState?.results.hrv_lnrmssd_ms.toFixed(1)}` : ''} />
        </div>

        <div>
          <BioDataItem label="HR (final):" value={sdkState?.results?.heart_rate_bpm && `${sdkState?.results.heart_rate_bpm.toFixed(0)} bpm`} />
          <BioDataItem label="BR:" value={sdkState?.results?.breathing_rate_bpm ? `${sdkState?.results.breathing_rate_bpm.toFixed(0)} bpm` : ''} />
        </div>
        <div>
          <BioDataItem
            label="Systolic BP:"
            value={sdkState?.results?.systolic_blood_pressure_mmhg ? `${sdkState?.results.systolic_blood_pressure_mmhg?.toFixed(0)} mmHg` : ''}
          />
          <BioDataItem
            label="Diastolic BP:"
            value={sdkState?.results?.diastolic_blood_pressure_mmhg ? `${sdkState?.results.diastolic_blood_pressure_mmhg?.toFixed(0)} mmHg` : ''}
          />
        </div>

        <div>
          <BioDataItem label="Stress Index:" value={sdkState?.results?.stress_index ? `${sdkState?.results.stress_index.toFixed(1)}` : ''} />
        </div>
      </ExtraDataContainer>
    </ExtraDataWrapper>
  );
};

export default BioModeExtraData;
