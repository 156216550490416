import { useEffect, useState } from 'react';
import Button from '@components/Button';
import { KeyMomentsData, Transcript } from '@interfaces/keyMoments';
import {
  getVideoSessionSummaryContent,
  getVideoSessionNotes,
  saveVideoSessionNotes,
  saveVideoSessionSummary,
  getVideoSessionSummary,
} from '@api/userAPI';
import DOMPurify from 'dompurify';
import { useSelector } from '@xstate/react';
import { refaelaTherapistActor } from '@components/xState/machines/refaelaTherapistMachine';
import { Loader } from '@shared/ui/loader/Loader';

import 'react-quill/dist/quill.snow.css';

import TextEditor from '@components/RichTextEditor/TextEditor';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ShamefulAny } from '@interfaces/index';
import { CheckIcon, RefreshCcwIcon } from 'lucide-react';

interface SummaryProps {
  sessionTranscripts?: Transcript[];
  updatedKeyMoments?: KeyMomentsData;
  setIsNotesApproved: (x: boolean) => void;
  setIsSummaryApproved: (x: boolean) => void;
  isNotesApproved: boolean;
  isSummaryApproved: boolean;
}

const Summary: React.FC<SummaryProps> = ({
  sessionTranscripts,
  updatedKeyMoments,
  setIsNotesApproved,
  setIsSummaryApproved,
  isNotesApproved,
  isSummaryApproved,
}) => {
  const [summary, setSummary] = useState('Generating a summary, please wait...');
  const [note, setNote] = useState('');
  const [isSummaryLoading, setIsSummaryLoading] = useState(false);

  const stateContext = useSelector(refaelaTherapistActor, (state) => state.context);

  const sessionId = stateContext.currentSessionId;
  const patientId = stateContext.currentPatientId;
  const therapistId = stateContext.currentUser?.therapistId;

  useEffect(() => {
    if (patientId && therapistId && sessionId) {
      const fetchData = async () => {
        try {
          const fetchedSummary = await getVideoSessionSummaryContent(
            patientId,
            therapistId,
            sessionId
          );
          const fetchedNotes = await getVideoSessionNotes(patientId, therapistId, sessionId);

          setSummary(fetchedSummary || 'No summary available');
          setNote(fetchedNotes || 'No notes available');
        } catch (error: ShamefulAny) {
          const { message, details } = error;

          console.error('Error fetching summary or notes:', message, details);
          setSummary(`Error loading summary: ${message}`);
          setNote(`Error loading notes: ${message}`);
        }
      };

      fetchData();
    }
  }, [patientId, therapistId, sessionId]);

  const handleSummaryGenerate = async () => {
    if (!patientId || !therapistId || !sessionId) return;
    if (updatedKeyMoments && sessionTranscripts) {
      const sessionSummary = await getVideoSessionSummary(
        patientId,
        therapistId,
        sessionId,
        updatedKeyMoments,
        sessionTranscripts,
        setIsSummaryLoading
      );
      setSummary(sessionSummary);
    }
    if (!updatedKeyMoments || !sessionTranscripts) {
      setSummary('No previous sessions to generate a session summary');
    }
  };

  // Sanitize HTML before saving
  const handleSaveSummary = () => {
    if (!patientId || !therapistId || !sessionId) return;

    // Sanitize the summary content before saving it
    const sanitizedSummary = DOMPurify.sanitize(summary);
    setIsSummaryApproved(true);
    saveVideoSessionSummary(patientId, therapistId, sessionId, sanitizedSummary);
  };

  const handleSaveNotes = () => {
    if (!patientId || !therapistId || !sessionId) return;

    // Sanitize the note content before saving it
    const sanitizedNote = DOMPurify.sanitize(note);
    setIsNotesApproved(true);
    saveVideoSessionNotes(patientId, therapistId, sessionId, sanitizedNote);
  };

  return (
    <div className="w-full h-full overflow-hidden flex justify-start gap-20">
      <div className="flex flex-col w-[450px] h-full items-start gap-5">
        <div className="flex w-full justify-between">
          <h2 className="text-gray-800 text-lg font-semibold">Summary</h2>
          {isSummaryApproved && (
            <div className="flex gap-2 items-center text-purple-600">
              <CheckIcon
                width={'16px'}
                height={'16px'}
              />
              <span className="text-sm font-normal font-['Inter']">Summary approved</span>
            </div>
          )}
        </div>

        <form
          className="flex flex-col w-full gap-8"
          onSubmit={(e) => e.preventDefault()}
        >
          {summary === 'no data' ? (
            <div className="relative">
              <Button
                variant="primary"
                className="py-2 px-3 w-[174px]"
                text="Generate Summary"
                onClick={handleSummaryGenerate}
                disabled={isSummaryLoading}
              />
              {isSummaryLoading && (
                <div className="absolute inset-0 bg-white bg-opacity-70 flex items-center justify-center w-[174px]">
                  <Loader
                    className="m-0 justify-between w-fit"
                    spinnerClasses="!ml-0"
                  />
                </div>
              )}
            </div>
          ) : (
            <>
              <TextEditor
                editorClasses="w-full h-[400px]  bg-white p-2"
                value={summary}
                onChange={setSummary}
                isLoading={isSummaryLoading}
              />

              <div className="flex gap-5 justify-end items-center">
                <Button
                  className="py-3 px-4"
                  variant="plainSecondary"
                  icon={
                    <RefreshCcwIcon
                      width={'24px'}
                      height={'24px'}
                    />
                  }
                  text="Re-generate summary"
                  onClick={handleSummaryGenerate}
                  disabled={isSummaryLoading}
                />
                <Button
                  className="py-2 px-3"
                  variant="primary"
                  text="Approve Summary"
                  onClick={handleSaveSummary}
                />
              </div>
            </>
          )}
        </form>
      </div>

      <div className="flex flex-col w-[450px] h-full items-start gap-5">
        <div className="flex w-full justify-between">
          <h2 className="text-gray-800 text-lg font-semibold">Notes</h2>
          {isNotesApproved && (
            <div className="flex gap-2 items-center text-purple-600">
              <CheckIcon
                width={'16px'}
                height={'16px'}
              />
              <span className="text-sm font-normal font-['Inter']">Notes approved</span>
            </div>
          )}
        </div>
        <form
          className="flex flex-col w-full gap-5"
          onSubmit={(e) => {
            e.preventDefault();
            handleSaveNotes();
          }}
        >
          <TextEditor
            editorClasses="w-full h-[300px]  bg-white p-2"
            value={note}
            onChange={setNote}
          />
          <div className="flex gap-8 justify-end">
            <Button
              variant="primary"
              className="py-2 px-3"
              text="Approve Notes"
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default Summary;
