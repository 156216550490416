import styled from '@emotion/styled';

export const CallContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: end;
  position: relative;

  @media only screen and (max-width: 600px) {
    padding: 0;
    grid-template-columns: repeat(auto-fit, minmax(290px, 1fr));
    grid-auto-rows: unset;
  }

  &.is-screenshare {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-row-gap: 15px;
  }
`;

export const InfoBox = styled.div`
  background: var(--dark-blue-border);
  color: var(--white);
  width: 480px;
  height: 270px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  box-sizing: border-box;
  border-radius: 4px;

  @media only screen and (max-width: 600px) {
    width: 240px;
    height: auto;
    padding: 2rem 1rem;
  }
`;

export const RoomUrl = styled.span`
  padding: 0 1rem;
  word-break: break-word;
  font-weight: normal;
  font-size: 12px;
`;

export const SelfView = styled.div`
  width: 240px;
  height: 135px;
`;

export const RemoteParticipant = styled.div`
  width: 240px;
  height: 135px;
`;
