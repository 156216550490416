// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ShamefulAny } from '@interfaces/index';
import { apiClient } from '.';

export const sendEmail = async (
  to: string,
  subject: string,
  text: string,
  html?: string
): Promise<void> => {
  try {
    const response = await apiClient.post('/email/send_email', {
      to,
      subject,
      text,
      html,
    });

    if (response.data.success) {
      console.log('Email sent successfully');
    } else {
      console.error('Failed to send email:', response.data.message);
    }
  } catch (error: ShamefulAny) {
    console.error('Error sending email:', error.message, error.details);
    throw new Error('Failed to send email');
  }
};
