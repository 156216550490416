import { ShamefulAny } from '@interfaces/index';

export const getDataForPlot = (filteredData: ShamefulAny[], parameter: string) => {
  return filteredData?.map((item) => {
    return {
      parameter: item[parameter],
      timestamp: item.timestamp,
      measurementState: item.measurementState,
    };
  });
};

interface DataEntry {
  timestamp: number;
  [key: string]: any;
}

// example of use: const percentage = calculatePercentage(data, 'measurementState', 3);

export const calculatePercentage = (data: DataEntry[], parameter: string, value: any): number => {
  if (!data || data.length === 0) return 0;

  data.sort((a, b) => a.timestamp - b.timestamp);

  const totalDuration = data[data.length - 1].timestamp - data[0].timestamp;

  // Calculate the duration of the specified value
  let targetDuration = 0;

  for (let i = 0; i < data.length - 1; i++) {
    const currentParamValue = data[i][parameter];
    const nextTimestamp = data[i + 1].timestamp;

    if (currentParamValue === value) {
      targetDuration += nextTimestamp - data[i].timestamp; // Add duration where parameter equals value
    }
  }

  // Calculate and return percentage
  return parseFloat(((targetDuration / totalDuration) * 100).toFixed(2));
};
