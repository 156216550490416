import Button from '@components/Button';
import { Loader } from '@shared/ui/loader/Loader';
import { CircleAlertIcon } from 'lucide-react';

type DeletePatientConfirmationProps = {
  onCancel: () => void;
  onDelete: () => void;
  isLoading?: boolean;
  error?: string | null;
};
export const DeletePatientConfirmationBlock = ({
  onCancel,
  onDelete,
  isLoading,
  error,
}: DeletePatientConfirmationProps) => {
  return (
    <div className="flex min-h-full flex-1 flex-col justify-center">
      {isLoading && (
        <div className="bg-loading-overlay h-full rounded-br-20 absolute top-0 bottom-0 left-0 right-0 z-[1]">
          <Loader spinnerClasses="absolute z-2 top-1/2 left-1/2 ml-[-0.75rem] mt-[-0.75rem]" />
        </div>
      )}
      <div>
        <p className=" text-gray-900 text-base font-normal font-['Inter'] leading-normal">
          Are you sure you want to delete this patient?
        </p>
        <div className={`text-rose-600 text-sm ${error ? 'opacity-100' : 'opacity-0'} flex gap-1`}>
          <CircleAlertIcon
            color="#dc2626"
            height="24px"
            width="24px"
          />
          <span> {error}</span>
        </div>
        <div className="flex justify-center pt-10 gap-4">
          <Button
            variant="outline"
            text="Cancel"
            onClick={onCancel}
            className="w-[50%]"
          />
          <Button
            variant="primary"
            text="Delete patient"
            onClick={onDelete}
            className="w-[50%]"
          />
        </div>
      </div>
    </div>
  );
};
